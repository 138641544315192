import { default as bridgeBRMGD9wZogMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/bridge.vue?macro=true";
import { default as sign_45inMa8EngbaOXMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upWuKxqnGrsmMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/sign-up.vue?macro=true";
import { default as authek9YNbqEqAMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth.vue?macro=true";
import { default as _91careerId_93kQ4ZOaPOC1Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/detail/[careerId].vue?macro=true";
import { default as indexlMjYjlx8PJMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/index.vue?macro=true";
import { default as search6pbHeZoauFMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/search.vue?macro=true";
import { default as surveyOTJj9bh6kFMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/survey.vue?macro=true";
import { default as _91collegeId_93pyrOnNaYOaMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/detail/[collegeId].vue?macro=true";
import { default as findUzCqhq7JtxMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/find.vue?macro=true";
import { default as result60mrgpem19Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/result.vue?macro=true";
import { default as surveydtKgXtCS7KMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/survey.vue?macro=true";
import { default as viewmorelXoiR8lDWEMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/viewmore.vue?macro=true";
import { default as collegesurvey0g5fPVLsHjMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/collegesurvey.vue?macro=true";
import { default as indexjQZIk5WkNOMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/extracurriculars/index.vue?macro=true";
import { default as extracurricularsN76nRnllmAMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/extracurriculars.vue?macro=true";
import { default as index06bSq8Mg4zMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/index.vue?macro=true";
import { default as index2eRAGc8YRiQMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/index2.vue?macro=true";
import { default as indexjR5UNGDLp6Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/find/index.vue?macro=true";
import { default as indexMpvABcetMDMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/find/researchprogram/index.vue?macro=true";
import { default as indexQ7WYhmVsc9Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/index.vue?macro=true";
import { default as indexO0hoDjCjL3Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/index.vue?macro=true";
import { default as index0LMEAZbKMNMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/program/index.vue?macro=true";
import { default as _91programId_93rC4g6LoeCgMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/program/payment/[programId].vue?macro=true";
import { default as indexBDfebuvF5lMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/experience/index.vue?macro=true";
import { default as indexfvHtPWviZxMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/index.vue?macro=true";
import { default as editjwAsRMB7X0Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/[id]/edit.vue?macro=true";
import { default as createttWZ9KuaotMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/create.vue?macro=true";
import { default as indexz4XmF7bN90Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/index.vue?macro=true";
import { default as infooFmk62yM8HMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info.vue?macro=true";
import { default as list4idpJgvI5JMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/career/list.vue?macro=true";
import { default as listyJjpx8LhRDMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/college/list.vue?macro=true";
import { default as index85kE7vXfBAMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/index.vue?macro=true";
import { default as listXEYznxdklCMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/major/list.vue?macro=true";
import { default as edito2JQ8t3RLwMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/profile/edit.vue?macro=true";
import { default as indexyTZiS05dgOMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/profile/index.vue?macro=true";
import { default as cancel_45subscriptionm8Rpf4jjTPMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/cancel-subscription.vue?macro=true";
import { default as deactivatea6VMAfJ6UiMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/deactivate.vue?macro=true";
import { default as deleteyVtLti1HbMMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/delete.vue?macro=true";
import { default as indexiAfHb2fvaKMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/index.vue?macro=true";
import { default as mypondnOaZ8qH56xMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond.vue?macro=true";
import { default as callbackqY3UbLKsMrMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/open/sign-up/cronofy/callback.vue?macro=true";
import { default as subscriptiondQOWVwLdZKMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/subscription.vue?macro=true";
import { default as subscriptionNoAuthqrNiQ8SdR6Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/subscriptionNoAuth.vue?macro=true";
import { default as viewingpassLCeDpxgkA0Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/viewingpass.vue?macro=true";
import { default as _91reportId_93lphWSWNc3nMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/analysis/[reportId].vue?macro=true";
import { default as indexmzmwTj2XDxMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/index.vue?macro=true";
import { default as index3tOfTsq6NxMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/m/index.vue?macro=true";
import { default as collegeZE1CxoUDlPMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/college.vue?macro=true";
import { default as indexxGXbUCYK1vMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/index.vue?macro=true";
import { default as majorY40weRjoRnMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/major.vue?macro=true";
import { default as welcomejRwMYg0PizMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome.vue?macro=true";
import { default as collegeAByJNgppfKMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/college.vue?macro=true";
import { default as indexfUbOomkIKtMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/index.vue?macro=true";
import { default as majorktHjGW1ahdMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/major.vue?macro=true";
import { default as roadmap5RL3DpNaWgMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap.vue?macro=true";
import { default as surveyFIiZn69zwgMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/survey.vue?macro=true";
export default [
  {
    name: authek9YNbqEqAMeta?.name ?? "auth",
    path: authek9YNbqEqAMeta?.path ?? "/auth",
    children: [
  {
    name: bridgeBRMGD9wZogMeta?.name ?? "auth-bridge",
    path: bridgeBRMGD9wZogMeta?.path ?? "bridge",
    meta: bridgeBRMGD9wZogMeta || {},
    alias: bridgeBRMGD9wZogMeta?.alias || [],
    redirect: bridgeBRMGD9wZogMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/bridge.vue").then(m => m.default || m)
  },
  {
    name: sign_45inMa8EngbaOXMeta?.name ?? "auth-sign-in",
    path: sign_45inMa8EngbaOXMeta?.path ?? "sign-in",
    meta: sign_45inMa8EngbaOXMeta || {},
    alias: sign_45inMa8EngbaOXMeta?.alias || [],
    redirect: sign_45inMa8EngbaOXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upWuKxqnGrsmMeta?.name ?? "auth-sign-up",
    path: sign_45upWuKxqnGrsmMeta?.path ?? "sign-up",
    meta: sign_45upWuKxqnGrsmMeta || {},
    alias: sign_45upWuKxqnGrsmMeta?.alias || [],
    redirect: sign_45upWuKxqnGrsmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth/sign-up.vue").then(m => m.default || m)
  }
],
    meta: authek9YNbqEqAMeta || {},
    alias: authek9YNbqEqAMeta?.alias || [],
    redirect: authek9YNbqEqAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: _91careerId_93kQ4ZOaPOC1Meta?.name ?? "career-detail-careerId",
    path: _91careerId_93kQ4ZOaPOC1Meta?.path ?? "/career/detail/:careerId()",
    meta: _91careerId_93kQ4ZOaPOC1Meta || {},
    alias: _91careerId_93kQ4ZOaPOC1Meta?.alias || [],
    redirect: _91careerId_93kQ4ZOaPOC1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/detail/[careerId].vue").then(m => m.default || m)
  },
  {
    name: indexlMjYjlx8PJMeta?.name ?? "career",
    path: indexlMjYjlx8PJMeta?.path ?? "/career",
    meta: indexlMjYjlx8PJMeta || {},
    alias: indexlMjYjlx8PJMeta?.alias || [],
    redirect: indexlMjYjlx8PJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: search6pbHeZoauFMeta?.name ?? "career-search",
    path: search6pbHeZoauFMeta?.path ?? "/career/search",
    meta: search6pbHeZoauFMeta || {},
    alias: search6pbHeZoauFMeta?.alias || [],
    redirect: search6pbHeZoauFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/search.vue").then(m => m.default || m)
  },
  {
    name: surveyOTJj9bh6kFMeta?.name ?? "career-survey",
    path: surveyOTJj9bh6kFMeta?.path ?? "/career/survey",
    meta: surveyOTJj9bh6kFMeta || {},
    alias: surveyOTJj9bh6kFMeta?.alias || [],
    redirect: surveyOTJj9bh6kFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/career/survey.vue").then(m => m.default || m)
  },
  {
    name: _91collegeId_93pyrOnNaYOaMeta?.name ?? "college-detail-collegeId",
    path: _91collegeId_93pyrOnNaYOaMeta?.path ?? "/college/detail/:collegeId()",
    meta: _91collegeId_93pyrOnNaYOaMeta || {},
    alias: _91collegeId_93pyrOnNaYOaMeta?.alias || [],
    redirect: _91collegeId_93pyrOnNaYOaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/detail/[collegeId].vue").then(m => m.default || m)
  },
  {
    name: findUzCqhq7JtxMeta?.name ?? "college-find",
    path: findUzCqhq7JtxMeta?.path ?? "/college/find",
    meta: findUzCqhq7JtxMeta || {},
    alias: findUzCqhq7JtxMeta?.alias || [],
    redirect: findUzCqhq7JtxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/find.vue").then(m => m.default || m)
  },
  {
    name: result60mrgpem19Meta?.name ?? "college-result",
    path: result60mrgpem19Meta?.path ?? "/college/result",
    meta: result60mrgpem19Meta || {},
    alias: result60mrgpem19Meta?.alias || [],
    redirect: result60mrgpem19Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/result.vue").then(m => m.default || m)
  },
  {
    name: surveydtKgXtCS7KMeta?.name ?? "college-survey",
    path: surveydtKgXtCS7KMeta?.path ?? "/college/survey",
    meta: surveydtKgXtCS7KMeta || {},
    alias: surveydtKgXtCS7KMeta?.alias || [],
    redirect: surveydtKgXtCS7KMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/survey.vue").then(m => m.default || m)
  },
  {
    name: viewmorelXoiR8lDWEMeta?.name ?? "college-viewmore",
    path: viewmorelXoiR8lDWEMeta?.path ?? "/college/viewmore",
    meta: viewmorelXoiR8lDWEMeta || {},
    alias: viewmorelXoiR8lDWEMeta?.alias || [],
    redirect: viewmorelXoiR8lDWEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/college/viewmore.vue").then(m => m.default || m)
  },
  {
    name: collegesurvey0g5fPVLsHjMeta?.name ?? "collegesurvey",
    path: collegesurvey0g5fPVLsHjMeta?.path ?? "/collegesurvey",
    meta: collegesurvey0g5fPVLsHjMeta || {},
    alias: collegesurvey0g5fPVLsHjMeta?.alias || [],
    redirect: collegesurvey0g5fPVLsHjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/collegesurvey.vue").then(m => m.default || m)
  },
  {
    path: extracurricularsN76nRnllmAMeta?.path ?? "/extracurriculars",
    children: [
  {
    name: indexjQZIk5WkNOMeta?.name ?? "extracurriculars",
    path: indexjQZIk5WkNOMeta?.path ?? "",
    meta: indexjQZIk5WkNOMeta || {},
    alias: indexjQZIk5WkNOMeta?.alias || [],
    redirect: indexjQZIk5WkNOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/extracurriculars/index.vue").then(m => m.default || m)
  }
],
    name: extracurricularsN76nRnllmAMeta?.name ?? undefined,
    meta: extracurricularsN76nRnllmAMeta || {},
    alias: extracurricularsN76nRnllmAMeta?.alias || [],
    redirect: extracurricularsN76nRnllmAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/extracurriculars.vue").then(m => m.default || m)
  },
  {
    name: index06bSq8Mg4zMeta?.name ?? "index",
    path: index06bSq8Mg4zMeta?.path ?? "/",
    meta: index06bSq8Mg4zMeta || {},
    alias: index06bSq8Mg4zMeta?.alias || [],
    redirect: index06bSq8Mg4zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index2eRAGc8YRiQMeta?.name ?? "index2",
    path: index2eRAGc8YRiQMeta?.path ?? "/index2",
    meta: index2eRAGc8YRiQMeta || {},
    alias: index2eRAGc8YRiQMeta?.alias || [],
    redirect: index2eRAGc8YRiQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/index2.vue").then(m => m.default || m)
  },
  {
    name: indexjR5UNGDLp6Meta?.name ?? "mentoring-find",
    path: indexjR5UNGDLp6Meta?.path ?? "/mentoring/find",
    meta: indexjR5UNGDLp6Meta || {},
    alias: indexjR5UNGDLp6Meta?.alias || [],
    redirect: indexjR5UNGDLp6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/find/index.vue").then(m => m.default || m)
  },
  {
    name: indexMpvABcetMDMeta?.name ?? "mentoring-find-researchprogram",
    path: indexMpvABcetMDMeta?.path ?? "/mentoring/find/researchprogram",
    meta: indexMpvABcetMDMeta || {},
    alias: indexMpvABcetMDMeta?.alias || [],
    redirect: indexMpvABcetMDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/find/researchprogram/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ7WYhmVsc9Meta?.name ?? "mentoring",
    path: indexQ7WYhmVsc9Meta?.path ?? "/mentoring",
    meta: indexQ7WYhmVsc9Meta || {},
    alias: indexQ7WYhmVsc9Meta?.alias || [],
    redirect: indexQ7WYhmVsc9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/index.vue").then(m => m.default || m)
  },
  {
    path: infooFmk62yM8HMeta?.path ?? "/mentoring/info",
    children: [
  {
    name: indexO0hoDjCjL3Meta?.name ?? "mentoring-info-booking",
    path: indexO0hoDjCjL3Meta?.path ?? "booking",
    meta: indexO0hoDjCjL3Meta || {},
    alias: indexO0hoDjCjL3Meta?.alias || [],
    redirect: indexO0hoDjCjL3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/index.vue").then(m => m.default || m)
  },
  {
    name: index0LMEAZbKMNMeta?.name ?? "mentoring-info-booking-program",
    path: index0LMEAZbKMNMeta?.path ?? "booking/program",
    meta: index0LMEAZbKMNMeta || {},
    alias: index0LMEAZbKMNMeta?.alias || [],
    redirect: index0LMEAZbKMNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/program/index.vue").then(m => m.default || m)
  },
  {
    name: _91programId_93rC4g6LoeCgMeta?.name ?? "mentoring-info-booking-program-payment-programId",
    path: _91programId_93rC4g6LoeCgMeta?.path ?? "booking/program/payment/:programId()",
    meta: _91programId_93rC4g6LoeCgMeta || {},
    alias: _91programId_93rC4g6LoeCgMeta?.alias || [],
    redirect: _91programId_93rC4g6LoeCgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/booking/program/payment/[programId].vue").then(m => m.default || m)
  },
  {
    name: indexBDfebuvF5lMeta?.name ?? "mentoring-info-experience",
    path: indexBDfebuvF5lMeta?.path ?? "experience",
    meta: indexBDfebuvF5lMeta || {},
    alias: indexBDfebuvF5lMeta?.alias || [],
    redirect: indexBDfebuvF5lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/experience/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvHtPWviZxMeta?.name ?? "mentoring-info",
    path: indexfvHtPWviZxMeta?.path ?? "",
    meta: indexfvHtPWviZxMeta || {},
    alias: indexfvHtPWviZxMeta?.alias || [],
    redirect: indexfvHtPWviZxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/index.vue").then(m => m.default || m)
  },
  {
    name: editjwAsRMB7X0Meta?.name ?? "mentoring-info-programs-id-edit",
    path: editjwAsRMB7X0Meta?.path ?? "programs/:id()/edit",
    meta: editjwAsRMB7X0Meta || {},
    alias: editjwAsRMB7X0Meta?.alias || [],
    redirect: editjwAsRMB7X0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createttWZ9KuaotMeta?.name ?? "mentoring-info-programs-create",
    path: createttWZ9KuaotMeta?.path ?? "programs/create",
    meta: createttWZ9KuaotMeta || {},
    alias: createttWZ9KuaotMeta?.alias || [],
    redirect: createttWZ9KuaotMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/create.vue").then(m => m.default || m)
  },
  {
    name: indexz4XmF7bN90Meta?.name ?? "mentoring-info-programs",
    path: indexz4XmF7bN90Meta?.path ?? "programs",
    meta: indexz4XmF7bN90Meta || {},
    alias: indexz4XmF7bN90Meta?.alias || [],
    redirect: indexz4XmF7bN90Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info/programs/index.vue").then(m => m.default || m)
  }
],
    name: infooFmk62yM8HMeta?.name ?? undefined,
    meta: infooFmk62yM8HMeta || {},
    alias: infooFmk62yM8HMeta?.alias || [],
    redirect: infooFmk62yM8HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mentoring/info.vue").then(m => m.default || m)
  },
  {
    path: mypondnOaZ8qH56xMeta?.path ?? "/mypond",
    children: [
  {
    name: list4idpJgvI5JMeta?.name ?? "mypond-career-list",
    path: list4idpJgvI5JMeta?.path ?? "career/list",
    meta: list4idpJgvI5JMeta || {},
    alias: list4idpJgvI5JMeta?.alias || [],
    redirect: list4idpJgvI5JMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/career/list.vue").then(m => m.default || m)
  },
  {
    name: listyJjpx8LhRDMeta?.name ?? "mypond-college-list",
    path: listyJjpx8LhRDMeta?.path ?? "college/list",
    meta: listyJjpx8LhRDMeta || {},
    alias: listyJjpx8LhRDMeta?.alias || [],
    redirect: listyJjpx8LhRDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/college/list.vue").then(m => m.default || m)
  },
  {
    name: index85kE7vXfBAMeta?.name ?? "mypond",
    path: index85kE7vXfBAMeta?.path ?? "",
    meta: index85kE7vXfBAMeta || {},
    alias: index85kE7vXfBAMeta?.alias || [],
    redirect: index85kE7vXfBAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/index.vue").then(m => m.default || m)
  },
  {
    name: listXEYznxdklCMeta?.name ?? "mypond-major-list",
    path: listXEYznxdklCMeta?.path ?? "major/list",
    meta: listXEYznxdklCMeta || {},
    alias: listXEYznxdklCMeta?.alias || [],
    redirect: listXEYznxdklCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/major/list.vue").then(m => m.default || m)
  },
  {
    name: edito2JQ8t3RLwMeta?.name ?? "mypond-profile-edit",
    path: edito2JQ8t3RLwMeta?.path ?? "profile/edit",
    meta: edito2JQ8t3RLwMeta || {},
    alias: edito2JQ8t3RLwMeta?.alias || [],
    redirect: edito2JQ8t3RLwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyTZiS05dgOMeta?.name ?? "mypond-profile",
    path: indexyTZiS05dgOMeta?.path ?? "profile",
    meta: indexyTZiS05dgOMeta || {},
    alias: indexyTZiS05dgOMeta?.alias || [],
    redirect: indexyTZiS05dgOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/profile/index.vue").then(m => m.default || m)
  },
  {
    name: cancel_45subscriptionm8Rpf4jjTPMeta?.name ?? "mypond-settings-cancel-subscription",
    path: cancel_45subscriptionm8Rpf4jjTPMeta?.path ?? "settings/cancel-subscription",
    meta: cancel_45subscriptionm8Rpf4jjTPMeta || {},
    alias: cancel_45subscriptionm8Rpf4jjTPMeta?.alias || [],
    redirect: cancel_45subscriptionm8Rpf4jjTPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/cancel-subscription.vue").then(m => m.default || m)
  },
  {
    name: deactivatea6VMAfJ6UiMeta?.name ?? "mypond-settings-deactivate",
    path: deactivatea6VMAfJ6UiMeta?.path ?? "settings/deactivate",
    meta: deactivatea6VMAfJ6UiMeta || {},
    alias: deactivatea6VMAfJ6UiMeta?.alias || [],
    redirect: deactivatea6VMAfJ6UiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/deactivate.vue").then(m => m.default || m)
  },
  {
    name: deleteyVtLti1HbMMeta?.name ?? "mypond-settings-delete",
    path: deleteyVtLti1HbMMeta?.path ?? "settings/delete",
    meta: deleteyVtLti1HbMMeta || {},
    alias: deleteyVtLti1HbMMeta?.alias || [],
    redirect: deleteyVtLti1HbMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/delete.vue").then(m => m.default || m)
  },
  {
    name: indexiAfHb2fvaKMeta?.name ?? "mypond-settings",
    path: indexiAfHb2fvaKMeta?.path ?? "settings",
    meta: indexiAfHb2fvaKMeta || {},
    alias: indexiAfHb2fvaKMeta?.alias || [],
    redirect: indexiAfHb2fvaKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond/settings/index.vue").then(m => m.default || m)
  }
],
    name: mypondnOaZ8qH56xMeta?.name ?? undefined,
    meta: mypondnOaZ8qH56xMeta || {},
    alias: mypondnOaZ8qH56xMeta?.alias || [],
    redirect: mypondnOaZ8qH56xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/mypond.vue").then(m => m.default || m)
  },
  {
    name: callbackqY3UbLKsMrMeta?.name ?? "open-sign-up-cronofy-callback",
    path: callbackqY3UbLKsMrMeta?.path ?? "/open/sign-up/cronofy/callback",
    meta: callbackqY3UbLKsMrMeta || {},
    alias: callbackqY3UbLKsMrMeta?.alias || [],
    redirect: callbackqY3UbLKsMrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/open/sign-up/cronofy/callback.vue").then(m => m.default || m)
  },
  {
    name: subscriptiondQOWVwLdZKMeta?.name ?? "purchase-subscription",
    path: subscriptiondQOWVwLdZKMeta?.path ?? "/purchase/subscription",
    meta: subscriptiondQOWVwLdZKMeta || {},
    alias: subscriptiondQOWVwLdZKMeta?.alias || [],
    redirect: subscriptiondQOWVwLdZKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/subscription.vue").then(m => m.default || m)
  },
  {
    name: subscriptionNoAuthqrNiQ8SdR6Meta?.name ?? "purchase-subscriptionNoAuth",
    path: subscriptionNoAuthqrNiQ8SdR6Meta?.path ?? "/purchase/subscriptionNoAuth",
    meta: subscriptionNoAuthqrNiQ8SdR6Meta || {},
    alias: subscriptionNoAuthqrNiQ8SdR6Meta?.alias || [],
    redirect: subscriptionNoAuthqrNiQ8SdR6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/subscriptionNoAuth.vue").then(m => m.default || m)
  },
  {
    name: viewingpassLCeDpxgkA0Meta?.name ?? "purchase-viewingpass",
    path: viewingpassLCeDpxgkA0Meta?.path ?? "/purchase/viewingpass",
    meta: viewingpassLCeDpxgkA0Meta || {},
    alias: viewingpassLCeDpxgkA0Meta?.alias || [],
    redirect: viewingpassLCeDpxgkA0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/purchase/viewingpass.vue").then(m => m.default || m)
  },
  {
    path: roadmap5RL3DpNaWgMeta?.path ?? "/roadmap",
    children: [
  {
    name: _91reportId_93lphWSWNc3nMeta?.name ?? "roadmap-analysis-reportId",
    path: _91reportId_93lphWSWNc3nMeta?.path ?? "analysis/:reportId()",
    meta: _91reportId_93lphWSWNc3nMeta || {},
    alias: _91reportId_93lphWSWNc3nMeta?.alias || [],
    redirect: _91reportId_93lphWSWNc3nMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/analysis/[reportId].vue").then(m => m.default || m)
  },
  {
    name: indexmzmwTj2XDxMeta?.name ?? "roadmap",
    path: indexmzmwTj2XDxMeta?.path ?? "",
    meta: indexmzmwTj2XDxMeta || {},
    alias: indexmzmwTj2XDxMeta?.alias || [],
    redirect: indexmzmwTj2XDxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/index.vue").then(m => m.default || m)
  },
  {
    name: index3tOfTsq6NxMeta?.name ?? "roadmap-m",
    path: index3tOfTsq6NxMeta?.path ?? "m",
    meta: index3tOfTsq6NxMeta || {},
    alias: index3tOfTsq6NxMeta?.alias || [],
    redirect: index3tOfTsq6NxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/m/index.vue").then(m => m.default || m)
  },
  {
    path: welcomejRwMYg0PizMeta?.path ?? "welcome",
    children: [
  {
    name: collegeZE1CxoUDlPMeta?.name ?? "roadmap-welcome-college",
    path: collegeZE1CxoUDlPMeta?.path ?? "college",
    meta: collegeZE1CxoUDlPMeta || {},
    alias: collegeZE1CxoUDlPMeta?.alias || [],
    redirect: collegeZE1CxoUDlPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/college.vue").then(m => m.default || m)
  },
  {
    name: indexxGXbUCYK1vMeta?.name ?? "roadmap-welcome",
    path: indexxGXbUCYK1vMeta?.path ?? "",
    meta: indexxGXbUCYK1vMeta || {},
    alias: indexxGXbUCYK1vMeta?.alias || [],
    redirect: indexxGXbUCYK1vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: majorY40weRjoRnMeta?.name ?? "roadmap-welcome-major",
    path: majorY40weRjoRnMeta?.path ?? "major",
    meta: majorY40weRjoRnMeta || {},
    alias: majorY40weRjoRnMeta?.alias || [],
    redirect: majorY40weRjoRnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome/major.vue").then(m => m.default || m)
  }
],
    name: welcomejRwMYg0PizMeta?.name ?? undefined,
    meta: welcomejRwMYg0PizMeta || {},
    alias: welcomejRwMYg0PizMeta?.alias || [],
    redirect: welcomejRwMYg0PizMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcome.vue").then(m => m.default || m)
  },
  {
    name: collegeAByJNgppfKMeta?.name ?? "roadmap-welcomem-college",
    path: collegeAByJNgppfKMeta?.path ?? "welcomem/college",
    meta: collegeAByJNgppfKMeta || {},
    alias: collegeAByJNgppfKMeta?.alias || [],
    redirect: collegeAByJNgppfKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/college.vue").then(m => m.default || m)
  },
  {
    name: indexfUbOomkIKtMeta?.name ?? "roadmap-welcomem",
    path: indexfUbOomkIKtMeta?.path ?? "welcomem",
    meta: indexfUbOomkIKtMeta || {},
    alias: indexfUbOomkIKtMeta?.alias || [],
    redirect: indexfUbOomkIKtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/index.vue").then(m => m.default || m)
  },
  {
    name: majorktHjGW1ahdMeta?.name ?? "roadmap-welcomem-major",
    path: majorktHjGW1ahdMeta?.path ?? "welcomem/major",
    meta: majorktHjGW1ahdMeta || {},
    alias: majorktHjGW1ahdMeta?.alias || [],
    redirect: majorktHjGW1ahdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap/welcomem/major.vue").then(m => m.default || m)
  }
],
    name: roadmap5RL3DpNaWgMeta?.name ?? undefined,
    meta: roadmap5RL3DpNaWgMeta || {},
    alias: roadmap5RL3DpNaWgMeta?.alias || [],
    redirect: roadmap5RL3DpNaWgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: surveyFIiZn69zwgMeta?.name ?? "survey",
    path: surveyFIiZn69zwgMeta?.path ?? "/survey",
    meta: surveyFIiZn69zwgMeta || {},
    alias: surveyFIiZn69zwgMeta?.alias || [],
    redirect: surveyFIiZn69zwgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/pc/pages/survey.vue").then(m => m.default || m)
  }
]