import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import defaultProfileImage from '../assets/images/avatar.svg'
import { useRuntimeConfig } from 'nuxt/app'
import { navigateTo } from 'nuxt/app'
import { useRouter } from 'vue-router'


interface MemberInfo {
  accessToken
  email
  name
  memberId
  surveyYn
  memberType
  expertType
}

export const useMemberSessionStore = defineStore(
  'memberSessionStore',
  () => {
    const memberInfo = ref<MemberInfo>(null)
    
    const isLoggedIn = computed(() => !!memberInfo.value && !!memberInfo.value.accessToken)
    
    const router = useRouter()
    const accessToken = computed(() => {
      return memberInfo.value?.accessToken
    })

    const hasMemberType = (role: string) => {
      if (!isLoggedIn.value) {
        return false
      }
      return memberInfo.value?.memberType === role
    }

    const getProfileImage = (imageUrl) => {
      if (!imageUrl) {
        return defaultProfileImage
      }

      const config = useRuntimeConfig()
      return config.public['FILE_CDN_HOST'] + imageUrl
    }

    const login = (member: any) => {
      memberInfo.value = {
        ...member,
        imageUrl: getProfileImage(member.imageUrl),
      }
    }

    const logout = () => {
      try {
        memberInfo.value = null
        router.push('/')
      } catch (error) {
        console.error('Logout error:', error)
      }
    }

    const renewMember = (member) => {
      memberInfo.value = {
        ...memberInfo.value,
        ...member,
        imageUrl: getProfileImage(member.imageUrl),
      }
    }

    return {
      memberInfo,
      isLoggedIn,
      accessToken,
      login,
      logout,
      hasMemberType,
      renewMember,
    }
  },
  {
    // @ts-ignore
    persist: true,
  }
)
