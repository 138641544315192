import { useMemberSessionStore } from '../stores/memberSessionStore'
import { navigateTo, useRuntimeConfig } from 'nuxt/app'
import { useNotify } from './useNotify'
import dayjs from 'dayjs'
import {
  Configuration,
  AuthApi,
  MemberDataApi,
  OpenDataApi,
  OpenSignupApi,
  SurveyApi,
  MajorApi,
  CareerSurveyApi,
  CollegeSurveyApi,
  RoadmapApi,
  RoadmapCourseworkApi,
  RoadmapAnalysisApi,
  RoadmapActivityApi,
  RoadmapAwardsApi,
  RoadmapTestScoresApi,
  MyPondApi,
  AccountSettingApi,
  FrontApi,
  PaymentApi,
  ExpertApi,
  CronfyApi,
  MentoringApi,
} from '../openapi'

const { loading, alert } = useNotify()

export const useApi = () => {
  const config = useRuntimeConfig()
  const token = useMemberSessionStore().accessToken

  const configuration = new Configuration({
    basePath: config.public['API_HOST'] as string,
    accessToken: token,
  })

  return {
    auth: new AuthApi(configuration),
    signUp: new OpenSignupApi(configuration),
    openData: new OpenDataApi(configuration),
    survey: new SurveyApi(configuration),
    careerSurvey: new CareerSurveyApi(configuration),
    major: new MajorApi(configuration),
    college: new CollegeSurveyApi(configuration),
    memberData: new MemberDataApi(configuration),
    roadmap: new RoadmapApi(configuration),
    roadmapCoursework: new RoadmapCourseworkApi(configuration),
    roadmapActivity: new RoadmapActivityApi(configuration),
    roadmapAwards: new RoadmapAwardsApi(configuration),
    roadmapTestScores: new RoadmapTestScoresApi(configuration),
    roadmapAnalysis: new RoadmapAnalysisApi(configuration),
    myPond: new MyPondApi(configuration),
    accountSetting: new AccountSettingApi(configuration),
    main: new FrontApi(configuration),
    payment: new PaymentApi(configuration),
    expert: new ExpertApi(configuration),
    cronofy: new CronfyApi(configuration),
    mentoring: new MentoringApi(configuration),
  }
}

export const executeApi = async (execute: () => void = () => {}, onFail?: (e: any) => void) => {
  loading.show()
  try {
    await execute()
  } catch (e) {
    if (onFail) {
      onFail(e)
      return
    }

    if (e.response?.status === 403 || e.response?.status === 401) {
      // interceptor 에서 로그인 페이지 이동으로 처리되는걸 여기로 옮김
      console.error('401 403 error')
      useMemberSessionStore().logout()
      navigateTo('/auth/sign-in')
      return
    }

    alert(getServerErrorMessage(e), '', () => {})
  } finally {
    loading.remove()
  }
}

export const getServerErrorMessage = (error: any) => {
  console.error('getServerErrorMessage', error)
  const errorMsg = error?.response?.data?.errorMsg
  return errorMsg || 'Error.'
}

export const fileDownload = (blob: Blob, fileName: string, ext: string) => {
  const now = dayjs().format('YYYYMMDDHHmmss')
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}_${now}.${ext}`) //or any other extension
  document.body.appendChild(link)
  link.click()
  link.remove()
}
