import dayjs from 'dayjs'

export const useUtils = () => {
  const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
  const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

  const ensureHttpsPrefix = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url
    }
    return url
  }

  const calculateTodayPreviousDate = (amount: number) => {
    return dayjs(new Date()).subtract(amount, 'days').format(DEFAULT_DATE_FORMAT)
  }
  const toFormatNumber = (num: any) => {
    if (!num || typeof num !== 'number') {
      return '0'
    }
    return Intl.NumberFormat('ko-KR').format(num)
  }

  const toFormatDate = (date: any) => {
    if (!date) {
      return ''
    }
    return dayjs(date).format(DEFAULT_DATE_FORMAT) || ''
  }

  const toFormatDateTime = (date: any) => {
    if (!date) {
      return ''
    }
    return dayjs(date).format(DEFAULT_DATETIME_FORMAT).replace(' ', '\n') || ''
  }

  const toFormatDateUnix = (date: any) => {
    if (!date) {
      return ''
    }
    return dayjs.unix(date).format(DEFAULT_DATETIME_FORMAT) || ''
  }

  const toFormatDatePattern = (date: any, pattern: string) => {
    if (!date) {
      return ''
    }
    return dayjs(date).format(pattern) || ''
  }

  const maskingMobileNumber = (num: string) => {
    if (!num) {
      return ''
    }

    num = num.replace(/[^0-9]/g, '')
    if (num.indexOf('82') == 0) {
      return num.replace(/(^82)(2|\d{2})(\d+)?(\d{4})$/, '+$1-$2-$3-$4') // +82
    } else if (num.indexOf('1') == 0) {
      return num.replace(/(^1\d{3})(\d{4})$/, '$1-$2') // 1588, 1566, 1677, ...
    }
    return num.replace(/(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/, '$1-****-$3')
  }

  const validateEmail = (email: string) => {
    const emailReg = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,8}$/
    return emailReg.test(email)
  }

  const decComma = (amt: string) => {
    try {
      if (!amt) {
        return amt
      }

      return String(amt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } catch (e) {
      return amt
    }
  }

  const getByteSize = (str: string) => {
    let byte = 0
    for (let i = 0; i < str.length; ++i) {
      str.charCodeAt(i) > 127 ? (byte += 3) : byte++
    }
    return byte
  }

  const getByteLength = (decimal: any) => {
    const LINE_FEED = 10 // '\n'
    const KOREAN_BYTE = 3
    //  c >> 7은 unicodeDecimalValue가 0~127 범위에 있는 수인지 확인하는 코드이다. c값이 0부터 127이면 ASCII 영역에 해당되며 1바이트를 사용한다.
    //  128부터는 2바이트로 계산하였다. 추가로 커서를 아래로 이동하여 새로운 행이 추가되는 \n(LF - Line Feed)는 2바이트로 계산하였다.
    // https://ui.toast.com/posts/ko_20220318
    return decimal >> 7 || LINE_FEED === decimal ? KOREAN_BYTE : 1
  }

  const getLimitedByteText = (inputText: string, maxByte: number) => {
    const characters = inputText.split('')
    let validText = ''
    let totalByte = 0

    for (let i = 0; i < characters.length; i += 1) {
      const character = characters[i]
      const decimal = character.charCodeAt(0)
      const byte = getByteLength(decimal) // 글자 한 개가 몇 바이트 길이인지 구해주기
      // 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면
      if (totalByte + byte <= maxByte) {
        totalByte += byte // 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
        validText += character // 글자를 더해 현재까지의 총 문자열 값을 구함
      } else {
        // 최대 바이트 길이를 넘으면
        break // for 루프 종료
      }
    }
    return validText
  }

  const toFormatPercent = (rate) => {
    return `${Math.floor(rate * 100)}%`
  }

  const toSnakeCase = (str: string) => {
    if (!str) {
      return ''
    }

    return str.replace(/\.?([A-Z]+)/g, (x, y) => '_' + y.toLowerCase()).replace(/^_/, '')
  }

  const toPascalCase = (str: string) => {
    if (!str) {
      return ''
    }

    return str
      .replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
  }

  /**
   * Groups array data via date
   * @param data list of data
   * @param param date field to group by
   * @returns Array
   */
  const dateGroupings = (data, param) => {
    const grouped = data.reduce((result, item) => {
      const bookingDate = new Date(item[param])
      const month = bookingDate.toLocaleString('en-US', { month: 'long' })
      const year = bookingDate.getFullYear()

      if (!result[month + ' ' + year]) {
        result[month + ' ' + year] = []
      }

      result[month + ' ' + year].push(item)

      return result
    }, {})

    return grouped
  }
  /**
   * rename the index of Objects
   *
   * @param objects list of objects.
   * @param renameDetails list of array -> the first value of inner array is the current name of index and the second value of inner array is the new name of index
   *            example: [['reason', 'text'], ['reasonId', 'id']]
   *
   * @return Objects.
   */
  const renameIndexOfObject = (objects, renameDetails) => {
    for (let i = 0; i < objects.length; i++) {
      for (const pair of renameDetails) {
        const currentIndex = pair[0]
        const newIndex = pair[1]
        if (objects[i].hasOwnProperty(currentIndex)) {
          objects[i][newIndex] = objects[i][currentIndex]
          delete objects[i][currentIndex]
        }
      }
    }
    return objects
  }
  /**
   * if the amount is empty or null return 0
   *
   * @param amount list of objects.
   * 
   * @return string
   */
  const amountNullFormat = (amount) => {
    if(amount === null || amount === "" ) {
      return '0';
    }
    return amount
  }
  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  const humanFileSize = (bytes: number, si: boolean = true, dp: number = 1): string => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  }

  const dateBreakdown = (currentDate) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const daylist = ['Sunday', 'Monday', 'Tuesday', 'Wednesday ', 'Thursday', 'Friday', 'Saturday']
    const dateObj = new Date(currentDate)
    const date = dateObj.getDate()
    var day = daylist[dateObj.getDay()]
    const year = dateObj.getFullYear()
    const month = months[dateObj.getMonth()]
    var hour = ('0' + dateObj.getHours()).slice(-2)
    var minute = ('0' + dateObj.getMinutes()).slice(-2)

    return {
      date: date,
      day: day,
      year: year,
      month: month,
      hour: hour,
      minute: minute,
    }
  }

  /**
   * Mutate available program schedule so v-calendar can use it
   * @param inputArray
   * @returns
   */
  const mutateBookingForCalendar = (inputArray) => {
    const currentDate = new Date()

    const transformedArray = inputArray.map((item) => {
      const year = parseInt(item.bookingYear)
      const month = parseInt(item.bookingMonth) - 1 // Months in JavaScript are 0-based
      const day = parseInt(item.bookingDay)

      const date = new Date(year, month, day)

      if (item.bookingTime.length > 0) {
        return {
          highlight: {
            color: 'blue',
            fillMode: 'light',
          },
          dates: date,
        }
      }
    })
    transformedArray.push({
      highlight: {
        color: 'blue',
        fillMode: 'solid',
      },
      dates: currentDate,
    })

    return transformedArray
  }

  /**
   * Use to reformat string date to another format to use in saving booking
   * @param inputDateString 
   * @returns 
   */
  const bookingDateFormatter = (inputDateString) => {
    const inputDate = new Date(inputDateString);
  
    // Get month, date, and year from the input date
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = String(inputDate.getDate()).padStart(2, '0');
    const year = inputDate.getFullYear();
  
    // Format the date string as DD.MM.YYYY
    const formattedDate = `${day}.${month}.${year}`;
  
    return formattedDate;
  }
  /**
   * checking if it is url format
   *
   * @param url String
   * 
   * @return Boolean
  */
  const isURLFormat = (url) => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    return urlPattern.test(url);
  }

  const groupBySemester = (data) => {
    const groupedData = {};

    data.forEach(item => {
      const semester = item.semester;
      if (!groupedData[semester]) {
        groupedData[semester] = [];
      }
      groupedData[semester].push(item);
    });

    return groupedData;
  }

  const getSemesterValue = (semesterNumber) => {
    if (semesterNumber <= 0) {
      return "Invalid Semester Number";
    }
  
    const numericSuffix = ["th", "st", "nd", "rd"];
    const suffix = semesterNumber % 100 > 10 && semesterNumber % 100 < 14 ?
      numericSuffix[0] :
      numericSuffix[semesterNumber % 10] || numericSuffix[0];
  
    return `${semesterNumber}${suffix} Semester`;
  }

  return {
    bookingDateFormatter,
    toFormatNumber,
    toFormatDate,
    toFormatDateTime,
    toFormatDateUnix,
    toFormatDatePattern,
    maskingMobileNumber,
    validateEmail,
    decComma,
    calculateTodayPreviousDate,
    getLimitedByteText,
    getByteLength,
    getByteSize,
    toFormatPercent,
    toSnakeCase,
    toPascalCase,
    ensureHttpsPrefix,
    dateGroupings,
    humanFileSize,
    dateBreakdown,
    renameIndexOfObject,
    mutateBookingForCalendar,
    amountNullFormat,
    isURLFormat,
    groupBySemester,
    getSemesterValue
  }
}

export const useUiUtils = () => {
  const scrollToTopBySelector = (selector: string) => {
    document.querySelector(selector)?.scroll({
      top: 0,
    })
  }

  return {
    scrollToTopBySelector,
  }
}

export const useOgTitleByPath = (path: string) => {
  // console.log('path: ', path)
  if (path.includes('/college/detail')) {
    // TODO: get detail name
    return 'College'
  } else {
    switch (path) {
      case '/':
        return 'Home'
      case '/mypond':
        return 'My Pond'
      case '/roadmap':
        return 'Roadmap'
      case '/college/find':
        return 'Find a College'
      case '/mypond/college/list':
        return 'College List'
      case '/mypond/settings':
        return 'Settings'
      default:
        return 'College Admissions Guide'
    }
  }
}
