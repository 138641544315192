import { defineNuxtPlugin } from 'nuxt/app'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

export default defineNuxtPlugin(nuxtApp => {
  const firebaseConfig = {
    apiKey: "AIzaSyAJ-I3nnGwVO0zv2EU4UAGgxZcFILhDE_Y",
    authDomain: "letitu.firebaseapp.com",
    projectId: "letitu",
    storageBucket: "letitu.appspot.com",
    messagingSenderId: "814885494921",
    appId: "1:814885494921:web:0992350619c4826ac76658",
    measurementId: "G-3ZC84BYG3S"
  }

  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)

  nuxtApp.vueApp.provide('auth', auth)
  nuxtApp.provide('auth', auth)
})
