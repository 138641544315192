<script>
export default {
  name: "error-404",
  layout: null,
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  build: {
    debug: true,
  },
};
</script>

<template>
  <title>404 - The Pond</title>

  <div class="headerarea">
    <img
      src="@share/assets/images/logo.svg"
      @click="navigateTo('/')"
      class="cursor-pointer"
    />
  </div>
  <div class="main flex items-center w-100-p justify-center h-100-p">
    <div class="content">
      <div class="flex justify-center">
        <img
          src="@share/assets/images/404_header.png"
          class="m-bottom-20 text-center"
          style="width: 330px"
        />
      </div>

      <h1 class="text-primary4 text-center text-head3">Page not found</h1>
      <p class="text-body1 text-center p-top-12 m-bottom-20">
        Uh oh, we can’t seem to find the page you’re looking for. <br />Please
        try going back to the previous page or The Pond home.
      </p>

      <div class="flex justify-center">
        <button class="u-button outline" @click="navigateTo('/')">
          The Pond Home
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.headerarea {
  background: #f5f7ff;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.main {
  height: calc(100vh - 80px);
}
.u-button {
  border-radius: 12px;
  @include set-text($weight: 500);
  height: 48px;
  padding: 13px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $primary5;
  &:hover,
  &:active,
  &.active {
    background-color: $primary6;
  }
  &:disabled {
    background-color: $t2;
  }
  &.full {
    width: 100% !important;
  }
  &.icon {
    width: 48px !important;
    height: 48px;
  }
  &.small {
    padding: 12px 16px;
    @include set-text(13px, 500, 16px);
    height: 40px;
  }
  &.light-primary {
    color: $primary5;
    background-color: $primary2;
    &:hover,
    &:active,
    &.active {
      color: $primary6;
      background-color: $primary3;
    }
    &:disabled {
      color: $gray3;
      background-color: $t2;
    }
    &.dark {
      color: $primary6;
      background-color: $primary3;
      &:disabled {
        color: $gray3;
        background-color: $t2;
      }
    }
  }
  &.gray {
    color: $gray6;
    background-color: $gray2;
    &:hover,
    &:active,
    &.active {
      color: $white;
      background-color: $gray6;
      :deep(.icon) {
        color: $white;
      }
    }
    &:disabled {
      color: $gray3;
      background-color: $t2;
    }
  }
  &.outline {
    border: 1px solid $primary3;
    color: $primary5;
    background-color: $white;
    &.small {
      border-color: $primary2;
      &:hover,
      &:active,
      &.active {
        border-color: $primary3;
        background-color: $white;
      }
      &:disabled {
        color: $gray3;
        border-color: $t2;
        background-color: $white;
      }
    }
    &:hover,
    &:active,
    &.active {
      border-color: $primary4;
      background-color: $white;
    }
    &:disabled {
      color: $gray3;
      border-color: $t2;
      background-color: $white;
    }
  }
}
</style>
