import { Notify, Report, Confirm, Loading, Block } from 'notiflix'
import '../assets/style/notify.scss'

export const useNotify = () => {
  if (process.client) {
    // client에서만 작동 하도록 함
    Notify.init({
      position: 'center-top',
      timeout: 1200,
      fontFamily: 'Roboto',
      useFontAwesome: true,
      width: '360px',
      fontAwesomeIconSize: '24px',
      borderRadius: '12px',
      useIcon: true,
      success: {
        background: '#434247',
        textColor: '#fff',
        childClassName: 'notiflix-notify-success',
        notiflixIconColor: '#fff',
        fontAwesomeClassName: 'fas fa-check-circle',
        fontAwesomeIconColor: '#8BC94D',
        backOverlayColor: 'rgba(17, 17, 17, 0.3)',
      },
      failure: {
        background: '#434247',
        textColor: '#fff',
        childClassName: 'notiflix-notify-failure',
        notiflixIconColor: '#fff',
        fontAwesomeClassName: 'fas fa-check-circle',
        fontAwesomeIconColor: '#EB5545',
        backOverlayColor: 'rgba(17, 17, 17, 0.3)',
      },
    })

    Confirm.init({
      fontFamily: 'Roboto',
      plainText: false,
      borderRadius: '20px',
      width: '320px',
      titleColor: '#000',
      backgroundColor: '#fff',
      messageColor: '#131A29',
      titleFontSize: '20px',
      messageFontSize: '16px',
      buttonsFontSize: '13px',
      okButtonColor: '#0038FF',
      okButtonBackground: '#E6EBFF',
      cancelButtonColor: '#0038FF',
      cancelButtonBackground: '#fff',
      backOverlayColor: 'rgba(17, 17, 17, 0.3)',
      titleMaxLength: 50,
    })

    Report.init({
      fontFamily: 'Roboto',
      borderRadius: '20px',
      plainText: false,
      width: '320px',
      svgSize: '0',
      backgroundColor: '#fff',
      messageFontSize: '16px',
      buttonFontSize: '13px',
      success: {
        buttonColor: '#0038FF',
        buttonBackground: '#E6EBFF',
        messageColor: '#131A29',
        backOverlayColor: 'rgba(17, 17, 17, 0.3)',
      },
    })

    Loading.init({
      backgroundColor: 'rgba(0,0,0,0)',
      svgColor: '#0038FF',
    })

    return {
      notify: Notify,
      confirm: (
        message: string,
        okButtonText: string,
        cancelButtonText: string,
        ok: () => void = () => {},
        cancel: () => void = () => {},
        title: string,
      ) => {
        Confirm.show(
          title ? title : '',
          message,
          okButtonText ? okButtonText : 'OK',
          cancelButtonText ? cancelButtonText : 'Cancel',
          ok,
          cancel,
        )
      },
      prompt: (
        message: string,
        okButtonText: string,
        cancelButtonText: string,
        ok: (answer: string) => void = () => {},
        cancel: () => void = () => {},
      ) => {
        Confirm.prompt(
          '',
          message,
          '',
          okButtonText ? okButtonText : 'OK',
          cancelButtonText ? cancelButtonText : 'Cancel',
          ok,
          cancel,
        )
      },
      alert: (message: string, buttonText: string = 'OK', ok?: () => void) => {
        Report.success('', message, buttonText || 'OK', ok)
      },
      loading: {
        show: () => Loading.circle(),
        remove: (delay = 0) => Loading.remove(delay),
      },
      block: Block,
    }
  } else {
    return {
      notify: null,
      confirm: (
        message: string,
        okButtonText: string,
        cancelButtonText: string,
        ok: () => void = () => {},
        cancel: () => void = () => {},
      ) => {},
      prompt: (
        message: string,
        okButtonText: string,
        cancelButtonText: string,
        ok: (answer: string) => void = () => {},
        cancel: () => void = () => {},
      ) => {},
      alert: (message: string, buttonText?: string, ok?: () => void) => {},
      loading: {
        show: () => {},
        remove: (delay = 0) => {},
      },
      block: null,
    }
  }
}