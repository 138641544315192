/* tslint:disable */
/* eslint-disable */
/**
 * letitu API
 * 오른쪽 위에 Select a definition 선택  Expert~ : 전문가만 접근  Student~ : 학생만 접근  Open~ : 아무나 접근 Open~ : 아무나 접근 (추후 권한 필요한 공통 영역 분리 필요) 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityType
 */
export interface ActivityType {
    /**
     * 
     * @type {number}
     * @memberof ActivityType
     */
    'activityTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityType
     */
    'activityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityType
     */
    'mainAcitivtyCategory'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityType
     */
    'recommendedCategoryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityType
     */
    'myCategoryCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityType
     */
    'activityNames'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityType
     */
    'metFlag'?: boolean;
}
/**
 * 
 * @export
 * @interface AdmissionInfo
 */
export interface AdmissionInfo {
    /**
     * 
     * @type {number}
     * @memberof AdmissionInfo
     */
    'deadlineId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdmissionInfo
     */
    'admissionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'admissionSeason'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'admissionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'normalDeadlineDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'lateDeadlineDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'testDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'scoreReleaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'linkUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdmissionInfo
     */
    'dday'?: string;
}
/**
 * 
 * @export
 * @interface ApTestScore
 */
export interface ApTestScore {
    /**
     * AP subject id
     * @type {string}
     * @memberof ApTestScore
     */
    'testSubjectId'?: string;
    /**
     * AP subject title
     * @type {string}
     * @memberof ApTestScore
     */
    'testSubjectTitle'?: string;
    /**
     * AP Score
     * @type {number}
     * @memberof ApTestScore
     */
    'testScore'?: number;
}
/**
 * 
 * @export
 * @interface AttachFile
 */
export interface AttachFile {
    /**
     * file id
     * @type {number}
     * @memberof AttachFile
     */
    'fileId'?: number;
    /**
     * file size
     * @type {number}
     * @memberof AttachFile
     */
    'fileSize'?: number;
    /**
     * file name
     * @type {string}
     * @memberof AttachFile
     */
    'fileName'?: string;
    /**
     * file url
     * @type {string}
     * @memberof AttachFile
     */
    'fileLinkUrl'?: string;
}
/**
 * 
 * @export
 * @interface AvailabilityBookingTime
 */
export interface AvailabilityBookingTime {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBookingTime
     */
    'bookingYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBookingTime
     */
    'bookingMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBookingTime
     */
    'bookingDay'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilityBookingTime
     */
    'bookingTime'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilityBookingTime
     */
    'completeTime'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AvailabilityTime
 */
export interface AvailabilityTime {
    /**
     * weekly day
     * @type {string}
     * @memberof AvailabilityTime
     */
    'weeklyDay'?: string;
    /**
     * 
     * @type {Array<StartEndTime>}
     * @memberof AvailabilityTime
     */
    'timeObjectList'?: Array<StartEndTime>;
}
/**
 * 
 * @export
 * @interface BookingCancel
 */
export interface BookingCancel {
    /**
     * booking id
     * @type {number}
     * @memberof BookingCancel
     */
    'bookingId'?: number;
    /**
     * expert id
     * @type {number}
     * @memberof BookingCancel
     */
    'expertId'?: number;
    /**
     * expert name
     * @type {string}
     * @memberof BookingCancel
     */
    'expertName'?: string;
    /**
     * expert type
     * @type {string}
     * @memberof BookingCancel
     */
    'expertType'?: string;
    /**
     * program id
     * @type {number}
     * @memberof BookingCancel
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof BookingCancel
     */
    'programName'?: string;
    /**
     * 
     * @type {Duration}
     * @memberof BookingCancel
     */
    'duration'?: Duration;
    /**
     * booking datetime
     * @type {string}
     * @memberof BookingCancel
     */
    'bookingDatetime'?: string;
    /**
     * cancel member id
     * @type {number}
     * @memberof BookingCancel
     */
    'cancelMemberId'?: number;
    /**
     * cancel member type
     * @type {string}
     * @memberof BookingCancel
     */
    'cancelMemberType'?: string;
    /**
     * cancel reason code
     * @type {number}
     * @memberof BookingCancel
     */
    'cancelReasonCode'?: number;
    /**
     * cancel reason name
     * @type {string}
     * @memberof BookingCancel
     */
    'cancelReasonName'?: string;
    /**
     * cancel note
     * @type {string}
     * @memberof BookingCancel
     */
    'cancelNote'?: string;
    /**
     * paid amount
     * @type {number}
     * @memberof BookingCancel
     */
    'paidAmount'?: number;
    /**
     * cancellation fee
     * @type {number}
     * @memberof BookingCancel
     */
    'cancellationFee'?: number;
    /**
     * refund amount
     * @type {number}
     * @memberof BookingCancel
     */
    'refundAmount'?: number;
}
/**
 * 
 * @export
 * @interface BookingCancelReason
 */
export interface BookingCancelReason {
    /**
     * 
     * @type {number}
     * @memberof BookingCancelReason
     */
    'reasonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingCancelReason
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingCancelReason
     */
    'reason'?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingCancelReason
     */
    'displayOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingCancelReason
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BookingCount
 */
export interface BookingCount {
    /**
     * 
     * @type {number}
     * @memberof BookingCount
     */
    'standByBookingCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingCount
     */
    'feedbackRequiredCnt'?: number;
}
/**
 * 
 * @export
 * @interface BookingDetail
 */
export interface BookingDetail {
    /**
     * booking id
     * @type {number}
     * @memberof BookingDetail
     */
    'bookingId'?: number;
    /**
     * expert id
     * @type {number}
     * @memberof BookingDetail
     */
    'expertId'?: number;
    /**
     * expert profile image url
     * @type {string}
     * @memberof BookingDetail
     */
    'expertImgUrl'?: string;
    /**
     * expert type
     * @type {string}
     * @memberof BookingDetail
     */
    'expertType'?: string;
    /**
     * expert name
     * @type {string}
     * @memberof BookingDetail
     */
    'expertName'?: string;
    /**
     * program id
     * @type {number}
     * @memberof BookingDetail
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof BookingDetail
     */
    'programName'?: string;
    /**
     * Meeting room join URL
     * @type {string}
     * @memberof BookingDetail
     */
    'joinUrl'?: string;
    /**
     * booking state
     * @type {string}
     * @memberof BookingDetail
     */
    'bookingState'?: string;
    /**
     * 
     * @type {Duration}
     * @memberof BookingDetail
     */
    'durationObject'?: Duration;
    /**
     * booking datetime
     * @type {string}
     * @memberof BookingDetail
     */
    'bookingDatetime'?: string;
    /**
     * 
     * @type {MentoringStudent}
     * @memberof BookingDetail
     */
    'studentObject'?: MentoringStudent;
    /**
     * request term
     * @type {string}
     * @memberof BookingDetail
     */
    'requestTerm'?: string;
    /**
     * 
     * @type {Array<AttachFile>}
     * @memberof BookingDetail
     */
    'attachmentList'?: Array<AttachFile>;
    /**
     * payment amount
     * @type {number}
     * @memberof BookingDetail
     */
    'paymentAmount'?: number;
    /**
     * program free yn
     * @type {string}
     * @memberof BookingDetail
     */
    'programFreeYn'?: string;
    /**
     * expert feedback
     * @type {string}
     * @memberof BookingDetail
     */
    'expertFeedback'?: string;
    /**
     * Stripe priceId
     * @type {string}
     * @memberof BookingDetail
     */
    'stripePriceId'?: string;
    /**
     * Stripe paid Url
     * @type {string}
     * @memberof BookingDetail
     */
    'stripePaidUrl'?: string;
    /**
     * payment amount hide yn
     * @type {string}
     * @memberof BookingDetail
     */
    'paymentAmountHideYn'?: string;
    /**
     * estimated amount range
     * @type {string}
     * @memberof BookingDetail
     */
    'estimatedAmountRange'?: string;
    /**
     * studentRoadmapYn
     * @type {string}
     * @memberof BookingDetail
     */
    'studentRoadmapYn'?: string;
}
/**
 * 
 * @export
 * @interface BookingFeedback
 */
export interface BookingFeedback {
    /**
     * program id
     * @type {number}
     * @memberof BookingFeedback
     */
    'programId'?: number;
    /**
     * booking id
     * @type {number}
     * @memberof BookingFeedback
     */
    'bookingId'?: number;
    /**
     * booking feedback
     * @type {string}
     * @memberof BookingFeedback
     */
    'feedback'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingFeedback
     */
    'supplementUrl'?: Array<string>;
    /**
     * booking review YN
     * @type {string}
     * @memberof BookingFeedback
     */
    'reviewYn'?: string;
    /**
     * booking feedback create date
     * @type {string}
     * @memberof BookingFeedback
     */
    'createDate'?: string;
    /**
     * booking feedback update date
     * @type {string}
     * @memberof BookingFeedback
     */
    'updateDate'?: string;
}
/**
 * 
 * @export
 * @interface BookingList
 */
export interface BookingList {
    /**
     * booking id
     * @type {number}
     * @memberof BookingList
     */
    'bookingId'?: number;
    /**
     * expert id
     * @type {number}
     * @memberof BookingList
     */
    'expertId'?: number;
    /**
     * program id
     * @type {number}
     * @memberof BookingList
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof BookingList
     */
    'programName'?: string;
    /**
     * Meeting room join URL
     * @type {string}
     * @memberof BookingList
     */
    'joinUrl'?: string;
    /**
     * booking state
     * @type {string}
     * @memberof BookingList
     */
    'bookingState'?: string;
    /**
     * expert feedback
     * @type {string}
     * @memberof BookingList
     */
    'expertFeedback'?: string;
    /**
     * expert Name
     * @type {string}
     * @memberof BookingList
     */
    'expertName'?: string;
    /**
     * expert img url
     * @type {string}
     * @memberof BookingList
     */
    'expertImgUrl'?: string;
    /**
     * expert type
     * @type {string}
     * @memberof BookingList
     */
    'expertType'?: string;
    /**
     * 
     * @type {Duration}
     * @memberof BookingList
     */
    'durationObject'?: Duration;
    /**
     * booking datetime
     * @type {string}
     * @memberof BookingList
     */
    'bookingDatetime'?: string;
    /**
     * booking date
     * @type {string}
     * @memberof BookingList
     */
    'bookingDate'?: string;
    /**
     * booking date
     * @type {string}
     * @memberof BookingList
     */
    'bookingDateForSort'?: string;
    /**
     * studentRoadmapYn
     * @type {string}
     * @memberof BookingList
     */
    'studentRoadmapYn'?: string;
    /**
     * 
     * @type {MentoringStudent}
     * @memberof BookingList
     */
    'studentObject'?: MentoringStudent;
}
/**
 * 
 * @export
 * @interface CheckoutHistory
 */
export interface CheckoutHistory {
    /**
     * 
     * @type {number}
     * @memberof CheckoutHistory
     */
    'checkoutHistoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutHistory
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'objectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'memberEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'memberName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'memberCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'paymentIntent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'paymentMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'productName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutHistory
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'priceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckoutHistory
     */
    'paid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckoutHistory
     */
    'bookingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutHistory
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface CollegeGpaData
 */
export interface CollegeGpaData {
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'collegeGpaDataId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeGpaData
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'gpa'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satCompRange75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satCompRange25'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satCr75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satCr25'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satM75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'satM25'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'actc75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'actc25'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'acte75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'acte25'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'actm75'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'actm25'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeGpaData
     */
    'accept_rate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeGpaData
     */
    'rank_best_colleges_country'?: number;
}
/**
 * 
 * @export
 * @interface CollegeMajorGrad
 */
export interface CollegeMajorGrad {
    /**
     * 
     * @type {number}
     * @memberof CollegeMajorGrad
     */
    'collegeMajorGradId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeMajorGrad
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeMajorGrad
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeMajorGrad
     */
    'onetMajorCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeMajorGrad
     */
    'onetMajorTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeMajorGrad
     */
    'majorGraduateNum'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeMajorGrad
     */
    'onetMajorId'?: string;
}
/**
 * 
 * @export
 * @interface CollegeNicheIpeds
 */
export interface CollegeNicheIpeds {
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'ialias'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'stateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'addressAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'addressCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'addressState'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'addressRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'addressZipcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'accept'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'acceptRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'actc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'acte'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'actm'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'adminTest'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'admcon1'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'admcon4'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'admcon5'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'anyFinAidPct'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'faidurl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'applicants'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'applicationDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'applfeeu'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'adminurl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'athCon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'athDiv'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'calendar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'citySetting'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'collegeGradRate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'collegeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'collegeLiberalArts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'studentSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'totalUndergrad'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'ftUndergrad'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'ftUndergradPer'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'ptUndergrad'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'ptUndergradPer'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownAfricanAmerican'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownAsian'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownHispanic'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownInternational'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownMultiracial'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownNativeAmerican'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownPacificIslander'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownUnknown'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'diversityBreakdownWhite'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'dormsNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'roomamt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'earlyActionDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'earlyDecisionDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownAssistantProfessors'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownAssociateProfessors'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownInstructors'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownLecturers'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownNoAcademicRank'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'facultyTypeBreakdownProfessors'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'fedGrantAidAmt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'freshSubAct'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'freshSubSat'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeAthletics'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeAcademics'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeCampus'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeOverall'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeProfessors'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeSafety'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'gradeStudentLife'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'isOnline'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'inStateTuit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'level'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'level3'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'level5'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'level7'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'level20'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'npis412'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'npis422'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'npis432'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'npis442'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'npis452'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'npricurl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'offersRegularDecision'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'offersRolling'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'offersEarlyAction'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'offersEarlyDecision'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'offersOnlineCourses'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'distpgs'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'onCampusHousing'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'board'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'stusrv1'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'stusrv2'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'stusrv3'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'outStateTuit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeIvyCountry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeAthleticsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeCampusesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeDormsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeFoodCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegeLocationsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForAccountingAndFinanceCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForAgriculturalSciencesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForAnthropologyAndSociologyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForArchitectureCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForArtCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForBiologyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForBusinessCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForChemistryCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForCommunicationsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForComputerScienceCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForCriminalJusticeCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForCulinaryArtsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForDentalStudiesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForDesignCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForEconomicsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForEducationCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForEngineeringCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForEnglishCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForEnvironmentalScienceCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForFilmAndPhotographyCountry'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForGlobalStudiesCountry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForHistoryCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForInformationTechnologyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForInternationalRelationsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForKinesioAndPhysTherapyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForMathCountry'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForMilitaryAcademyCountry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForMusicCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForNursingCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPerformingArtsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPharmacyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPhilosophyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPremedicineStudiesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPhysicsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPoliticalScienceCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPsychologyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPublicHealthCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForPublicPolicyCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForReligiousStudiesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesForSportsManagementCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesThatAcceptTheCommonAppCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCollegesWithNoApplicationFeeCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestCommunityCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestLiberalArtsCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestOnlineCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestSmallCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestTestOptionalCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankBestValueCollegesCountry'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'rankBestWomensCollegesCountry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankCollegesWithTheBestAcademicsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankCollegesWithTheBestProfessorsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankCollegesWithTheBestStudentLifeCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankMostConservativeCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankMostDiverseCollegesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankSafestCollegeCampusesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankTopPartySchoolsCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankTopPrivateUniversitiesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rankTopPublicUniversitiesCountry'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rotcAirForce'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rotcArmy'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'rotcNavy'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'satCompRange'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'satCr'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'satM'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'studFacRatio'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'collegeClassify'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'satCompRangeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'actcMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'satCompRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeNicheIpeds
     */
    'actcMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'admissionStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'admissionRound'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeNicheIpeds
     */
    'imgUrl'?: string;
    /**
     * 
     * @type {Set<CollegeGpaData>}
     * @memberof CollegeNicheIpeds
     */
    'collegeGpaDatas'?: Set<CollegeGpaData>;
    /**
     * 
     * @type {CollegeGpaData}
     * @memberof CollegeNicheIpeds
     */
    'collegeGpaData'?: CollegeGpaData;
}
/**
 * 
 * @export
 * @interface CollegeSurveyCardData
 */
export interface CollegeSurveyCardData {
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyCardData
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyCardData
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyCardData
     */
    'imgUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyCardData
     */
    'satCompRange'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyCardData
     */
    'actc'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyCardData
     */
    'adminTest'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyCardData
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyCardData
     */
    'acceptRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyCardData
     */
    'weight'?: number;
    /**
     * 
     * @type {ResUserChancesInfo}
     * @memberof CollegeSurveyCardData
     */
    'userChancesInfo'?: ResUserChancesInfo;
}
/**
 * 
 * @export
 * @interface CounselorsTheme
 */
export interface CounselorsTheme {
    /**
     * 
     * @type {number}
     * @memberof CounselorsTheme
     */
    'themeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CounselorsTheme
     */
    'themeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CounselorsTheme
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface CourseCategory
 */
export interface CourseCategory {
    /**
     * 
     * @type {string}
     * @memberof CourseCategory
     */
    'courseCategoryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseCategory
     */
    'subjectCategoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCategory
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface CronofyLoginInfo
 */
export interface CronofyLoginInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CronofyLoginInfo
     */
    'isLogin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CronofyLoginInfo
     */
    'cronofyLoginUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronofyLoginInfo
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronofyLoginInfo
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronofyLoginInfo
     */
    'calendarProvider'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronofyLoginInfo
     */
    'meetingRoomType'?: string;
}
/**
 * 
 * @export
 * @interface DuolingoTestInfo
 */
export interface DuolingoTestInfo {
    /**
     * 
     * @type {number}
     * @memberof DuolingoTestInfo
     */
    'literacyScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof DuolingoTestInfo
     */
    'comprehensionScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof DuolingoTestInfo
     */
    'conversationScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof DuolingoTestInfo
     */
    'productionScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof DuolingoTestInfo
     */
    'overallScore'?: number;
}
/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * duration id
     * @type {number}
     * @memberof Duration
     */
    'durationId'?: number;
    /**
     * duration name
     * @type {string}
     * @memberof Duration
     */
    'durationName'?: string;
    /**
     * display order
     * @type {number}
     * @memberof Duration
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * education id
     * @type {number}
     * @memberof Education
     */
    'educationId'?: number;
    /**
     * college id
     * @type {string}
     * @memberof Education
     */
    'collegeId'?: string;
    /**
     * college name
     * @type {string}
     * @memberof Education
     */
    'collegeName'?: string;
    /**
     * college start date
     * @type {string}
     * @memberof Education
     */
    'startDate'?: string;
    /**
     * college end date
     * @type {string}
     * @memberof Education
     */
    'endDate'?: string;
    /**
     * graduated status
     * @type {string}
     * @memberof Education
     */
    'graduatedYn'?: string;
    /**
     * degree level id
     * @type {string}
     * @memberof Education
     */
    'degreeLevelId'?: string;
    /**
     * degree level name
     * @type {string}
     * @memberof Education
     */
    'degreeLevelName'?: string;
    /**
     * 
     * @type {Array<EducationMajor>}
     * @memberof Education
     */
    'majorObjectList'?: Array<EducationMajor>;
    /**
     * 
     * @type {Array<AttachFile>}
     * @memberof Education
     */
    'certificateFileObjectList'?: Array<AttachFile>;
}
/**
 * 
 * @export
 * @interface EducationDegree
 */
export interface EducationDegree {
    /**
     * 
     * @type {number}
     * @memberof EducationDegree
     */
    'degreeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationDegree
     */
    'degreeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationDegree
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface EducationMajor
 */
export interface EducationMajor {
    /**
     * major id
     * @type {string}
     * @memberof EducationMajor
     */
    'majorId'?: string;
    /**
     * major category id
     * @type {string}
     * @memberof EducationMajor
     */
    'majorCategoryId'?: string;
    /**
     * major name
     * @type {string}
     * @memberof EducationMajor
     */
    'majorName'?: string;
}
/**
 * 
 * @export
 * @interface ExpertBankAccountEntity
 */
export interface ExpertBankAccountEntity {
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertBankAccountEntity
     */
    'bankAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertBankAccountEntity
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'useYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'accountHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'routingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'zipCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertBankAccountEntity
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertBankAccountEntity
     */
    'countryName'?: string;
}
/**
 * 
 * @export
 * @interface ExpertExperience
 */
export interface ExpertExperience {
    /**
     * experience id
     * @type {number}
     * @memberof ExpertExperience
     */
    'experienceId'?: number;
    /**
     * member id
     * @type {number}
     * @memberof ExpertExperience
     */
    'memberId'?: number;
    /**
     * member name
     * @type {string}
     * @memberof ExpertExperience
     */
    'memberName'?: string;
    /**
     * profile photo url
     * @type {string}
     * @memberof ExpertExperience
     */
    'profilePhotoUrl'?: string;
    /**
     * introduction video url
     * @type {string}
     * @memberof ExpertExperience
     */
    'introVideoUrl'?: string;
    /**
     * experience complete rate
     * @type {number}
     * @memberof ExpertExperience
     */
    'completeRate'?: number;
    /**
     * expert type
     * @type {string}
     * @memberof ExpertExperience
     */
    'expertType'?: string;
    /**
     * verification status Id
     * @type {number}
     * @memberof ExpertExperience
     */
    'verificationStatusId'?: number;
    /**
     * verification status name
     * @type {string}
     * @memberof ExpertExperience
     */
    'verificationStatusName'?: string;
    /**
     * profile image status Id
     * @type {number}
     * @memberof ExpertExperience
     */
    'profileImageStatusId'?: number;
    /**
     * profile image status name
     * @type {string}
     * @memberof ExpertExperience
     */
    'profileImageStatusName'?: string;
    /**
     * 
     * @type {Region}
     * @memberof ExpertExperience
     */
    'region'?: Region;
    /**
     * brief introduction
     * @type {string}
     * @memberof ExpertExperience
     */
    'briefIntroduction'?: string;
    /**
     * 
     * @type {Array<ExpertLanguageInfo>}
     * @memberof ExpertExperience
     */
    'languageObjectList'?: Array<ExpertLanguageInfo>;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof ExpertExperience
     */
    'skillObjectList'?: Array<Skill>;
    /**
     * 
     * @type {Array<GetIntoCollege>}
     * @memberof ExpertExperience
     */
    'getIntoObjectList'?: Array<GetIntoCollege>;
    /**
     * 
     * @type {MemberTimeZone}
     * @memberof ExpertExperience
     */
    'memberTimeZone'?: MemberTimeZone;
    /**
     * 
     * @type {Array<AvailabilityTime>}
     * @memberof ExpertExperience
     */
    'availabilityObjectList'?: Array<AvailabilityTime>;
    /**
     * program type
     * @type {string}
     * @memberof ExpertExperience
     */
    'educationlyBackground'?: string;
    /**
     * 
     * @type {Array<WorkExperience>}
     * @memberof ExpertExperience
     */
    'workExperenceObjectList'?: Array<WorkExperience>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof ExpertExperience
     */
    'educationObjectList'?: Array<Education>;
    /**
     * 
     * @type {Array<AttachFile>}
     * @memberof ExpertExperience
     */
    'awardFileObjectList'?: Array<AttachFile>;
    /**
     * 
     * @type {Array<WebSite>}
     * @memberof ExpertExperience
     */
    'websiteObjectList'?: Array<WebSite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertExperience
     */
    'verificationDeniedComments'?: Array<string>;
    /**
     * program type
     * @type {number}
     * @memberof ExpertExperience
     */
    'reviewScore'?: number;
    /**
     * program type
     * @type {number}
     * @memberof ExpertExperience
     */
    'reviewCount'?: number;
    /**
     * total work experience years
     * @type {number}
     * @memberof ExpertExperience
     */
    'totalWorkYears'?: number;
    /**
     * create date
     * @type {string}
     * @memberof ExpertExperience
     */
    'createDate'?: string;
    /**
     * update date
     * @type {string}
     * @memberof ExpertExperience
     */
    'updateDate'?: string;
    /**
     * verified completed date
     * @type {string}
     * @memberof ExpertExperience
     */
    'verifiedCompletedDate'?: string;
}
/**
 * 
 * @export
 * @interface ExpertInfo
 */
export interface ExpertInfo {
    /**
     * expert id
     * @type {number}
     * @memberof ExpertInfo
     */
    'expertId'?: number;
    /**
     * country id
     * @type {number}
     * @memberof ExpertInfo
     */
    'countryId'?: number;
    /**
     * state id
     * @type {number}
     * @memberof ExpertInfo
     */
    'stateId'?: number;
    /**
     * brief introduction
     * @type {string}
     * @memberof ExpertInfo
     */
    'briefIntroduction'?: string;
    /**
     * education background
     * @type {string}
     * @memberof ExpertInfo
     */
    'educationBackground'?: string;
    /**
     * timezone id
     * @type {number}
     * @memberof ExpertInfo
     */
    'timeZoneId'?: number;
    /**
     * state
     * @type {string}
     * @memberof ExpertInfo
     */
    'statusId'?: string;
    /**
     * created at
     * @type {string}
     * @memberof ExpertInfo
     */
    'createdAt'?: string;
    /**
     * updated at
     * @type {string}
     * @memberof ExpertInfo
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ExpertLanguageInfo
 */
export interface ExpertLanguageInfo {
    /**
     * language info id
     * @type {number}
     * @memberof ExpertLanguageInfo
     */
    'languageInfoId'?: number;
    /**
     * 
     * @type {Language}
     * @memberof ExpertLanguageInfo
     */
    'language'?: Language;
    /**
     * 
     * @type {LanguageLevel}
     * @memberof ExpertLanguageInfo
     */
    'languageLevel'?: LanguageLevel;
}
/**
 * 
 * @export
 * @interface ExpertList
 */
export interface ExpertList {
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertList
     */
    'expertType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertList
     */
    'expertName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertList
     */
    'expertImgUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'expertReviewScore'?: number;
    /**
     * 
     * @type {Array<ProgramCategory>}
     * @memberof ExpertList
     */
    'expertCategory'?: Array<ProgramCategory>;
    /**
     * 
     * @type {string}
     * @memberof ExpertList
     */
    'lastCollegeName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertList
     */
    'lastCollegeMajorNames'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'bookingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'reviewCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'programCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'programActiveCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'programInactiveCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'feedbackPendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertList
     */
    'upcommingSessionCount'?: number;
}
/**
 * 
 * @export
 * @interface ExpertRequestQuestion
 */
export interface ExpertRequestQuestion {
    /**
     * id
     * @type {number}
     * @memberof ExpertRequestQuestion
     */
    'id'?: number;
    /**
     * requestSubjectId
     * @type {string}
     * @memberof ExpertRequestQuestion
     */
    'requestQuestionId'?: string;
    /**
     * requestSubjectName
     * @type {string}
     * @memberof ExpertRequestQuestion
     */
    'requestQuestionName'?: string;
    /**
     * requestSubjectName
     * @type {string}
     * @memberof ExpertRequestQuestion
     */
    'subRequestQuestionName'?: string;
    /**
     * objectLevel
     * @type {number}
     * @memberof ExpertRequestQuestion
     */
    'objectLevel'?: number;
    /**
     * displayOrder
     * @type {number}
     * @memberof ExpertRequestQuestion
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface ExpertStatus
 */
export interface ExpertStatus {
    /**
     * expert id
     * @type {number}
     * @memberof ExpertStatus
     */
    'expertId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'individualUser'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'careerVerification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'bankAccountInserted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'programActivated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'calendarIntegrated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertStatus
     */
    'profileDisplayed'?: boolean;
}
/**
 * 
 * @export
 * @interface GetIntoCollege
 */
export interface GetIntoCollege {
    /**
     * college id
     * @type {string}
     * @memberof GetIntoCollege
     */
    'collegeId'?: string;
    /**
     * college name
     * @type {string}
     * @memberof GetIntoCollege
     */
    'collegeName'?: string;
    /**
     * display order
     * @type {number}
     * @memberof GetIntoCollege
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface IeltsTestInfo
 */
export interface IeltsTestInfo {
    /**
     * 
     * @type {number}
     * @memberof IeltsTestInfo
     */
    'readingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof IeltsTestInfo
     */
    'speakingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof IeltsTestInfo
     */
    'listeningScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof IeltsTestInfo
     */
    'writingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof IeltsTestInfo
     */
    'overallBandScore'?: number;
}
/**
 * language
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    'languageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'languageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    'displayOrder'?: number;
}
/**
 * language level
 * @export
 * @interface LanguageLevel
 */
export interface LanguageLevel {
    /**
     * 
     * @type {number}
     * @memberof LanguageLevel
     */
    'languageLevelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageLevel
     */
    'languageLevelName'?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageLevel
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'memberEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'memberName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'memberType'?: MemberMemberTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'joinDt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'withdrawDt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'briefIntroduction'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'stripeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'subscriptionStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    'menuStatus'?: boolean;
    /**
     * 
     * @type {Array<MemberProfileEduHist>}
     * @memberof Member
     */
    'memberProfileEduHistList'?: Array<MemberProfileEduHist>;
}

export const MemberMemberTypeEnum = {
    Student: 'STUDENT',
    Expert: 'EXPERT',
    Parent: 'PARENT'
} as const;

export type MemberMemberTypeEnum = typeof MemberMemberTypeEnum[keyof typeof MemberMemberTypeEnum];

/**
 * 
 * @export
 * @interface MemberCollegeSurveyResult
 */
export interface MemberCollegeSurveyResult {
    /**
     * 
     * @type {number}
     * @memberof MemberCollegeSurveyResult
     */
    'memberCollegeSurveyResultId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberCollegeSurveyResult
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberCollegeSurveyResult
     */
    'collegeId'?: number;
}
/**
 * 선호 경력 리스트
 * @export
 * @interface MemberLikeCareer
 */
export interface MemberLikeCareer {
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCareer
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCareer
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCareer
     */
    'memberLikeCareerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCareer
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCareer
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {OnetCareer}
     * @memberof MemberLikeCareer
     */
    'onetCareer'?: OnetCareer;
}
/**
 * 선호 대학 리스트
 * @export
 * @interface MemberLikeCollege
 */
export interface MemberLikeCollege {
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCollege
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCollege
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCollege
     */
    'memberLikeCollegeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCollege
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCollege
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeCollege
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {CollegeNicheIpeds}
     * @memberof MemberLikeCollege
     */
    'collegeNicheIpeds'?: CollegeNicheIpeds;
    /**
     * 
     * @type {CollegeMajorGrad}
     * @memberof MemberLikeCollege
     */
    'collegeMajorGrad'?: CollegeMajorGrad;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeCollege
     */
    'anlysisReportId'?: number;
}
/**
 * 선호 전공 리스트
 * @export
 * @interface MemberLikeMajor
 */
export interface MemberLikeMajor {
    /**
     * 
     * @type {string}
     * @memberof MemberLikeMajor
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeMajor
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeMajor
     */
    'memberLikeMajorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberLikeMajor
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberLikeMajor
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {OnetMajorcategoryMajor}
     * @memberof MemberLikeMajor
     */
    'onetMajorcategoryMajor'?: OnetMajorcategoryMajor;
}
/**
 * 
 * @export
 * @interface MemberProfileEduHist
 */
export interface MemberProfileEduHist {
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberProfileEduHist
     */
    'memberEduHistId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberProfileEduHist
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberProfileEduHist
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'currentGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'degreeLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'graduationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProfileEduHist
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MemberStatus
 */
export interface MemberStatus {
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberStatus
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'memberStatus'?: MemberStatusMemberStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'email'?: MemberStatusEmailEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'push'?: MemberStatusPushEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberStatus
     */
    'reasonCode'?: MemberStatusReasonCodeEnum;
}

export const MemberStatusMemberStatusEnum = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Deleted: 'DELETED'
} as const;

export type MemberStatusMemberStatusEnum = typeof MemberStatusMemberStatusEnum[keyof typeof MemberStatusMemberStatusEnum];
export const MemberStatusEmailEnum = {
    AllowAll: 'ALLOW_ALL',
    Refuse: 'REFUSE'
} as const;

export type MemberStatusEmailEnum = typeof MemberStatusEmailEnum[keyof typeof MemberStatusEmailEnum];
export const MemberStatusPushEnum = {
    AllowAll: 'ALLOW_ALL',
    Refuse: 'REFUSE'
} as const;

export type MemberStatusPushEnum = typeof MemberStatusPushEnum[keyof typeof MemberStatusPushEnum];
export const MemberStatusReasonCodeEnum = {
    Dar01: 'DAR01',
    Dar02: 'DAR02',
    Dar03: 'DAR03',
    Dar04: 'DAR04',
    Dar05: 'DAR05',
    Dar06: 'DAR06',
    Dar07: 'DAR07',
    Dar08: 'DAR08',
    Dar09: 'DAR09',
    Dar99: 'DAR99'
} as const;

export type MemberStatusReasonCodeEnum = typeof MemberStatusReasonCodeEnum[keyof typeof MemberStatusReasonCodeEnum];

/**
 * 
 * @export
 * @interface MemberTimeZone
 */
export interface MemberTimeZone {
    /**
     * 
     * @type {number}
     * @memberof MemberTimeZone
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberTimeZone
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberTimeZone
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberTimeZone
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface MentorAdvice
 */
export interface MentorAdvice {
    /**
     * 
     * @type {number}
     * @memberof MentorAdvice
     */
    'adviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorAdvice
     */
    'adviceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorAdvice
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface MentoringStudent
 */
export interface MentoringStudent {
    /**
     * student id
     * @type {number}
     * @memberof MentoringStudent
     */
    'studentId'?: number;
    /**
     * student type
     * @type {string}
     * @memberof MentoringStudent
     */
    'studentType'?: string;
    /**
     * student name
     * @type {string}
     * @memberof MentoringStudent
     */
    'studentName'?: string;
    /**
     * student image url
     * @type {string}
     * @memberof MentoringStudent
     */
    'studentImgUrl'?: string;
}
/**
 * 
 * @export
 * @interface MentoringSummary
 */
export interface MentoringSummary {
    /**
     * member id
     * @type {number}
     * @memberof MentoringSummary
     */
    'memberId'?: number;
    /**
     * stand by session count
     * @type {number}
     * @memberof MentoringSummary
     */
    'standBySessionCnt'?: number;
    /**
     * paymentAmountHideYn
     * @type {string}
     * @memberof MentoringSummary
     */
    'paymentAmountHideYn'?: string;
    /**
     * estimatedAmountRange
     * @type {string}
     * @memberof MentoringSummary
     */
    'estimatedAmountRange'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentoringSummary
     */
    'feedbackRequiredCnt'?: number;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'notificationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'messageType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'send'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface OnetCareer
 */
export interface OnetCareer {
    /**
     * 
     * @type {string}
     * @memberof OnetCareer
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareer
     */
    'onetCareerTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareer
     */
    'onetCareerDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof OnetCareer
     */
    'onetCareerSalary'?: number;
    /**
     * 
     * @type {Array<OnetCareerEducationLevel>}
     * @memberof OnetCareer
     */
    'educationLevels'?: Array<OnetCareerEducationLevel>;
    /**
     * 
     * @type {Array<OnetCareerCareerCluster>}
     * @memberof OnetCareer
     */
    'careerClusters'?: Array<OnetCareerCareerCluster>;
    /**
     * 
     * @type {Array<OnetMajorCareer>}
     * @memberof OnetCareer
     */
    'majors'?: Array<OnetMajorCareer>;
    /**
     * 
     * @type {Array<OnetCareerMajorKnowledge>}
     * @memberof OnetCareer
     */
    'knowledges'?: Array<OnetCareerMajorKnowledge>;
}
/**
 * 
 * @export
 * @interface OnetCareerCareerCluster
 */
export interface OnetCareerCareerCluster {
    /**
     * 
     * @type {string}
     * @memberof OnetCareerCareerCluster
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerCareerCluster
     */
    'onetCareerClusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerCareerCluster
     */
    'onetCareerClusterTitle'?: string;
}
/**
 * 
 * @export
 * @interface OnetCareerEducationLevel
 */
export interface OnetCareerEducationLevel {
    /**
     * 
     * @type {string}
     * @memberof OnetCareerEducationLevel
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof OnetCareerEducationLevel
     */
    'onetEducationLevelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnetCareerEducationLevel
     */
    'onetEducationLevelSubId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerEducationLevel
     */
    'onetEducationLevelTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerEducationLevel
     */
    'onetEducationLevelSubtitle'?: string;
}
/**
 * 
 * @export
 * @interface OnetCareerMajorKnowledge
 */
export interface OnetCareerMajorKnowledge {
    /**
     * 
     * @type {string}
     * @memberof OnetCareerMajorKnowledge
     */
    'knowledgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerMajorKnowledge
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetCareerMajorKnowledge
     */
    'knowledgeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OnetCareerMajorKnowledge
     */
    'importance'?: number;
}
/**
 * 
 * @export
 * @interface OnetMajorCareer
 */
export interface OnetMajorCareer {
    /**
     * 
     * @type {string}
     * @memberof OnetMajorCareer
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetMajorCareer
     */
    'onetMajorTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetMajorCareer
     */
    'onetCareerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetMajorCareer
     */
    'onetCareerTitle'?: string;
}
/**
 * 
 * @export
 * @interface OnetMajorcategoryMajor
 */
export interface OnetMajorcategoryMajor {
    /**
     * 
     * @type {string}
     * @memberof OnetMajorcategoryMajor
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetMajorcategoryMajor
     */
    'onetMajorCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnetMajorcategoryMajor
     */
    'onetMajorTitle'?: string;
}
/**
 * 
 * @export
 * @interface PageRecommendedActivity
 */
export interface PageRecommendedActivity {
    /**
     * 
     * @type {number}
     * @memberof PageRecommendedActivity
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRecommendedActivity
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRecommendedActivity
     */
    'size'?: number;
    /**
     * 
     * @type {Array<RecommendedActivity>}
     * @memberof PageRecommendedActivity
     */
    'content'?: Array<RecommendedActivity>;
    /**
     * 
     * @type {number}
     * @memberof PageRecommendedActivity
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageRecommendedActivity
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRecommendedActivity
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRecommendedActivity
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecommendedActivity
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecommendedActivity
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecommendedActivity
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCareer
 */
export interface PageResCareer {
    /**
     * 
     * @type {number}
     * @memberof PageResCareer
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCareer
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCareer
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCareer>}
     * @memberof PageResCareer
     */
    'content'?: Array<ResCareer>;
    /**
     * 
     * @type {number}
     * @memberof PageResCareer
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCareer
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCareer
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCareer
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareer
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareer
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareer
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCareers
 */
export interface PageResCareers {
    /**
     * 
     * @type {number}
     * @memberof PageResCareers
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCareers
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCareers
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCareers>}
     * @memberof PageResCareers
     */
    'content'?: Array<ResCareers>;
    /**
     * 
     * @type {number}
     * @memberof PageResCareers
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCareers
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCareers
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCareers
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareers
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareers
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCareers
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCollege
 */
export interface PageResCollege {
    /**
     * 
     * @type {number}
     * @memberof PageResCollege
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollege
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollege
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCollege>}
     * @memberof PageResCollege
     */
    'content'?: Array<ResCollege>;
    /**
     * 
     * @type {number}
     * @memberof PageResCollege
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCollege
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCollege
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCollege
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollege
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollege
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollege
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCollegeMajorGrad
 */
export interface PageResCollegeMajorGrad {
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGrad
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGrad
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGrad
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCollegeMajorGrad>}
     * @memberof PageResCollegeMajorGrad
     */
    'content'?: Array<ResCollegeMajorGrad>;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGrad
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCollegeMajorGrad
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCollegeMajorGrad
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGrad
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGrad
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGrad
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGrad
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCollegeMajorGradList
 */
export interface PageResCollegeMajorGradList {
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGradList
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGradList
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGradList
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCollegeMajorGradList>}
     * @memberof PageResCollegeMajorGradList
     */
    'content'?: Array<ResCollegeMajorGradList>;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGradList
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCollegeMajorGradList
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCollegeMajorGradList
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeMajorGradList
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGradList
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGradList
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeMajorGradList
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCollegeNicheIpeds
 */
export interface PageResCollegeNicheIpeds {
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeNicheIpeds
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeNicheIpeds
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeNicheIpeds
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCollegeNicheIpeds>}
     * @memberof PageResCollegeNicheIpeds
     */
    'content'?: Array<ResCollegeNicheIpeds>;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeNicheIpeds
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCollegeNicheIpeds
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCollegeNicheIpeds
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCollegeNicheIpeds
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeNicheIpeds
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeNicheIpeds
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCollegeNicheIpeds
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResCountry
 */
export interface PageResCountry {
    /**
     * 
     * @type {number}
     * @memberof PageResCountry
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCountry
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResCountry
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResCountry>}
     * @memberof PageResCountry
     */
    'content'?: Array<ResCountry>;
    /**
     * 
     * @type {number}
     * @memberof PageResCountry
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResCountry
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResCountry
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResCountry
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCountry
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCountry
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResCountry
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResExpertSkill
 */
export interface PageResExpertSkill {
    /**
     * 
     * @type {number}
     * @memberof PageResExpertSkill
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResExpertSkill
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResExpertSkill
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResExpertSkill>}
     * @memberof PageResExpertSkill
     */
    'content'?: Array<ResExpertSkill>;
    /**
     * 
     * @type {number}
     * @memberof PageResExpertSkill
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResExpertSkill
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResExpertSkill
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResExpertSkill
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResExpertSkill
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResExpertSkill
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResExpertSkill
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResHighSchool
 */
export interface PageResHighSchool {
    /**
     * 
     * @type {number}
     * @memberof PageResHighSchool
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResHighSchool
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResHighSchool
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResHighSchool>}
     * @memberof PageResHighSchool
     */
    'content'?: Array<ResHighSchool>;
    /**
     * 
     * @type {number}
     * @memberof PageResHighSchool
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResHighSchool
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResHighSchool
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResHighSchool
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResHighSchool
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResHighSchool
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResHighSchool
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResMajor
 */
export interface PageResMajor {
    /**
     * 
     * @type {number}
     * @memberof PageResMajor
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResMajor
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResMajor
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResMajor>}
     * @memberof PageResMajor
     */
    'content'?: Array<ResMajor>;
    /**
     * 
     * @type {number}
     * @memberof PageResMajor
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResMajor
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResMajor
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResMajor
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResMajor
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResMajor
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResMajor
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResState
 */
export interface PageResState {
    /**
     * 
     * @type {number}
     * @memberof PageResState
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResState
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResState
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResState>}
     * @memberof PageResState
     */
    'content'?: Array<ResState>;
    /**
     * 
     * @type {number}
     * @memberof PageResState
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResState
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResState
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResState
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResState
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResState
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResState
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageResSubjectCategory
 */
export interface PageResSubjectCategory {
    /**
     * 
     * @type {number}
     * @memberof PageResSubjectCategory
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResSubjectCategory
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageResSubjectCategory
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ResSubjectCategory>}
     * @memberof PageResSubjectCategory
     */
    'content'?: Array<ResSubjectCategory>;
    /**
     * 
     * @type {number}
     * @memberof PageResSubjectCategory
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageResSubjectCategory
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageResSubjectCategory
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageResSubjectCategory
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageResSubjectCategory
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResSubjectCategory
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageResSubjectCategory
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface ProgramBooking
 */
export interface ProgramBooking {
    /**
     * 
     * @type {number}
     * @memberof ProgramBooking
     */
    'programId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'programName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramBooking
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'expertName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'programType'?: string;
    /**
     * 
     * @type {Array<ProgramCategory>}
     * @memberof ProgramBooking
     */
    'programCategoryObjectList'?: Array<ProgramCategory>;
    /**
     * 
     * @type {Array<AvailabilityBookingTime>}
     * @memberof ProgramBooking
     */
    'bookingTimeList'?: Array<AvailabilityBookingTime>;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'desc'?: string;
    /**
     * 
     * @type {Duration}
     * @memberof ProgramBooking
     */
    'durationObject'?: Duration;
    /**
     * 
     * @type {number}
     * @memberof ProgramBooking
     */
    'programPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'profilePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramBooking
     */
    'studentTimeZoneRegion'?: string;
}
/**
 * 
 * @export
 * @interface ProgramCategory
 */
export interface ProgramCategory {
    /**
     * program category id
     * @type {number}
     * @memberof ProgramCategory
     */
    'categoryId'?: number;
    /**
     * program category name
     * @type {string}
     * @memberof ProgramCategory
     */
    'categoryName'?: string;
    /**
     * program display order
     * @type {number}
     * @memberof ProgramCategory
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface ProgramCount
 */
export interface ProgramCount {
    /**
     * 
     * @type {number}
     * @memberof ProgramCount
     */
    'programCount'?: number;
}
/**
 * 
 * @export
 * @interface ProgramDetail
 */
export interface ProgramDetail {
    /**
     * program id
     * @type {number}
     * @memberof ProgramDetail
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof ProgramDetail
     */
    'programName'?: string;
    /**
     * program type
     * @type {string}
     * @memberof ProgramDetail
     */
    'programType'?: string;
    /**
     * 
     * @type {Array<ProgramCategory>}
     * @memberof ProgramDetail
     */
    'programCategoryObjectList'?: Array<ProgramCategory>;
    /**
     * program description
     * @type {string}
     * @memberof ProgramDetail
     */
    'desc'?: string;
    /**
     * 
     * @type {Duration}
     * @memberof ProgramDetail
     */
    'durationObject'?: Duration;
    /**
     * program price
     * @type {number}
     * @memberof ProgramDetail
     */
    'programPrice'?: number;
    /**
     * program active YN
     * @type {string}
     * @memberof ProgramDetail
     */
    'activeYn'?: string;
    /**
     * program free YN
     * @type {string}
     * @memberof ProgramDetail
     */
    'freeYn'?: string;
    /**
     * create date
     * @type {string}
     * @memberof ProgramDetail
     */
    'createDate'?: string;
    /**
     * update date
     * @type {string}
     * @memberof ProgramDetail
     */
    'updateDate'?: string;
}
/**
 * 
 * @export
 * @interface ProgramInput
 */
export interface ProgramInput {
    /**
     * program id
     * @type {number}
     * @memberof ProgramInput
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof ProgramInput
     */
    'programName'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramInput
     */
    'categoryIds'?: Array<number>;
    /**
     * program description
     * @type {string}
     * @memberof ProgramInput
     */
    'desc'?: string;
    /**
     * program active YN
     * @type {string}
     * @memberof ProgramInput
     */
    'activeYn'?: string;
    /**
     * duration id
     * @type {number}
     * @memberof ProgramInput
     */
    'durationId'?: number;
    /**
     * program price
     * @type {number}
     * @memberof ProgramInput
     */
    'programPrice'?: number;
    /**
     * program free YN
     * @type {string}
     * @memberof ProgramInput
     */
    'freeYn'?: string;
}
/**
 * 
 * @export
 * @interface ProgramList
 */
export interface ProgramList {
    /**
     * program id
     * @type {number}
     * @memberof ProgramList
     */
    'programId'?: number;
    /**
     * program name
     * @type {string}
     * @memberof ProgramList
     */
    'programName'?: string;
    /**
     * program type
     * @type {string}
     * @memberof ProgramList
     */
    'programType'?: string;
    /**
     * description
     * @type {string}
     * @memberof ProgramList
     */
    'description'?: string;
    /**
     * update date
     * @type {string}
     * @memberof ProgramList
     */
    'updateDate'?: string;
    /**
     * 
     * @type {Array<ProgramCategory>}
     * @memberof ProgramList
     */
    'programCategoryObjectList'?: Array<ProgramCategory>;
    /**
     * program active YN
     * @type {string}
     * @memberof ProgramList
     */
    'activeYn'?: string;
    /**
     * program active YN
     * @type {number}
     * @memberof ProgramList
     */
    'programPrice'?: number;
    /**
     * 
     * @type {Duration}
     * @memberof ProgramList
     */
    'programDuration'?: Duration;
}
/**
 * 
 * @export
 * @interface ProgramReviewDto
 */
export interface ProgramReviewDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'reviewId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'programId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'expertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'studentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'bookingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewDto
     */
    'studentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewDto
     */
    'studentImgUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewDto
     */
    'reviewScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewDto
     */
    'reviewDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewDto
     */
    'programTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewDto
     */
    'review'?: string;
}
/**
 * 
 * @export
 * @interface ProgramReviewList
 */
export interface ProgramReviewList {
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'reviewId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'programId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'expertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'studentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'bookingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'studentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'studentImgUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramReviewList
     */
    'reviewScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'reviewDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'programTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'review'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramReviewList
     */
    'review_date'?: string;
}
/**
 * 
 * @export
 * @interface RecommendedActivity
 */
export interface RecommendedActivity {
    /**
     * 
     * @type {number}
     * @memberof RecommendedActivity
     */
    'recommendedActivityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendedActivity
     */
    'stateGroupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'activityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'subjectCategoryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'strengthName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'stateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'onlineOnlyYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'sessionLength'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'recommendGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'premiumYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'listingType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'registrationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'registrationEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendedActivity
     */
    'memberAddedYn'?: string;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * country id
     * @type {number}
     * @memberof Region
     */
    'countryId'?: number;
    /**
     * country name
     * @type {string}
     * @memberof Region
     */
    'countryName'?: string;
    /**
     * country abbreviation
     * @type {string}
     * @memberof Region
     */
    'countryShortName'?: string;
    /**
     * state id
     * @type {number}
     * @memberof Region
     */
    'stateId'?: number;
    /**
     * state name
     * @type {string}
     * @memberof Region
     */
    'stateName'?: string;
}
/**
 * 
 * @export
 * @interface ReqBookingCancel
 */
export interface ReqBookingCancel {
    /**
     * 
     * @type {number}
     * @memberof ReqBookingCancel
     */
    'bookingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqBookingCancel
     */
    'reasonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqBookingCancel
     */
    'reasonNote'?: string;
}
/**
 * 
 * @export
 * @interface ReqCancelSubParam
 */
export interface ReqCancelSubParam {
    /**
     * 
     * @type {string}
     * @memberof ReqCancelSubParam
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface ReqCareerLike
 */
export interface ReqCareerLike {
    /**
     * 
     * @type {string}
     * @memberof ReqCareerLike
     */
    'careerId'?: string;
}
/**
 * 
 * @export
 * @interface ReqCareerSurveyResult
 */
export interface ReqCareerSurveyResult {
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question14'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question15'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question16'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question17'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question18'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question19'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question20'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question21'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question22'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question23'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question24'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question25'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question26'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question27'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question28'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question29'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question30'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question31'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question32'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question33'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question34'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question35'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question36'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question37'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question38'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question39'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question40'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question41'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question42'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question43'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question44'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question45'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question46'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question47'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question48'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question49'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question50'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question51'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question52'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question53'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question54'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question55'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question56'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question57'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question58'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question59'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCareerSurveyResult
     */
    'question60'?: number;
}
/**
 * 
 * @export
 * @interface ReqChangeNotification
 */
export interface ReqChangeNotification {
    /**
     * Email 허용 여부 / NotificationStatusType의 코드로 전달
     * @type {string}
     * @memberof ReqChangeNotification
     */
    'email'?: string;
    /**
     * push 허용 여부 / NotificationStatusType의 코드로 전달
     * @type {string}
     * @memberof ReqChangeNotification
     */
    'push'?: string;
}
/**
 * 
 * @export
 * @interface ReqCheckoutParam
 */
export interface ReqCheckoutParam {
    /**
     * 상품종류
     * @type {string}
     * @memberof ReqCheckoutParam
     */
    'product'?: ReqCheckoutParamProductEnum;
    /**
     * 성공시 redirect 주소
     * @type {string}
     * @memberof ReqCheckoutParam
     */
    'successUrl'?: string;
    /**
     * 실패시 redirect 주소
     * @type {string}
     * @memberof ReqCheckoutParam
     */
    'failUrl'?: string;
    /**
     * 무료평가기간 사용 여부
     * @type {boolean}
     * @memberof ReqCheckoutParam
     */
    'useTrial'?: boolean;
}

export const ReqCheckoutParamProductEnum = {
    MonthlySubscription: 'MONTHLY_SUBSCRIPTION',
    YearSubscription: 'YEAR_SUBSCRIPTION',
    TestSubscription: 'TEST_SUBSCRIPTION',
    Report4: 'REPORT4',
    Report8: 'REPORT8'
} as const;

export type ReqCheckoutParamProductEnum = typeof ReqCheckoutParamProductEnum[keyof typeof ReqCheckoutParamProductEnum];

/**
 * 
 * @export
 * @interface ReqCollegeLike
 */
export interface ReqCollegeLike {
    /**
     * 
     * @type {number}
     * @memberof ReqCollegeLike
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqCollegeLike
     */
    'onetMajorId'?: string;
}
/**
 * 
 * @export
 * @interface ReqCollegeSurveyViewMore
 */
export interface ReqCollegeSurveyViewMore {
    /**
     * 
     * @type {Array<CollegeSurveyCardData>}
     * @memberof ReqCollegeSurveyViewMore
     */
    'reachCollegeList'?: Array<CollegeSurveyCardData>;
    /**
     * 
     * @type {Array<CollegeSurveyCardData>}
     * @memberof ReqCollegeSurveyViewMore
     */
    'targetCollegeList'?: Array<CollegeSurveyCardData>;
    /**
     * 
     * @type {Array<CollegeSurveyCardData>}
     * @memberof ReqCollegeSurveyViewMore
     */
    'safetyCollegeList'?: Array<CollegeSurveyCardData>;
    /**
     * 
     * @type {number}
     * @memberof ReqCollegeSurveyViewMore
     */
    'reachCollegeCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCollegeSurveyViewMore
     */
    'targetCollegeCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqCollegeSurveyViewMore
     */
    'safetyCollegeCnt'?: number;
}
/**
 * 
 * @export
 * @interface ReqDeleteAccount
 */
export interface ReqDeleteAccount {
    /**
     * Deactivate / Delete account Reason for Leaving 물음 답변 - 라디오버튼 선택항목
     * @type {string}
     * @memberof ReqDeleteAccount
     */
    'reasonCode'?: string;
    /**
     * Deactivate / Delete account Reason for Leaving 물음 답변 - Others 선택시 필수
     * @type {string}
     * @memberof ReqDeleteAccount
     */
    'reason'?: string;
    /**
     * Email 허용 여부 / NotificationStatusType의 코드로 전달
     * @type {string}
     * @memberof ReqDeleteAccount
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ReqEmailCodeIssued
 */
export interface ReqEmailCodeIssued {
    /**
     * 
     * @type {string}
     * @memberof ReqEmailCodeIssued
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ReqEmailCodeValid
 */
export interface ReqEmailCodeValid {
    /**
     * 
     * @type {string}
     * @memberof ReqEmailCodeValid
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ReqEmailCodeValid
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface ReqExpertProfile
 */
export interface ReqExpertProfile {
    /**
     * 
     * @type {string}
     * @memberof ReqExpertProfile
     */
    'memberName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqExpertProfile
     */
    'countryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReqExpertProfile
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqExpertProfile
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqExpertProfile
     */
    'briefIntroduction'?: string;
}
/**
 * 
 * @export
 * @interface ReqExpertSurvey
 */
export interface ReqExpertSurvey {
    /**
     * How did you hear about us? 물음 답변
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'findOut': string;
    /**
     * How did you hear about us? - other 추가 설명
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'findOutDesc'?: string;
    /**
     * 이름
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'name': string;
    /**
     * 국가
     * @type {number}
     * @memberof ReqExpertSurvey
     */
    'countryId': number;
    /**
     * 지역
     * @type {number}
     * @memberof ReqExpertSurvey
     */
    'stateId'?: number;
    /**
     * 주소(옵션)
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'address'?: string;
    /**
     * 도움이 필요한 항목(What do you need help with?)
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'helpNeed': string;
    /**
     * What do you need help with?(부모, 전문가) - other 추가 설명
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * 요약정보
     * @type {string}
     * @memberof ReqExpertSurvey
     */
    'briefIntroduction'?: string;
}
/**
 * 
 * @export
 * @interface ReqGraduateCollege
 */
export interface ReqGraduateCollege {
    /**
     * 
     * @type {number}
     * @memberof ReqGraduateCollege
     */
    'collegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqGraduateCollege
     */
    'collegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqGraduateCollege
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqGraduateCollege
     */
    'degreeLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqGraduateCollege
     */
    'graduationDate'?: string;
}
/**
 * 
 * @export
 * @interface ReqMemberCollegeAdmission
 */
export interface ReqMemberCollegeAdmission {
    /**
     * college 식별 번호
     * @type {number}
     * @memberof ReqMemberCollegeAdmission
     */
    'ipedsCollegeId'?: number;
    /**
     * Admission status
     * @type {string}
     * @memberof ReqMemberCollegeAdmission
     */
    'status'?: string;
    /**
     * Admission round
     * @type {string}
     * @memberof ReqMemberCollegeAdmission
     */
    'round'?: string;
}
/**
 * 
 * @export
 * @interface ReqMemberCollegeSurvey
 */
export interface ReqMemberCollegeSurvey {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReqMemberCollegeSurvey
     */
    'addressRegions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReqMemberCollegeSurvey
     */
    'citySettings'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReqMemberCollegeSurvey
     */
    'studentSize'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'gpa'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqMemberCollegeSurvey
     */
    'waiverYn'?: string;
    /**
     * 
     * @type {DuolingoTestInfo}
     * @memberof ReqMemberCollegeSurvey
     */
    'duolingoTestInfo'?: DuolingoTestInfo;
    /**
     * 
     * @type {ToeflTestInfo}
     * @memberof ReqMemberCollegeSurvey
     */
    'toeflTestInfo'?: ToeflTestInfo;
    /**
     * 
     * @type {IeltsTestInfo}
     * @memberof ReqMemberCollegeSurvey
     */
    'ieltsTestInfo'?: IeltsTestInfo;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'satRwScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'satMathScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'actReadingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'actMathScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'actEnglishScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'actScienceScore'?: number;
    /**
     * 
     * @type {Array<ApTestScore>}
     * @memberof ReqMemberCollegeSurvey
     */
    'apTestScoreList'?: Array<ApTestScore>;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'athleticsScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqMemberCollegeSurvey
     */
    'academicsScore'?: number;
}
/**
 * 학교정보
 * @export
 * @interface ReqParentEduHist
 */
export interface ReqParentEduHist {
    /**
     * 학교일련번호
     * @type {number}
     * @memberof ReqParentEduHist
     */
    'collegeId'?: number;
    /**
     * 학교이름(학교조회가 되지 않을 경우 입력)
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'collegeName'?: string;
    /**
     * 레벨
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'degreeLevel'?: string;
    /**
     * 졸업일자 (MM/YYYY)
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'graduationDate'?: string;
    /**
     * 전공
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'majorId'?: string;
    /**
     * 재학여부(부모 - I am currently studying in this college.)
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'inCollegeYn'?: string;
    /**
     * 학교 지역
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'state'?: string;
    /**
     * 기본 주소
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'address'?: string;
    /**
     * 상세 주소
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'addressDesc'?: string;
    /**
     * 우편번호
     * @type {string}
     * @memberof ReqParentEduHist
     */
    'zipcode'?: string;
}
/**
 * 
 * @export
 * @interface ReqParentProfile
 */
export interface ReqParentProfile {
    /**
     * 
     * @type {string}
     * @memberof ReqParentProfile
     */
    'memberName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqParentProfile
     */
    'countryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReqParentProfile
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqParentProfile
     */
    'address'?: string;
    /**
     * 
     * @type {Array<ReqGraduateCollege>}
     * @memberof ReqParentProfile
     */
    'reqGraduateCollegeList'?: Array<ReqGraduateCollege>;
}
/**
 * 
 * @export
 * @interface ReqParentSurvey
 */
export interface ReqParentSurvey {
    /**
     * How did you hear about us? 물음 답변
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'findOut': string;
    /**
     * How did you hear about us? - other 추가 설명
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'findOutDesc'?: string;
    /**
     * 이름
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'name': string;
    /**
     * 국가
     * @type {number}
     * @memberof ReqParentSurvey
     */
    'countryId': number;
    /**
     * 지역
     * @type {number}
     * @memberof ReqParentSurvey
     */
    'stateId'?: number;
    /**
     * 주소(옵션)
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'address'?: string;
    /**
     * 도움이 필요한 항목(What do you need help with?)
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'helpNeed': string;
    /**
     * What do you need help with?(부모, 전문가) - other 추가 설명
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * 꿈의 대학
     * @type {number}
     * @memberof ReqParentSurvey
     */
    'targetSchoolId'?: number;
    /**
     * 꿈의 대학
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'targetMajorId'?: string;
    /**
     * 꿈의 커리어
     * @type {string}
     * @memberof ReqParentSurvey
     */
    'targetCareerId'?: string;
    /**
     * 학교정보
     * @type {Array<ReqParentEduHist>}
     * @memberof ReqParentSurvey
     */
    'parentEduHists'?: Array<ReqParentEduHist>;
}
/**
 * 
 * @export
 * @interface ReqProgramBooking
 */
export interface ReqProgramBooking {
    /**
     * 
     * @type {number}
     * @memberof ReqProgramBooking
     */
    'programId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqProgramBooking
     */
    'studentRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqProgramBooking
     */
    'bookingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqProgramBooking
     */
    'bookingTime'?: string;
    /**
     * 
     * @type {Array<AttachFile>}
     * @memberof ReqProgramBooking
     */
    'attachFileList'?: Array<AttachFile>;
}
/**
 * 
 * @export
 * @interface ReqProgramReviewDto
 */
export interface ReqProgramReviewDto {
    /**
     * 
     * @type {number}
     * @memberof ReqProgramReviewDto
     */
    'bookingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqProgramReviewDto
     */
    'reviewScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqProgramReviewDto
     */
    'review'?: string;
}
/**
 * 
 * @export
 * @interface ReqRoadmap
 */
export interface ReqRoadmap {
    /**
     * Dream College Id
     * @type {number}
     * @memberof ReqRoadmap
     */
    'dreamIpedsCollegeId'?: number;
    /**
     * Dream Major Id
     * @type {string}
     * @memberof ReqRoadmap
     */
    'dreamOnetMajorId'?: string;
    /**
     * Semester List Id ( 1.9.1 ( 9 Grade 1st ))
     * @type {string}
     * @memberof ReqRoadmap
     */
    'semesterListId'?: string;
}
/**
 * 
 * @export
 * @interface ReqRoadmapAct
 */
export interface ReqRoadmapAct {
    /**
     * DisplayName
     * @type {string}
     * @memberof ReqRoadmapAct
     */
    'displayName'?: ReqRoadmapActDisplayNameEnum;
    /**
     * ACT English
     * @type {number}
     * @memberof ReqRoadmapAct
     */
    'actEnglish'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ReqRoadmapAct
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ReqRoadmapAct
     */
    'actScience'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ReqRoadmapAct
     */
    'actMath'?: number;
}

export const ReqRoadmapActDisplayNameEnum = {
    Sat: 'SAT',
    Act: 'ACT'
} as const;

export type ReqRoadmapActDisplayNameEnum = typeof ReqRoadmapActDisplayNameEnum[keyof typeof ReqRoadmapActDisplayNameEnum];

/**
 * 
 * @export
 * @interface ReqRoadmapActivity
 */
export interface ReqRoadmapActivity {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivity
     */
    'activityId'?: number;
    /**
     * 액티비티 명
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'activityName': string;
    /**
     * 액티비티 타입 ID
     * @type {number}
     * @memberof ReqRoadmapActivity
     */
    'activityTypeId'?: number;
    /**
     * 액티비티 메모
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'memo'?: string;
    /**
     * 액티비티 웹사이트 주소
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'website'?: string;
    /**
     * 액티비티 완료 여부
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'completedYn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivity
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'onlyOnlineActivityYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'startDt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapActivity
     */
    'endDt'?: string;
    /**
     * 
     * @type {ReqRoadmapActivityCourse}
     * @memberof ReqRoadmapActivity
     */
    'roadmapActivityCourse'?: ReqRoadmapActivityCourse;
    /**
     * Strength Point 정보
     * @type {Array<ReqRoadmapActivityStrengthPoint>}
     * @memberof ReqRoadmapActivity
     */
    'roadmapActivityStrengthPoints'?: Array<ReqRoadmapActivityStrengthPoint>;
}
/**
 * Related Courses 정보
 * @export
 * @interface ReqRoadmapActivityCourse
 */
export interface ReqRoadmapActivityCourse {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivityCourse
     */
    'activityId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReqRoadmapActivityCourse
     */
    'subjectCategoryIds'?: Array<string>;
}
/**
 * Strength Point 정보
 * @export
 * @interface ReqRoadmapActivityStrengthPoint
 */
export interface ReqRoadmapActivityStrengthPoint {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivityStrengthPoint
     */
    'activityStrengthPointId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivityStrengthPoint
     */
    'activityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapActivityStrengthPoint
     */
    'strengthId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReqRoadmapActivityStrengthPoint
     */
    'strengthPointIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReqRoadmapAnalysisReport
 */
export interface ReqRoadmapAnalysisReport {
    /**
     * College Id
     * @type {number}
     * @memberof ReqRoadmapAnalysisReport
     */
    'ipedsCollegeId'?: number;
    /**
     * Major Id
     * @type {string}
     * @memberof ReqRoadmapAnalysisReport
     */
    'onetMajorId': string;
    /**
     * Semester List Id 값 [1.1.1] ( 11th Grade 1st semester )
     * @type {string}
     * @memberof ReqRoadmapAnalysisReport
     */
    'semesterListId': string;
    /**
     * Favorite YN
     * @type {string}
     * @memberof ReqRoadmapAnalysisReport
     */
    'favoriteYn': string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapAnalysisReport
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReqRoadmapAnalysisReport
     */
    'activityIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ReqRoadmapAnalysisReportAct
 */
export interface ReqRoadmapAnalysisReportAct {
    /**
     * College Id
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'ipedsCollegeId'?: number;
    /**
     * Major Id
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'onetMajorId': string;
    /**
     * Semester List Id 값 [1.1.1] ( 11th Grade 1st semester )
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'semesterListId': string;
    /**
     * Favorite YN
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'favoriteYn': string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'testTypeId'?: number;
    /**
     * ACT English
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'actEnglish'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'actScience'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'actMath'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReqRoadmapAnalysisReportAct
     */
    'activityIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ReqRoadmapAnalysisReportSat
 */
export interface ReqRoadmapAnalysisReportSat {
    /**
     * College Id
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'ipedsCollegeId'?: number;
    /**
     * Major Id
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'onetMajorId': string;
    /**
     * Semester List Id 값 [1.1.1] ( 11th Grade 1st semester )
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'semesterListId': string;
    /**
     * Favorite YN
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'favoriteYn': string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'targetGpa': string;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'testTypeId'?: number;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'satMath'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReqRoadmapAnalysisReportSat
     */
    'activityIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ReqRoadmapAward
 */
export interface ReqRoadmapAward {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapAward
     */
    'awardId'?: number;
    /**
     * Award Title
     * @type {string}
     * @memberof ReqRoadmapAward
     */
    'title': string;
    /**
     * Grade Id
     * @type {number}
     * @memberof ReqRoadmapAward
     */
    'gradeId'?: number;
    /**
     * Level Id
     * @type {number}
     * @memberof ReqRoadmapAward
     */
    'awardLevelId'?: number;
}
/**
 * 
 * @export
 * @interface ReqRoadmapCourse
 */
export interface ReqRoadmapCourse {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapCourse
     */
    'courseId'?: number;
    /**
     * Add Course By 항목
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'courseByType'?: ReqRoadmapCourseCourseByTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapCourse
     */
    'courseWorkId': number;
    /**
     * Subject Category Id
     * @type {number}
     * @memberof ReqRoadmapCourse
     */
    'subjectCategoryId'?: number;
    /**
     * 코스카테고리Id
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'courseCategoryId': string;
    /**
     * 코스명
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'courseName': string;
    /**
     * 학년
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'grade': ReqRoadmapCourseGradeEnum;
    /**
     * 학기
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'semester': ReqRoadmapCourseSemesterEnum;
    /**
     * 수강 여부[Y,N]
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'courseCompleteYn'?: string;
    /**
     * 점수
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'score'?: string;
    /**
     * 점수등급
     * @type {string}
     * @memberof ReqRoadmapCourse
     */
    'scoreGrade'?: string;
    /**
     * 정렬
     * @type {number}
     * @memberof ReqRoadmapCourse
     */
    'orderNumber'?: number;
}

export const ReqRoadmapCourseCourseByTypeEnum = {
    Semester: 'SEMESTER',
    Year: 'YEAR'
} as const;

export type ReqRoadmapCourseCourseByTypeEnum = typeof ReqRoadmapCourseCourseByTypeEnum[keyof typeof ReqRoadmapCourseCourseByTypeEnum];
export const ReqRoadmapCourseGradeEnum = {
    _9: 'GRADE_9',
    _10: 'GRADE_10',
    _11: 'GRADE_11',
    _12: 'GRADE_12'
} as const;

export type ReqRoadmapCourseGradeEnum = typeof ReqRoadmapCourseGradeEnum[keyof typeof ReqRoadmapCourseGradeEnum];
export const ReqRoadmapCourseSemesterEnum = {
    FirstSemester: 'FIRST_SEMESTER',
    Semester: 'SEMESTER',
    Trimester: 'TRIMESTER',
    Quarter: 'QUARTER'
} as const;

export type ReqRoadmapCourseSemesterEnum = typeof ReqRoadmapCourseSemesterEnum[keyof typeof ReqRoadmapCourseSemesterEnum];

/**
 * 
 * @export
 * @interface ReqRoadmapSat
 */
export interface ReqRoadmapSat {
    /**
     * DisplayName
     * @type {string}
     * @memberof ReqRoadmapSat
     */
    'displayName'?: ReqRoadmapSatDisplayNameEnum;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ReqRoadmapSat
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ReqRoadmapSat
     */
    'satMath'?: number;
}

export const ReqRoadmapSatDisplayNameEnum = {
    Sat: 'SAT',
    Act: 'ACT'
} as const;

export type ReqRoadmapSatDisplayNameEnum = typeof ReqRoadmapSatDisplayNameEnum[keyof typeof ReqRoadmapSatDisplayNameEnum];

/**
 * 
 * @export
 * @interface ReqRoadmapTestScoreAct
 */
export interface ReqRoadmapTestScoreAct {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapTestScoreAct
     */
    'testTypeId'?: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof ReqRoadmapTestScoreAct
     */
    'testDate'?: string;
    /**
     * Test Plan 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreAct
     */
    'planYn'?: string;
    /**
     * Test Plan Enter test results 체크 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreAct
     */
    'testResultYn'?: string;
    /**
     * ACT English
     * @type {number}
     * @memberof ReqRoadmapTestScoreAct
     */
    'actEnglish'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ReqRoadmapTestScoreAct
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ReqRoadmapTestScoreAct
     */
    'actScience'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ReqRoadmapTestScoreAct
     */
    'actMath'?: number;
}
/**
 * 
 * @export
 * @interface ReqRoadmapTestScoreAp
 */
export interface ReqRoadmapTestScoreAp {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapTestScoreAp
     */
    'testTypeId'?: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof ReqRoadmapTestScoreAp
     */
    'testDate'?: string;
    /**
     * Test Plan 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreAp
     */
    'planYn'?: string;
    /**
     * Test Plan Enter test results 체크 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreAp
     */
    'testResultYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqRoadmapTestScoreAp
     */
    'aptestSubjectId'?: string;
    /**
     * AP Score
     * @type {number}
     * @memberof ReqRoadmapTestScoreAp
     */
    'apScore'?: number;
}
/**
 * 
 * @export
 * @interface ReqRoadmapTestScoreSat
 */
export interface ReqRoadmapTestScoreSat {
    /**
     * 
     * @type {number}
     * @memberof ReqRoadmapTestScoreSat
     */
    'testTypeId'?: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof ReqRoadmapTestScoreSat
     */
    'testDate'?: string;
    /**
     * Test Plan 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreSat
     */
    'planYn'?: string;
    /**
     * Test Plan Enter test results 체크 여부
     * @type {string}
     * @memberof ReqRoadmapTestScoreSat
     */
    'testResultYn'?: string;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ReqRoadmapTestScoreSat
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ReqRoadmapTestScoreSat
     */
    'satMath'?: number;
}
/**
 * 
 * @export
 * @interface ReqSignInSocial
 */
export interface ReqSignInSocial {
    /**
     * 
     * @type {string}
     * @memberof ReqSignInSocial
     */
    'idToken': string;
}
/**
 * 
 * @export
 * @interface ReqSignUp
 */
export interface ReqSignUp {
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'idToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'authCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'memberType'?: ReqSignUpMemberTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'memberName': string;
    /**
     * 
     * @type {string}
     * @memberof ReqSignUp
     */
    'expertType'?: ReqSignUpExpertTypeEnum;
}

export const ReqSignUpMemberTypeEnum = {
    Student: 'STUDENT',
    Expert: 'EXPERT',
    Parent: 'PARENT'
} as const;

export type ReqSignUpMemberTypeEnum = typeof ReqSignUpMemberTypeEnum[keyof typeof ReqSignUpMemberTypeEnum];
export const ReqSignUpExpertTypeEnum = {
    Mentor: 'MENTOR',
    Tutor: 'TUTOR',
    Counselor: 'COUNSELOR'
} as const;

export type ReqSignUpExpertTypeEnum = typeof ReqSignUpExpertTypeEnum[keyof typeof ReqSignUpExpertTypeEnum];

/**
 * 
 * @export
 * @interface ReqSocialIdTokenValid
 */
export interface ReqSocialIdTokenValid {
    /**
     * 
     * @type {string}
     * @memberof ReqSocialIdTokenValid
     */
    'idToken': string;
}
/**
 * 학교정보
 * @export
 * @interface ReqStudentEduHist
 */
export interface ReqStudentEduHist {
    /**
     * 학교일련번호
     * @type {number}
     * @memberof ReqStudentEduHist
     */
    'schoolId'?: number;
    /**
     * 학교이름(학교조회가 되지 않을 경우 입력)
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'schoolName'?: string;
    /**
     * 학점
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'currentGrade'?: string;
    /**
     * 졸업일자 (MM/YYYY)
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'graduationDate'?: string;
    /**
     * 학교 지역
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'state'?: string;
    /**
     * 기본 주소
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'address'?: string;
    /**
     * 상세 주소
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'addressDesc'?: string;
    /**
     * 우편번호
     * @type {string}
     * @memberof ReqStudentEduHist
     */
    'zipcode'?: string;
}
/**
 * 
 * @export
 * @interface ReqStudentProfile
 */
export interface ReqStudentProfile {
    /**
     * 
     * @type {string}
     * @memberof ReqStudentProfile
     */
    'memberName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqStudentProfile
     */
    'countryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReqStudentProfile
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqStudentProfile
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReqStudentProfile
     */
    'schoolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReqStudentProfile
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqStudentProfile
     */
    'currentGrade': string;
    /**
     * 
     * @type {string}
     * @memberof ReqStudentProfile
     */
    'graduationDate': string;
}
/**
 * 
 * @export
 * @interface ReqStudentSurvey
 */
export interface ReqStudentSurvey {
    /**
     * How did you hear about us? 물음 답변
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'findOut': string;
    /**
     * How did you hear about us? - other 추가 설명
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'findOutDesc'?: string;
    /**
     * 이름
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'name': string;
    /**
     * 국가
     * @type {number}
     * @memberof ReqStudentSurvey
     */
    'countryId': number;
    /**
     * 지역
     * @type {number}
     * @memberof ReqStudentSurvey
     */
    'stateId'?: number;
    /**
     * 주소(옵션)
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'address'?: string;
    /**
     * 도움이 필요한 항목(What do you need help with?)
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'helpNeed': string;
    /**
     * What do you need help with?(부모, 전문가) - other 추가 설명
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * satReadingWriting
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'satReadingWriting'?: string;
    /**
     * satMath
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'satMath'?: string;
    /**
     * actEnglish
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'actEnglish'?: string;
    /**
     * actMath
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'actMath'?: string;
    /**
     * actReading
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'actReading'?: string;
    /**
     * actScience
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'actScience'?: string;
    /**
     * averageGrade
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'averageGrade'?: string;
    /**
     * 꿈의 대학
     * @type {number}
     * @memberof ReqStudentSurvey
     */
    'targetSchoolId'?: number;
    /**
     * 꿈의 대학
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'targetMajorId'?: string;
    /**
     * 꿈의 커리어
     * @type {string}
     * @memberof ReqStudentSurvey
     */
    'targetCareerId'?: string;
    /**
     * 
     * @type {ReqStudentEduHist}
     * @memberof ReqStudentSurvey
     */
    'studentEduHist'?: ReqStudentEduHist;
}
/**
 * 
 * @export
 * @interface ReqStudentSurveyUpdate
 */
export interface ReqStudentSurveyUpdate {
    /**
     * 꿈의 대학
     * @type {number}
     * @memberof ReqStudentSurveyUpdate
     */
    'targetSchoolId'?: number;
    /**
     * 꿈의 전공
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'targetMajorId'?: string;
    /**
     * target GPA
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'targetGrade'?: string;
    /**
     * Current Displayed [SAT,ACT]
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'currentDisplayed'?: string;
    /**
     * SAT readingAndWriting
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'satReadingWriting'?: string;
    /**
     * SAT Math
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'satMath'?: string;
    /**
     * ACT English
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'actEnglish'?: string;
    /**
     * ACT Math
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'actMath'?: string;
    /**
     * ACT Reading
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'actReading'?: string;
    /**
     * ACT Science
     * @type {string}
     * @memberof ReqStudentSurveyUpdate
     */
    'actScience'?: string;
}
/**
 * 
 * @export
 * @interface ReqTbExpertInfo
 */
export interface ReqTbExpertInfo {
    /**
     * expert pk
     * @type {number}
     * @memberof ReqTbExpertInfo
     */
    'expertId'?: number;
    /**
     * profile image url
     * @type {string}
     * @memberof ReqTbExpertInfo
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqTbExpertInfo
     */
    'introVideoUrl'?: string;
    /**
     * 
     * @type {Region}
     * @memberof ReqTbExpertInfo
     */
    'region'?: Region;
    /**
     * briefIntroduction
     * @type {string}
     * @memberof ReqTbExpertInfo
     */
    'briefIntroduction'?: string;
    /**
     * educationalBackground
     * @type {string}
     * @memberof ReqTbExpertInfo
     */
    'educationlyBackground'?: string;
    /**
     * 
     * @type {Array<ExpertLanguageInfo>}
     * @memberof ReqTbExpertInfo
     */
    'languageObjectList'?: Array<ExpertLanguageInfo>;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof ReqTbExpertInfo
     */
    'skillObjectList'?: Array<Skill>;
    /**
     * 
     * @type {MemberTimeZone}
     * @memberof ReqTbExpertInfo
     */
    'memberTimeZone'?: MemberTimeZone;
    /**
     * 
     * @type {Array<GetIntoCollege>}
     * @memberof ReqTbExpertInfo
     */
    'getIntoObjectList'?: Array<GetIntoCollege>;
    /**
     * 
     * @type {Array<AvailabilityTime>}
     * @memberof ReqTbExpertInfo
     */
    'availabilityObjectList'?: Array<AvailabilityTime>;
    /**
     * 
     * @type {Array<WorkExperience>}
     * @memberof ReqTbExpertInfo
     */
    'workExperenceObjectList'?: Array<WorkExperience>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof ReqTbExpertInfo
     */
    'educationObjectList'?: Array<Education>;
    /**
     * 
     * @type {Array<AttachFile>}
     * @memberof ReqTbExpertInfo
     */
    'awardFileObjectList'?: Array<AttachFile>;
    /**
     * 
     * @type {Array<WebSite>}
     * @memberof ReqTbExpertInfo
     */
    'websiteObjectList'?: Array<WebSite>;
}
/**
 * 
 * @export
 * @interface ResActivityType
 */
export interface ResActivityType {
    /**
     * 
     * @type {number}
     * @memberof ResActivityType
     */
    'activityTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResActivityType
     */
    'activityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResActivityType
     */
    'mainAcitivtyCategory'?: string;
    /**
     * Activities Distribution by Category > Recommended Count
     * @type {number}
     * @memberof ResActivityType
     */
    'recommendedCategoryCount'?: number;
    /**
     * Activities Distribution by Category > Recommended Count
     * @type {number}
     * @memberof ResActivityType
     */
    'myCategoryCount'?: number;
    /**
     * Activities Distribution by Category > Main Activity Category에 연관된 activityNames 정보
     * @type {string}
     * @memberof ResActivityType
     */
    'activityNames'?: string;
    /**
     * Activities Distribution by Category 충족 여부
     * @type {boolean}
     * @memberof ResActivityType
     */
    'metFlag'?: boolean;
}
/**
 * 
 * @export
 * @interface ResAptestSubject
 */
export interface ResAptestSubject {
    /**
     * 
     * @type {string}
     * @memberof ResAptestSubject
     */
    'aptestSubjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResAptestSubject
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResAptestSubject
     */
    'testTypeId'?: number;
    /**
     * 
     * @type {ResTestType}
     * @memberof ResAptestSubject
     */
    'testType'?: ResTestType;
}
/**
 * 
 * @export
 * @interface ResAwardLevel
 */
export interface ResAwardLevel {
    /**
     * 
     * @type {number}
     * @memberof ResAwardLevel
     */
    'awardLevelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResAwardLevel
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ResCareer
 */
export interface ResCareer {
    /**
     * onet에서 제공하는 커리어 Id
     * @type {string}
     * @memberof ResCareer
     */
    'careerId'?: string;
    /**
     * onet에서 제공하는 커리어 명칭
     * @type {string}
     * @memberof ResCareer
     */
    'careerTitle'?: string;
}
/**
 * Career Cluster
 * @export
 * @interface ResCareerClusters
 */
export interface ResCareerClusters {
    /**
     * Career Cluster Code
     * @type {string}
     * @memberof ResCareerClusters
     */
    'code'?: string;
    /**
     * Career Cluster Title
     * @type {string}
     * @memberof ResCareerClusters
     */
    'title'?: string;
}
/**
 * 교육
 * @export
 * @interface ResCareerMajorKnowledge
 */
export interface ResCareerMajorKnowledge {
    /**
     * 
     * @type {string}
     * @memberof ResCareerMajorKnowledge
     */
    'knowledgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResCareerMajorKnowledge
     */
    'knowledgeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCareerMajorKnowledge
     */
    'importance'?: number;
}
/**
 * 
 * @export
 * @interface ResCareerSurveyResult
 */
export interface ResCareerSurveyResult {
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'careerSurveyResultId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question14'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question15'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question16'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question17'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question18'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question19'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question20'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question21'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question22'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question23'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question24'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question25'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question26'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question27'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question28'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question29'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question30'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question31'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question32'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question33'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question34'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question35'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question36'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question37'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question38'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question39'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question40'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question41'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question42'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question43'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question44'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question45'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question46'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question47'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question48'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question49'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question50'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question51'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question52'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question53'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question54'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question55'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question56'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question57'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question58'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question59'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'question60'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResCareerSurveyResult
     */
    'startQuestion'?: number;
}
/**
 * 
 * @export
 * @interface ResCareerSurveyStatus
 */
export interface ResCareerSurveyStatus {
    /**
     * 
     * @type {string}
     * @memberof ResCareerSurveyStatus
     */
    'careerSurveyStatus'?: string;
    /**
     * 
     * @type {ResCareerSurveyResult}
     * @memberof ResCareerSurveyStatus
     */
    'careerSurveyResult'?: ResCareerSurveyResult;
    /**
     * 
     * @type {Array<ResOnetResult>}
     * @memberof ResCareerSurveyStatus
     */
    'resOnetResults'?: Array<ResOnetResult>;
}
/**
 * 
 * @export
 * @interface ResCareers
 */
export interface ResCareers {
    /**
     * Career 식별번호
     * @type {string}
     * @memberof ResCareers
     */
    'careerId'?: string;
    /**
     * Career 명칭
     * @type {string}
     * @memberof ResCareers
     */
    'careerTitle'?: string;
    /**
     * Career 설명
     * @type {string}
     * @memberof ResCareers
     */
    'careerDescription'?: string;
    /**
     * 수입
     * @type {number}
     * @memberof ResCareers
     */
    'income'?: number;
    /**
     * 학력
     * @type {Array<ResEducationLevel>}
     * @memberof ResCareers
     */
    'educationLevels'?: Array<ResEducationLevel>;
    /**
     * Career Cluster
     * @type {Array<ResCareerClusters>}
     * @memberof ResCareers
     */
    'careerClusters'?: Array<ResCareerClusters>;
    /**
     * 전공
     * @type {Array<ResMajor>}
     * @memberof ResCareers
     */
    'majors'?: Array<ResMajor>;
    /**
     * 교육
     * @type {Array<ResCareerMajorKnowledge>}
     * @memberof ResCareers
     */
    'knowledges'?: Array<ResCareerMajorKnowledge>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResCareers
     */
    'tasks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResCollege
 */
export interface ResCollege {
    /**
     * 
     * @type {number}
     * @memberof ResCollege
     */
    'collegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResCollege
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ResCollegeMajorGrad
 */
export interface ResCollegeMajorGrad {
    /**
     * 대학명
     * @type {string}
     * @memberof ResCollegeMajorGrad
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCollegeMajorGrad
     */
    'collegeMajorGradId'?: number;
    /**
     * 대학식별번호
     * @type {number}
     * @memberof ResCollegeMajorGrad
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGrad
     */
    'onet 전공 카테고리'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGrad
     */
    'onet 전공 명'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCollegeMajorGrad
     */
    '전공 졸업자 수 '?: number;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGrad
     */
    'onet_major_id'?: string;
}
/**
 * 
 * @export
 * @interface ResCollegeMajorGradList
 */
export interface ResCollegeMajorGradList {
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGradList
     */
    'onet_major_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGradList
     */
    'onet 전공 카테고리'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeMajorGradList
     */
    'onet 전공 명'?: string;
}
/**
 * 
 * @export
 * @interface ResCollegeNicheIpeds
 */
export interface ResCollegeNicheIpeds {
    /**
     * ipeds id
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'ipedsCollegeId'?: number;
    /**
     * 대학명
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'ipedsCollegeName'?: string;
    /**
     * 대학명 별칭
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'ialias'?: string;
    /**
     * 대학 주(State)
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'stateName'?: string;
    /**
     * 대학 주소
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'addressAddress'?: string;
    /**
     * 대학 도시
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'addressCity'?: string;
    /**
     * 주(State) 약어
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'addressState'?: string;
    /**
     * 대학 Region
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'addressRegion'?: string;
    /**
     * 대학 우편번호
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'addressZipcode'?: string;
    /**
     * 대학 합격자 수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'accept'?: number;
    /**
     * 대학 합격률
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'acceptRate'?: number;
    /**
     * 제출된 ACT 평균값 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'actc'?: string;
    /**
     * 제출된 ACT English 점수 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'acte'?: string;
    /**
     * 제출된 ACT Math 점수 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'actm'?: string;
    /**
     * 시험점수 제출 필수 여부 (Test optional)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'adminTest'?: number;
    /**
     * 중등학교 GPA
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'admcon1'?: number;
    /**
     * 대학 준비 프로그램 이수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'admcon4'?: number;
    /**
     * 추천 사항
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'admcon5'?: number;
    /**
     * 재정지원 받는 학생 퍼센트
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'anyFinAidPct'?: number;
    /**
     * 재정지원실 웹주소
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'faidurl'?: string;
    /**
     * 지원자 수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'applicants'?: number;
    /**
     * Regular Decision 지원 마감일
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'applicationDeadline'?: string;
    /**
     * 대학 학부 응시료
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'applfeeu'?: number;
    /**
     * 대학 입시 지원 URL
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'adminurl'?: string;
    /**
     * 미국 체육 컨퍼런스
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'athCon'?: string;
    /**
     * 미국 체육 협회
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'athDiv'?: string;
    /**
     * 학기제
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'calendar'?: string;
    /**
     * 도시화 정도(도시 중심 지역)
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'citySetting'?: string;
    /**
     * 대학 졸업률
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'collegeGradRate'?: string;
    /**
     * 대학 타입 (Pub./Priv.)
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'collegeType'?: string;
    /**
     * College type이 Liberal Arts College 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'collegeLiberalArts'?: number;
    /**
     * 재학생 크기
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'studentSize'?: number;
    /**
     * 총 학부생 수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'totalUndergrad'?: number;
    /**
     * Full-time 총 학부생 수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'ftUndergrad'?: number;
    /**
     * Full-time 총 학부생 퍼센트
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'ftUndergradPer'?: string;
    /**
     * Part-time 총 학부생 수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'ptUndergrad'?: number;
    /**
     * Part-time 총 학부생 퍼센트
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'ptUndergradPer'?: string;
    /**
     * 인종 - 아프리카 미국 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownAfricanAmerican'?: number;
    /**
     * 인종 - 아시아 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownAsian'?: number;
    /**
     * 인종 - 흑인 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownHispanic'?: number;
    /**
     * 인종 - 국제 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownInternational'?: number;
    /**
     * 인종 - 그외 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownMultiracial'?: number;
    /**
     * 인종 - 미국 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownNativeAmerican'?: number;
    /**
     * 인종 - 태평양 섬 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownPacificIslander'?: number;
    /**
     * 인종 - 알 수 없음
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownUnknown'?: number;
    /**
     * 인종 - 백인 계열
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'diversityBreakdownWhite'?: number;
    /**
     * 기숙사 수용 인원
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'dormsNum'?: number;
    /**
     * 기숙사 비용
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'roomamt'?: number;
    /**
     * Early Action 지원 마감일
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'earlyActionDeadline'?: string;
    /**
     * Early Decision 지원 마감일
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'earlyDecisionDeadline'?: string;
    /**
     * 학부 조교
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownAssistantProfessors'?: number;
    /**
     * 부교수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownAssociateProfessors'?: number;
    /**
     * 강사
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownInstructors'?: number;
    /**
     * 강연자
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownLecturers'?: number;
    /**
     * 비정규직 교수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownNoAcademicRank'?: number;
    /**
     * 정규직 교수
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'facultyTypeBreakdownProfessors'?: number;
    /**
     * 장학금 수혜 학생 평균 비율
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'fedGrantAidAmt'?: number;
    /**
     * 신입생 ACT 점수 제출 퍼센트
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'freshSubAct'?: number;
    /**
     * 신입생 SAT 점수 제출 퍼센트
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'freshSubSat'?: number;
    /**
     * 교육 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeAcademics'?: string;
    /**
     * 전체 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeCampus'?: string;
    /**
     * 전체 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeLocation'?: string;
    /**
     * 전체 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeOverall'?: string;
    /**
     * 교수 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeProfessors'?: string;
    /**
     * 안전 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeSafety'?: string;
    /**
     * 학생생활 등급
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'gradeStudentLife'?: string;
    /**
     * 온라인 학교 제공
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'isOnline'?: string;
    /**
     * 주 내 수업료
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'inStateTuit'?: number;
    /**
     * 학년제
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'level'?: string;
    /**
     * 준학사 학위 (Associate\'s degree)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'level3'?: number;
    /**
     * 학사 (Bachelor\'s degree)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'level5'?: number;
    /**
     * 석사 (Master\'s degree)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'level7'?: number;
    /**
     * 박사 학위 (Doctor\'s degree)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'level20'?: number;
    /**
     * 대학 평균 Net price
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'netPrice'?: number;
    /**
     * 소득수준기반 수업료 (0k~30k)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'npis412'?: number;
    /**
     * 소득수준기반 수업료 (30k~48k)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'npis422'?: number;
    /**
     * 소득수준기반 수업료 (48k~75k)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'npis432'?: number;
    /**
     * 소득수준기반 수업료 (75k~110k)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'npis442'?: number;
    /**
     * 소득수준기반 수업료 (110k~)
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'npis452'?: number;
    /**
     * Net price 가격 계산기 웹 주소
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'npricurl'?: string;
    /**
     * Regular Decision 지원 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'offersRegularDecision'?: number;
    /**
     * Rolling 지원 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'offersRolling'?: number;
    /**
     * Early Action 지원 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'offersEarlyAction'?: number;
    /**
     * Early Decision 지원 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'offersEarlyDecision'?: number;
    /**
     * Online 코스 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'offersOnlineCourses'?: number;
    /**
     * 원격 교육 프로그램 제공
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'distpgs'?: number;
    /**
     * 기숙사 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'onCampusHousing'?: number;
    /**
     * 학식 제공 여부
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'board'?: number;
    /**
     * 교정 서비스
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'stusrv1'?: number;
    /**
     * 학업/진로상담서비스
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'stusrv2'?: number;
    /**
     * 학생 고용 서비스
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'stusrv3'?: number;
    /**
     * 주 외 수업료
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'outStateTuit'?: number;
    /**
     * 대학 전화번호
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'phoneNumber'?: string;
    /**
     * Ivy College 
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeIvyCountry'?: string;
    /**
     * Athletics college 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeAthleticsCountry'?: number;
    /**
     * Campuses college 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeCampusesCountry'?: number;
    /**
     * Dorms college
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeDormsCountry'?: number;
    /**
     * Food college
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeFoodCountry'?: number;
    /**
     * Location college
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegeLocationsCountry'?: number;
    /**
     * Best college 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesCountry'?: number;
    /**
     * Accounting and finanace 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForAccountingAndFinanceCountry'?: number;
    /**
     * Agricultural sciences 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForAgriculturalSciencesCountry'?: number;
    /**
     * Anthropology and sociology 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForAnthropologyAndSociologyCountry'?: number;
    /**
     * Architecture 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForArchitectureCountry'?: number;
    /**
     * Art
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForArtCountry'?: number;
    /**
     * Biology
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForBiologyCountry'?: number;
    /**
     * Business
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForBusinessCountry'?: number;
    /**
     * Chemistry 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForChemistryCountry'?: number;
    /**
     * Communications 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForCommunicationsCountry'?: number;
    /**
     * Computer science 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForComputerScienceCountry'?: number;
    /**
     * Criminal justice 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForCriminalJusticeCountry'?: number;
    /**
     * Culinary arts 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForCulinaryArtsCountry'?: number;
    /**
     * Dental studies
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForDentalStudiesCountry'?: number;
    /**
     * Design 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForDesignCountry'?: number;
    /**
     * Economics 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForEconomicsCountry'?: number;
    /**
     * Education 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForEducationCountry'?: number;
    /**
     * Engineering
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForEngineeringCountry'?: number;
    /**
     * English 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForEnglishCountry'?: number;
    /**
     * Environmental science 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForEnvironmentalScienceCountry'?: number;
    /**
     * Film and photography 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForFilmAndPhotographyCountry'?: number;
    /**
     * Global studies 
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForGlobalStudiesCountry'?: string;
    /**
     * History 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForHistoryCountry'?: number;
    /**
     * Information technology 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForInformationTechnologyCountry'?: number;
    /**
     * International relations 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForInternationalRelationsCountry'?: number;
    /**
     * Kinesio and phys therapy 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForKinesioAndPhysTherapyCountry'?: number;
    /**
     * Math 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForMathCountry'?: number;
    /**
     * Military academy
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForMilitaryAcademyCountry'?: string;
    /**
     * Music 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForMusicCountry'?: number;
    /**
     * Nursing 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForNursingCountry'?: number;
    /**
     * Performing arts 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPerformingArtsCountry'?: number;
    /**
     * Pharmacy
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPharmacyCountry'?: number;
    /**
     * Philosophy 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPhilosophyCountry'?: number;
    /**
     * Premedicine studies 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPremedicineStudiesCountry'?: number;
    /**
     * Physics 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPhysicsCountry'?: number;
    /**
     * Political science 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPoliticalScienceCountry'?: number;
    /**
     * Psychology 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPsychologyCountry'?: number;
    /**
     * Public health 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPublicHealthCountry'?: number;
    /**
     * Public policy 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForPublicPolicyCountry'?: number;
    /**
     * Religious studies 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForReligiousStudiesCountry'?: number;
    /**
     * Sports management 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesForSportsManagementCountry'?: number;
    /**
     * Accept the common app 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesThatAcceptTheCommonAppCountry'?: number;
    /**
     * No application fee 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCollegesWithNoApplicationFeeCountry'?: number;
    /**
     * Community colleges 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestCommunityCollegesCountry'?: number;
    /**
     * Liberal arts college 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestLiberalArtsCollegesCountry'?: number;
    /**
     * Online colleges 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestOnlineCollegesCountry'?: number;
    /**
     * Small colleges 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestSmallCollegesCountry'?: number;
    /**
     * Test optional colleges 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestTestOptionalCollegesCountry'?: number;
    /**
     * Value colleges 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestValueCollegesCountry'?: number;
    /**
     * Womans colleges 
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'rankBestWomensCollegesCountry'?: string;
    /**
     * Best academics 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankCollegesWithTheBestAcademicsCountry'?: number;
    /**
     * Best professors 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankCollegesWithTheBestProfessorsCountry'?: number;
    /**
     * Best student life 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankCollegesWithTheBestStudentLifeCountry'?: number;
    /**
     * Most conservation colleges
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankMostConservativeCollegesCountry'?: number;
    /**
     * Most diverse colleges
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankMostDiverseCollegesCountry'?: number;
    /**
     * Safest college campuses 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankSafestCollegeCampusesCountry'?: number;
    /**
     * Party Schools 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankTopPartySchoolsCountry'?: number;
    /**
     * Private College 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankTopPrivateUniversitiesCountry'?: number;
    /**
     * Public College 
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rankTopPublicUniversitiesCountry'?: number;
    /**
     * 공군 ROTC
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rotcAirForce'?: number;
    /**
     * 육군 ROTC
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rotcArmy'?: number;
    /**
     * 해군 ROTC
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'rotcNavy'?: number;
    /**
     * 제출된 SAT 총점 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'satCompRange'?: string;
    /**
     * 제출된 SAT Reading & Writing 점수 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'satCr'?: string;
    /**
     * 제출된 SAT Math 점수 범위
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'satM'?: string;
    /**
     * 학생 교수 비율
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'studFacRatio'?: number;
    /**
     * 대학 웹사이트 주소
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'website'?: string;
    /**
     * 대학 이미지 주소
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'imgUrl'?: string;
    /**
     * admissionStatus
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'admissionStatus'?: string;
    /**
     * admissionRound
     * @type {string}
     * @memberof ResCollegeNicheIpeds
     */
    'admissionRound'?: string;
    /**
     * 
     * @type {CollegeGpaData}
     * @memberof ResCollegeNicheIpeds
     */
    'collegeGpaData'?: CollegeGpaData;
    /**
     * 가중치
     * @type {number}
     * @memberof ResCollegeNicheIpeds
     */
    'weight'?: number;
    /**
     * 
     * @type {ResUserChancesInfo}
     * @memberof ResCollegeNicheIpeds
     */
    'userChancesInfo'?: ResUserChancesInfo;
}
/**
 * 
 * @export
 * @interface ResCollegeSurveyResults
 */
export interface ResCollegeSurveyResults {
    /**
     * 
     * @type {CollegeSurveyCardData}
     * @memberof ResCollegeSurveyResults
     */
    'reachCollege'?: CollegeSurveyCardData;
    /**
     * 
     * @type {CollegeSurveyCardData}
     * @memberof ResCollegeSurveyResults
     */
    'targetCollege'?: CollegeSurveyCardData;
    /**
     * 
     * @type {CollegeSurveyCardData}
     * @memberof ResCollegeSurveyResults
     */
    'safetyCollege'?: CollegeSurveyCardData;
}
/**
 * 
 * @export
 * @interface ResCollegeTier
 */
export interface ResCollegeTier {
    /**
     * 
     * @type {number}
     * @memberof ResCollegeTier
     */
    'collegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResCollegeTier
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCollegeTier
     */
    'collegeTier'?: number;
}
/**
 * 
 * @export
 * @interface ResCountry
 */
export interface ResCountry {
    /**
     * 
     * @type {number}
     * @memberof ResCountry
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResCountry
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResCountry
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCountry
     */
    'phoneCode'?: number;
}
/**
 * 
 * @export
 * @interface ResCourseCategory
 */
export interface ResCourseCategory {
    /**
     * 
     * @type {string}
     * @memberof ResCourseCategory
     */
    'courseCategoryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResCourseCategory
     */
    'subjectCategoryId'?: number;
    /**
     * 과목명
     * @type {string}
     * @memberof ResCourseCategory
     */
    'title'?: string;
}
/**
 * 학력
 * @export
 * @interface ResEducationLevel
 */
export interface ResEducationLevel {
    /**
     * Education Level Code
     * @type {number}
     * @memberof ResEducationLevel
     */
    'code'?: number;
    /**
     * Career Cluster Title
     * @type {string}
     * @memberof ResEducationLevel
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ResEmailCodeIssued
 */
export interface ResEmailCodeIssued {
    /**
     * 
     * @type {string}
     * @memberof ResEmailCodeIssued
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResEmailCodeIssued
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ResEmailCodeValid
 */
export interface ResEmailCodeValid {
    /**
     * 
     * @type {string}
     * @memberof ResEmailCodeValid
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResEmailCodeValid
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ResExpertSkill
 */
export interface ResExpertSkill {
    /**
     * 
     * @type {number}
     * @memberof ResExpertSkill
     */
    'expertSkillId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSkill
     */
    'skillName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSkill
     */
    'expertType'?: ResExpertSkillExpertTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResExpertSkill
     */
    'parentExpertSkillId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResExpertSkill
     */
    'depth'?: number;
}

export const ResExpertSkillExpertTypeEnum = {
    Mentor: 'MENTOR',
    Tutor: 'TUTOR',
    Counselor: 'COUNSELOR'
} as const;

export type ResExpertSkillExpertTypeEnum = typeof ResExpertSkillExpertTypeEnum[keyof typeof ResExpertSkillExpertTypeEnum];

/**
 * 
 * @export
 * @interface ResExpertSurvey
 */
export interface ResExpertSurvey {
    /**
     * 
     * @type {number}
     * @memberof ResExpertSurvey
     */
    'memberSurveyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResExpertSurvey
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'findOut'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'findOutDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResExpertSurvey
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResExpertSurvey
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'helpNeed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExpertSurvey
     */
    'briefIntroduction'?: string;
}
/**
 * 
 * @export
 * @interface ResExperts
 */
export interface ResExperts {
    /**
     * 
     * @type {number}
     * @memberof ResExperts
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResExperts
     */
    'expertName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExperts
     */
    'expertType'?: ResExpertsExpertTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResExperts
     */
    'expertImgUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResExperts
     */
    'briefIntroduction'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResExperts
     */
    'expertReviewScore'?: number;
    /**
     * 
     * @type {Array<ProgramCategory>}
     * @memberof ResExperts
     */
    'expertCategory'?: Array<ProgramCategory>;
    /**
     * 
     * @type {string}
     * @memberof ResExperts
     */
    'lastCollegeName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResExperts
     */
    'lastCollegeMajorNames'?: Array<string>;
}

export const ResExpertsExpertTypeEnum = {
    Mentor: 'MENTOR',
    Tutor: 'TUTOR',
    Counselor: 'COUNSELOR'
} as const;

export type ResExpertsExpertTypeEnum = typeof ResExpertsExpertTypeEnum[keyof typeof ResExpertsExpertTypeEnum];

/**
 * 
 * @export
 * @interface ResFieldStudy
 */
export interface ResFieldStudy {
    /**
     * 필드명
     * @type {string}
     * @memberof ResFieldStudy
     */
    'fieldName'?: string;
}
/**
 * 
 * @export
 * @interface ResFrontStep
 */
export interface ResFrontStep {
    /**
     * 스탭 별 진행 여부 정보
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ResFrontStep
     */
    'frontStepInfo'?: Array<{ [key: string]: object; }>;
}
/**
 * 선호 대학,전공,경력 정보
 * @export
 * @interface ResFutureList
 */
export interface ResFutureList {
    /**
     * 선호 대학 리스트
     * @type {Array<MemberLikeCollege>}
     * @memberof ResFutureList
     */
    'memberLikeCollegeList'?: Array<MemberLikeCollege>;
    /**
     * 선호 전공 리스트
     * @type {Array<MemberLikeMajor>}
     * @memberof ResFutureList
     */
    'memberLikeMajorList'?: Array<MemberLikeMajor>;
    /**
     * 선호 경력 리스트
     * @type {Array<MemberLikeCareer>}
     * @memberof ResFutureList
     */
    'memberLikeCareerList'?: Array<MemberLikeCareer>;
}
/**
 * 
 * @export
 * @interface ResGrade
 */
export interface ResGrade {
    /**
     * 
     * @type {number}
     * @memberof ResGrade
     */
    'gradeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResGrade
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ResGraduateCollege
 */
export interface ResGraduateCollege {
    /**
     * 
     * @type {number}
     * @memberof ResGraduateCollege
     */
    'collegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'collegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'collegeAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'majorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'degreeLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResGraduateCollege
     */
    'graduationDate'?: string;
}
/**
 * 
 * @export
 * @interface ResHighSchool
 */
export interface ResHighSchool {
    /**
     * 
     * @type {number}
     * @memberof ResHighSchool
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResHighSchool
     */
    'schoolType'?: string;
}
/**
 * 
 * @export
 * @interface ResLinkStatusInfo
 */
export interface ResLinkStatusInfo {
    /**
     * memberLinkInfoId
     * @type {number}
     * @memberof ResLinkStatusInfo
     */
    'memberLinkInfoId'?: number;
    /**
     * 요청 보낸 멤버 시퀀스
     * @type {number}
     * @memberof ResLinkStatusInfo
     */
    'memberId'?: number;
    /**
     * 요청 받은 멤버 시퀀스
     * @type {number}
     * @memberof ResLinkStatusInfo
     */
    'linkMemberId'?: number;
    /**
     * 요청 타입
     * @type {string}
     * @memberof ResLinkStatusInfo
     */
    'linkType'?: ResLinkStatusInfoLinkTypeEnum;
    /**
     * 보낸요청, 받은요청 구분타입
     * @type {string}
     * @memberof ResLinkStatusInfo
     */
    'sendReceiveType'?: string;
    /**
     * 화면에 보여줄 멤버 이름
     * @type {string}
     * @memberof ResLinkStatusInfo
     */
    'displayMemberName'?: string;
    /**
     * 화면에 보여줄 멤버 메일
     * @type {string}
     * @memberof ResLinkStatusInfo
     */
    'displayMemberEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResLinkStatusInfo
     */
    'displayMemberImageUrl'?: string;
}

export const ResLinkStatusInfoLinkTypeEnum = {
    Request: 'REQUEST',
    Unlinking: 'UNLINKING',
    Accept: 'ACCEPT',
    Reject: 'REJECT',
    Cancel: 'CANCEL'
} as const;

export type ResLinkStatusInfoLinkTypeEnum = typeof ResLinkStatusInfoLinkTypeEnum[keyof typeof ResLinkStatusInfoLinkTypeEnum];

/**
 * 전공
 * @export
 * @interface ResMajor
 */
export interface ResMajor {
    /**
     * 
     * @type {string}
     * @memberof ResMajor
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMajor
     */
    'majorTitle'?: string;
}
/**
 * 
 * @export
 * @interface ResMajorCategory
 */
export interface ResMajorCategory {
    /**
     * 
     * @type {number}
     * @memberof ResMajorCategory
     */
    'categoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMajorCategory
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ResMajorList
 */
export interface ResMajorList {
    /**
     * 대학 ID
     * @type {number}
     * @memberof ResMajorList
     */
    'collegeId'?: number;
    /**
     * 전공목록
     * @type {Array<ResMajor>}
     * @memberof ResMajorList
     */
    'majorList'?: Array<ResMajor>;
    /**
     * analysisReportMajor
     * @type {Array<ResMajor>}
     * @memberof ResMajorList
     */
    'analysisMajorList'?: Array<ResMajor>;
}
/**
 * 
 * @export
 * @interface ResMajorcategoryMajor
 */
export interface ResMajorcategoryMajor {
    /**
     * 
     * @type {string}
     * @memberof ResMajorcategoryMajor
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMajorcategoryMajor
     */
    'majorCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMajorcategoryMajor
     */
    'majorTitle'?: string;
}
/**
 * 
 * @export
 * @interface ResMember
 */
export interface ResMember {
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'accessToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'memberType'?: ResMemberMemberTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'surveyYn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'studentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMember
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMember
     */
    'expertType'?: ResMemberExpertTypeEnum;
}

export const ResMemberMemberTypeEnum = {
    Student: 'STUDENT',
    Expert: 'EXPERT',
    Parent: 'PARENT'
} as const;

export type ResMemberMemberTypeEnum = typeof ResMemberMemberTypeEnum[keyof typeof ResMemberMemberTypeEnum];
export const ResMemberExpertTypeEnum = {
    Mentor: 'MENTOR',
    Tutor: 'TUTOR',
    Counselor: 'COUNSELOR'
} as const;

export type ResMemberExpertTypeEnum = typeof ResMemberExpertTypeEnum[keyof typeof ResMemberExpertTypeEnum];

/**
 * 
 * @export
 * @interface ResMemberCollegeAdmission
 */
export interface ResMemberCollegeAdmission {
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeAdmission
     */
    'memberCollegeAdmissionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeAdmission
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeAdmission
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberCollegeAdmission
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberCollegeAdmission
     */
    'round'?: string;
}
/**
 * 
 * @export
 * @interface ResMemberCollegeSurvey
 */
export interface ResMemberCollegeSurvey {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResMemberCollegeSurvey
     */
    'addressRegions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResMemberCollegeSurvey
     */
    'citySettings'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResMemberCollegeSurvey
     */
    'studentSize'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'gpa'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberCollegeSurvey
     */
    'waiverYn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'duolingoOverallScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'toeflTotalScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'ieltsBandScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'satRwScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'satMathScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'satTotalScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'actReadingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'actMathScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'actEnglishScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'actScienceScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'actCompScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberCollegeSurvey
     */
    'apTestYn'?: string;
    /**
     * 
     * @type {Array<ApTestScore>}
     * @memberof ResMemberCollegeSurvey
     */
    'apTestScoreList'?: Array<ApTestScore>;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'athleticsScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberCollegeSurvey
     */
    'academicsScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberCollegeSurvey
     */
    'collegeSurveyYn'?: string;
}
/**
 * 
 * @export
 * @interface ResMemberEduHist
 */
export interface ResMemberEduHist {
    /**
     * 
     * @type {number}
     * @memberof ResMemberEduHist
     */
    'memberEduHistId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberEduHist
     */
    'memberSurveyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberEduHist
     */
    'collegeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberEduHist
     */
    'schoolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'currentGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'degreeLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'graduationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'majorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'inCollegeYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'addressDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberEduHist
     */
    'zipcode'?: string;
}
/**
 * 
 * @export
 * @interface ResMemberLikeCareer
 */
export interface ResMemberLikeCareer {
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCareer
     */
    'memberLikeCareerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCareer
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberLikeCareer
     */
    'careerId'?: string;
    /**
     * 
     * @type {ResCareers}
     * @memberof ResMemberLikeCareer
     */
    'resCareer'?: ResCareers;
}
/**
 * 
 * @export
 * @interface ResMemberLikeCollege
 */
export interface ResMemberLikeCollege {
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCollege
     */
    'memberLikeCollegeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCollege
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCollege
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {CollegeNicheIpeds}
     * @memberof ResMemberLikeCollege
     */
    'collegeNicheIpeds'?: CollegeNicheIpeds;
    /**
     * 
     * @type {string}
     * @memberof ResMemberLikeCollege
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResMemberLikeCollege
     */
    'onetMajorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeCollege
     */
    'analysisReportId'?: number;
}
/**
 * 
 * @export
 * @interface ResMemberLikeMajor
 */
export interface ResMemberLikeMajor {
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeMajor
     */
    'memberLikeMajorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResMemberLikeMajor
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResMemberLikeMajor
     */
    'majorId'?: string;
    /**
     * 
     * @type {ResMajorcategoryMajor}
     * @memberof ResMemberLikeMajor
     */
    'major'?: ResMajorcategoryMajor;
}
/**
 * 
 * @export
 * @interface ResMemberSurveyTempProfile
 */
export interface ResMemberSurveyTempProfile {
    /**
     * 국가
     * @type {number}
     * @memberof ResMemberSurveyTempProfile
     */
    'countryId'?: number;
    /**
     * 지역
     * @type {number}
     * @memberof ResMemberSurveyTempProfile
     */
    'stateId'?: number;
    /**
     * 주소(옵션)
     * @type {string}
     * @memberof ResMemberSurveyTempProfile
     */
    'address'?: string;
    /**
     * 전문가용 소개글
     * @type {string}
     * @memberof ResMemberSurveyTempProfile
     */
    'briefIntroduction'?: string;
    /**
     * 학력정보
     * @type {Array<MemberProfileEduHist>}
     * @memberof ResMemberSurveyTempProfile
     */
    'memberProfileEduHists'?: Array<MemberProfileEduHist>;
}
/**
 * 
 * @export
 * @interface ResMyPond
 */
export interface ResMyPond {
    /**
     * 
     * @type {ResProfile}
     * @memberof ResMyPond
     */
    'resProfile'?: ResProfile;
    /**
     * 
     * @type {ResRoadmapInfo}
     * @memberof ResMyPond
     */
    'resRoadmapInfo'?: ResRoadmapInfo;
    /**
     * 부모용 연결된 학생 리스트
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ResMyPond
     */
    'resLinkedInfo'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {ResFutureList}
     * @memberof ResMyPond
     */
    'resFutureList'?: ResFutureList;
    /**
     * 연결 상태 정보
     * @type {Array<ResLinkStatusInfo>}
     * @memberof ResMyPond
     */
    'resLinkStatusInfoList'?: Array<ResLinkStatusInfo>;
}
/**
 * 
 * @export
 * @interface ResOnetCareer
 */
export interface ResOnetCareer {
    /**
     * 
     * @type {string}
     * @memberof ResOnetCareer
     */
    'careerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResOnetCareer
     */
    'careerTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResOnetCareer
     */
    'salary'?: number;
    /**
     * 
     * @type {Array<ResEducationLevel>}
     * @memberof ResOnetCareer
     */
    'eduLevel'?: Array<ResEducationLevel>;
    /**
     * 
     * @type {Array<ResCareerClusters>}
     * @memberof ResOnetCareer
     */
    'careerClusters'?: Array<ResCareerClusters>;
}
/**
 * 
 * @export
 * @interface ResOnetCareerSearchParameter
 */
export interface ResOnetCareerSearchParameter {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResOnetCareerSearchParameter
     */
    'careerClusterIds'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ResOnetCareerSearchParameter
     */
    'educationLevelIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ResOnetCareerSearchParameter
     */
    'startIncome'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResOnetCareerSearchParameter
     */
    'endIncome'?: number;
}
/**
 * 
 * @export
 * @interface ResOnetQuestion
 */
export interface ResOnetQuestion {
    /**
     * 순서
     * @type {number}
     * @memberof ResOnetQuestion
     */
    'number'?: number;
    /**
     * 설문 문항 타입
     * @type {string}
     * @memberof ResOnetQuestion
     */
    'type'?: string;
    /**
     * 설문 문항 제목
     * @type {string}
     * @memberof ResOnetQuestion
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ResOnetResult
 */
export interface ResOnetResult {
    /**
     * 타입별 설문 총합
     * @type {number}
     * @memberof ResOnetResult
     */
    'score'?: number;
    /**
     * 문항 타입
     * @type {string}
     * @memberof ResOnetResult
     */
    'type'?: string;
    /**
     * 타입별 설문 설명
     * @type {string}
     * @memberof ResOnetResult
     */
    'desc'?: string;
}
/**
 * 
 * @export
 * @interface ResParentSurvey
 */
export interface ResParentSurvey {
    /**
     * 
     * @type {number}
     * @memberof ResParentSurvey
     */
    'memberSurveyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResParentSurvey
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'findOut'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'findOutDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResParentSurvey
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResParentSurvey
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'helpNeed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResParentSurvey
     */
    'targetSchoolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'targetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResParentSurvey
     */
    'targetCareerId'?: string;
    /**
     * 
     * @type {Array<ResMemberEduHist>}
     * @memberof ResParentSurvey
     */
    'memberEduHists'?: Array<ResMemberEduHist>;
}
/**
 * 프로필 정보
 * @export
 * @interface ResProfile
 */
export interface ResProfile {
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'memberName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResProfile
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'countryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResProfile
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'stateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResProfile
     */
    'schoolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'schoolAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'currentGrade'?: string;
    /**
     * 
     * @type {Array<AdmissionInfo>}
     * @memberof ResProfile
     */
    'admissionInfos'?: Array<AdmissionInfo>;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'graduationDate'?: string;
    /**
     * 
     * @type {Array<ResGraduateCollege>}
     * @memberof ResProfile
     */
    'resGraduateCollegeList'?: Array<ResGraduateCollege>;
    /**
     * 
     * @type {string}
     * @memberof ResProfile
     */
    'briefIntroduction'?: string;
}
/**
 * Recommended Activities 목록
 * @export
 * @interface ResRecommendedActivity
 */
export interface ResRecommendedActivity {
    /**
     * 
     * @type {number}
     * @memberof ResRecommendedActivity
     */
    'recommendedActivityId'?: number;
    /**
     * State Group Id
     * @type {number}
     * @memberof ResRecommendedActivity
     */
    'stateGroupId'?: number;
    /**
     * 활동 타이틀
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'title'?: string;
    /**
     * 활동 설명
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'description'?: string;
    /**
     * 해당 활동의 웹사이트 링크
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'link'?: string;
    /**
     * 활동 타입
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'activityName'?: string;
    /**
     * 활동 관련 과목 카테고리
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'subjectCategoryName'?: string;
    /**
     * 활동 강점
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'strengthName'?: string;
    /**
     * 활동 위치 - 주
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'stateName'?: string;
    /**
     * 활동 위치 - 도시
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'city'?: string;
    /**
     * Only 온라인 활동 여부
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'onlineOnlyYn'?: string;
    /**
     * 활동 시작일
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'startDate'?: string;
    /**
     * 활동 길이
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'sessionLength'?: string;
    /**
     * 활동 종료일
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'endDate'?: string;
    /**
     * 활동 추천 학년
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'recommendGrade'?: string;
    /**
     * 프리미엄 여부
     * @type {string}
     * @memberof ResRecommendedActivity
     */
    'premiumYn'?: string;
}
/**
 * 
 * @export
 * @interface ResRegionState
 */
export interface ResRegionState {
    /**
     * 
     * @type {string}
     * @memberof ResRegionState
     */
    'addressState'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRegionState
     */
    'stateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRegionState
     */
    'region'?: string;
}
/**
 * 
 * @export
 * @interface ResRoadmap
 */
export interface ResRoadmap {
    /**
     * 로드맵 데이터 존재 유무
     * @type {boolean}
     * @memberof ResRoadmap
     */
    'exist'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmap
     */
    'roadmapId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmap
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmap
     */
    'studentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmap
     */
    'studentProfileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmap
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmap
     */
    'graduationDate'?: string;
    /**
     * DisplayName
     * @type {string}
     * @memberof ResRoadmap
     */
    'displayName'?: ResRoadmapDisplayNameEnum;
    /**
     * Dream College Id
     * @type {number}
     * @memberof ResRoadmap
     */
    'dreamIpedsCollegeId'?: number;
    /**
     * Dream Major Id
     * @type {string}
     * @memberof ResRoadmap
     */
    'dreamOnetMajorId'?: string;
    /**
     * Dream College Name
     * @type {string}
     * @memberof ResRoadmap
     */
    'dreamIpedsCollegeName'?: string;
    /**
     * Dream Major Name
     * @type {string}
     * @memberof ResRoadmap
     */
    'dreamOnetMajorName'?: string;
    /**
     * Target GPA
     * @type {string}
     * @memberof ResRoadmap
     */
    'targetGpa'?: string;
    /**
     * Target GPA Grade
     * @type {string}
     * @memberof ResRoadmap
     */
    'targetGpaGrade'?: string;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ResRoadmap
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ResRoadmap
     */
    'satMath'?: number;
    /**
     * ACT English
     * @type {number}
     * @memberof ResRoadmap
     */
    'actEnglish'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ResRoadmap
     */
    'actMath'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ResRoadmap
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ResRoadmap
     */
    'actScience'?: number;
    /**
     * Test Scores - Target Score 정보 (DisplayName 값에 따라 SAT, ACT 계산식 적용)
     * @type {number}
     * @memberof ResRoadmap
     */
    'targetScore'?: number;
    /**
     * dream college, major 기반 report 가능 여부 //  사용안함 RoadmapDataFlag로 대체 
     * @type {boolean}
     * @memberof ResRoadmap
     */
    'reportFlag'?: boolean;
    /**
     * 
     * @type {RoadmapDataFlag}
     * @memberof ResRoadmap
     */
    'roadmapDataFlag'?: RoadmapDataFlag;
    /**
     * Report Coursework Count
     * @type {number}
     * @memberof ResRoadmap
     */
    'reportCourseWorkCnt'?: number;
    /**
     * Report Upcoming Activities Count
     * @type {number}
     * @memberof ResRoadmap
     */
    'reportUpcomingActivityCnt'?: number;
    /**
     * My SAT Reading And Writing
     * @type {number}
     * @memberof ResRoadmap
     */
    'mySatReadingWriting'?: number;
    /**
     * My SAT Math
     * @type {number}
     * @memberof ResRoadmap
     */
    'mySatMath'?: number;
    /**
     * My ACT English
     * @type {number}
     * @memberof ResRoadmap
     */
    'myActEnglish'?: number;
    /**
     * My ACT Math
     * @type {number}
     * @memberof ResRoadmap
     */
    'myActMath'?: number;
    /**
     * My ACT Reading
     * @type {number}
     * @memberof ResRoadmap
     */
    'myActReading'?: number;
    /**
     * My ACT Science
     * @type {number}
     * @memberof ResRoadmap
     */
    'myActScience'?: number;
}

export const ResRoadmapDisplayNameEnum = {
    Sat: 'SAT',
    Act: 'ACT'
} as const;

export type ResRoadmapDisplayNameEnum = typeof ResRoadmapDisplayNameEnum[keyof typeof ResRoadmapDisplayNameEnum];

/**
 * 
 * @export
 * @interface ResRoadmapActivity
 */
export interface ResRoadmapActivity {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivity
     */
    'activityId'?: number;
    /**
     * 액티비티 명
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'activityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivity
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivity
     */
    'activityTypeId'?: number;
    /**
     * 액티비티 상태
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'activityState'?: string;
    /**
     * 액티비티 메모
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'memo'?: string;
    /**
     * 액티비티 웹사이트 주소
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'website'?: string;
    /**
     * 액티비티 시작일
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'startDt'?: string;
    /**
     * 액티비티 종료일
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'endDt'?: string;
    /**
     * 액티비티 완료 여부
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'completedYn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivity
     */
    'stateId'?: number;
    /**
     * 액티비티 상세 주소
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'onlyOnlineActivityYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'position'?: string;
    /**
     * 
     * @type {ResActivityType}
     * @memberof ResRoadmapActivity
     */
    'activityType'?: ResActivityType;
    /**
     * 
     * @type {Array<ResRoadmapActivityStrengthPoint>}
     * @memberof ResRoadmapActivity
     */
    'activityStrengthPoints'?: Array<ResRoadmapActivityStrengthPoint>;
    /**
     * 
     * @type {ResRoadmapActivityCourse}
     * @memberof ResRoadmapActivity
     */
    'activityCourse'?: ResRoadmapActivityCourse;
    /**
     * 
     * @type {ResState}
     * @memberof ResRoadmapActivity
     */
    'state'?: ResState;
    /**
     * Report Academic > Recommended Activity 의 Grade 값
     * @type {string}
     * @memberof ResRoadmapActivity
     */
    'grade'?: string;
}
/**
 * 
 * @export
 * @interface ResRoadmapActivityCourse
 */
export interface ResRoadmapActivityCourse {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityCourse
     */
    'activityCourseId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityCourse
     */
    'activityId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResRoadmapActivityCourse
     */
    'subjectCategoryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResRoadmapActivityList
 */
export interface ResRoadmapActivityList {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityList
     */
    'currentCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityList
     */
    'completedCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityList
     */
    'upcomingCnt'?: number;
    /**
     * 
     * @type {Array<ResRoadmapActivity>}
     * @memberof ResRoadmapActivityList
     */
    'roadmapActivities'?: Array<ResRoadmapActivity>;
}
/**
 * 
 * @export
 * @interface ResRoadmapActivityStrengthPoint
 */
export interface ResRoadmapActivityStrengthPoint {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityStrengthPoint
     */
    'activityStrengthPointId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityStrengthPoint
     */
    'activityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapActivityStrengthPoint
     */
    'strengthId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResRoadmapActivityStrengthPoint
     */
    'strengthPointIds'?: Array<string>;
    /**
     * 
     * @type {ResStrength}
     * @memberof ResRoadmapActivityStrengthPoint
     */
    'strength'?: ResStrength;
}
/**
 * 
 * @export
 * @interface ResRoadmapAnalysisActivity
 */
export interface ResRoadmapAnalysisActivity {
    /**
     * Analysis Activity Id
     * @type {number}
     * @memberof ResRoadmapAnalysisActivity
     */
    'analysisActivityId'?: number;
    /**
     * Activity Id
     * @type {number}
     * @memberof ResRoadmapAnalysisActivity
     */
    'activityId'?: number;
    /**
     * 
     * @type {ResRoadmapActivity}
     * @memberof ResRoadmapAnalysisActivity
     */
    'roadmapActivity'?: ResRoadmapActivity;
}
/**
 * 
 * @export
 * @interface ResRoadmapAnalysisReport
 */
export interface ResRoadmapAnalysisReport {
    /**
     * Report Id
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'analysisReportId'?: number;
    /**
     * College Id
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'ipedsCollegeId'?: number;
    /**
     * College Name
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'ipedsCollegeName'?: string;
    /**
     * Major Id
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'onetMajorId'?: string;
    /**
     * Major Name
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'onetMajorName'?: string;
    /**
     * Semester List Id 값 [1.1.1] ( 11th Grade 1st semester )
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'semesterListId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'testTypeId'?: number;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'satMath'?: number;
    /**
     * ACT English
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'actEnglish'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'actMath'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ResRoadmapAnalysisReport
     */
    'actScience'?: number;
    /**
     * Favorite
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'favoriteYn'?: string;
    /**
     * Report Data 여부 // 사용안함
     * @type {boolean}
     * @memberof ResRoadmapAnalysisReport
     */
    'reportDataFlag'?: boolean;
    /**
     * 
     * @type {RoadmapDataFlag}
     * @memberof ResRoadmapAnalysisReport
     */
    'roadmapDataFlag'?: RoadmapDataFlag;
    /**
     * 대학 이미지 주소
     * @type {string}
     * @memberof ResRoadmapAnalysisReport
     */
    'imgUrl'?: string;
}
/**
 * 
 * @export
 * @interface ResRoadmapAward
 */
export interface ResRoadmapAward {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapAward
     */
    'awardId'?: number;
    /**
     * Award Title
     * @type {string}
     * @memberof ResRoadmapAward
     */
    'title'?: string;
    /**
     * Grade Id
     * @type {number}
     * @memberof ResRoadmapAward
     */
    'gradeId'?: number;
    /**
     * Level Id
     * @type {number}
     * @memberof ResRoadmapAward
     */
    'awardLevelId'?: number;
    /**
     * 
     * @type {ResGrade}
     * @memberof ResRoadmapAward
     */
    'grade'?: ResGrade;
    /**
     * 
     * @type {ResAwardLevel}
     * @memberof ResRoadmapAward
     */
    'awardLevel'?: ResAwardLevel;
}
/**
 * 
 * @export
 * @interface ResRoadmapCourse
 */
export interface ResRoadmapCourse {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'courseId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'courseWorkId'?: number;
    /**
     * Subject Category Id
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'subjectCategoryId'?: number;
    /**
     * 코스카테고리Id
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'courseCategoryId'?: string;
    /**
     * 코스명
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'courseName'?: string;
    /**
     * 학년
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'grade'?: number;
    /**
     * 학기
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'semester'?: number;
    /**
     * 수강 완료 여부
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'courseCompleteYn'?: string;
    /**
     * 점수등급
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'scoreGrade'?: string;
    /**
     * 점수
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'score'?: string;
    /**
     * AP여부
     * @type {string}
     * @memberof ResRoadmapCourse
     */
    'apYn'?: string;
    /**
     * 정렬
     * @type {number}
     * @memberof ResRoadmapCourse
     */
    'orderNumber'?: number;
    /**
     * 
     * @type {ResSubjectCategory}
     * @memberof ResRoadmapCourse
     */
    'subjectCategory'?: ResSubjectCategory;
    /**
     * 
     * @type {ResCourseCategory}
     * @memberof ResRoadmapCourse
     */
    'courseCategory'?: ResCourseCategory;
}
/**
 * 
 * @export
 * @interface ResRoadmapCourseWork
 */
export interface ResRoadmapCourseWork {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'courseWorkId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'memberId'?: number;
    /**
     * My GPA
     * @type {string}
     * @memberof ResRoadmapCourseWork
     */
    'myGpa'?: string;
    /**
     * Roadmap 정보의 Target GPA
     * @type {string}
     * @memberof ResRoadmapCourseWork
     */
    'targetGpa'?: string;
    /**
     * Roadmap 정보의 Semester 값
     * @type {string}
     * @memberof ResRoadmapCourseWork
     */
    'semester'?: ResRoadmapCourseWorkSemesterEnum;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'completionRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'totalNumberOfCourses'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'completedCourses'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapCourseWork
     */
    'remainingCourses'?: number;
}

export const ResRoadmapCourseWorkSemesterEnum = {
    FirstSemester: 'FIRST_SEMESTER',
    Semester: 'SEMESTER',
    Trimester: 'TRIMESTER',
    Quarter: 'QUARTER'
} as const;

export type ResRoadmapCourseWorkSemesterEnum = typeof ResRoadmapCourseWorkSemesterEnum[keyof typeof ResRoadmapCourseWorkSemesterEnum];

/**
 * 로드맵 정보
 * @export
 * @interface ResRoadmapInfo
 */
export interface ResRoadmapInfo {
    /**
     * 
     * @type {RoadmapCourseWork}
     * @memberof ResRoadmapInfo
     */
    'roadmapCourseWork'?: RoadmapCourseWork;
    /**
     * 엑티비티수
     * @type {{ [key: string]: string; }}
     * @memberof ResRoadmapInfo
     */
    'roadmapActivityCount'?: { [key: string]: string; };
    /**
     * 
     * @type {ResRoadmapReportAcademic}
     * @memberof ResRoadmapInfo
     */
    'resRoadmapReportAcademic'?: ResRoadmapReportAcademic;
    /**
     * 
     * @type {ResRoadmapReportActivity}
     * @memberof ResRoadmapInfo
     */
    'resRoadmapReportActivity'?: ResRoadmapReportActivity;
}
/**
 * 
 * @export
 * @interface ResRoadmapReportAcademic
 */
export interface ResRoadmapReportAcademic {
    /**
     * 
     * @type {ResRoadmapReportMyScore}
     * @memberof ResRoadmapReportAcademic
     */
    'myScore'?: ResRoadmapReportMyScore;
    /**
     * Admission Requirements Based on Core Curriculum & Recommended Course
     * @type {Array<ResSubjectCategory>}
     * @memberof ResRoadmapReportAcademic
     */
    'subjectCategories'?: Array<ResSubjectCategory>;
    /**
     * 필수 과목 카테고리 명
     * @type {Array<ResSubjectCategory>}
     * @memberof ResRoadmapReportAcademic
     */
    'coreSubjectCategories'?: Array<ResSubjectCategory>;
}
/**
 * 
 * @export
 * @interface ResRoadmapReportActivity
 */
export interface ResRoadmapReportActivity {
    /**
     * Activities Strength Points 목록
     * @type {Array<ResStrength>}
     * @memberof ResRoadmapReportActivity
     */
    'strengths'?: Array<ResStrength>;
    /**
     * Activities Distribution by Category 목록
     * @type {Array<ResActivityType>}
     * @memberof ResRoadmapReportActivity
     */
    'activityCategories'?: Array<ResActivityType>;
    /**
     * Recommended Activities 목록
     * @type {Array<ResRecommendedActivity>}
     * @memberof ResRoadmapReportActivity
     */
    'recommendedActivities'?: Array<ResRecommendedActivity>;
}
/**
 * Test Score, Hight School GPA
 * @export
 * @interface ResRoadmapReportMyScore
 */
export interface ResRoadmapReportMyScore {
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'myGpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'myTestScore'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'targetTestScore'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'collegeGpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'collegeMaxTestScore'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'collegeMinTestScore'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapReportMyScore
     */
    'averageGpa'?: string;
}
/**
 * 
 * @export
 * @interface ResRoadmapTestScore
 */
export interface ResRoadmapTestScore {
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'testScoreId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'testTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapTestScore
     */
    'testDate'?: string;
    /**
     * Test Plan 여부
     * @type {string}
     * @memberof ResRoadmapTestScore
     */
    'planYn'?: string;
    /**
     * Test Plan Enter test results 체크 여부
     * @type {string}
     * @memberof ResRoadmapTestScore
     */
    'testResultYn'?: string;
    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'satReadingWriting'?: number;
    /**
     * SAT Math
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'satMath'?: number;
    /**
     * ACT English
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'actEnglish'?: number;
    /**
     * ACT Math
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'actMath'?: number;
    /**
     * ACT Reading
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'actReading'?: number;
    /**
     * ACT Science
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'actScience'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResRoadmapTestScore
     */
    'aptestSubjectId'?: string;
    /**
     * AP Score
     * @type {number}
     * @memberof ResRoadmapTestScore
     */
    'apScore'?: number;
    /**
     * 
     * @type {ResTestType}
     * @memberof ResRoadmapTestScore
     */
    'testType'?: ResTestType;
    /**
     * 
     * @type {ResAptestSubject}
     * @memberof ResRoadmapTestScore
     */
    'aptestSubject'?: ResAptestSubject;
}
/**
 * 
 * @export
 * @interface ResRoadmapTestScoreMyScore
 */
export interface ResRoadmapTestScoreMyScore {
    /**
     * My Score
     * @type {number}
     * @memberof ResRoadmapTestScoreMyScore
     */
    'myScore'?: number;
    /**
     * Target Score 정보 (DisplayName 값에 따라 SAT, ACT 계산식 적용)
     * @type {number}
     * @memberof ResRoadmapTestScoreMyScore
     */
    'targetScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResRoadmapTestScoreMyScore
     */
    'upcoming'?: number;
}
/**
 * 
 * @export
 * @interface ResSemesterList
 */
export interface ResSemesterList {
    /**
     * 
     * @type {string}
     * @memberof ResSemesterList
     */
    'semesterListId'?: string;
    /**
     * 설명
     * @type {string}
     * @memberof ResSemesterList
     */
    'description'?: string;
    /**
     * 학년 명칭
     * @type {string}
     * @memberof ResSemesterList
     */
    'grade'?: string;
    /**
     * 학년 값
     * @type {number}
     * @memberof ResSemesterList
     */
    'gradeValue'?: number;
    /**
     * 학기
     * @type {number}
     * @memberof ResSemesterList
     */
    'semester'?: number;
}
/**
 * 
 * @export
 * @interface ResSimpleMemberLikeCollege
 */
export interface ResSimpleMemberLikeCollege {
    /**
     * 
     * @type {number}
     * @memberof ResSimpleMemberLikeCollege
     */
    'memberLikeCollegeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResSimpleMemberLikeCollege
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResSimpleMemberLikeCollege
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {SimpleCollegeNicheIpeds}
     * @memberof ResSimpleMemberLikeCollege
     */
    'collegeNicheIpeds'?: SimpleCollegeNicheIpeds;
    /**
     * 
     * @type {string}
     * @memberof ResSimpleMemberLikeCollege
     */
    'onetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResSimpleMemberLikeCollege
     */
    'onetMajorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResSimpleMemberLikeCollege
     */
    'analysisReportId'?: number;
}
/**
 * 
 * @export
 * @interface ResSocialIdTokenValid
 */
export interface ResSocialIdTokenValid {
    /**
     * 
     * @type {string}
     * @memberof ResSocialIdTokenValid
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResSocialIdTokenValid
     */
    'success'?: boolean;
}
/**
 * State 정보
 * @export
 * @interface ResState
 */
export interface ResState {
    /**
     * 
     * @type {number}
     * @memberof ResState
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResState
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResState
     */
    'countryId'?: number;
}
/**
 * 
 * @export
 * @interface ResStepInfo
 */
export interface ResStepInfo {
    /**
     * 스탭 별 진행 여부 정보
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ResStepInfo
     */
    'myPondStepList'?: Array<{ [key: string]: object; }>;
    /**
     * 스탭 진행상태 수치
     * @type {number}
     * @memberof ResStepInfo
     */
    'stepRate'?: number;
}
/**
 * 
 * @export
 * @interface ResStrength
 */
export interface ResStrength {
    /**
     * 
     * @type {number}
     * @memberof ResStrength
     */
    'strengthId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResStrength
     */
    'strengthName'?: string;
    /**
     * Analysis Report > Activities Strength Points > My Strength Count
     * @type {number}
     * @memberof ResStrength
     */
    'myStrength'?: number;
    /**
     * Analysis Report > Activities Strength Points > Recommended Strength Count
     * @type {number}
     * @memberof ResStrength
     */
    'recommendedStrength'?: number;
    /**
     * Analysis Report > Activities Strength Points > 충족 여부
     * @type {boolean}
     * @memberof ResStrength
     */
    'metFlag'?: boolean;
}
/**
 * 
 * @export
 * @interface ResStrengthPoint
 */
export interface ResStrengthPoint {
    /**
     * 
     * @type {string}
     * @memberof ResStrengthPoint
     */
    'strengthPointId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStrengthPoint
     */
    'strengthPointName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResStrengthPoint
     */
    'strengthId'?: number;
    /**
     * 
     * @type {ResStrength}
     * @memberof ResStrengthPoint
     */
    'strength'?: ResStrength;
}
/**
 * 
 * @export
 * @interface ResStudentSurvey
 */
export interface ResStudentSurvey {
    /**
     * 
     * @type {number}
     * @memberof ResStudentSurvey
     */
    'memberSurveyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResStudentSurvey
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'findOut'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'findOutDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResStudentSurvey
     */
    'countryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResStudentSurvey
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'helpNeed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'helpNeedDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'satReadingWriting'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'satMath'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'actEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'actMath'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'actReading'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'actScience'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'averageGrade'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResStudentSurvey
     */
    'targetSchoolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'targetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'targetCareerId'?: string;
    /**
     * Current Displayed [SAT,ACT]
     * @type {string}
     * @memberof ResStudentSurvey
     */
    'currentDisplayed'?: string;
    /**
     * 
     * @type {Array<ResMemberEduHist>}
     * @memberof ResStudentSurvey
     */
    'memberEduHists'?: Array<ResMemberEduHist>;
}
/**
 * 
 * @export
 * @interface ResSubjectCategory
 */
export interface ResSubjectCategory {
    /**
     * 
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'subjectCategoryId'?: number;
    /**
     * 과목명
     * @type {string}
     * @memberof ResSubjectCategory
     */
    'title'?: string;
    /**
     * 약어
     * @type {string}
     * @memberof ResSubjectCategory
     */
    'abbreviation'?: string;
    /**
     * My CourseWork Year
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'myCourseWorkYear'?: number;
    /**
     * My Course Count
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'myCourseCount'?: number;
    /**
     * Recommended CourseWork Year
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'recommendedCourseWorkYear'?: number;
    /**
     * Recommended Course Count
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'recommendedCourseCount'?: number;
    /**
     * Core Curriculum CourseWork Year
     * @type {number}
     * @memberof ResSubjectCategory
     */
    'coreCurriculumCourseWorkYear'?: number;
    /**
     * Admission Requirements Bases 충족 여부
     * @type {boolean}
     * @memberof ResSubjectCategory
     */
    'metFlag'?: boolean;
    /**
     * Recommended Course Count 충족 여부
     * @type {boolean}
     * @memberof ResSubjectCategory
     */
    'metCourseFlag'?: boolean;
    /**
     * Recommended Course > course category 목록
     * @type {Array<ResCourseCategory>}
     * @memberof ResSubjectCategory
     */
    'courses'?: Array<ResCourseCategory>;
}
/**
 * 
 * @export
 * @interface ResTargetGPA
 */
export interface ResTargetGPA {
    /**
     * 등급
     * @type {string}
     * @memberof ResTargetGPA
     */
    'name'?: string;
    /**
     * 점수
     * @type {string}
     * @memberof ResTargetGPA
     */
    'score'?: string;
}
/**
 * 
 * @export
 * @interface ResTestType
 */
export interface ResTestType {
    /**
     * 
     * @type {number}
     * @memberof ResTestType
     */
    'testTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResTestType
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ResUnweightedGpa
 */
export interface ResUnweightedGpa {
    /**
     * 
     * @type {number}
     * @memberof ResUnweightedGpa
     */
    'unweightedGpaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResUnweightedGpa
     */
    'satScore'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResUnweightedGpa
     */
    'actScore'?: string;
}
/**
 * 
 * @export
 * @interface ResUserChancesInfo
 */
export interface ResUserChancesInfo {
    /**
     * 
     * @type {number}
     * @memberof ResUserChancesInfo
     */
    'userChancesRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResUserChancesInfo
     */
    'userChancesTitle'?: string;
}
/**
 * 
 * @export
 * @interface ResearchPaperMember
 */
export interface ResearchPaperMember {
    /**
     * research paper id
     * @type {number}
     * @memberof ResearchPaperMember
     */
    'researchPaperId'?: number;
    /**
     * member id
     * @type {number}
     * @memberof ResearchPaperMember
     */
    'memberId'?: number;
    /**
     * member type
     * @type {string}
     * @memberof ResearchPaperMember
     */
    'memberType'?: string;
    /**
     * member name
     * @type {string}
     * @memberof ResearchPaperMember
     */
    'memberName'?: string;
    /**
     * member email
     * @type {string}
     * @memberof ResearchPaperMember
     */
    'memberEmail'?: string;
    /**
     * create date
     * @type {string}
     * @memberof ResearchPaperMember
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface Roadmap
 */
export interface Roadmap {
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'roadmapId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'memberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'semester'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'dreamIpedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'dreamOnetMajorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'targetGpaGrade'?: string;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'satReadingWriting'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'satMath'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'actEnglish'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'actMath'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'actReading'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'actScience'?: number;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roadmap
     */
    'onetMajorName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Roadmap
     */
    'reportFlag'?: boolean;
    /**
     * 
     * @type {RoadmapDataFlag}
     * @memberof Roadmap
     */
    'roadmapDataFlag'?: RoadmapDataFlag;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'reportCourseWorkCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'reportUpcomingActivityCnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'mySatReadingWriting'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'mySatMath'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'myActEnglish'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'myActMath'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'myActReading'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roadmap
     */
    'myActScience'?: number;
}
/**
 * 
 * @export
 * @interface RoadmapCourse
 */
export interface RoadmapCourse {
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'courseId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'courseWorkId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'subjectCategoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'courseCategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'apYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'courseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'grade'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'semester'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'courseCompleteYn'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourse
     */
    'orderNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'scoreGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourse
     */
    'score'?: string;
    /**
     * 
     * @type {SubjectCategory}
     * @memberof RoadmapCourse
     */
    'subjectCategory'?: SubjectCategory;
    /**
     * 
     * @type {CourseCategory}
     * @memberof RoadmapCourse
     */
    'courseCategory'?: CourseCategory;
}
/**
 * 코스정보
 * @export
 * @interface RoadmapCourseWork
 */
export interface RoadmapCourseWork {
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'courseWorkId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'memberId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'completionRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'totalNumberOfCourses'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'completedCourses'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseWork
     */
    'remainingCourses'?: number;
    /**
     * 
     * @type {Array<RoadmapCourse>}
     * @memberof RoadmapCourseWork
     */
    'roadmapCourses'?: Array<RoadmapCourse>;
    /**
     * 
     * @type {Roadmap}
     * @memberof RoadmapCourseWork
     */
    'roadmap'?: Roadmap;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourseWork
     */
    'semester'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourseWork
     */
    'targetGpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadmapCourseWork
     */
    'myGpa'?: string;
}
/**
 * dream college, major 기반 report 가능 여부
 * @export
 * @interface RoadmapDataFlag
 */
export interface RoadmapDataFlag {
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isCollege'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isMajor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isRoadmap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isAcademic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isActivity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoadmapDataFlag
     */
    'isRecommendedActivity'?: boolean;
}
/**
 * 
 * @export
 * @interface SimpleCollegeNicheIpeds
 */
export interface SimpleCollegeNicheIpeds {
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'ipedsCollegeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'ipedsCollegeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'ialias'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'stateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'addressAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'addressCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'addressState'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'addressRegion'?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'acceptRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'actc'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'acte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'actm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'satCompRange'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'satCr'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'satM'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'satCompRangeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'actcMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'satCompRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleCollegeNicheIpeds
     */
    'actcMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'admissionStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'admissionRound'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCollegeNicheIpeds
     */
    'imgUrl'?: string;
}
/**
 * 
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * skill level id
     * @type {number}
     * @memberof Skill
     */
    'skillLevel'?: number;
    /**
     * skill master id
     * @type {number}
     * @memberof Skill
     */
    'skillMasterId'?: number;
    /**
     * skill master name
     * @type {string}
     * @memberof Skill
     */
    'skillMasterName'?: string;
    /**
     * skill detail id
     * @type {number}
     * @memberof Skill
     */
    'skillDetailId'?: number;
    /**
     * sill detail name
     * @type {string}
     * @memberof Skill
     */
    'skillDetailName'?: string;
    /**
     * display order
     * @type {number}
     * @memberof Skill
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface StartEndTime
 */
export interface StartEndTime {
    /**
     * start time
     * @type {string}
     * @memberof StartEndTime
     */
    'startTime'?: string;
    /**
     * end time
     * @type {string}
     * @memberof StartEndTime
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface StripeInfo
 */
export interface StripeInfo {
    /**
     * 
     * @type {number}
     * @memberof StripeInfo
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    'stripeConnectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StripeInfo
     */
    'stripeEnable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    'accountSettingUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    'settlementUrl'?: string;
}
/**
 * 
 * @export
 * @interface SubjectCategory
 */
export interface SubjectCategory {
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'subjectCategoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectCategory
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'myCourseWorkYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'myCourseCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'recommendedCourseWorkYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'recommendedCourseCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectCategory
     */
    'coreCurriculumCourseWorkYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectCategory
     */
    'metFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectCategory
     */
    'metCourseFlag'?: boolean;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof SubjectCategory
     */
    'courses'?: Array<CourseCategory>;
}
/**
 * 
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     * 상품종류
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'product'?: SubscriptionInfoProductEnum;
    /**
     * 시작일
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'startDay'?: string;
    /**
     * 종료일
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'endDay'?: string;
    /**
     * 종료일
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'amount'?: number;
    /**
     * 상태
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'status'?: string;
    /**
     * 결제주기
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'interval'?: string;
    /**
     * 구독명
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'subscriptionTitle'?: string;
    /**
     * 통화
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'currency'?: string;
    /**
     * 무료평가기간 사용 여부
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'freeTrial'?: boolean;
    /**
     * 구독취소여부
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'cancelAtPeriodEnd'?: boolean;
    /**
     * 무료뷰잉패스사용갯수
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'freeUsedPassCount'?: number;
    /**
     * 무료뷰잉패스총갯수
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'freeTotalPassCount'?: number;
    /**
     * 구매뷰잉패스사용갯수
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'purchasedUsedPassCount'?: number;
    /**
     * 구매뷰잉패스총갯수
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'purchasedTotalPassCount'?: number;
}

export const SubscriptionInfoProductEnum = {
    MonthlySubscription: 'MONTHLY_SUBSCRIPTION',
    YearSubscription: 'YEAR_SUBSCRIPTION',
    TestSubscription: 'TEST_SUBSCRIPTION',
    Report4: 'REPORT4',
    Report8: 'REPORT8'
} as const;

export type SubscriptionInfoProductEnum = typeof SubscriptionInfoProductEnum[keyof typeof SubscriptionInfoProductEnum];

/**
 * 
 * @export
 * @interface TbExpertBankAccountEntity
 */
export interface TbExpertBankAccountEntity {
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TbExpertBankAccountEntity
     */
    'bankAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TbExpertBankAccountEntity
     */
    'expertId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'useYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'accountHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'routingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof TbExpertBankAccountEntity
     */
    'zipCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof TbExpertBankAccountEntity
     */
    'countryId'?: number;
}
/**
 * 
 * @export
 * @interface ToeflTestInfo
 */
export interface ToeflTestInfo {
    /**
     * 
     * @type {number}
     * @memberof ToeflTestInfo
     */
    'readingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ToeflTestInfo
     */
    'speakingScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ToeflTestInfo
     */
    'listeningScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ToeflTestInfo
     */
    'writingScore'?: number;
}
/**
 * 
 * @export
 * @interface TutorTypeCode
 */
export interface TutorTypeCode {
    /**
     * 
     * @type {number}
     * @memberof TutorTypeCode
     */
    'categoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TutorTypeCode
     */
    'categoryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TutorTypeCode
     */
    'objectLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof TutorTypeCode
     */
    'typeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TutorTypeCode
     */
    'typeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TutorTypeCode
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface VerificationStatus
 */
export interface VerificationStatus {
    /**
     * 
     * @type {number}
     * @memberof VerificationStatus
     */
    'verificationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerificationStatus
     */
    'verificationName'?: string;
    /**
     * 
     * @type {number}
     * @memberof VerificationStatus
     */
    'displayOrder'?: number;
}
/**
 * 
 * @export
 * @interface WebSite
 */
export interface WebSite {
    /**
     * website id
     * @type {number}
     * @memberof WebSite
     */
    'siteId'?: number;
    /**
     * site name
     * @type {string}
     * @memberof WebSite
     */
    'siteName'?: string;
    /**
     * site url
     * @type {string}
     * @memberof WebSite
     */
    'siteUrl'?: string;
}
/**
 * 
 * @export
 * @interface WorkExperience
 */
export interface WorkExperience {
    /**
     * work experience id
     * @type {number}
     * @memberof WorkExperience
     */
    'workExperienceId'?: number;
    /**
     * career type
     * @type {string}
     * @memberof WorkExperience
     */
    'careerType'?: string;
    /**
     * career start date
     * @type {string}
     * @memberof WorkExperience
     */
    'startDate'?: string;
    /**
     * career end date
     * @type {string}
     * @memberof WorkExperience
     */
    'endDate'?: string;
    /**
     * employment status
     * @type {string}
     * @memberof WorkExperience
     */
    'workingNowYn'?: string;
    /**
     * company name
     * @type {string}
     * @memberof WorkExperience
     */
    'companyName'?: string;
    /**
     * 
     * @type {Region}
     * @memberof WorkExperience
     */
    'region'?: Region;
    /**
     * position
     * @type {string}
     * @memberof WorkExperience
     */
    'position'?: string;
    /**
     * role description
     * @type {string}
     * @memberof WorkExperience
     */
    'roleDesc'?: string;
}

/**
 * AccountSettingApi - axios parameter creator
 * @export
 */
export const AccountSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enum Type의 NotificationStatusType을 사용<br>ALLOW_ALL : 모두 허용 <br>REFUSE : 거절
         * @summary 계정 알림 상태변경 등록.
         * @param {ReqChangeNotification} reqChangeNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotificationStatus: async (reqChangeNotification: ReqChangeNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqChangeNotification' is not null or undefined
            assertParamExists('changeNotificationStatus', 'reqChangeNotification', reqChangeNotification)
            const localVarPath = `/settings/account/notification/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqChangeNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 계정 비활성화 / 탈퇴 사유 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeleteAccountReason: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/account/reason/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 계정 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * email, Notification의 상태를 내려준다.
         * @summary 계정 알림 상태 조회.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/account/notification/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary NotificationStatusType 코드 / 설명 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationStatusType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/account/notification/status/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 계정 사용 상태변경 등록.
         * @param {string} action delete : 계정 삭제&lt;br&gt; deactivate : 계정 비활성화&lt;br&gt; activate : 계정 활성화
         * @param {ReqDeleteAccount} [reqDeleteAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountStatus: async (action: string, reqDeleteAccount?: ReqDeleteAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('setAccountStatus', 'action', action)
            const localVarPath = `/settings/account/status/{action}`
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqDeleteAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountSettingApi - functional programming interface
 * @export
 */
export const AccountSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Enum Type의 NotificationStatusType을 사용<br>ALLOW_ALL : 모두 허용 <br>REFUSE : 거절
         * @summary 계정 알림 상태변경 등록.
         * @param {ReqChangeNotification} reqChangeNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeNotificationStatus(reqChangeNotification: ReqChangeNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeNotificationStatus(reqChangeNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 계정 비활성화 / 탈퇴 사유 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeleteAccountReason(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: string; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeleteAccountReason(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 계정 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * email, Notification의 상태를 내려준다.
         * @summary 계정 알림 상태 조회.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary NotificationStatusType 코드 / 설명 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationStatusType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: string; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationStatusType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 계정 사용 상태변경 등록.
         * @param {string} action delete : 계정 삭제&lt;br&gt; deactivate : 계정 비활성화&lt;br&gt; activate : 계정 활성화
         * @param {ReqDeleteAccount} [reqDeleteAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAccountStatus(action: string, reqDeleteAccount?: ReqDeleteAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAccountStatus(action, reqDeleteAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountSettingApi - factory interface
 * @export
 */
export const AccountSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountSettingApiFp(configuration)
    return {
        /**
         * Enum Type의 NotificationStatusType을 사용<br>ALLOW_ALL : 모두 허용 <br>REFUSE : 거절
         * @summary 계정 알림 상태변경 등록.
         * @param {AccountSettingApiChangeNotificationStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotificationStatus(requestParameters: AccountSettingApiChangeNotificationStatusRequest, options?: AxiosRequestConfig): AxiosPromise<MemberStatus> {
            return localVarFp.changeNotificationStatus(requestParameters.reqChangeNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 계정 비활성화 / 탈퇴 사유 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeleteAccountReason(options?: AxiosRequestConfig): AxiosPromise<Array<{ [key: string]: string; }>> {
            return localVarFp.getDeleteAccountReason(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 계정 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberInfo(options?: AxiosRequestConfig): AxiosPromise<ResMember> {
            return localVarFp.getMemberInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * email, Notification의 상태를 내려준다.
         * @summary 계정 알림 상태 조회.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationStatus(options?: AxiosRequestConfig): AxiosPromise<MemberStatus> {
            return localVarFp.getNotificationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary NotificationStatusType 코드 / 설명 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationStatusType(options?: AxiosRequestConfig): AxiosPromise<Array<{ [key: string]: string; }>> {
            return localVarFp.getNotificationStatusType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 계정 사용 상태변경 등록.
         * @param {AccountSettingApiSetAccountStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountStatus(requestParameters: AccountSettingApiSetAccountStatusRequest, options?: AxiosRequestConfig): AxiosPromise<MemberStatus> {
            return localVarFp.setAccountStatus(requestParameters.action, requestParameters.reqDeleteAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeNotificationStatus operation in AccountSettingApi.
 * @export
 * @interface AccountSettingApiChangeNotificationStatusRequest
 */
export interface AccountSettingApiChangeNotificationStatusRequest {
    /**
     * 
     * @type {ReqChangeNotification}
     * @memberof AccountSettingApiChangeNotificationStatus
     */
    readonly reqChangeNotification: ReqChangeNotification
}

/**
 * Request parameters for setAccountStatus operation in AccountSettingApi.
 * @export
 * @interface AccountSettingApiSetAccountStatusRequest
 */
export interface AccountSettingApiSetAccountStatusRequest {
    /**
     * delete : 계정 삭제&lt;br&gt; deactivate : 계정 비활성화&lt;br&gt; activate : 계정 활성화
     * @type {string}
     * @memberof AccountSettingApiSetAccountStatus
     */
    readonly action: string

    /**
     * 
     * @type {ReqDeleteAccount}
     * @memberof AccountSettingApiSetAccountStatus
     */
    readonly reqDeleteAccount?: ReqDeleteAccount
}

/**
 * AccountSettingApi - object-oriented interface
 * @export
 * @class AccountSettingApi
 * @extends {BaseAPI}
 */
export class AccountSettingApi extends BaseAPI {
    /**
     * Enum Type의 NotificationStatusType을 사용<br>ALLOW_ALL : 모두 허용 <br>REFUSE : 거절
     * @summary 계정 알림 상태변경 등록.
     * @param {AccountSettingApiChangeNotificationStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public changeNotificationStatus(requestParameters: AccountSettingApiChangeNotificationStatusRequest, options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).changeNotificationStatus(requestParameters.reqChangeNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 계정 비활성화 / 탈퇴 사유 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public getDeleteAccountReason(options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).getDeleteAccountReason(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 계정 기본정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public getMemberInfo(options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).getMemberInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * email, Notification의 상태를 내려준다.
     * @summary 계정 알림 상태 조회.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public getNotificationStatus(options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).getNotificationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary NotificationStatusType 코드 / 설명 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public getNotificationStatusType(options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).getNotificationStatusType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 계정 사용 상태변경 등록.
     * @param {AccountSettingApiSetAccountStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingApi
     */
    public setAccountStatus(requestParameters: AccountSettingApiSetAccountStatusRequest, options?: AxiosRequestConfig) {
        return AccountSettingApiFp(this.configuration).setAccountStatus(requestParameters.action, requestParameters.reqDeleteAccount, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Email 로 인증 코드를 발급한다.
         * @summary Email 인증 코드 발급
         * @param {ReqEmailCodeIssued} reqEmailCodeIssued 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeIssued1: async (reqEmailCodeIssued: ReqEmailCodeIssued, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqEmailCodeIssued' is not null or undefined
            assertParamExists('postEmailCodeIssued1', 'reqEmailCodeIssued', reqEmailCodeIssued)
            const localVarPath = `/auth/sign-in/email/code-issued`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqEmailCodeIssued, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 인증 코드를 검사 후 토큰을 발급한다.
         * @summary Email 인증 코드 유효성 검사
         * @param {ReqEmailCodeValid} reqEmailCodeValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeValid1: async (reqEmailCodeValid: ReqEmailCodeValid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqEmailCodeValid' is not null or undefined
            assertParamExists('postEmailCodeValid1', 'reqEmailCodeValid', reqEmailCodeValid)
            const localVarPath = `/auth/sign-in/email/code-valid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqEmailCodeValid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 소셜 계정으로 로그인한다.
         * @summary 소셜 로그인
         * @param {ReqSignInSocial} reqSignInSocial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignInSocial: async (reqSignInSocial: ReqSignInSocial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqSignInSocial' is not null or undefined
            assertParamExists('postSignInSocial', 'reqSignInSocial', reqSignInSocial)
            const localVarPath = `/auth/sign-in/social`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqSignInSocial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Email 로 인증 코드를 발급한다.
         * @summary Email 인증 코드 발급
         * @param {ReqEmailCodeIssued} reqEmailCodeIssued 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailCodeIssued1(reqEmailCodeIssued: ReqEmailCodeIssued, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResEmailCodeIssued>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailCodeIssued1(reqEmailCodeIssued, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 인증 코드를 검사 후 토큰을 발급한다.
         * @summary Email 인증 코드 유효성 검사
         * @param {ReqEmailCodeValid} reqEmailCodeValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailCodeValid1(reqEmailCodeValid: ReqEmailCodeValid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailCodeValid1(reqEmailCodeValid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 소셜 계정으로 로그인한다.
         * @summary 소셜 로그인
         * @param {ReqSignInSocial} reqSignInSocial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignInSocial(reqSignInSocial: ReqSignInSocial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignInSocial(reqSignInSocial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Email 로 인증 코드를 발급한다.
         * @summary Email 인증 코드 발급
         * @param {AuthApiPostEmailCodeIssued1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeIssued1(requestParameters: AuthApiPostEmailCodeIssued1Request, options?: AxiosRequestConfig): AxiosPromise<ResEmailCodeIssued> {
            return localVarFp.postEmailCodeIssued1(requestParameters.reqEmailCodeIssued, options).then((request) => request(axios, basePath));
        },
        /**
         * 인증 코드를 검사 후 토큰을 발급한다.
         * @summary Email 인증 코드 유효성 검사
         * @param {AuthApiPostEmailCodeValid1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeValid1(requestParameters: AuthApiPostEmailCodeValid1Request, options?: AxiosRequestConfig): AxiosPromise<ResMember> {
            return localVarFp.postEmailCodeValid1(requestParameters.reqEmailCodeValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 소셜 계정으로 로그인한다.
         * @summary 소셜 로그인
         * @param {AuthApiPostSignInSocialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignInSocial(requestParameters: AuthApiPostSignInSocialRequest, options?: AxiosRequestConfig): AxiosPromise<ResMember> {
            return localVarFp.postSignInSocial(requestParameters.reqSignInSocial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for postEmailCodeIssued1 operation in AuthApi.
 * @export
 * @interface AuthApiPostEmailCodeIssued1Request
 */
export interface AuthApiPostEmailCodeIssued1Request {
    /**
     * 
     * @type {ReqEmailCodeIssued}
     * @memberof AuthApiPostEmailCodeIssued1
     */
    readonly reqEmailCodeIssued: ReqEmailCodeIssued
}

/**
 * Request parameters for postEmailCodeValid1 operation in AuthApi.
 * @export
 * @interface AuthApiPostEmailCodeValid1Request
 */
export interface AuthApiPostEmailCodeValid1Request {
    /**
     * 
     * @type {ReqEmailCodeValid}
     * @memberof AuthApiPostEmailCodeValid1
     */
    readonly reqEmailCodeValid: ReqEmailCodeValid
}

/**
 * Request parameters for postSignInSocial operation in AuthApi.
 * @export
 * @interface AuthApiPostSignInSocialRequest
 */
export interface AuthApiPostSignInSocialRequest {
    /**
     * 
     * @type {ReqSignInSocial}
     * @memberof AuthApiPostSignInSocial
     */
    readonly reqSignInSocial: ReqSignInSocial
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Email 로 인증 코드를 발급한다.
     * @summary Email 인증 코드 발급
     * @param {AuthApiPostEmailCodeIssued1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postEmailCodeIssued1(requestParameters: AuthApiPostEmailCodeIssued1Request, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postEmailCodeIssued1(requestParameters.reqEmailCodeIssued, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 인증 코드를 검사 후 토큰을 발급한다.
     * @summary Email 인증 코드 유효성 검사
     * @param {AuthApiPostEmailCodeValid1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postEmailCodeValid1(requestParameters: AuthApiPostEmailCodeValid1Request, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postEmailCodeValid1(requestParameters.reqEmailCodeValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 소셜 계정으로 로그인한다.
     * @summary 소셜 로그인
     * @param {AuthApiPostSignInSocialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postSignInSocial(requestParameters: AuthApiPostSignInSocialRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postSignInSocial(requestParameters.reqSignInSocial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CareerSurveyApi - axios parameter creator
 * @export
 */
export const CareerSurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Career 상세 조회
         * @param {string} careerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareer: async (careerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careerId' is not null or undefined
            assertParamExists('getCareer', 'careerId', careerId)
            const localVarPath = `/career-survey/careers/{careerId}`
                .replace(`{${"careerId"}}`, encodeURIComponent(String(careerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Clusters 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/career-clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurvey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 문항정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurveyQuestions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurveyStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career 목록 조회
         * @param {string} [surveyYn] 
         * @param {Array<string>} [careerClusterIds] 
         * @param {Array<number>} [educationLevelIds] 
         * @param {number} [startIncome] 
         * @param {number} [endIncome] 
         * @param {string} [careerName] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareers: async (surveyYn?: string, careerClusterIds?: Array<string>, educationLevelIds?: Array<number>, startIncome?: number, endIncome?: number, careerName?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/careers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (surveyYn !== undefined) {
                localVarQueryParameter['surveyYn'] = surveyYn;
            }

            if (careerClusterIds) {
                localVarQueryParameter['careerClusterIds'] = careerClusterIds;
            }

            if (educationLevelIds) {
                localVarQueryParameter['educationLevelIds'] = educationLevelIds;
            }

            if (startIncome !== undefined) {
                localVarQueryParameter['startIncome'] = startIncome;
            }

            if (endIncome !== undefined) {
                localVarQueryParameter['endIncome'] = endIncome;
            }

            if (careerName !== undefined) {
                localVarQueryParameter['careerName'] = careerName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career 검색 조건 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareersSearchParameters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/careers-search-parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Education Levels 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationLevels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/education-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeCareerList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 결과조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career 관심 삭제
         * @param {string} careerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeCareerDelete: async (careerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careerId' is not null or undefined
            assertParamExists('likeCareerDelete', 'careerId', careerId)
            const localVarPath = `/career-survey/likes/delete/{careerId}`
                .replace(`{${"careerId"}}`, encodeURIComponent(String(careerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career 관심 등록
         * @param {ReqCareerLike} reqCareerLike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeCareerSave: async (reqCareerLike: ReqCareerLike, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCareerLike' is not null or undefined
            assertParamExists('likeCareerSave', 'reqCareerLike', reqCareerLike)
            const localVarPath = `/career-survey/likes/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCareerLike, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 다시 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reSubmitCareerSurvey: async (reqCareerSurveyResult: ReqCareerSurveyResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCareerSurveyResult' is not null or undefined
            assertParamExists('reSubmitCareerSurvey', 'reqCareerSurveyResult', reqCareerSurveyResult)
            const localVarPath = `/career-survey/re-submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCareerSurveyResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCareerSurvey: async (reqCareerSurveyResult: ReqCareerSurveyResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCareerSurveyResult' is not null or undefined
            assertParamExists('submitCareerSurvey', 'reqCareerSurveyResult', reqCareerSurveyResult)
            const localVarPath = `/career-survey/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCareerSurveyResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 임시 저장 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/career-survey/temp-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Career Survey 임시 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempSubmitCareerSurvey: async (reqCareerSurveyResult: ReqCareerSurveyResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCareerSurveyResult' is not null or undefined
            assertParamExists('tempSubmitCareerSurvey', 'reqCareerSurveyResult', reqCareerSurveyResult)
            const localVarPath = `/career-survey/temp-submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCareerSurveyResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerSurveyApi - functional programming interface
 * @export
 */
export const CareerSurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerSurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Career 상세 조회
         * @param {string} careerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareer(careerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareer(careerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Clusters 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResCareerClusters>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerSurvey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareerSurveyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerSurvey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 문항정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerSurveyQuestions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResOnetQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerSurveyQuestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerSurveyStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareerSurveyStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerSurveyStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career 목록 조회
         * @param {string} [surveyYn] 
         * @param {Array<string>} [careerClusterIds] 
         * @param {Array<number>} [educationLevelIds] 
         * @param {number} [startIncome] 
         * @param {number} [endIncome] 
         * @param {string} [careerName] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareers(surveyYn?: string, careerClusterIds?: Array<string>, educationLevelIds?: Array<number>, startIncome?: number, endIncome?: number, careerName?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCareers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareers(surveyYn, careerClusterIds, educationLevelIds, startIncome, endIncome, careerName, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career 검색 조건 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareersSearchParameters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOnetCareerSearchParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareersSearchParameters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Education Levels 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducationLevels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResEducationLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEducationLevels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLikeCareerList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResMemberLikeCareer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLikeCareerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 결과조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResOnetResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career 관심 삭제
         * @param {string} careerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeCareerDelete(careerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeCareerDelete(careerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career 관심 등록
         * @param {ReqCareerLike} reqCareerLike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeCareerSave(reqCareerLike: ReqCareerLike, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberLikeCareer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeCareerSave(reqCareerLike, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 다시 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reSubmitCareerSurvey(reqCareerSurveyResult: ReqCareerSurveyResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareerSurveyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reSubmitCareerSurvey(reqCareerSurveyResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitCareerSurvey(reqCareerSurveyResult: ReqCareerSurveyResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareerSurveyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitCareerSurvey(reqCareerSurveyResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 임시 저장 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tempDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tempDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Career Survey 임시 저장
         * @param {ReqCareerSurveyResult} reqCareerSurveyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tempSubmitCareerSurvey(reqCareerSurveyResult: ReqCareerSurveyResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCareerSurveyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tempSubmitCareerSurvey(reqCareerSurveyResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CareerSurveyApi - factory interface
 * @export
 */
export const CareerSurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerSurveyApiFp(configuration)
    return {
        /**
         * 
         * @summary Career 상세 조회
         * @param {CareerSurveyApiGetCareerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareer(requestParameters: CareerSurveyApiGetCareerRequest, options?: AxiosRequestConfig): AxiosPromise<ResCareers> {
            return localVarFp.getCareer(requestParameters.careerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Clusters 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusters(options?: AxiosRequestConfig): AxiosPromise<Array<ResCareerClusters>> {
            return localVarFp.getCareerClusters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurvey(options?: AxiosRequestConfig): AxiosPromise<ResCareerSurveyResult> {
            return localVarFp.getCareerSurvey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 문항정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurveyQuestions(options?: AxiosRequestConfig): AxiosPromise<Array<ResOnetQuestion>> {
            return localVarFp.getCareerSurveyQuestions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSurveyStatus(options?: AxiosRequestConfig): AxiosPromise<ResCareerSurveyStatus> {
            return localVarFp.getCareerSurveyStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career 목록 조회
         * @param {CareerSurveyApiGetCareersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareers(requestParameters: CareerSurveyApiGetCareersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResCareers> {
            return localVarFp.getCareers(requestParameters.surveyYn, requestParameters.careerClusterIds, requestParameters.educationLevelIds, requestParameters.startIncome, requestParameters.endIncome, requestParameters.careerName, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career 검색 조건 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareersSearchParameters(options?: AxiosRequestConfig): AxiosPromise<ResOnetCareerSearchParameter> {
            return localVarFp.getCareersSearchParameters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Education Levels 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationLevels(options?: AxiosRequestConfig): AxiosPromise<Array<ResEducationLevel>> {
            return localVarFp.getEducationLevels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeCareerList(options?: AxiosRequestConfig): AxiosPromise<Array<ResMemberLikeCareer>> {
            return localVarFp.getLikeCareerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 결과조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(options?: AxiosRequestConfig): AxiosPromise<Array<ResOnetResult>> {
            return localVarFp.getResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career 관심 삭제
         * @param {CareerSurveyApiLikeCareerDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeCareerDelete(requestParameters: CareerSurveyApiLikeCareerDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.likeCareerDelete(requestParameters.careerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career 관심 등록
         * @param {CareerSurveyApiLikeCareerSaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeCareerSave(requestParameters: CareerSurveyApiLikeCareerSaveRequest, options?: AxiosRequestConfig): AxiosPromise<ResMemberLikeCareer> {
            return localVarFp.likeCareerSave(requestParameters.reqCareerLike, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 다시 저장
         * @param {CareerSurveyApiReSubmitCareerSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reSubmitCareerSurvey(requestParameters: CareerSurveyApiReSubmitCareerSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<ResCareerSurveyResult> {
            return localVarFp.reSubmitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 저장
         * @param {CareerSurveyApiSubmitCareerSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCareerSurvey(requestParameters: CareerSurveyApiSubmitCareerSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<ResCareerSurveyResult> {
            return localVarFp.submitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 임시 저장 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempDelete(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.tempDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Career Survey 임시 저장
         * @param {CareerSurveyApiTempSubmitCareerSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempSubmitCareerSurvey(requestParameters: CareerSurveyApiTempSubmitCareerSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<ResCareerSurveyResult> {
            return localVarFp.tempSubmitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCareer operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiGetCareerRequest
 */
export interface CareerSurveyApiGetCareerRequest {
    /**
     * 
     * @type {string}
     * @memberof CareerSurveyApiGetCareer
     */
    readonly careerId: string
}

/**
 * Request parameters for getCareers operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiGetCareersRequest
 */
export interface CareerSurveyApiGetCareersRequest {
    /**
     * 
     * @type {string}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly surveyYn?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly careerClusterIds?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly educationLevelIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly startIncome?: number

    /**
     * 
     * @type {number}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly endIncome?: number

    /**
     * 
     * @type {string}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly careerName?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CareerSurveyApiGetCareers
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for likeCareerDelete operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiLikeCareerDeleteRequest
 */
export interface CareerSurveyApiLikeCareerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CareerSurveyApiLikeCareerDelete
     */
    readonly careerId: string
}

/**
 * Request parameters for likeCareerSave operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiLikeCareerSaveRequest
 */
export interface CareerSurveyApiLikeCareerSaveRequest {
    /**
     * 
     * @type {ReqCareerLike}
     * @memberof CareerSurveyApiLikeCareerSave
     */
    readonly reqCareerLike: ReqCareerLike
}

/**
 * Request parameters for reSubmitCareerSurvey operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiReSubmitCareerSurveyRequest
 */
export interface CareerSurveyApiReSubmitCareerSurveyRequest {
    /**
     * 
     * @type {ReqCareerSurveyResult}
     * @memberof CareerSurveyApiReSubmitCareerSurvey
     */
    readonly reqCareerSurveyResult: ReqCareerSurveyResult
}

/**
 * Request parameters for submitCareerSurvey operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiSubmitCareerSurveyRequest
 */
export interface CareerSurveyApiSubmitCareerSurveyRequest {
    /**
     * 
     * @type {ReqCareerSurveyResult}
     * @memberof CareerSurveyApiSubmitCareerSurvey
     */
    readonly reqCareerSurveyResult: ReqCareerSurveyResult
}

/**
 * Request parameters for tempSubmitCareerSurvey operation in CareerSurveyApi.
 * @export
 * @interface CareerSurveyApiTempSubmitCareerSurveyRequest
 */
export interface CareerSurveyApiTempSubmitCareerSurveyRequest {
    /**
     * 
     * @type {ReqCareerSurveyResult}
     * @memberof CareerSurveyApiTempSubmitCareerSurvey
     */
    readonly reqCareerSurveyResult: ReqCareerSurveyResult
}

/**
 * CareerSurveyApi - object-oriented interface
 * @export
 * @class CareerSurveyApi
 * @extends {BaseAPI}
 */
export class CareerSurveyApi extends BaseAPI {
    /**
     * 
     * @summary Career 상세 조회
     * @param {CareerSurveyApiGetCareerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareer(requestParameters: CareerSurveyApiGetCareerRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareer(requestParameters.careerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Clusters 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareerClusters(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareerClusters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareerSurvey(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareerSurvey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 문항정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareerSurveyQuestions(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareerSurveyQuestions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareerSurveyStatus(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareerSurveyStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career 목록 조회
     * @param {CareerSurveyApiGetCareersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareers(requestParameters: CareerSurveyApiGetCareersRequest = {}, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareers(requestParameters.surveyYn, requestParameters.careerClusterIds, requestParameters.educationLevelIds, requestParameters.startIncome, requestParameters.endIncome, requestParameters.careerName, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career 검색 조건 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getCareersSearchParameters(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getCareersSearchParameters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Education Levels 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getEducationLevels(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getEducationLevels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career 관심 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getLikeCareerList(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getLikeCareerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 결과조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public getResults(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).getResults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career 관심 삭제
     * @param {CareerSurveyApiLikeCareerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public likeCareerDelete(requestParameters: CareerSurveyApiLikeCareerDeleteRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).likeCareerDelete(requestParameters.careerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career 관심 등록
     * @param {CareerSurveyApiLikeCareerSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public likeCareerSave(requestParameters: CareerSurveyApiLikeCareerSaveRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).likeCareerSave(requestParameters.reqCareerLike, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 다시 저장
     * @param {CareerSurveyApiReSubmitCareerSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public reSubmitCareerSurvey(requestParameters: CareerSurveyApiReSubmitCareerSurveyRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).reSubmitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 저장
     * @param {CareerSurveyApiSubmitCareerSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public submitCareerSurvey(requestParameters: CareerSurveyApiSubmitCareerSurveyRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).submitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 임시 저장 삭제
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public tempDelete(options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).tempDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Career Survey 임시 저장
     * @param {CareerSurveyApiTempSubmitCareerSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSurveyApi
     */
    public tempSubmitCareerSurvey(requestParameters: CareerSurveyApiTempSubmitCareerSurveyRequest, options?: AxiosRequestConfig) {
        return CareerSurveyApiFp(this.configuration).tempSubmitCareerSurvey(requestParameters.reqCareerSurveyResult, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodeDataApi - axios parameter creator
 * @export
 */
export const CodeDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 코드 전체 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/code/resultList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeDataApi - functional programming interface
 * @export
 */
export const CodeDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 코드 전체 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: string; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodeDataApi - factory interface
 * @export
 */
export const CodeDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeDataApiFp(configuration)
    return {
        /**
         * 
         * @summary 코드 전체 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultList(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: string; }; }> {
            return localVarFp.resultList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeDataApi - object-oriented interface
 * @export
 * @class CodeDataApi
 * @extends {BaseAPI}
 */
export class CodeDataApi extends BaseAPI {
    /**
     * 
     * @summary 코드 전체 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeDataApi
     */
    public resultList(options?: AxiosRequestConfig) {
        return CodeDataApiFp(this.configuration).resultList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollegeSurveyApi - axios parameter creator
 * @export
 */
export const CollegeSurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admission 상태 등록, 수정
         * @param {ReqMemberCollegeAdmission} reqMemberCollegeAdmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberCollegeAdmission: async (reqMemberCollegeAdmission: ReqMemberCollegeAdmission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqMemberCollegeAdmission' is not null or undefined
            assertParamExists('addMemberCollegeAdmission', 'reqMemberCollegeAdmission', reqMemberCollegeAdmission)
            const localVarPath = `/member-college-admission/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqMemberCollegeAdmission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college survey 저장
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberCollegeSurvey: async (reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqMemberCollegeSurvey' is not null or undefined
            assertParamExists('addMemberCollegeSurvey', 'reqMemberCollegeSurvey', reqMemberCollegeSurvey)
            const localVarPath = `/member-college-survey/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqMemberCollegeSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 관심 삭제
         * @param {number} ipedsCollegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberCollegeLike: async (ipedsCollegeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ipedsCollegeId' is not null or undefined
            assertParamExists('deleteMemberCollegeLike', 'ipedsCollegeId', ipedsCollegeId)
            const localVarPath = `/member-college-like/delete/{ipedsCollegeId}`
                .replace(`{${"ipedsCollegeId"}}`, encodeURIComponent(String(ipedsCollegeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 상세
         * @param {string} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheDetail: async (collegeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collegeId' is not null or undefined
            assertParamExists('getCollegeNicheDetail', 'collegeId', collegeId)
            const localVarPath = `/college-niche/detail/{collegeId}`
                .replace(`{${"collegeId"}}`, encodeURIComponent(String(collegeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 인기 전공 목록
         * @param {string} collegeId 
         * @param {string} [likeMajor] 즐겨찾기 전공 조회 여부(로그인필수)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheMostPopularMajors: async (collegeId: string, likeMajor?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collegeId' is not null or undefined
            assertParamExists('getCollegeNicheMostPopularMajors', 'collegeId', collegeId)
            const localVarPath = `/college-niche/detail/{collegeId}/most-popular-majors`
                .replace(`{${"collegeId"}}`, encodeURIComponent(String(collegeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (likeMajor !== undefined) {
                localVarQueryParameter['likeMajor'] = likeMajor;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 목록
         * @param {string} [keyword] keyword
         * @param {Array<string>} [addressRegion] 대학 Region (West Region, Midwest Region, South Region, Northeast Region)
         * @param {Array<string>} [addressState] State - 약어 text로 전달(AL, AK , CA 등)
         * @param {Array<string>} [citySetting] 도시화 정도(도시 중심 지역 : Rural,Town,Suburb, City)
         * @param {Array<number>} [studentSize] 재학생 크기(Small :1, Medium:2, Large:3
         * @param {number} [netPrice] 대학 평균 Net price(30000 , 40000, 50000, 60000)
         * @param {Array<string>} [collegeType] 대학 타입 (Public, Private, Liberal)
         * @param {Array<string>} [admissionType] Admission type - text 그대로 전달 (Early Action, Early Decision, Regular Decision, Rolling)
         * @param {Array<number>} [requireScores] SAT/ACT Scores(1:Required, 5:Optional, 3:Not required, -1:Not available)
         * @param {Array<number>} [requireRecommendations] Recommendations letters(1:Required, 5:Optional, 3:Not required, -1:Not available)
         * @param {number} [budgetFrom] Budget from
         * @param {number} [budgetTo] Budget to
         * @param {Array<string>} [majors] majors(onetMajorId 식별번호)
         * @param {string} [fieldStudy] Field of study( open/field-study 의 필드명 항목 사용)
         * @param {number} [satScore] SAT Total
         * @param {number} [actScore] Act Score
         * @param {number} [satReadingWriting] SAT Reading And Writing
         * @param {number} [satMath] SAT Math
         * @param {number} [actEnglish] ACT English
         * @param {number} [actReading] ACT Reading
         * @param {number} [actScience] ACT Science
         * @param {number} [actMath] ACT Math
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNichePage: async (keyword?: string, addressRegion?: Array<string>, addressState?: Array<string>, citySetting?: Array<string>, studentSize?: Array<number>, netPrice?: number, collegeType?: Array<string>, admissionType?: Array<string>, requireScores?: Array<number>, requireRecommendations?: Array<number>, budgetFrom?: number, budgetTo?: number, majors?: Array<string>, fieldStudy?: string, satScore?: number, actScore?: number, satReadingWriting?: number, satMath?: number, actEnglish?: number, actReading?: number, actScience?: number, actMath?: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/college-niche/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (addressRegion) {
                localVarQueryParameter['addressRegion'] = addressRegion;
            }

            if (addressState) {
                localVarQueryParameter['addressState'] = addressState;
            }

            if (citySetting) {
                localVarQueryParameter['citySetting'] = citySetting;
            }

            if (studentSize) {
                localVarQueryParameter['studentSize'] = studentSize;
            }

            if (netPrice !== undefined) {
                localVarQueryParameter['netPrice'] = netPrice;
            }

            if (collegeType) {
                localVarQueryParameter['collegeType'] = collegeType;
            }

            if (admissionType) {
                localVarQueryParameter['admissionType'] = admissionType;
            }

            if (requireScores) {
                localVarQueryParameter['requireScores'] = requireScores;
            }

            if (requireRecommendations) {
                localVarQueryParameter['requireRecommendations'] = requireRecommendations;
            }

            if (budgetFrom !== undefined) {
                localVarQueryParameter['budgetFrom'] = budgetFrom;
            }

            if (budgetTo !== undefined) {
                localVarQueryParameter['budgetTo'] = budgetTo;
            }

            if (majors) {
                localVarQueryParameter['majors'] = majors;
            }

            if (fieldStudy !== undefined) {
                localVarQueryParameter['fieldStudy'] = fieldStudy;
            }

            if (satScore !== undefined) {
                localVarQueryParameter['satScore'] = satScore;
            }

            if (actScore !== undefined) {
                localVarQueryParameter['actScore'] = actScore;
            }

            if (satReadingWriting !== undefined) {
                localVarQueryParameter['satReadingWriting'] = satReadingWriting;
            }

            if (satMath !== undefined) {
                localVarQueryParameter['satMath'] = satMath;
            }

            if (actEnglish !== undefined) {
                localVarQueryParameter['actEnglish'] = actEnglish;
            }

            if (actReading !== undefined) {
                localVarQueryParameter['actReading'] = actReading;
            }

            if (actScience !== undefined) {
                localVarQueryParameter['actScience'] = actScience;
            }

            if (actMath !== undefined) {
                localVarQueryParameter['actMath'] = actMath;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 유사 대학 목록
         * @param {string} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheSimilarCollegeList: async (collegeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collegeId' is not null or undefined
            assertParamExists('getCollegeNicheSimilarCollegeList', 'collegeId', collegeId)
            const localVarPath = `/college-niche/detail/{collegeId}/similar-college`
                .replace(`{${"collegeId"}}`, encodeURIComponent(String(collegeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 후)
         * @param {Array<number>} [collegeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResult1: async (collegeIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-survey/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (collegeIds) {
                localVarQueryParameter['collegeIds'] = collegeIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 서베이 View More 결과 조회 (로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResultViewMore: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-survey/result/view-more`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeLikeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-like/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeSurvey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-survey/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college survey 여부 조회 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeSurveyCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-survey/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleMemberCollegeLikeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-college-like/simplelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 서베이 결과 ID 값 저장 (로그인 후)
         * @param {Array<CollegeSurveyCardData>} collegeList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCollegeSurveyResult: async (collegeList: Array<CollegeSurveyCardData>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collegeList' is not null or undefined
            assertParamExists('saveCollegeSurveyResult', 'collegeList', collegeList)
            const localVarPath = `/member-college-survey/result-save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (collegeList) {
                localVarQueryParameter['collegeList'] = collegeList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college 관심 등록
         * @param {ReqCollegeLike} reqCollegeLike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberCollegeLike: async (reqCollegeLike: ReqCollegeLike, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCollegeLike' is not null or undefined
            assertParamExists('saveMemberCollegeLike', 'reqCollegeLike', reqCollegeLike)
            const localVarPath = `/member-college-like/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCollegeLike, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college survey 저장
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberCollegeSurvey: async (reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqMemberCollegeSurvey' is not null or undefined
            assertParamExists('saveMemberCollegeSurvey', 'reqMemberCollegeSurvey', reqMemberCollegeSurvey)
            const localVarPath = `/member-college-survey/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqMemberCollegeSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollegeSurveyApi - functional programming interface
 * @export
 */
export const CollegeSurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollegeSurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admission 상태 등록, 수정
         * @param {ReqMemberCollegeAdmission} reqMemberCollegeAdmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberCollegeAdmission(reqMemberCollegeAdmission: ReqMemberCollegeAdmission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberCollegeAdmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberCollegeAdmission(reqMemberCollegeAdmission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college survey 저장
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberCollegeSurvey(reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberCollegeSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberCollegeSurvey(reqMemberCollegeSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 관심 삭제
         * @param {number} ipedsCollegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberCollegeLike(ipedsCollegeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemberCollegeLike(ipedsCollegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 상세
         * @param {string} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeNicheDetail(collegeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCollegeNicheIpeds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeNicheDetail(collegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 인기 전공 목록
         * @param {string} collegeId 
         * @param {string} [likeMajor] 즐겨찾기 전공 조회 여부(로그인필수)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeNicheMostPopularMajors(collegeId: string, likeMajor?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCollegeMajorGrad>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeNicheMostPopularMajors(collegeId, likeMajor, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 목록
         * @param {string} [keyword] keyword
         * @param {Array<string>} [addressRegion] 대학 Region (West Region, Midwest Region, South Region, Northeast Region)
         * @param {Array<string>} [addressState] State - 약어 text로 전달(AL, AK , CA 등)
         * @param {Array<string>} [citySetting] 도시화 정도(도시 중심 지역 : Rural,Town,Suburb, City)
         * @param {Array<number>} [studentSize] 재학생 크기(Small :1, Medium:2, Large:3
         * @param {number} [netPrice] 대학 평균 Net price(30000 , 40000, 50000, 60000)
         * @param {Array<string>} [collegeType] 대학 타입 (Public, Private, Liberal)
         * @param {Array<string>} [admissionType] Admission type - text 그대로 전달 (Early Action, Early Decision, Regular Decision, Rolling)
         * @param {Array<number>} [requireScores] SAT/ACT Scores(1:Required, 5:Optional, 3:Not required, -1:Not available)
         * @param {Array<number>} [requireRecommendations] Recommendations letters(1:Required, 5:Optional, 3:Not required, -1:Not available)
         * @param {number} [budgetFrom] Budget from
         * @param {number} [budgetTo] Budget to
         * @param {Array<string>} [majors] majors(onetMajorId 식별번호)
         * @param {string} [fieldStudy] Field of study( open/field-study 의 필드명 항목 사용)
         * @param {number} [satScore] SAT Total
         * @param {number} [actScore] Act Score
         * @param {number} [satReadingWriting] SAT Reading And Writing
         * @param {number} [satMath] SAT Math
         * @param {number} [actEnglish] ACT English
         * @param {number} [actReading] ACT Reading
         * @param {number} [actScience] ACT Science
         * @param {number} [actMath] ACT Math
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeNichePage(keyword?: string, addressRegion?: Array<string>, addressState?: Array<string>, citySetting?: Array<string>, studentSize?: Array<number>, netPrice?: number, collegeType?: Array<string>, admissionType?: Array<string>, requireScores?: Array<number>, requireRecommendations?: Array<number>, budgetFrom?: number, budgetTo?: number, majors?: Array<string>, fieldStudy?: string, satScore?: number, actScore?: number, satReadingWriting?: number, satMath?: number, actEnglish?: number, actReading?: number, actScience?: number, actMath?: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCollegeNicheIpeds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeNichePage(keyword, addressRegion, addressState, citySetting, studentSize, netPrice, collegeType, admissionType, requireScores, requireRecommendations, budgetFrom, budgetTo, majors, fieldStudy, satScore, actScore, satReadingWriting, satMath, actEnglish, actReading, actScience, actMath, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 유사 대학 목록
         * @param {string} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeNicheSimilarCollegeList(collegeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResCollegeNicheIpeds>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeNicheSimilarCollegeList(collegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 후)
         * @param {Array<number>} [collegeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeSurveyResult1(collegeIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CollegeSurveyCardData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeSurveyResult1(collegeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 서베이 View More 결과 조회 (로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeSurveyResultViewMore(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReqCollegeSurveyViewMore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeSurveyResultViewMore(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberCollegeLikeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResMemberLikeCollege>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberCollegeLikeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberCollegeSurvey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberCollegeSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberCollegeSurvey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college survey 여부 조회 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberCollegeSurveyCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberCollegeSurveyCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleMemberCollegeLikeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResSimpleMemberLikeCollege>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimpleMemberCollegeLikeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 서베이 결과 ID 값 저장 (로그인 후)
         * @param {Array<CollegeSurveyCardData>} collegeList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCollegeSurveyResult(collegeList: Array<CollegeSurveyCardData>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberCollegeSurveyResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCollegeSurveyResult(collegeList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college 관심 등록
         * @param {ReqCollegeLike} reqCollegeLike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMemberCollegeLike(reqCollegeLike: ReqCollegeLike, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberLikeCollege>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMemberCollegeLike(reqCollegeLike, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college survey 저장
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMemberCollegeSurvey(reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberCollegeSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMemberCollegeSurvey(reqMemberCollegeSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollegeSurveyApi - factory interface
 * @export
 */
export const CollegeSurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollegeSurveyApiFp(configuration)
    return {
        /**
         * 
         * @summary admission 상태 등록, 수정
         * @param {CollegeSurveyApiAddMemberCollegeAdmissionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberCollegeAdmission(requestParameters: CollegeSurveyApiAddMemberCollegeAdmissionRequest, options?: AxiosRequestConfig): AxiosPromise<ResMemberCollegeAdmission> {
            return localVarFp.addMemberCollegeAdmission(requestParameters.reqMemberCollegeAdmission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college survey 저장
         * @param {CollegeSurveyApiAddMemberCollegeSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberCollegeSurvey(requestParameters: CollegeSurveyApiAddMemberCollegeSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<ResMemberCollegeSurvey> {
            return localVarFp.addMemberCollegeSurvey(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 관심 삭제
         * @param {CollegeSurveyApiDeleteMemberCollegeLikeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberCollegeLike(requestParameters: CollegeSurveyApiDeleteMemberCollegeLikeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMemberCollegeLike(requestParameters.ipedsCollegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 상세
         * @param {CollegeSurveyApiGetCollegeNicheDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheDetail(requestParameters: CollegeSurveyApiGetCollegeNicheDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ResCollegeNicheIpeds> {
            return localVarFp.getCollegeNicheDetail(requestParameters.collegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 인기 전공 목록
         * @param {CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheMostPopularMajors(requestParameters: CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest, options?: AxiosRequestConfig): AxiosPromise<PageResCollegeMajorGrad> {
            return localVarFp.getCollegeNicheMostPopularMajors(requestParameters.collegeId, requestParameters.likeMajor, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 목록
         * @param {CollegeSurveyApiGetCollegeNichePageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNichePage(requestParameters: CollegeSurveyApiGetCollegeNichePageRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResCollegeNicheIpeds> {
            return localVarFp.getCollegeNichePage(requestParameters.keyword, requestParameters.addressRegion, requestParameters.addressState, requestParameters.citySetting, requestParameters.studentSize, requestParameters.netPrice, requestParameters.collegeType, requestParameters.admissionType, requestParameters.requireScores, requestParameters.requireRecommendations, requestParameters.budgetFrom, requestParameters.budgetTo, requestParameters.majors, requestParameters.fieldStudy, requestParameters.satScore, requestParameters.actScore, requestParameters.satReadingWriting, requestParameters.satMath, requestParameters.actEnglish, requestParameters.actReading, requestParameters.actScience, requestParameters.actMath, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 유사 대학 목록
         * @param {CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeNicheSimilarCollegeList(requestParameters: CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResCollegeNicheIpeds>> {
            return localVarFp.getCollegeNicheSimilarCollegeList(requestParameters.collegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 후)
         * @param {CollegeSurveyApiGetCollegeSurveyResult1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResult1(requestParameters: CollegeSurveyApiGetCollegeSurveyResult1Request = {}, options?: AxiosRequestConfig): AxiosPromise<Array<CollegeSurveyCardData>> {
            return localVarFp.getCollegeSurveyResult1(requestParameters.collegeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 서베이 View More 결과 조회 (로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResultViewMore(options?: AxiosRequestConfig): AxiosPromise<ReqCollegeSurveyViewMore> {
            return localVarFp.getCollegeSurveyResultViewMore(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeLikeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResMemberLikeCollege>> {
            return localVarFp.getMemberCollegeLikeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college survey 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeSurvey(options?: AxiosRequestConfig): AxiosPromise<ResMemberCollegeSurvey> {
            return localVarFp.getMemberCollegeSurvey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college survey 여부 조회 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberCollegeSurveyCheck(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getMemberCollegeSurveyCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleMemberCollegeLikeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResSimpleMemberLikeCollege>> {
            return localVarFp.getSimpleMemberCollegeLikeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 서베이 결과 ID 값 저장 (로그인 후)
         * @param {CollegeSurveyApiSaveCollegeSurveyResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCollegeSurveyResult(requestParameters: CollegeSurveyApiSaveCollegeSurveyResultRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MemberCollegeSurveyResult>> {
            return localVarFp.saveCollegeSurveyResult(requestParameters.collegeList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college 관심 등록
         * @param {CollegeSurveyApiSaveMemberCollegeLikeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberCollegeLike(requestParameters: CollegeSurveyApiSaveMemberCollegeLikeRequest, options?: AxiosRequestConfig): AxiosPromise<ResMemberLikeCollege> {
            return localVarFp.saveMemberCollegeLike(requestParameters.reqCollegeLike, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college survey 저장
         * @param {CollegeSurveyApiSaveMemberCollegeSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberCollegeSurvey(requestParameters: CollegeSurveyApiSaveMemberCollegeSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<ResMemberCollegeSurvey> {
            return localVarFp.saveMemberCollegeSurvey(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addMemberCollegeAdmission operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiAddMemberCollegeAdmissionRequest
 */
export interface CollegeSurveyApiAddMemberCollegeAdmissionRequest {
    /**
     * 
     * @type {ReqMemberCollegeAdmission}
     * @memberof CollegeSurveyApiAddMemberCollegeAdmission
     */
    readonly reqMemberCollegeAdmission: ReqMemberCollegeAdmission
}

/**
 * Request parameters for addMemberCollegeSurvey operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiAddMemberCollegeSurveyRequest
 */
export interface CollegeSurveyApiAddMemberCollegeSurveyRequest {
    /**
     * 
     * @type {ReqMemberCollegeSurvey}
     * @memberof CollegeSurveyApiAddMemberCollegeSurvey
     */
    readonly reqMemberCollegeSurvey: ReqMemberCollegeSurvey
}

/**
 * Request parameters for deleteMemberCollegeLike operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiDeleteMemberCollegeLikeRequest
 */
export interface CollegeSurveyApiDeleteMemberCollegeLikeRequest {
    /**
     * 
     * @type {number}
     * @memberof CollegeSurveyApiDeleteMemberCollegeLike
     */
    readonly ipedsCollegeId: number
}

/**
 * Request parameters for getCollegeNicheDetail operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiGetCollegeNicheDetailRequest
 */
export interface CollegeSurveyApiGetCollegeNicheDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNicheDetail
     */
    readonly collegeId: string
}

/**
 * Request parameters for getCollegeNicheMostPopularMajors operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest
 */
export interface CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest {
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNicheMostPopularMajors
     */
    readonly collegeId: string

    /**
     * 즐겨찾기 전공 조회 여부(로그인필수)
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNicheMostPopularMajors
     */
    readonly likeMajor?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNicheMostPopularMajors
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNicheMostPopularMajors
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNicheMostPopularMajors
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getCollegeNichePage operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiGetCollegeNichePageRequest
 */
export interface CollegeSurveyApiGetCollegeNichePageRequest {
    /**
     * keyword
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly keyword?: string

    /**
     * 대학 Region (West Region, Midwest Region, South Region, Northeast Region)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly addressRegion?: Array<string>

    /**
     * State - 약어 text로 전달(AL, AK , CA 등)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly addressState?: Array<string>

    /**
     * 도시화 정도(도시 중심 지역 : Rural,Town,Suburb, City)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly citySetting?: Array<string>

    /**
     * 재학생 크기(Small :1, Medium:2, Large:3
     * @type {Array<number>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly studentSize?: Array<number>

    /**
     * 대학 평균 Net price(30000 , 40000, 50000, 60000)
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly netPrice?: number

    /**
     * 대학 타입 (Public, Private, Liberal)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly collegeType?: Array<string>

    /**
     * Admission type - text 그대로 전달 (Early Action, Early Decision, Regular Decision, Rolling)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly admissionType?: Array<string>

    /**
     * SAT/ACT Scores(1:Required, 5:Optional, 3:Not required, -1:Not available)
     * @type {Array<number>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly requireScores?: Array<number>

    /**
     * Recommendations letters(1:Required, 5:Optional, 3:Not required, -1:Not available)
     * @type {Array<number>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly requireRecommendations?: Array<number>

    /**
     * Budget from
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly budgetFrom?: number

    /**
     * Budget to
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly budgetTo?: number

    /**
     * majors(onetMajorId 식별번호)
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly majors?: Array<string>

    /**
     * Field of study( open/field-study 의 필드명 항목 사용)
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly fieldStudy?: string

    /**
     * SAT Total
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly satScore?: number

    /**
     * Act Score
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly actScore?: number

    /**
     * SAT Reading And Writing
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly satReadingWriting?: number

    /**
     * SAT Math
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly satMath?: number

    /**
     * ACT English
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly actEnglish?: number

    /**
     * ACT Reading
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly actReading?: number

    /**
     * ACT Science
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly actScience?: number

    /**
     * ACT Math
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly actMath?: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CollegeSurveyApiGetCollegeNichePage
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getCollegeNicheSimilarCollegeList operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest
 */
export interface CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest {
    /**
     * 
     * @type {string}
     * @memberof CollegeSurveyApiGetCollegeNicheSimilarCollegeList
     */
    readonly collegeId: string
}

/**
 * Request parameters for getCollegeSurveyResult1 operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiGetCollegeSurveyResult1Request
 */
export interface CollegeSurveyApiGetCollegeSurveyResult1Request {
    /**
     * 
     * @type {Array<number>}
     * @memberof CollegeSurveyApiGetCollegeSurveyResult1
     */
    readonly collegeIds?: Array<number>
}

/**
 * Request parameters for saveCollegeSurveyResult operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiSaveCollegeSurveyResultRequest
 */
export interface CollegeSurveyApiSaveCollegeSurveyResultRequest {
    /**
     * 
     * @type {Array<CollegeSurveyCardData>}
     * @memberof CollegeSurveyApiSaveCollegeSurveyResult
     */
    readonly collegeList: Array<CollegeSurveyCardData>
}

/**
 * Request parameters for saveMemberCollegeLike operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiSaveMemberCollegeLikeRequest
 */
export interface CollegeSurveyApiSaveMemberCollegeLikeRequest {
    /**
     * 
     * @type {ReqCollegeLike}
     * @memberof CollegeSurveyApiSaveMemberCollegeLike
     */
    readonly reqCollegeLike: ReqCollegeLike
}

/**
 * Request parameters for saveMemberCollegeSurvey operation in CollegeSurveyApi.
 * @export
 * @interface CollegeSurveyApiSaveMemberCollegeSurveyRequest
 */
export interface CollegeSurveyApiSaveMemberCollegeSurveyRequest {
    /**
     * 
     * @type {ReqMemberCollegeSurvey}
     * @memberof CollegeSurveyApiSaveMemberCollegeSurvey
     */
    readonly reqMemberCollegeSurvey: ReqMemberCollegeSurvey
}

/**
 * CollegeSurveyApi - object-oriented interface
 * @export
 * @class CollegeSurveyApi
 * @extends {BaseAPI}
 */
export class CollegeSurveyApi extends BaseAPI {
    /**
     * 
     * @summary admission 상태 등록, 수정
     * @param {CollegeSurveyApiAddMemberCollegeAdmissionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public addMemberCollegeAdmission(requestParameters: CollegeSurveyApiAddMemberCollegeAdmissionRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).addMemberCollegeAdmission(requestParameters.reqMemberCollegeAdmission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college survey 저장
     * @param {CollegeSurveyApiAddMemberCollegeSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public addMemberCollegeSurvey(requestParameters: CollegeSurveyApiAddMemberCollegeSurveyRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).addMemberCollegeSurvey(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 관심 삭제
     * @param {CollegeSurveyApiDeleteMemberCollegeLikeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public deleteMemberCollegeLike(requestParameters: CollegeSurveyApiDeleteMemberCollegeLikeRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).deleteMemberCollegeLike(requestParameters.ipedsCollegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 상세
     * @param {CollegeSurveyApiGetCollegeNicheDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeNicheDetail(requestParameters: CollegeSurveyApiGetCollegeNicheDetailRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeNicheDetail(requestParameters.collegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 인기 전공 목록
     * @param {CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeNicheMostPopularMajors(requestParameters: CollegeSurveyApiGetCollegeNicheMostPopularMajorsRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeNicheMostPopularMajors(requestParameters.collegeId, requestParameters.likeMajor, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 목록
     * @param {CollegeSurveyApiGetCollegeNichePageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeNichePage(requestParameters: CollegeSurveyApiGetCollegeNichePageRequest = {}, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeNichePage(requestParameters.keyword, requestParameters.addressRegion, requestParameters.addressState, requestParameters.citySetting, requestParameters.studentSize, requestParameters.netPrice, requestParameters.collegeType, requestParameters.admissionType, requestParameters.requireScores, requestParameters.requireRecommendations, requestParameters.budgetFrom, requestParameters.budgetTo, requestParameters.majors, requestParameters.fieldStudy, requestParameters.satScore, requestParameters.actScore, requestParameters.satReadingWriting, requestParameters.satMath, requestParameters.actEnglish, requestParameters.actReading, requestParameters.actScience, requestParameters.actMath, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 유사 대학 목록
     * @param {CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeNicheSimilarCollegeList(requestParameters: CollegeSurveyApiGetCollegeNicheSimilarCollegeListRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeNicheSimilarCollegeList(requestParameters.collegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 서베이 결과 조회 (로그인 후)
     * @param {CollegeSurveyApiGetCollegeSurveyResult1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeSurveyResult1(requestParameters: CollegeSurveyApiGetCollegeSurveyResult1Request = {}, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeSurveyResult1(requestParameters.collegeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 서베이 View More 결과 조회 (로그인 후)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getCollegeSurveyResultViewMore(options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getCollegeSurveyResultViewMore(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 관심 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getMemberCollegeLikeList(options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getMemberCollegeLikeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college survey 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getMemberCollegeSurvey(options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getMemberCollegeSurvey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college survey 여부 조회 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getMemberCollegeSurveyCheck(options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getMemberCollegeSurveyCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 관심 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public getSimpleMemberCollegeLikeList(options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).getSimpleMemberCollegeLikeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 서베이 결과 ID 값 저장 (로그인 후)
     * @param {CollegeSurveyApiSaveCollegeSurveyResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public saveCollegeSurveyResult(requestParameters: CollegeSurveyApiSaveCollegeSurveyResultRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).saveCollegeSurveyResult(requestParameters.collegeList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college 관심 등록
     * @param {CollegeSurveyApiSaveMemberCollegeLikeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public saveMemberCollegeLike(requestParameters: CollegeSurveyApiSaveMemberCollegeLikeRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).saveMemberCollegeLike(requestParameters.reqCollegeLike, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college survey 저장
     * @param {CollegeSurveyApiSaveMemberCollegeSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollegeSurveyApi
     */
    public saveMemberCollegeSurvey(requestParameters: CollegeSurveyApiSaveMemberCollegeSurveyRequest, options?: AxiosRequestConfig) {
        return CollegeSurveyApiFp(this.configuration).saveMemberCollegeSurvey(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CronfyApi - axios parameter creator
 * @export
 */
export const CronfyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 크로노피 redirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronofyRedirect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronofy/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronofy/emailtest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronofyInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronofy/isLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCronofyEvent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronofy/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CronfyApi - functional programming interface
 * @export
 */
export const CronfyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CronfyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 크로노피 redirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronofyRedirect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronofyRedirect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCronofyInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronofyLoginInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCronofyInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCronofyEvent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCronofyEvent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CronfyApi - factory interface
 * @export
 */
export const CronfyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CronfyApiFp(configuration)
    return {
        /**
         * 
         * @summary 크로노피 redirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronofyRedirect(options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.cronofyRedirect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTest(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.emailTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronofyInfo(options?: AxiosRequestConfig): AxiosPromise<CronofyLoginInfo> {
            return localVarFp.getCronofyInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 크로노피 로그인 되어있는지 확인
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCronofyEvent(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.setCronofyEvent(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CronfyApi - object-oriented interface
 * @export
 * @class CronfyApi
 * @extends {BaseAPI}
 */
export class CronfyApi extends BaseAPI {
    /**
     * 
     * @summary 크로노피 redirect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronfyApi
     */
    public cronofyRedirect(options?: AxiosRequestConfig) {
        return CronfyApiFp(this.configuration).cronofyRedirect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronfyApi
     */
    public emailTest(options?: AxiosRequestConfig) {
        return CronfyApiFp(this.configuration).emailTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 크로노피 로그인 되어있는지 확인
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronfyApi
     */
    public getCronofyInfo(options?: AxiosRequestConfig) {
        return CronfyApiFp(this.configuration).getCronofyInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 크로노피 로그인 되어있는지 확인
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronfyApi
     */
    public setCronofyEvent(options?: AxiosRequestConfig) {
        return CronfyApiFp(this.configuration).setCronofyEvent(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertApi - axios parameter creator
 * @export
 */
export const ExpertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaserPage: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('createPurchaserPage', 'programId', programId)
            const localVarPath = `/expert/stripe/createPurchaserPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeAccountLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/stripe/accountLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 experience education 정보 상세 삭제
         * @param {number} educationId educationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertEducation: async (educationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationId' is not null or undefined
            assertParamExists('deleteExpertEducation', 'educationId', educationId)
            const localVarPath = `/expert/experience/education/{educationId}`
                .replace(`{${"educationId"}}`, encodeURIComponent(String(educationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 workExperience 정보 상세 삭제
         * @param {number} workExperienceId workExperienceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertExperience: async (workExperienceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workExperienceId' is not null or undefined
            assertParamExists('deleteExpertExperience', 'workExperienceId', workExperienceId)
            const localVarPath = `/expert/experience/workExperience/{workExperienceId}`
                .replace(`{${"workExperienceId"}}`, encodeURIComponent(String(workExperienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 languages 정보 삭제
         * @param {number} languageInfoId languageInfoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertLanguages: async (languageInfoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageInfoId' is not null or undefined
            assertParamExists('deleteExpertLanguages', 'languageInfoId', languageInfoId)
            const localVarPath = `/expert/experience/language/{languageInfoId}`
                .replace(`{${"languageInfoId"}}`, encodeURIComponent(String(languageInfoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 삭제
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramDetail: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('deleteProgramDetail', 'programId', programId)
            const localVarPath = `/expert/mentoring/program/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/booking/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingDetail: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getBookingDetail', 'bookingId', bookingId)
            const localVarPath = `/expert/booking/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary feedback 정보 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingFeedback: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getBookingFeedback', 'bookingId', bookingId)
            const localVarPath = `/expert/booking/feedback/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 조회
         * @param {string} [bookingState] Classify booking status
         * @param {string} [sort] Filter values based on time period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingList: async (bookingState?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/booking/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bookingState !== undefined) {
                localVarQueryParameter['bookingState'] = bookingState;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancelBooking: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getCancelBooking', 'bookingId', bookingId)
            const localVarPath = `/expert/program/booking/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bookingId !== undefined) {
                localVarQueryParameter['bookingId'] = bookingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertBankExpertInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/bank/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertExperience: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/experience`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 멘토링 현황 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentoringSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/mentoring/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ongoing 예약 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOngoingBookingList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/booking/list/ongoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/mentoring/program/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 상세 조회
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDetail: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramDetail', 'programId', programId)
            const localVarPath = `/expert/mentoring/program/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramList1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/mentoring/program/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 스킬 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/skill/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expert/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 Booking feedback 정보 상세 수정
         * @param {BookingFeedback} bookingFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBookingFeedback: async (bookingFeedback: BookingFeedback, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingFeedback' is not null or undefined
            assertParamExists('setBookingFeedback', 'bookingFeedback', bookingFeedback)
            const localVarPath = `/expert/booking/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {TbExpertBankAccountEntity} tbExpertBankAccountEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertBankExpertInfo: async (tbExpertBankAccountEntity: TbExpertBankAccountEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tbExpertBankAccountEntity' is not null or undefined
            assertParamExists('setExpertBankExpertInfo', 'tbExpertBankAccountEntity', tbExpertBankAccountEntity)
            const localVarPath = `/expert/bank/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tbExpertBankAccountEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 수정
         * @param {ReqTbExpertInfo} reqTbExpertInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertExperience: async (reqTbExpertInfo: ReqTbExpertInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqTbExpertInfo' is not null or undefined
            assertParamExists('setExpertExperience', 'reqTbExpertInfo', reqTbExpertInfo)
            const localVarPath = `/expert/experience`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqTbExpertInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 program 정보 상세 수정
         * @param {ProgramInput} programInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertProgram: async (programInput: ProgramInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programInput' is not null or undefined
            assertParamExists('setExpertProgram', 'programInput', programInput)
            const localVarPath = `/expert/mentoring/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(programInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 verification status: in progress
         * @param {number} requestCode verification request code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationInProgress: async (requestCode: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestCode' is not null or undefined
            assertParamExists('setVerificationInProgress', 'requestCode', requestCode)
            const localVarPath = `/expert/experience/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestCode !== undefined) {
                localVarQueryParameter['requestCode'] = requestCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertApi - functional programming interface
 * @export
 */
export const ExpertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPurchaserPage(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPurchaserPage(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripeAccountLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripeAccountLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 experience education 정보 상세 삭제
         * @param {number} educationId educationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExpertEducation(educationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExpertEducation(educationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 workExperience 정보 상세 삭제
         * @param {number} workExperienceId workExperienceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExpertExperience(workExperienceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExpertExperience(workExperienceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 languages 정보 삭제
         * @param {number} languageInfoId languageInfoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExpertLanguages(languageInfoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExpertLanguages(languageInfoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 삭제
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramDetail(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgramDetail(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingDetail(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingDetail(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary feedback 정보 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingFeedback(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingFeedback(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 조회
         * @param {string} [bookingState] Classify booking status
         * @param {string} [sort] Filter values based on time period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingList(bookingState?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingList(bookingState, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCancelBooking(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingCancel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCancelBooking(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertBankExpertInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertBankAccountEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertBankExpertInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertExperience(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertExperience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertExperience(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 멘토링 현황 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMentoringSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentoringSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMentoringSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ongoing 예약 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOngoingBookingList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOngoingBookingList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 상세 조회
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramDetail(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramDetail(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramList1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramList1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 스킬 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Skill>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 Booking feedback 정보 상세 수정
         * @param {BookingFeedback} bookingFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBookingFeedback(bookingFeedback: BookingFeedback, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBookingFeedback(bookingFeedback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {TbExpertBankAccountEntity} tbExpertBankAccountEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setExpertBankExpertInfo(tbExpertBankAccountEntity: TbExpertBankAccountEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TbExpertBankAccountEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setExpertBankExpertInfo(tbExpertBankAccountEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 수정
         * @param {ReqTbExpertInfo} reqTbExpertInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setExpertExperience(reqTbExpertInfo: ReqTbExpertInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertExperience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setExpertExperience(reqTbExpertInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 program 정보 상세 수정
         * @param {ProgramInput} programInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setExpertProgram(programInput: ProgramInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setExpertProgram(programInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 verification status: in progress
         * @param {number} requestCode verification request code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setVerificationInProgress(requestCode: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setVerificationInProgress(requestCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertApi - factory interface
 * @export
 */
export const ExpertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertApiFp(configuration)
    return {
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {ExpertApiCreatePurchaserPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaserPage(requestParameters: ExpertApiCreatePurchaserPageRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createPurchaserPage(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 스트라이프 계정 생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeAccountLink(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createStripeAccountLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 experience education 정보 상세 삭제
         * @param {ExpertApiDeleteExpertEducationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertEducation(requestParameters: ExpertApiDeleteExpertEducationRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.deleteExpertEducation(requestParameters.educationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 workExperience 정보 상세 삭제
         * @param {ExpertApiDeleteExpertExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertExperience(requestParameters: ExpertApiDeleteExpertExperienceRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.deleteExpertExperience(requestParameters.workExperienceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 languages 정보 삭제
         * @param {ExpertApiDeleteExpertLanguagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertLanguages(requestParameters: ExpertApiDeleteExpertLanguagesRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.deleteExpertLanguages(requestParameters.languageInfoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 삭제
         * @param {ExpertApiDeleteProgramDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramDetail(requestParameters: ExpertApiDeleteProgramDetailRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.deleteProgramDetail(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCount(options?: AxiosRequestConfig): AxiosPromise<BookingCount> {
            return localVarFp.getBookingCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 조회
         * @param {ExpertApiGetBookingDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingDetail(requestParameters: ExpertApiGetBookingDetailRequest, options?: AxiosRequestConfig): AxiosPromise<BookingDetail> {
            return localVarFp.getBookingDetail(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary feedback 정보 조회
         * @param {ExpertApiGetBookingFeedbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingFeedback(requestParameters: ExpertApiGetBookingFeedbackRequest, options?: AxiosRequestConfig): AxiosPromise<BookingFeedback> {
            return localVarFp.getBookingFeedback(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 조회
         * @param {ExpertApiGetBookingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingList(requestParameters: ExpertApiGetBookingListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<BookingList>> {
            return localVarFp.getBookingList(requestParameters.bookingState, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {ExpertApiGetCancelBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancelBooking(requestParameters: ExpertApiGetCancelBookingRequest, options?: AxiosRequestConfig): AxiosPromise<BookingCancel> {
            return localVarFp.getCancelBooking(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertBankExpertInfo(options?: AxiosRequestConfig): AxiosPromise<ExpertBankAccountEntity> {
            return localVarFp.getExpertBankExpertInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertExperience(options?: AxiosRequestConfig): AxiosPromise<ExpertExperience> {
            return localVarFp.getExpertExperience(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertStatus(options?: AxiosRequestConfig): AxiosPromise<ExpertStatus> {
            return localVarFp.getExpertStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 멘토링 현황 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentoringSummary(options?: AxiosRequestConfig): AxiosPromise<MentoringSummary> {
            return localVarFp.getMentoringSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ongoing 예약 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOngoingBookingList(options?: AxiosRequestConfig): AxiosPromise<Array<BookingList>> {
            return localVarFp.getOngoingBookingList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 갯수 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramCount(options?: AxiosRequestConfig): AxiosPromise<ProgramCount> {
            return localVarFp.getProgramCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 상세 조회
         * @param {ExpertApiGetProgramDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDetail(requestParameters: ExpertApiGetProgramDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramDetail> {
            return localVarFp.getProgramDetail(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramList1(options?: AxiosRequestConfig): AxiosPromise<Array<ProgramList>> {
            return localVarFp.getProgramList1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 스킬 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillList(options?: AxiosRequestConfig): AxiosPromise<Array<Skill>> {
            return localVarFp.getSkillList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 Stripe 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeInfo(options?: AxiosRequestConfig): AxiosPromise<StripeInfo> {
            return localVarFp.getStripeInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 Booking feedback 정보 상세 수정
         * @param {ExpertApiSetBookingFeedbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBookingFeedback(requestParameters: ExpertApiSetBookingFeedbackRequest, options?: AxiosRequestConfig): AxiosPromise<BookingFeedback> {
            return localVarFp.setBookingFeedback(requestParameters.bookingFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 계좌정보 조회
         * @param {ExpertApiSetExpertBankExpertInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertBankExpertInfo(requestParameters: ExpertApiSetExpertBankExpertInfoRequest, options?: AxiosRequestConfig): AxiosPromise<TbExpertBankAccountEntity> {
            return localVarFp.setExpertBankExpertInfo(requestParameters.tbExpertBankAccountEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 experience 정보 상세 수정
         * @param {ExpertApiSetExpertExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertExperience(requestParameters: ExpertApiSetExpertExperienceRequest, options?: AxiosRequestConfig): AxiosPromise<ExpertExperience> {
            return localVarFp.setExpertExperience(requestParameters.reqTbExpertInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 program 정보 상세 수정
         * @param {ExpertApiSetExpertProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExpertProgram(requestParameters: ExpertApiSetExpertProgramRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramDetail> {
            return localVarFp.setExpertProgram(requestParameters.programInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 verification status: in progress
         * @param {ExpertApiSetVerificationInProgressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationInProgress(requestParameters: ExpertApiSetVerificationInProgressRequest, options?: AxiosRequestConfig): AxiosPromise<ExpertInfo> {
            return localVarFp.setVerificationInProgress(requestParameters.requestCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPurchaserPage operation in ExpertApi.
 * @export
 * @interface ExpertApiCreatePurchaserPageRequest
 */
export interface ExpertApiCreatePurchaserPageRequest {
    /**
     * programId
     * @type {number}
     * @memberof ExpertApiCreatePurchaserPage
     */
    readonly programId: number
}

/**
 * Request parameters for deleteExpertEducation operation in ExpertApi.
 * @export
 * @interface ExpertApiDeleteExpertEducationRequest
 */
export interface ExpertApiDeleteExpertEducationRequest {
    /**
     * educationId
     * @type {number}
     * @memberof ExpertApiDeleteExpertEducation
     */
    readonly educationId: number
}

/**
 * Request parameters for deleteExpertExperience operation in ExpertApi.
 * @export
 * @interface ExpertApiDeleteExpertExperienceRequest
 */
export interface ExpertApiDeleteExpertExperienceRequest {
    /**
     * workExperienceId
     * @type {number}
     * @memberof ExpertApiDeleteExpertExperience
     */
    readonly workExperienceId: number
}

/**
 * Request parameters for deleteExpertLanguages operation in ExpertApi.
 * @export
 * @interface ExpertApiDeleteExpertLanguagesRequest
 */
export interface ExpertApiDeleteExpertLanguagesRequest {
    /**
     * languageInfoId
     * @type {number}
     * @memberof ExpertApiDeleteExpertLanguages
     */
    readonly languageInfoId: number
}

/**
 * Request parameters for deleteProgramDetail operation in ExpertApi.
 * @export
 * @interface ExpertApiDeleteProgramDetailRequest
 */
export interface ExpertApiDeleteProgramDetailRequest {
    /**
     * programId
     * @type {number}
     * @memberof ExpertApiDeleteProgramDetail
     */
    readonly programId: number
}

/**
 * Request parameters for getBookingDetail operation in ExpertApi.
 * @export
 * @interface ExpertApiGetBookingDetailRequest
 */
export interface ExpertApiGetBookingDetailRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof ExpertApiGetBookingDetail
     */
    readonly bookingId: number
}

/**
 * Request parameters for getBookingFeedback operation in ExpertApi.
 * @export
 * @interface ExpertApiGetBookingFeedbackRequest
 */
export interface ExpertApiGetBookingFeedbackRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof ExpertApiGetBookingFeedback
     */
    readonly bookingId: number
}

/**
 * Request parameters for getBookingList operation in ExpertApi.
 * @export
 * @interface ExpertApiGetBookingListRequest
 */
export interface ExpertApiGetBookingListRequest {
    /**
     * Classify booking status
     * @type {string}
     * @memberof ExpertApiGetBookingList
     */
    readonly bookingState?: string

    /**
     * Filter values based on time period
     * @type {string}
     * @memberof ExpertApiGetBookingList
     */
    readonly sort?: string
}

/**
 * Request parameters for getCancelBooking operation in ExpertApi.
 * @export
 * @interface ExpertApiGetCancelBookingRequest
 */
export interface ExpertApiGetCancelBookingRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof ExpertApiGetCancelBooking
     */
    readonly bookingId: number
}

/**
 * Request parameters for getProgramDetail operation in ExpertApi.
 * @export
 * @interface ExpertApiGetProgramDetailRequest
 */
export interface ExpertApiGetProgramDetailRequest {
    /**
     * programId
     * @type {number}
     * @memberof ExpertApiGetProgramDetail
     */
    readonly programId: number
}

/**
 * Request parameters for setBookingFeedback operation in ExpertApi.
 * @export
 * @interface ExpertApiSetBookingFeedbackRequest
 */
export interface ExpertApiSetBookingFeedbackRequest {
    /**
     * 
     * @type {BookingFeedback}
     * @memberof ExpertApiSetBookingFeedback
     */
    readonly bookingFeedback: BookingFeedback
}

/**
 * Request parameters for setExpertBankExpertInfo operation in ExpertApi.
 * @export
 * @interface ExpertApiSetExpertBankExpertInfoRequest
 */
export interface ExpertApiSetExpertBankExpertInfoRequest {
    /**
     * 
     * @type {TbExpertBankAccountEntity}
     * @memberof ExpertApiSetExpertBankExpertInfo
     */
    readonly tbExpertBankAccountEntity: TbExpertBankAccountEntity
}

/**
 * Request parameters for setExpertExperience operation in ExpertApi.
 * @export
 * @interface ExpertApiSetExpertExperienceRequest
 */
export interface ExpertApiSetExpertExperienceRequest {
    /**
     * 
     * @type {ReqTbExpertInfo}
     * @memberof ExpertApiSetExpertExperience
     */
    readonly reqTbExpertInfo: ReqTbExpertInfo
}

/**
 * Request parameters for setExpertProgram operation in ExpertApi.
 * @export
 * @interface ExpertApiSetExpertProgramRequest
 */
export interface ExpertApiSetExpertProgramRequest {
    /**
     * 
     * @type {ProgramInput}
     * @memberof ExpertApiSetExpertProgram
     */
    readonly programInput: ProgramInput
}

/**
 * Request parameters for setVerificationInProgress operation in ExpertApi.
 * @export
 * @interface ExpertApiSetVerificationInProgressRequest
 */
export interface ExpertApiSetVerificationInProgressRequest {
    /**
     * verification request code
     * @type {number}
     * @memberof ExpertApiSetVerificationInProgress
     */
    readonly requestCode: number
}

/**
 * ExpertApi - object-oriented interface
 * @export
 * @class ExpertApi
 * @extends {BaseAPI}
 */
export class ExpertApi extends BaseAPI {
    /**
     * 
     * @summary 스트라이프 계정 생성
     * @param {ExpertApiCreatePurchaserPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public createPurchaserPage(requestParameters: ExpertApiCreatePurchaserPageRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).createPurchaserPage(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 스트라이프 계정 생성
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public createStripeAccountLink(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).createStripeAccountLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 experience education 정보 상세 삭제
     * @param {ExpertApiDeleteExpertEducationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public deleteExpertEducation(requestParameters: ExpertApiDeleteExpertEducationRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).deleteExpertEducation(requestParameters.educationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 workExperience 정보 상세 삭제
     * @param {ExpertApiDeleteExpertExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public deleteExpertExperience(requestParameters: ExpertApiDeleteExpertExperienceRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).deleteExpertExperience(requestParameters.workExperienceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 languages 정보 삭제
     * @param {ExpertApiDeleteExpertLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public deleteExpertLanguages(requestParameters: ExpertApiDeleteExpertLanguagesRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).deleteExpertLanguages(requestParameters.languageInfoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 experience 정보 상세 삭제
     * @param {ExpertApiDeleteProgramDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public deleteProgramDetail(requestParameters: ExpertApiDeleteProgramDetailRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).deleteProgramDetail(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 갯수 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getBookingCount(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getBookingCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 조회
     * @param {ExpertApiGetBookingDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getBookingDetail(requestParameters: ExpertApiGetBookingDetailRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getBookingDetail(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary feedback 정보 조회
     * @param {ExpertApiGetBookingFeedbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getBookingFeedback(requestParameters: ExpertApiGetBookingFeedbackRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getBookingFeedback(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 조회
     * @param {ExpertApiGetBookingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getBookingList(requestParameters: ExpertApiGetBookingListRequest = {}, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getBookingList(requestParameters.bookingState, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약 취소
     * @param {ExpertApiGetCancelBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getCancelBooking(requestParameters: ExpertApiGetCancelBookingRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getCancelBooking(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 계좌정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getExpertBankExpertInfo(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getExpertBankExpertInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 experience 정보 상세 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getExpertExperience(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getExpertExperience(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 Stripe 정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getExpertStatus(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getExpertStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 멘토링 현황 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getMentoringSummary(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getMentoringSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ongoing 예약 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getOngoingBookingList(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getOngoingBookingList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 갯수 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getProgramCount(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getProgramCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 상세 조회
     * @param {ExpertApiGetProgramDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getProgramDetail(requestParameters: ExpertApiGetProgramDetailRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getProgramDetail(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getProgramList1(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getProgramList1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 스킬 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getSkillList(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getSkillList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 Stripe 정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public getStripeInfo(options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).getStripeInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 Booking feedback 정보 상세 수정
     * @param {ExpertApiSetBookingFeedbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public setBookingFeedback(requestParameters: ExpertApiSetBookingFeedbackRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).setBookingFeedback(requestParameters.bookingFeedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 계좌정보 조회
     * @param {ExpertApiSetExpertBankExpertInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public setExpertBankExpertInfo(requestParameters: ExpertApiSetExpertBankExpertInfoRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).setExpertBankExpertInfo(requestParameters.tbExpertBankAccountEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 experience 정보 상세 수정
     * @param {ExpertApiSetExpertExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public setExpertExperience(requestParameters: ExpertApiSetExpertExperienceRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).setExpertExperience(requestParameters.reqTbExpertInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 program 정보 상세 수정
     * @param {ExpertApiSetExpertProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public setExpertProgram(requestParameters: ExpertApiSetExpertProgramRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).setExpertProgram(requestParameters.programInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 verification status: in progress
     * @param {ExpertApiSetVerificationInProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertApi
     */
    public setVerificationInProgress(requestParameters: ExpertApiSetVerificationInProgressRequest, options?: AxiosRequestConfig) {
        return ExpertApiFp(this.configuration).setVerificationInProgress(requestParameters.requestCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExtracurricularApi - axios parameter creator
 * @export
 */
export const ExtracurricularApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {string} [keyword] 
         * @param {Array<number>} [listingType] 
         * @param {Array<number>} [ecType] 
         * @param {Array<number>} [strengthPoint] 
         * @param {Array<number>} [majors] 
         * @param {Array<string>} [addressState] 
         * @param {boolean} [isOnline] 
         * @param {string} [onlyPremium] 
         * @param {string} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [memberId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityList1: async (keyword?: string, listingType?: Array<number>, ecType?: Array<number>, strengthPoint?: Array<number>, majors?: Array<number>, addressState?: Array<string>, isOnline?: boolean, onlyPremium?: string, sort?: string, memberId?: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (listingType) {
                localVarQueryParameter['listingType'] = listingType;
            }

            if (ecType) {
                localVarQueryParameter['ecType'] = ecType;
            }

            if (strengthPoint) {
                localVarQueryParameter['strengthPoint'] = strengthPoint;
            }

            if (majors) {
                localVarQueryParameter['majors'] = majors;
            }

            if (addressState) {
                localVarQueryParameter['addressState'] = addressState;
            }

            if (isOnline !== undefined) {
                localVarQueryParameter['isOnline'] = isOnline;
            }

            if (onlyPremium !== undefined) {
                localVarQueryParameter['onlyPremium'] = onlyPremium;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtracurricularApi - functional programming interface
 * @export
 */
export const ExtracurricularApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtracurricularApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {string} [keyword] 
         * @param {Array<number>} [listingType] 
         * @param {Array<number>} [ecType] 
         * @param {Array<number>} [strengthPoint] 
         * @param {Array<number>} [majors] 
         * @param {Array<string>} [addressState] 
         * @param {boolean} [isOnline] 
         * @param {string} [onlyPremium] 
         * @param {string} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [memberId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityList1(keyword?: string, listingType?: Array<number>, ecType?: Array<number>, strengthPoint?: Array<number>, majors?: Array<number>, addressState?: Array<string>, isOnline?: boolean, onlyPremium?: string, sort?: string, memberId?: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendedActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityList1(keyword, listingType, ecType, strengthPoint, majors, addressState, isOnline, onlyPremium, sort, memberId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtracurricularApi - factory interface
 * @export
 */
export const ExtracurricularApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtracurricularApiFp(configuration)
    return {
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {ExtracurricularApiPublicActivityList1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityList1(requestParameters: ExtracurricularApiPublicActivityList1Request = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RecommendedActivity>> {
            return localVarFp.publicActivityList1(requestParameters.keyword, requestParameters.listingType, requestParameters.ecType, requestParameters.strengthPoint, requestParameters.majors, requestParameters.addressState, requestParameters.isOnline, requestParameters.onlyPremium, requestParameters.sort, requestParameters.memberId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicActivityList1 operation in ExtracurricularApi.
 * @export
 * @interface ExtracurricularApiPublicActivityList1Request
 */
export interface ExtracurricularApiPublicActivityList1Request {
    /**
     * 
     * @type {string}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly keyword?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly listingType?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly ecType?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly strengthPoint?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly majors?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly addressState?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly isOnline?: boolean

    /**
     * 
     * @type {string}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly onlyPremium?: string

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {string}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly memberId?: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ExtracurricularApiPublicActivityList1
     */
    readonly size?: number
}

/**
 * ExtracurricularApi - object-oriented interface
 * @export
 * @class ExtracurricularApi
 * @extends {BaseAPI}
 */
export class ExtracurricularApi extends BaseAPI {
    /**
     * 
     * @summary Activity Type 목록 조회
     * @param {ExtracurricularApiPublicActivityList1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtracurricularApi
     */
    public publicActivityList1(requestParameters: ExtracurricularApiPublicActivityList1Request = {}, options?: AxiosRequestConfig) {
        return ExtracurricularApiFp(this.configuration).publicActivityList1(requestParameters.keyword, requestParameters.listingType, requestParameters.ecType, requestParameters.strengthPoint, requestParameters.majors, requestParameters.addressState, requestParameters.isOnline, requestParameters.onlyPremium, requestParameters.sort, requestParameters.memberId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontApi - axios parameter creator
 * @export
 */
export const FrontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activity 상세조회
         * @param {number} recommendedActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityDetail: async (recommendedActivityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendedActivityId' is not null or undefined
            assertParamExists('getActivityDetail', 'recommendedActivityId', recommendedActivityId)
            const localVarPath = `/front/activity/{recommendedActivityId}`
                .replace(`{${"recommendedActivityId"}}`, encodeURIComponent(String(recommendedActivityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommend Activity 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityRecommend: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/activity-recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommend Career 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRecommend: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/career-recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 전)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeRecommend: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/college-recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommend Experts 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertsRecommend: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/experts-recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Quick tips Step 체크
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontStepInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/frontStep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginCollegeRecommend: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/login/college-recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Premium Activity List 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumActivityList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/premium-activity/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontApi - functional programming interface
 * @export
 */
export const FrontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activity 상세조회
         * @param {number} recommendedActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityDetail(recommendedActivityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRecommendedActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityDetail(recommendedActivityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommend Activity 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityRecommend(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRecommendedActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityRecommend(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommend Career 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerRecommend(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResOnetCareer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerRecommend(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 전)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeRecommend(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResCollegeNicheIpeds>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeRecommend(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommend Experts 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertsRecommend(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResExperts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertsRecommend(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Quick tips Step 체크
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrontStepInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResFrontStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrontStepInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginCollegeRecommend(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResCollegeNicheIpeds>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginCollegeRecommend(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Premium Activity List 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumActivityList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRecommendedActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumActivityList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontApi - factory interface
 * @export
 */
export const FrontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontApiFp(configuration)
    return {
        /**
         * 
         * @summary Activity 상세조회
         * @param {FrontApiGetActivityDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityDetail(requestParameters: FrontApiGetActivityDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ResRecommendedActivity> {
            return localVarFp.getActivityDetail(requestParameters.recommendedActivityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommend Activity 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityRecommend(options?: AxiosRequestConfig): AxiosPromise<Array<ResRecommendedActivity>> {
            return localVarFp.getActivityRecommend(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommend Career 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRecommend(options?: AxiosRequestConfig): AxiosPromise<Array<ResOnetCareer>> {
            return localVarFp.getCareerRecommend(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 전)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeRecommend(options?: AxiosRequestConfig): AxiosPromise<Array<ResCollegeNicheIpeds>> {
            return localVarFp.getCollegeRecommend(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommend Experts 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertsRecommend(options?: AxiosRequestConfig): AxiosPromise<Array<ResExperts>> {
            return localVarFp.getExpertsRecommend(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Quick tips Step 체크
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontStepInfo(options?: AxiosRequestConfig): AxiosPromise<ResFrontStep> {
            return localVarFp.getFrontStepInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommend College 조회(로그인 후)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginCollegeRecommend(options?: AxiosRequestConfig): AxiosPromise<Array<ResCollegeNicheIpeds>> {
            return localVarFp.getLoginCollegeRecommend(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Premium Activity List 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumActivityList(options?: AxiosRequestConfig): AxiosPromise<Array<ResRecommendedActivity>> {
            return localVarFp.getPremiumActivityList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActivityDetail operation in FrontApi.
 * @export
 * @interface FrontApiGetActivityDetailRequest
 */
export interface FrontApiGetActivityDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontApiGetActivityDetail
     */
    readonly recommendedActivityId: number
}

/**
 * FrontApi - object-oriented interface
 * @export
 * @class FrontApi
 * @extends {BaseAPI}
 */
export class FrontApi extends BaseAPI {
    /**
     * 
     * @summary Activity 상세조회
     * @param {FrontApiGetActivityDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getActivityDetail(requestParameters: FrontApiGetActivityDetailRequest, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getActivityDetail(requestParameters.recommendedActivityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommend Activity 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getActivityRecommend(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getActivityRecommend(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommend Career 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getCareerRecommend(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getCareerRecommend(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommend College 조회(로그인 전)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getCollegeRecommend(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getCollegeRecommend(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommend Experts 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getExpertsRecommend(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getExpertsRecommend(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Quick tips Step 체크
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getFrontStepInfo(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getFrontStepInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommend College 조회(로그인 후)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getLoginCollegeRecommend(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getLoginCollegeRecommend(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Premium Activity List 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getPremiumActivityList(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getPremiumActivityList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MajorApi - axios parameter creator
 * @export
 */
export const MajorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Major 관심 삭제
         * @param {string} majorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeDelete: async (majorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'majorId' is not null or undefined
            assertParamExists('getLikeDelete', 'majorId', majorId)
            const localVarPath = `/major/likes/delete/{majorId}`
                .replace(`{${"majorId"}}`, encodeURIComponent(String(majorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/major/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {number} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeListInCollege: async (collegeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collegeId' is not null or undefined
            assertParamExists('getLikeListInCollege', 'collegeId', collegeId)
            const localVarPath = `/major/likes/college/{collegeId}`
                .replace(`{${"collegeId"}}`, encodeURIComponent(String(collegeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Major 관심 등록
         * @param {string} [majorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeSave: async (majorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/major/likes/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (majorId !== undefined) {
                localVarQueryParameter['majorId'] = majorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MajorApi - functional programming interface
 * @export
 */
export const MajorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MajorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Major 관심 삭제
         * @param {string} majorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLikeDelete(majorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLikeDelete(majorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLikeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResMemberLikeMajor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLikeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {number} collegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLikeListInCollege(collegeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResMemberLikeMajor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLikeListInCollege(collegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Major 관심 등록
         * @param {string} [majorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLikeSave(majorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberLikeMajor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLikeSave(majorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MajorApi - factory interface
 * @export
 */
export const MajorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MajorApiFp(configuration)
    return {
        /**
         * 
         * @summary Major 관심 삭제
         * @param {MajorApiGetLikeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeDelete(requestParameters: MajorApiGetLikeDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getLikeDelete(requestParameters.majorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResMemberLikeMajor>> {
            return localVarFp.getLikeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Major 관심 목록 조회
         * @param {MajorApiGetLikeListInCollegeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeListInCollege(requestParameters: MajorApiGetLikeListInCollegeRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResMemberLikeMajor>> {
            return localVarFp.getLikeListInCollege(requestParameters.collegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Major 관심 등록
         * @param {MajorApiGetLikeSaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLikeSave(requestParameters: MajorApiGetLikeSaveRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResMemberLikeMajor> {
            return localVarFp.getLikeSave(requestParameters.majorId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLikeDelete operation in MajorApi.
 * @export
 * @interface MajorApiGetLikeDeleteRequest
 */
export interface MajorApiGetLikeDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MajorApiGetLikeDelete
     */
    readonly majorId: string
}

/**
 * Request parameters for getLikeListInCollege operation in MajorApi.
 * @export
 * @interface MajorApiGetLikeListInCollegeRequest
 */
export interface MajorApiGetLikeListInCollegeRequest {
    /**
     * 
     * @type {number}
     * @memberof MajorApiGetLikeListInCollege
     */
    readonly collegeId: number
}

/**
 * Request parameters for getLikeSave operation in MajorApi.
 * @export
 * @interface MajorApiGetLikeSaveRequest
 */
export interface MajorApiGetLikeSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof MajorApiGetLikeSave
     */
    readonly majorId?: string
}

/**
 * MajorApi - object-oriented interface
 * @export
 * @class MajorApi
 * @extends {BaseAPI}
 */
export class MajorApi extends BaseAPI {
    /**
     * 
     * @summary Major 관심 삭제
     * @param {MajorApiGetLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getLikeDelete(requestParameters: MajorApiGetLikeDeleteRequest, options?: AxiosRequestConfig) {
        return MajorApiFp(this.configuration).getLikeDelete(requestParameters.majorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Major 관심 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getLikeList(options?: AxiosRequestConfig) {
        return MajorApiFp(this.configuration).getLikeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Major 관심 목록 조회
     * @param {MajorApiGetLikeListInCollegeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getLikeListInCollege(requestParameters: MajorApiGetLikeListInCollegeRequest, options?: AxiosRequestConfig) {
        return MajorApiFp(this.configuration).getLikeListInCollege(requestParameters.collegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Major 관심 등록
     * @param {MajorApiGetLikeSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getLikeSave(requestParameters: MajorApiGetLikeSaveRequest = {}, options?: AxiosRequestConfig) {
        return MajorApiFp(this.configuration).getLikeSave(requestParameters.majorId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberDataApi - axios parameter creator
 * @export
 */
export const MemberDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 구독취소
         * @param {ReqCancelSubParam} reqCancelSubParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (reqCancelSubParam: ReqCancelSubParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCancelSubParam' is not null or undefined
            assertParamExists('cancelSubscription', 'reqCancelSubParam', reqCancelSubParam)
            const localVarPath = `/member/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCancelSubParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 뷰잉패스 결제이력
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutHistories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/checkout-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 갯수
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNewNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notifications/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 삭제(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: async (notificationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 현재 로그인한 회원 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 결제고객포털url생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPotalUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/customer-potal-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/menu/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 확인(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: async (notificationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notification-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 확인 (여러개 , notificationId 보다작은 id의 안읽은 모든 알림 읽음처리
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotifications: async (notificationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notifications-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 현재 로그인한 회원 강제 삭제 (테스트용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 구독재시작
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription-restart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {boolean} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuStatus: async (status: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('setMenuStatus', 'status', status)
            const localVarPath = `/member/menu/status/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 구독정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 내 알림 확인전으로변경(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unReadNotification: async (notificationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/notification-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberDataApi - functional programming interface
 * @export
 */
export const MemberDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 구독취소
         * @param {ReqCancelSubParam} reqCancelSubParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(reqCancelSubParam: ReqCancelSubParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(reqCancelSubParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 뷰잉패스 결제이력
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutHistories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckoutHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutHistories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 갯수
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countNewNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countNewNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 삭제(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotification(notificationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 현재 로그인한 회원 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 결제고객포털url생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerPotalUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerPotalUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 확인(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotification(notificationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 확인 (여러개 , notificationId 보다작은 id의 안읽은 모든 알림 읽음처리
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotifications(notificationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotifications(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 현재 로그인한 회원 강제 삭제 (테스트용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 구독재시작
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {boolean} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMenuStatus(status: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMenuStatus(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 구독정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 내 알림 확인전으로변경(한개)
         * @param {string} [notificationId] 알림 id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unReadNotification(notificationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unReadNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberDataApi - factory interface
 * @export
 */
export const MemberDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberDataApiFp(configuration)
    return {
        /**
         * 
         * @summary 구독취소
         * @param {MemberDataApiCancelSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(requestParameters: MemberDataApiCancelSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.cancelSubscription(requestParameters.reqCancelSubParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 뷰잉패스 결제이력
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutHistories(options?: AxiosRequestConfig): AxiosPromise<Array<CheckoutHistory>> {
            return localVarFp.checkoutHistories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 갯수
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNewNotification(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.countNewNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 삭제(한개)
         * @param {MemberDataApiDeleteNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification(requestParameters: MemberDataApiDeleteNotificationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteNotification(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 현재 로그인한 회원 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detail(options?: AxiosRequestConfig): AxiosPromise<ResMember> {
            return localVarFp.detail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 결제고객포털url생성
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPotalUrl(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getCustomerPotalUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuStatus(options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.getMenuStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 리스트
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: AxiosRequestConfig): AxiosPromise<Array<Notification>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 확인(한개)
         * @param {MemberDataApiReadNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(requestParameters: MemberDataApiReadNotificationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.readNotification(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 확인 (여러개 , notificationId 보다작은 id의 안읽은 모든 알림 읽음처리
         * @param {MemberDataApiReadNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotifications(requestParameters: MemberDataApiReadNotificationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.readNotifications(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 현재 로그인한 회원 강제 삭제 (테스트용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.remove(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 구독재시작
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartSubscription(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.restartSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 메뉴 상태 수정
         * @param {MemberDataApiSetMenuStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuStatus(requestParameters: MemberDataApiSetMenuStatusRequest, options?: AxiosRequestConfig): AxiosPromise<Member> {
            return localVarFp.setMenuStatus(requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 구독정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription(options?: AxiosRequestConfig): AxiosPromise<SubscriptionInfo> {
            return localVarFp.subscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 내 알림 확인전으로변경(한개)
         * @param {MemberDataApiUnReadNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unReadNotification(requestParameters: MemberDataApiUnReadNotificationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.unReadNotification(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelSubscription operation in MemberDataApi.
 * @export
 * @interface MemberDataApiCancelSubscriptionRequest
 */
export interface MemberDataApiCancelSubscriptionRequest {
    /**
     * 
     * @type {ReqCancelSubParam}
     * @memberof MemberDataApiCancelSubscription
     */
    readonly reqCancelSubParam: ReqCancelSubParam
}

/**
 * Request parameters for deleteNotification operation in MemberDataApi.
 * @export
 * @interface MemberDataApiDeleteNotificationRequest
 */
export interface MemberDataApiDeleteNotificationRequest {
    /**
     * 알림 id
     * @type {string}
     * @memberof MemberDataApiDeleteNotification
     */
    readonly notificationId?: string
}

/**
 * Request parameters for readNotification operation in MemberDataApi.
 * @export
 * @interface MemberDataApiReadNotificationRequest
 */
export interface MemberDataApiReadNotificationRequest {
    /**
     * 알림 id
     * @type {string}
     * @memberof MemberDataApiReadNotification
     */
    readonly notificationId?: string
}

/**
 * Request parameters for readNotifications operation in MemberDataApi.
 * @export
 * @interface MemberDataApiReadNotificationsRequest
 */
export interface MemberDataApiReadNotificationsRequest {
    /**
     * 알림 id
     * @type {string}
     * @memberof MemberDataApiReadNotifications
     */
    readonly notificationId?: string
}

/**
 * Request parameters for setMenuStatus operation in MemberDataApi.
 * @export
 * @interface MemberDataApiSetMenuStatusRequest
 */
export interface MemberDataApiSetMenuStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MemberDataApiSetMenuStatus
     */
    readonly status: boolean
}

/**
 * Request parameters for unReadNotification operation in MemberDataApi.
 * @export
 * @interface MemberDataApiUnReadNotificationRequest
 */
export interface MemberDataApiUnReadNotificationRequest {
    /**
     * 알림 id
     * @type {string}
     * @memberof MemberDataApiUnReadNotification
     */
    readonly notificationId?: string
}

/**
 * MemberDataApi - object-oriented interface
 * @export
 * @class MemberDataApi
 * @extends {BaseAPI}
 */
export class MemberDataApi extends BaseAPI {
    /**
     * 
     * @summary 구독취소
     * @param {MemberDataApiCancelSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public cancelSubscription(requestParameters: MemberDataApiCancelSubscriptionRequest, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).cancelSubscription(requestParameters.reqCancelSubParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 뷰잉패스 결제이력
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public checkoutHistories(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).checkoutHistories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public countNewNotification(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).countNewNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 삭제(한개)
     * @param {MemberDataApiDeleteNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public deleteNotification(requestParameters: MemberDataApiDeleteNotificationRequest = {}, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).deleteNotification(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 현재 로그인한 회원 정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public detail(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).detail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 결제고객포털url생성
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public getCustomerPotalUrl(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).getCustomerPotalUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 메뉴 상태 수정
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public getMenuStatus(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).getMenuStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public list(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 확인(한개)
     * @param {MemberDataApiReadNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public readNotification(requestParameters: MemberDataApiReadNotificationRequest = {}, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).readNotification(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 확인 (여러개 , notificationId 보다작은 id의 안읽은 모든 알림 읽음처리
     * @param {MemberDataApiReadNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public readNotifications(requestParameters: MemberDataApiReadNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).readNotifications(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 현재 로그인한 회원 강제 삭제 (테스트용)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public remove(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).remove(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 구독재시작
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public restartSubscription(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).restartSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 메뉴 상태 수정
     * @param {MemberDataApiSetMenuStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public setMenuStatus(requestParameters: MemberDataApiSetMenuStatusRequest, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).setMenuStatus(requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 구독정보
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public subscription(options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).subscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 내 알림 확인전으로변경(한개)
     * @param {MemberDataApiUnReadNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberDataApi
     */
    public unReadNotification(requestParameters: MemberDataApiUnReadNotificationRequest = {}, options?: AxiosRequestConfig) {
        return MemberDataApiFp(this.configuration).unReadNotification(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MentoringApi - axios parameter creator
 * @export
 */
export const MentoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReview: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getBookingReview', 'bookingId', bookingId)
            const localVarPath = `/mentoring/expert/review/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventPayment: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getEventPayment', 'productId', productId)
            const localVarPath = `/mentoring/event/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertDetail: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getExpertDetail', 'expertId', expertId)
            const localVarPath = `/mentoring/expert/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {string} [expertType] 
         * @param {Array<string>} [weeklyDate] 
         * @param {Array<string>} [timeSet] 
         * @param {Array<string>} [collegeIds] 
         * @param {Array<string>} [getIntoCollegeIds] 
         * @param {Array<string>} [majors] 
         * @param {Array<number>} [counselorThemes] 
         * @param {Array<number>} [tutorTypes] 
         * @param {Array<number>} [mentorAdvices] 
         * @param {number} [memberId] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertList: async (expertType?: string, weeklyDate?: Array<string>, timeSet?: Array<string>, collegeIds?: Array<string>, getIntoCollegeIds?: Array<string>, majors?: Array<string>, counselorThemes?: Array<number>, tutorTypes?: Array<number>, mentorAdvices?: Array<number>, memberId?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/expert/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expertType !== undefined) {
                localVarQueryParameter['expertType'] = expertType;
            }

            if (weeklyDate) {
                localVarQueryParameter['weeklyDate'] = weeklyDate;
            }

            if (timeSet) {
                localVarQueryParameter['timeSet'] = timeSet;
            }

            if (collegeIds) {
                localVarQueryParameter['collegeIds'] = collegeIds;
            }

            if (getIntoCollegeIds) {
                localVarQueryParameter['getIntoCollegeIds'] = getIntoCollegeIds;
            }

            if (majors) {
                localVarQueryParameter['majors'] = majors;
            }

            if (counselorThemes) {
                localVarQueryParameter['counselorThemes'] = counselorThemes;
            }

            if (tutorTypes) {
                localVarQueryParameter['tutorTypes'] = tutorTypes;
            }

            if (mentorAdvices) {
                localVarQueryParameter['mentorAdvices'] = mentorAdvices;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 예약 사전 질문
         * @param {number} [expertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertRequestQuestion: async (expertId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/expert/booking/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertSummaryinfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/expert/summaryinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 목록 조회
         * @param {string} [bookingState] Classify booking status
         * @param {string} [sort] Filter values based on time period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentoringBookingList: async (bookingState?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/booking/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bookingState !== undefined) {
                localVarQueryParameter['bookingState'] = bookingState;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약을 위한 기본 조회
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBooking: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramBooking', 'programId', programId)
            const localVarPath = `/mentoring/expert/program/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 상세조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBookingDetail: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getProgramBookingDetail', 'bookingId', bookingId)
            const localVarPath = `/mentoring/booking/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramList: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getProgramList', 'expertId', expertId)
            const localVarPath = `/mentoring/expert/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewList: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getReviewList', 'expertId', expertId)
            const localVarPath = `/mentoring/expert/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 Course 목록 조회
         * @param {number} bookingId 
         * @param {number} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGradeAndSemester1: async (bookingId: number, grade: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getRoadmapCourseListByGradeAndSemester1', 'bookingId', bookingId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('getRoadmapCourseListByGradeAndSemester1', 'grade', grade)
            const localVarPath = `/mentoring/booking/{bookingId}/roadmap/coursework/course/{grade}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ongoing 예약 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentOngoingMentoringBookingList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/booking/list/ongoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 로드맵 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentRoadmap: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getStudentRoadmap', 'bookingId', bookingId)
            const localVarPath = `/mentoring/booking/{bookingId}/roadmap`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 로드맵 Activity 목록 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentRoadmapActivities: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getStudentRoadmapActivities', 'bookingId', bookingId)
            const localVarPath = `/mentoring/booking/{bookingId}/roadmap/activities`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {ReqBookingCancel} reqBookingCancel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCancelBooking: async (reqBookingCancel: ReqBookingCancel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqBookingCancel' is not null or undefined
            assertParamExists('setCancelBooking', 'reqBookingCancel', reqBookingCancel)
            const localVarPath = `/mentoring/expert/program/booking/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBookingCancel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약
         * @param {ReqProgramBooking} reqProgramBooking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBooking: async (reqProgramBooking: ReqProgramBooking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqProgramBooking' is not null or undefined
            assertParamExists('setProgramBooking', 'reqProgramBooking', reqProgramBooking)
            const localVarPath = `/mentoring/expert/program/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqProgramBooking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 리서치 페이퍼 요청 유저 정보 저장
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResearchPaperMember: async (memberId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/research-paper-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 리뷰 입력
         * @param {ReqProgramReviewDto} reqProgramReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReview: async (reqProgramReviewDto: ReqProgramReviewDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqProgramReviewDto' is not null or undefined
            assertParamExists('setReview', 'reqProgramReviewDto', reqProgramReviewDto)
            const localVarPath = `/mentoring/expert/program/booking/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqProgramReviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 파일 업로드
         * @param {File} [uploadFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mentoring/file/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (uploadFile !== undefined) { 
                localVarFormParams.append('uploadFile', uploadFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MentoringApi - functional programming interface
 * @export
 */
export const MentoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MentoringApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingReview(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramReviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingReview(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventPayment(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventPayment(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertDetail(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertExperience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertDetail(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {string} [expertType] 
         * @param {Array<string>} [weeklyDate] 
         * @param {Array<string>} [timeSet] 
         * @param {Array<string>} [collegeIds] 
         * @param {Array<string>} [getIntoCollegeIds] 
         * @param {Array<string>} [majors] 
         * @param {Array<number>} [counselorThemes] 
         * @param {Array<number>} [tutorTypes] 
         * @param {Array<number>} [mentorAdvices] 
         * @param {number} [memberId] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertList(expertType?: string, weeklyDate?: Array<string>, timeSet?: Array<string>, collegeIds?: Array<string>, getIntoCollegeIds?: Array<string>, majors?: Array<string>, counselorThemes?: Array<number>, tutorTypes?: Array<number>, mentorAdvices?: Array<number>, memberId?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertList(expertType, weeklyDate, timeSet, collegeIds, getIntoCollegeIds, majors, counselorThemes, tutorTypes, mentorAdvices, memberId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 예약 사전 질문
         * @param {number} [expertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertRequestQuestion(expertId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertRequestQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertRequestQuestion(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertSummaryinfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertSummaryinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 목록 조회
         * @param {string} [bookingState] Classify booking status
         * @param {string} [sort] Filter values based on time period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMentoringBookingList(bookingState?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMentoringBookingList(bookingState, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약을 위한 기본 조회
         * @param {number} programId programId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramBooking(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramBooking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramBooking(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 상세조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramBookingDetail(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramBookingDetail(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramList(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramList(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewList(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramReviewList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewList(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 Course 목록 조회
         * @param {number} bookingId 
         * @param {number} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapCourseListByGradeAndSemester1(bookingId: number, grade: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoadmapCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapCourseListByGradeAndSemester1(bookingId, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ongoing 예약 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentOngoingMentoringBookingList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentOngoingMentoringBookingList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 로드맵 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentRoadmap(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentRoadmap(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 로드맵 Activity 목록 조회
         * @param {number} bookingId bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentRoadmapActivities(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentRoadmapActivities(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {ReqBookingCancel} reqBookingCancel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCancelBooking(reqBookingCancel: ReqBookingCancel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingCancel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCancelBooking(reqBookingCancel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약
         * @param {ReqProgramBooking} reqProgramBooking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProgramBooking(reqProgramBooking: ReqProgramBooking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProgramBooking(reqProgramBooking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 리서치 페이퍼 요청 유저 정보 저장
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setResearchPaperMember(memberId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResearchPaperMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setResearchPaperMember(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 리뷰 입력
         * @param {ReqProgramReviewDto} reqProgramReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReview(reqProgramReviewDto: ReqProgramReviewDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramReviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setReview(reqProgramReviewDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async test1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.test1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 파일 업로드
         * @param {File} [uploadFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MentoringApi - factory interface
 * @export
 */
export const MentoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MentoringApiFp(configuration)
    return {
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {MentoringApiGetBookingReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingReview(requestParameters: MentoringApiGetBookingReviewRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramReviewDto> {
            return localVarFp.getBookingReview(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {MentoringApiGetEventPaymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventPayment(requestParameters: MentoringApiGetEventPaymentRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getEventPayment(requestParameters.productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {MentoringApiGetExpertDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertDetail(requestParameters: MentoringApiGetExpertDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ExpertExperience> {
            return localVarFp.getExpertDetail(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {MentoringApiGetExpertListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertList(requestParameters: MentoringApiGetExpertListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ExpertList>> {
            return localVarFp.getExpertList(requestParameters.expertType, requestParameters.weeklyDate, requestParameters.timeSet, requestParameters.collegeIds, requestParameters.getIntoCollegeIds, requestParameters.majors, requestParameters.counselorThemes, requestParameters.tutorTypes, requestParameters.mentorAdvices, requestParameters.memberId, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 예약 사전 질문
         * @param {MentoringApiGetExpertRequestQuestionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertRequestQuestion(requestParameters: MentoringApiGetExpertRequestQuestionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ExpertRequestQuestion>> {
            return localVarFp.getExpertRequestQuestion(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertSummaryinfo(options?: AxiosRequestConfig): AxiosPromise<ExpertList> {
            return localVarFp.getExpertSummaryinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 목록 조회
         * @param {MentoringApiGetMentoringBookingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentoringBookingList(requestParameters: MentoringApiGetMentoringBookingListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<BookingList>> {
            return localVarFp.getMentoringBookingList(requestParameters.bookingState, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약을 위한 기본 조회
         * @param {MentoringApiGetProgramBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBooking(requestParameters: MentoringApiGetProgramBookingRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramBooking> {
            return localVarFp.getProgramBooking(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 상세조회
         * @param {MentoringApiGetProgramBookingDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBookingDetail(requestParameters: MentoringApiGetProgramBookingDetailRequest, options?: AxiosRequestConfig): AxiosPromise<BookingDetail> {
            return localVarFp.getProgramBookingDetail(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {MentoringApiGetProgramListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramList(requestParameters: MentoringApiGetProgramListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ProgramList>> {
            return localVarFp.getProgramList(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {MentoringApiGetReviewListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewList(requestParameters: MentoringApiGetReviewListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ProgramReviewList>> {
            return localVarFp.getReviewList(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 Course 목록 조회
         * @param {MentoringApiGetRoadmapCourseListByGradeAndSemester1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGradeAndSemester1(requestParameters: MentoringApiGetRoadmapCourseListByGradeAndSemester1Request, options?: AxiosRequestConfig): AxiosPromise<Array<RoadmapCourse>> {
            return localVarFp.getRoadmapCourseListByGradeAndSemester1(requestParameters.bookingId, requestParameters.grade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ongoing 예약 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentOngoingMentoringBookingList(options?: AxiosRequestConfig): AxiosPromise<Array<BookingList>> {
            return localVarFp.getStudentOngoingMentoringBookingList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 로드맵 조회
         * @param {MentoringApiGetStudentRoadmapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentRoadmap(requestParameters: MentoringApiGetStudentRoadmapRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.getStudentRoadmap(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 로드맵 Activity 목록 조회
         * @param {MentoringApiGetStudentRoadmapActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentRoadmapActivities(requestParameters: MentoringApiGetStudentRoadmapActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivityList> {
            return localVarFp.getStudentRoadmapActivities(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약 취소
         * @param {MentoringApiSetCancelBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCancelBooking(requestParameters: MentoringApiSetCancelBookingRequest, options?: AxiosRequestConfig): AxiosPromise<BookingCancel> {
            return localVarFp.setCancelBooking(requestParameters.reqBookingCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약
         * @param {MentoringApiSetProgramBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBooking(requestParameters: MentoringApiSetProgramBookingRequest, options?: AxiosRequestConfig): AxiosPromise<BookingDetail> {
            return localVarFp.setProgramBooking(requestParameters.reqProgramBooking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 리서치 페이퍼 요청 유저 정보 저장
         * @param {MentoringApiSetResearchPaperMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResearchPaperMember(requestParameters: MentoringApiSetResearchPaperMemberRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResearchPaperMember> {
            return localVarFp.setResearchPaperMember(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 리뷰 입력
         * @param {MentoringApiSetReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReview(requestParameters: MentoringApiSetReviewRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramReviewDto> {
            return localVarFp.setReview(requestParameters.reqProgramReviewDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 크로노피 콜백
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test1(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.test1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 파일 업로드
         * @param {MentoringApiUploadFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(requestParameters: MentoringApiUploadFileRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AttachFile> {
            return localVarFp.uploadFile(requestParameters.uploadFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBookingReview operation in MentoringApi.
 * @export
 * @interface MentoringApiGetBookingReviewRequest
 */
export interface MentoringApiGetBookingReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof MentoringApiGetBookingReview
     */
    readonly bookingId: number
}

/**
 * Request parameters for getEventPayment operation in MentoringApi.
 * @export
 * @interface MentoringApiGetEventPaymentRequest
 */
export interface MentoringApiGetEventPaymentRequest {
    /**
     * productId
     * @type {number}
     * @memberof MentoringApiGetEventPayment
     */
    readonly productId: number
}

/**
 * Request parameters for getExpertDetail operation in MentoringApi.
 * @export
 * @interface MentoringApiGetExpertDetailRequest
 */
export interface MentoringApiGetExpertDetailRequest {
    /**
     * expertId
     * @type {number}
     * @memberof MentoringApiGetExpertDetail
     */
    readonly expertId: number
}

/**
 * Request parameters for getExpertList operation in MentoringApi.
 * @export
 * @interface MentoringApiGetExpertListRequest
 */
export interface MentoringApiGetExpertListRequest {
    /**
     * 
     * @type {string}
     * @memberof MentoringApiGetExpertList
     */
    readonly expertType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MentoringApiGetExpertList
     */
    readonly weeklyDate?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MentoringApiGetExpertList
     */
    readonly timeSet?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MentoringApiGetExpertList
     */
    readonly collegeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MentoringApiGetExpertList
     */
    readonly getIntoCollegeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MentoringApiGetExpertList
     */
    readonly majors?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof MentoringApiGetExpertList
     */
    readonly counselorThemes?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MentoringApiGetExpertList
     */
    readonly tutorTypes?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MentoringApiGetExpertList
     */
    readonly mentorAdvices?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof MentoringApiGetExpertList
     */
    readonly memberId?: number

    /**
     * 
     * @type {string}
     * @memberof MentoringApiGetExpertList
     */
    readonly sort?: string
}

/**
 * Request parameters for getExpertRequestQuestion operation in MentoringApi.
 * @export
 * @interface MentoringApiGetExpertRequestQuestionRequest
 */
export interface MentoringApiGetExpertRequestQuestionRequest {
    /**
     * 
     * @type {number}
     * @memberof MentoringApiGetExpertRequestQuestion
     */
    readonly expertId?: number
}

/**
 * Request parameters for getMentoringBookingList operation in MentoringApi.
 * @export
 * @interface MentoringApiGetMentoringBookingListRequest
 */
export interface MentoringApiGetMentoringBookingListRequest {
    /**
     * Classify booking status
     * @type {string}
     * @memberof MentoringApiGetMentoringBookingList
     */
    readonly bookingState?: string

    /**
     * Filter values based on time period
     * @type {string}
     * @memberof MentoringApiGetMentoringBookingList
     */
    readonly sort?: string
}

/**
 * Request parameters for getProgramBooking operation in MentoringApi.
 * @export
 * @interface MentoringApiGetProgramBookingRequest
 */
export interface MentoringApiGetProgramBookingRequest {
    /**
     * programId
     * @type {number}
     * @memberof MentoringApiGetProgramBooking
     */
    readonly programId: number
}

/**
 * Request parameters for getProgramBookingDetail operation in MentoringApi.
 * @export
 * @interface MentoringApiGetProgramBookingDetailRequest
 */
export interface MentoringApiGetProgramBookingDetailRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof MentoringApiGetProgramBookingDetail
     */
    readonly bookingId: number
}

/**
 * Request parameters for getProgramList operation in MentoringApi.
 * @export
 * @interface MentoringApiGetProgramListRequest
 */
export interface MentoringApiGetProgramListRequest {
    /**
     * expertId
     * @type {number}
     * @memberof MentoringApiGetProgramList
     */
    readonly expertId: number
}

/**
 * Request parameters for getReviewList operation in MentoringApi.
 * @export
 * @interface MentoringApiGetReviewListRequest
 */
export interface MentoringApiGetReviewListRequest {
    /**
     * expertId
     * @type {number}
     * @memberof MentoringApiGetReviewList
     */
    readonly expertId: number
}

/**
 * Request parameters for getRoadmapCourseListByGradeAndSemester1 operation in MentoringApi.
 * @export
 * @interface MentoringApiGetRoadmapCourseListByGradeAndSemester1Request
 */
export interface MentoringApiGetRoadmapCourseListByGradeAndSemester1Request {
    /**
     * 
     * @type {number}
     * @memberof MentoringApiGetRoadmapCourseListByGradeAndSemester1
     */
    readonly bookingId: number

    /**
     * 
     * @type {number}
     * @memberof MentoringApiGetRoadmapCourseListByGradeAndSemester1
     */
    readonly grade: number
}

/**
 * Request parameters for getStudentRoadmap operation in MentoringApi.
 * @export
 * @interface MentoringApiGetStudentRoadmapRequest
 */
export interface MentoringApiGetStudentRoadmapRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof MentoringApiGetStudentRoadmap
     */
    readonly bookingId: number
}

/**
 * Request parameters for getStudentRoadmapActivities operation in MentoringApi.
 * @export
 * @interface MentoringApiGetStudentRoadmapActivitiesRequest
 */
export interface MentoringApiGetStudentRoadmapActivitiesRequest {
    /**
     * bookingId
     * @type {number}
     * @memberof MentoringApiGetStudentRoadmapActivities
     */
    readonly bookingId: number
}

/**
 * Request parameters for setCancelBooking operation in MentoringApi.
 * @export
 * @interface MentoringApiSetCancelBookingRequest
 */
export interface MentoringApiSetCancelBookingRequest {
    /**
     * 
     * @type {ReqBookingCancel}
     * @memberof MentoringApiSetCancelBooking
     */
    readonly reqBookingCancel: ReqBookingCancel
}

/**
 * Request parameters for setProgramBooking operation in MentoringApi.
 * @export
 * @interface MentoringApiSetProgramBookingRequest
 */
export interface MentoringApiSetProgramBookingRequest {
    /**
     * 
     * @type {ReqProgramBooking}
     * @memberof MentoringApiSetProgramBooking
     */
    readonly reqProgramBooking: ReqProgramBooking
}

/**
 * Request parameters for setResearchPaperMember operation in MentoringApi.
 * @export
 * @interface MentoringApiSetResearchPaperMemberRequest
 */
export interface MentoringApiSetResearchPaperMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof MentoringApiSetResearchPaperMember
     */
    readonly memberId?: number
}

/**
 * Request parameters for setReview operation in MentoringApi.
 * @export
 * @interface MentoringApiSetReviewRequest
 */
export interface MentoringApiSetReviewRequest {
    /**
     * 
     * @type {ReqProgramReviewDto}
     * @memberof MentoringApiSetReview
     */
    readonly reqProgramReviewDto: ReqProgramReviewDto
}

/**
 * Request parameters for uploadFile operation in MentoringApi.
 * @export
 * @interface MentoringApiUploadFileRequest
 */
export interface MentoringApiUploadFileRequest {
    /**
     * 
     * @type {File}
     * @memberof MentoringApiUploadFile
     */
    readonly uploadFile?: File
}

/**
 * MentoringApi - object-oriented interface
 * @export
 * @class MentoringApi
 * @extends {BaseAPI}
 */
export class MentoringApi extends BaseAPI {
    /**
     * 
     * @summary 프로그램 리뷰 목록 조회
     * @param {MentoringApiGetBookingReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getBookingReview(requestParameters: MentoringApiGetBookingReviewRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getBookingReview(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 크로노피 콜백
     * @param {MentoringApiGetEventPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getEventPayment(requestParameters: MentoringApiGetEventPaymentRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getEventPayment(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 상세 조회
     * @param {MentoringApiGetExpertDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getExpertDetail(requestParameters: MentoringApiGetExpertDetailRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getExpertDetail(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 조회
     * @param {MentoringApiGetExpertListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getExpertList(requestParameters: MentoringApiGetExpertListRequest = {}, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getExpertList(requestParameters.expertType, requestParameters.weeklyDate, requestParameters.timeSet, requestParameters.collegeIds, requestParameters.getIntoCollegeIds, requestParameters.majors, requestParameters.counselorThemes, requestParameters.tutorTypes, requestParameters.mentorAdvices, requestParameters.memberId, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 예약 사전 질문
     * @param {MentoringApiGetExpertRequestQuestionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getExpertRequestQuestion(requestParameters: MentoringApiGetExpertRequestQuestionRequest = {}, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getExpertRequestQuestion(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getExpertSummaryinfo(options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getExpertSummaryinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 목록 조회
     * @param {MentoringApiGetMentoringBookingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getMentoringBookingList(requestParameters: MentoringApiGetMentoringBookingListRequest = {}, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getMentoringBookingList(requestParameters.bookingState, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약을 위한 기본 조회
     * @param {MentoringApiGetProgramBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getProgramBooking(requestParameters: MentoringApiGetProgramBookingRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getProgramBooking(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 상세조회
     * @param {MentoringApiGetProgramBookingDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getProgramBookingDetail(requestParameters: MentoringApiGetProgramBookingDetailRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getProgramBookingDetail(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 목록 조회
     * @param {MentoringApiGetProgramListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getProgramList(requestParameters: MentoringApiGetProgramListRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getProgramList(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 리뷰 목록 조회
     * @param {MentoringApiGetReviewListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getReviewList(requestParameters: MentoringApiGetReviewListRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getReviewList(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 Course 목록 조회
     * @param {MentoringApiGetRoadmapCourseListByGradeAndSemester1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getRoadmapCourseListByGradeAndSemester1(requestParameters: MentoringApiGetRoadmapCourseListByGradeAndSemester1Request, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getRoadmapCourseListByGradeAndSemester1(requestParameters.bookingId, requestParameters.grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ongoing 예약 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getStudentOngoingMentoringBookingList(options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getStudentOngoingMentoringBookingList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 로드맵 조회
     * @param {MentoringApiGetStudentRoadmapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getStudentRoadmap(requestParameters: MentoringApiGetStudentRoadmapRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getStudentRoadmap(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 로드맵 Activity 목록 조회
     * @param {MentoringApiGetStudentRoadmapActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public getStudentRoadmapActivities(requestParameters: MentoringApiGetStudentRoadmapActivitiesRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).getStudentRoadmapActivities(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약 취소
     * @param {MentoringApiSetCancelBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public setCancelBooking(requestParameters: MentoringApiSetCancelBookingRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).setCancelBooking(requestParameters.reqBookingCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약
     * @param {MentoringApiSetProgramBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public setProgramBooking(requestParameters: MentoringApiSetProgramBookingRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).setProgramBooking(requestParameters.reqProgramBooking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 리서치 페이퍼 요청 유저 정보 저장
     * @param {MentoringApiSetResearchPaperMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public setResearchPaperMember(requestParameters: MentoringApiSetResearchPaperMemberRequest = {}, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).setResearchPaperMember(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 리뷰 입력
     * @param {MentoringApiSetReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public setReview(requestParameters: MentoringApiSetReviewRequest, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).setReview(requestParameters.reqProgramReviewDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 크로노피 콜백
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public test1(options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).test1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 파일 업로드
     * @param {MentoringApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentoringApi
     */
    public uploadFile(requestParameters: MentoringApiUploadFileRequest = {}, options?: AxiosRequestConfig) {
        return MentoringApiFp(this.configuration).uploadFile(requestParameters.uploadFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyPondApi - axios parameter creator
 * @export
 */
export const MyPondApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary future list 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFutureList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/future-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Linked parent/guardian 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkStatusInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/link-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary linked member info 조회(부모용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/roadmap/linked-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary my pond 전체 데이터 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPond: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary college major List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPondCollegeMajorList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/college/major`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the pond step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStep: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로필 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 로드맵 조회
         * @param {number} searchMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmap: async (searchMemberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchMemberId' is not null or undefined
            assertParamExists('getRoadmap', 'searchMemberId', searchMemberId)
            const localVarPath = `/mypond/roadmap/{searchMemberId}`
                .replace(`{${"searchMemberId"}}`, encodeURIComponent(String(searchMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary linked account 요청
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkMember: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('linkMember', 'email', email)
            const localVarPath = `/mypond/link-info/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 프로필 업데이트
         * @param {ReqExpertProfile} reqExpertProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpertProfile: async (reqExpertProfile: ReqExpertProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqExpertProfile' is not null or undefined
            assertParamExists('updateExpertProfile', 'reqExpertProfile', reqExpertProfile)
            const localVarPath = `/mypond/profile/update/expert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqExpertProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update link type
         * @param {number} linkId 
         * @param {'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL'} linkType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkedInfo: async (linkId: number, linkType: 'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('updateLinkedInfo', 'linkId', linkId)
            // verify required parameter 'linkType' is not null or undefined
            assertParamExists('updateLinkedInfo', 'linkType', linkType)
            const localVarPath = `/mypond/link-info/{linkId}`
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (linkType !== undefined) {
                localVarQueryParameter['linkType'] = linkType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 부모 프로필 업데이트
         * @param {ReqParentProfile} reqParentProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParentProfile: async (reqParentProfile: ReqParentProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqParentProfile' is not null or undefined
            assertParamExists('updateParentProfile', 'reqParentProfile', reqParentProfile)
            const localVarPath = `/mypond/profile/update/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqParentProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 프로필 업데이트
         * @param {ReqStudentProfile} reqStudentProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentProfile: async (reqStudentProfile: ReqStudentProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqStudentProfile' is not null or undefined
            assertParamExists('updateStudentProfile', 'reqStudentProfile', reqStudentProfile)
            const localVarPath = `/mypond/profile/update/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqStudentProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로필 사진 업로드
         * @param {File} [profileImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage: async (profileImage?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mypond/profile/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (profileImage !== undefined) { 
                localVarFormParams.append('profileImage', profileImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyPondApi - functional programming interface
 * @export
 */
export const MyPondApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyPondApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary future list 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFutureList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResFutureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFutureList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Linked parent/guardian 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkStatusInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResLinkStatusInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkStatusInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary linked member info 조회(부모용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkedInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkedInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary my pond 전체 데이터 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPond(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMyPond>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyPond(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary college major List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPondCollegeMajorList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: ResMajorList; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyPondCollegeMajorList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the pond step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyStep(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResStepInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyStep(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로필 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 로드맵 조회
         * @param {number} searchMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmap(searchMemberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmap(searchMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary linked account 요청
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkMember(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkMember(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 프로필 업데이트
         * @param {ReqExpertProfile} reqExpertProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExpertProfile(reqExpertProfile: ReqExpertProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExpertProfile(reqExpertProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update link type
         * @param {number} linkId 
         * @param {'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL'} linkType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkedInfo(linkId: number, linkType: 'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResLinkStatusInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkedInfo(linkId, linkType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 부모 프로필 업데이트
         * @param {ReqParentProfile} reqParentProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParentProfile(reqParentProfile: ReqParentProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParentProfile(reqParentProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 프로필 업데이트
         * @param {ReqStudentProfile} reqStudentProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentProfile(reqStudentProfile: ReqStudentProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudentProfile(reqStudentProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로필 사진 업로드
         * @param {File} [profileImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfileImage(profileImage?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfileImage(profileImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyPondApi - factory interface
 * @export
 */
export const MyPondApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyPondApiFp(configuration)
    return {
        /**
         * 
         * @summary future list 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFutureList(options?: AxiosRequestConfig): AxiosPromise<ResFutureList> {
            return localVarFp.getFutureList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Linked parent/guardian 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkStatusInfo(options?: AxiosRequestConfig): AxiosPromise<Array<ResLinkStatusInfo>> {
            return localVarFp.getLinkStatusInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary linked member info 조회(부모용)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedInfo(options?: AxiosRequestConfig): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.getLinkedInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary my pond 전체 데이터 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPond(options?: AxiosRequestConfig): AxiosPromise<ResMyPond> {
            return localVarFp.getMyPond(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary college major List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPondCollegeMajorList(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: ResMajorList; }> {
            return localVarFp.getMyPondCollegeMajorList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the pond step
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStep(options?: AxiosRequestConfig): AxiosPromise<ResStepInfo> {
            return localVarFp.getMyStep(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로필 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: AxiosRequestConfig): AxiosPromise<ResProfile> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 로드맵 조회
         * @param {MyPondApiGetRoadmapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmap(requestParameters: MyPondApiGetRoadmapRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapInfo> {
            return localVarFp.getRoadmap(requestParameters.searchMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary linked account 요청
         * @param {MyPondApiLinkMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkMember(requestParameters: MyPondApiLinkMemberRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.linkMember(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 프로필 업데이트
         * @param {MyPondApiUpdateExpertProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpertProfile(requestParameters: MyPondApiUpdateExpertProfileRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateExpertProfile(requestParameters.reqExpertProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update link type
         * @param {MyPondApiUpdateLinkedInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkedInfo(requestParameters: MyPondApiUpdateLinkedInfoRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResLinkStatusInfo>> {
            return localVarFp.updateLinkedInfo(requestParameters.linkId, requestParameters.linkType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 부모 프로필 업데이트
         * @param {MyPondApiUpdateParentProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParentProfile(requestParameters: MyPondApiUpdateParentProfileRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateParentProfile(requestParameters.reqParentProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 프로필 업데이트
         * @param {MyPondApiUpdateStudentProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentProfile(requestParameters: MyPondApiUpdateStudentProfileRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateStudentProfile(requestParameters.reqStudentProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로필 사진 업로드
         * @param {MyPondApiUploadProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage(requestParameters: MyPondApiUploadProfileImageRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.uploadProfileImage(requestParameters.profileImage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRoadmap operation in MyPondApi.
 * @export
 * @interface MyPondApiGetRoadmapRequest
 */
export interface MyPondApiGetRoadmapRequest {
    /**
     * 
     * @type {number}
     * @memberof MyPondApiGetRoadmap
     */
    readonly searchMemberId: number
}

/**
 * Request parameters for linkMember operation in MyPondApi.
 * @export
 * @interface MyPondApiLinkMemberRequest
 */
export interface MyPondApiLinkMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof MyPondApiLinkMember
     */
    readonly email: string
}

/**
 * Request parameters for updateExpertProfile operation in MyPondApi.
 * @export
 * @interface MyPondApiUpdateExpertProfileRequest
 */
export interface MyPondApiUpdateExpertProfileRequest {
    /**
     * 
     * @type {ReqExpertProfile}
     * @memberof MyPondApiUpdateExpertProfile
     */
    readonly reqExpertProfile: ReqExpertProfile
}

/**
 * Request parameters for updateLinkedInfo operation in MyPondApi.
 * @export
 * @interface MyPondApiUpdateLinkedInfoRequest
 */
export interface MyPondApiUpdateLinkedInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof MyPondApiUpdateLinkedInfo
     */
    readonly linkId: number

    /**
     * 
     * @type {'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL'}
     * @memberof MyPondApiUpdateLinkedInfo
     */
    readonly linkType: 'REQUEST' | 'UNLINKING' | 'ACCEPT' | 'REJECT' | 'CANCEL'
}

/**
 * Request parameters for updateParentProfile operation in MyPondApi.
 * @export
 * @interface MyPondApiUpdateParentProfileRequest
 */
export interface MyPondApiUpdateParentProfileRequest {
    /**
     * 
     * @type {ReqParentProfile}
     * @memberof MyPondApiUpdateParentProfile
     */
    readonly reqParentProfile: ReqParentProfile
}

/**
 * Request parameters for updateStudentProfile operation in MyPondApi.
 * @export
 * @interface MyPondApiUpdateStudentProfileRequest
 */
export interface MyPondApiUpdateStudentProfileRequest {
    /**
     * 
     * @type {ReqStudentProfile}
     * @memberof MyPondApiUpdateStudentProfile
     */
    readonly reqStudentProfile: ReqStudentProfile
}

/**
 * Request parameters for uploadProfileImage operation in MyPondApi.
 * @export
 * @interface MyPondApiUploadProfileImageRequest
 */
export interface MyPondApiUploadProfileImageRequest {
    /**
     * 
     * @type {File}
     * @memberof MyPondApiUploadProfileImage
     */
    readonly profileImage?: File
}

/**
 * MyPondApi - object-oriented interface
 * @export
 * @class MyPondApi
 * @extends {BaseAPI}
 */
export class MyPondApi extends BaseAPI {
    /**
     * 
     * @summary future list 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getFutureList(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getFutureList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Linked parent/guardian 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getLinkStatusInfo(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getLinkStatusInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary linked member info 조회(부모용)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getLinkedInfo(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getLinkedInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary my pond 전체 데이터 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getMyPond(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getMyPond(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary college major List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getMyPondCollegeMajorList(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getMyPondCollegeMajorList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the pond step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getMyStep(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getMyStep(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로필 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 로드맵 조회
     * @param {MyPondApiGetRoadmapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public getRoadmap(requestParameters: MyPondApiGetRoadmapRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).getRoadmap(requestParameters.searchMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary linked account 요청
     * @param {MyPondApiLinkMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public linkMember(requestParameters: MyPondApiLinkMemberRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).linkMember(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 프로필 업데이트
     * @param {MyPondApiUpdateExpertProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public updateExpertProfile(requestParameters: MyPondApiUpdateExpertProfileRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).updateExpertProfile(requestParameters.reqExpertProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update link type
     * @param {MyPondApiUpdateLinkedInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public updateLinkedInfo(requestParameters: MyPondApiUpdateLinkedInfoRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).updateLinkedInfo(requestParameters.linkId, requestParameters.linkType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 부모 프로필 업데이트
     * @param {MyPondApiUpdateParentProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public updateParentProfile(requestParameters: MyPondApiUpdateParentProfileRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).updateParentProfile(requestParameters.reqParentProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 프로필 업데이트
     * @param {MyPondApiUpdateStudentProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public updateStudentProfile(requestParameters: MyPondApiUpdateStudentProfileRequest, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).updateStudentProfile(requestParameters.reqStudentProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로필 사진 업로드
     * @param {MyPondApiUploadProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPondApi
     */
    public uploadProfileImage(requestParameters: MyPondApiUploadProfileImageRequest = {}, options?: AxiosRequestConfig) {
        return MyPondApiFp(this.configuration).uploadProfileImage(requestParameters.profileImage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpenDataApi - axios parameter creator
 * @export
 */
export const OpenDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {string} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDeviceRedirectFromEmail: async (bookingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('checkDeviceRedirectFromEmail', 'bookingId', bookingId)
            const localVarPath = `/open/booking/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bookingId !== undefined) {
                localVarQueryParameter['bookingId'] = bookingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {number} recommendedActivityId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportActivity1: async (recommendedActivityId: number, memberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendedActivityId' is not null or undefined
            assertParamExists('createAnalysisReportActivity1', 'recommendedActivityId', recommendedActivityId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('createAnalysisReportActivity1', 'memberId', memberId)
            const localVarPath = `/open/add/recommended/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (recommendedActivityId !== undefined) {
                localVarQueryParameter['recommendedActivityId'] = recommendedActivityId;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary paymentRedirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventPaymentRedirect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/payment/mentoring/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mentor verification 상태 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geVerificationStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/expert/verification/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCancelReason: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/programs/cancelreason/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingExpertCancelReason: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/programs/cancelreason/expert/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 등급별 ACT 계산
         * @param {number} userAct 
         * @param {number} tierRank 
         * @param {number} collegeAct25 
         * @param {number} collegeAct75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeActCalc: async (userAct: number, tierRank: number, collegeAct25: number, collegeAct75: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAct' is not null or undefined
            assertParamExists('getCollegeActCalc', 'userAct', userAct)
            // verify required parameter 'tierRank' is not null or undefined
            assertParamExists('getCollegeActCalc', 'tierRank', tierRank)
            // verify required parameter 'collegeAct25' is not null or undefined
            assertParamExists('getCollegeActCalc', 'collegeAct25', collegeAct25)
            // verify required parameter 'collegeAct75' is not null or undefined
            assertParamExists('getCollegeActCalc', 'collegeAct75', collegeAct75)
            const localVarPath = `/open/college/act-calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAct !== undefined) {
                localVarQueryParameter['userAct'] = userAct;
            }

            if (tierRank !== undefined) {
                localVarQueryParameter['tierRank'] = tierRank;
            }

            if (collegeAct25 !== undefined) {
                localVarQueryParameter['collegeAct25'] = collegeAct25;
            }

            if (collegeAct75 !== undefined) {
                localVarQueryParameter['collegeAct75'] = collegeAct75;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 등급별 GPA 계산
         * @param {number} userGpa 
         * @param {number} tierRank 
         * @param {number} collegeGpa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeGpaCalc: async (userGpa: number, tierRank: number, collegeGpa: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGpa' is not null or undefined
            assertParamExists('getCollegeGpaCalc', 'userGpa', userGpa)
            // verify required parameter 'tierRank' is not null or undefined
            assertParamExists('getCollegeGpaCalc', 'tierRank', tierRank)
            // verify required parameter 'collegeGpa' is not null or undefined
            assertParamExists('getCollegeGpaCalc', 'collegeGpa', collegeGpa)
            const localVarPath = `/open/college/gpa-calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGpa !== undefined) {
                localVarQueryParameter['userGpa'] = userGpa;
            }

            if (tierRank !== undefined) {
                localVarQueryParameter['tierRank'] = tierRank;
            }

            if (collegeGpa !== undefined) {
                localVarQueryParameter['collegeGpa'] = collegeGpa;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 등급별 SAT 계산
         * @param {number} userSat 
         * @param {number} tierRank 
         * @param {number} collegeSat25 
         * @param {number} collegeSat75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSatCalc: async (userSat: number, tierRank: number, collegeSat25: number, collegeSat75: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSat' is not null or undefined
            assertParamExists('getCollegeSatCalc', 'userSat', userSat)
            // verify required parameter 'tierRank' is not null or undefined
            assertParamExists('getCollegeSatCalc', 'tierRank', tierRank)
            // verify required parameter 'collegeSat25' is not null or undefined
            assertParamExists('getCollegeSatCalc', 'collegeSat25', collegeSat25)
            // verify required parameter 'collegeSat75' is not null or undefined
            assertParamExists('getCollegeSatCalc', 'collegeSat75', collegeSat75)
            const localVarPath = `/open/college/sat-calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userSat !== undefined) {
                localVarQueryParameter['userSat'] = userSat;
            }

            if (tierRank !== undefined) {
                localVarQueryParameter['tierRank'] = tierRank;
            }

            if (collegeSat25 !== undefined) {
                localVarQueryParameter['collegeSat25'] = collegeSat25;
            }

            if (collegeSat75 !== undefined) {
                localVarQueryParameter['collegeSat75'] = collegeSat75;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 서베이 결과 계산
         * @param {ReqMemberCollegeSurvey} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyCalc: async (req: ReqMemberCollegeSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getCollegeSurveyCalc', 'req', req)
            const localVarPath = `/open/college/survey-calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 전)
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResult: async (reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqMemberCollegeSurvey' is not null or undefined
            assertParamExists('getCollegeSurveyResult', 'reqMemberCollegeSurvey', reqMemberCollegeSurvey)
            const localVarPath = `/open/college/survey-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqMemberCollegeSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 등급 구분
         * @param {number} ipedsCollegeId 
         * @param {string} collegeTitle 
         * @param {number} acceptRate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeTierCalc: async (ipedsCollegeId: number, collegeTitle: string, acceptRate: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ipedsCollegeId' is not null or undefined
            assertParamExists('getCollegeTierCalc', 'ipedsCollegeId', ipedsCollegeId)
            // verify required parameter 'collegeTitle' is not null or undefined
            assertParamExists('getCollegeTierCalc', 'collegeTitle', collegeTitle)
            // verify required parameter 'acceptRate' is not null or undefined
            assertParamExists('getCollegeTierCalc', 'acceptRate', acceptRate)
            const localVarPath = `/open/college/tier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ipedsCollegeId !== undefined) {
                localVarQueryParameter['ipedsCollegeId'] = ipedsCollegeId;
            }

            if (collegeTitle !== undefined) {
                localVarQueryParameter['collegeTitle'] = collegeTitle;
            }

            if (acceptRate !== undefined) {
                localVarQueryParameter['acceptRate'] = acceptRate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary counselors theme 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounselorsThemeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/counselors/theme/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/programs/degree/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDuration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/programs/duration/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field of Study 항목
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldStudy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/field-study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageLevelList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/languagelevel/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/language/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mentor advice 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentorAdvice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/mentors/advice/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenExpertDetail: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getOpenExpertDetail', 'expertId', expertId)
            const localVarPath = `/open/expert/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 인증없이 전문가 조회
         * @param {string} [expertType] 
         * @param {Array<string>} [weeklyDate] 
         * @param {Array<string>} [timeSet] 
         * @param {Array<string>} [collegeIds] 
         * @param {Array<string>} [getIntoCollegeIds] 
         * @param {Array<string>} [majors] 
         * @param {Array<number>} [counselorThemes] 
         * @param {Array<number>} [tutorTypes] 
         * @param {Array<number>} [mentorAdvices] 
         * @param {number} [memberId] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenExpertList: async (expertType?: string, weeklyDate?: Array<string>, timeSet?: Array<string>, collegeIds?: Array<string>, getIntoCollegeIds?: Array<string>, majors?: Array<string>, counselorThemes?: Array<number>, tutorTypes?: Array<number>, mentorAdvices?: Array<number>, memberId?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/expert/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertType !== undefined) {
                localVarQueryParameter['expertType'] = expertType;
            }

            if (weeklyDate) {
                localVarQueryParameter['weeklyDate'] = weeklyDate;
            }

            if (timeSet) {
                localVarQueryParameter['timeSet'] = timeSet;
            }

            if (collegeIds) {
                localVarQueryParameter['collegeIds'] = collegeIds;
            }

            if (getIntoCollegeIds) {
                localVarQueryParameter['getIntoCollegeIds'] = getIntoCollegeIds;
            }

            if (majors) {
                localVarQueryParameter['majors'] = majors;
            }

            if (counselorThemes) {
                localVarQueryParameter['counselorThemes'] = counselorThemes;
            }

            if (tutorTypes) {
                localVarQueryParameter['tutorTypes'] = tutorTypes;
            }

            if (mentorAdvices) {
                localVarQueryParameter['mentorAdvices'] = mentorAdvices;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenProgramList: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getOpenProgramList', 'expertId', expertId)
            const localVarPath = `/open/expert/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenReviewList: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('getOpenReviewList', 'expertId', expertId)
            const localVarPath = `/open/expert/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterList1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/semester-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 타임존 정보
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZoneList: async (title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/timezone/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary tutor type 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorTypeCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/tutors/type/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unweighted GPA 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnweightedGpaList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/unweighted-gpa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 유저 예상 합격률 계산
         * @param {number} userGpa 
         * @param {number} userSat 
         * @param {number} userAct 
         * @param {ResCollegeNicheIpeds} college 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserChancesRateCalc: async (userGpa: number, userSat: number, userAct: number, college: ResCollegeNicheIpeds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGpa' is not null or undefined
            assertParamExists('getUserChancesRateCalc', 'userGpa', userGpa)
            // verify required parameter 'userSat' is not null or undefined
            assertParamExists('getUserChancesRateCalc', 'userSat', userSat)
            // verify required parameter 'userAct' is not null or undefined
            assertParamExists('getUserChancesRateCalc', 'userAct', userAct)
            // verify required parameter 'college' is not null or undefined
            assertParamExists('getUserChancesRateCalc', 'college', college)
            const localVarPath = `/open/college/user-chances-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGpa !== undefined) {
                localVarQueryParameter['userGpa'] = userGpa;
            }

            if (userSat !== undefined) {
                localVarQueryParameter['userSat'] = userSat;
            }

            if (userAct !== undefined) {
                localVarQueryParameter['userAct'] = userAct;
            }

            if (college !== undefined) {
                localVarQueryParameter['college'] = college;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingCareer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/landing/career/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary landing page college
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingCollege: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/landing/college`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingExtracurriculars: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/landing/extracurriculars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingSignIn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/landing/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingSignUp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/landing/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary major category 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        majorCategoryList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/major/category/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전공 조회
         * @param {string} searchType 검색 구분(survey, open, college, career)
         * @param {string} [careerId] career 식별번호(searchType 이 career 일 경우 필수)
         * @param {number} [collegeId] college 식별번호(searchType 이 college 일 경우 필수)
         * @param {string} [keyword] major 검색 키워드
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        majorList: async (searchType: string, careerId?: string, collegeId?: number, keyword?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchType' is not null or undefined
            assertParamExists('majorList', 'searchType', searchType)
            const localVarPath = `/open/majorSimpleList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (careerId !== undefined) {
                localVarQueryParameter['careerId'] = careerId;
            }

            if (collegeId !== undefined) {
                localVarQueryParameter['collegeId'] = collegeId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {string} [keyword] 
         * @param {Array<number>} [listingType] 
         * @param {Array<number>} [ecType] 
         * @param {Array<number>} [strengthPoint] 
         * @param {Array<number>} [majors] 
         * @param {Array<string>} [addressState] 
         * @param {boolean} [isOnline] 
         * @param {string} [onlyPremium] 
         * @param {string} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [memberId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityList: async (keyword?: string, listingType?: Array<number>, ecType?: Array<number>, strengthPoint?: Array<number>, majors?: Array<number>, addressState?: Array<string>, isOnline?: boolean, onlyPremium?: string, sort?: string, memberId?: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/activity/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (listingType) {
                localVarQueryParameter['listingType'] = listingType;
            }

            if (ecType) {
                localVarQueryParameter['ecType'] = ecType;
            }

            if (strengthPoint) {
                localVarQueryParameter['strengthPoint'] = strengthPoint;
            }

            if (majors) {
                localVarQueryParameter['majors'] = majors;
            }

            if (addressState) {
                localVarQueryParameter['addressState'] = addressState;
            }

            if (isOnline !== undefined) {
                localVarQueryParameter['isOnline'] = isOnline;
            }

            if (onlyPremium !== undefined) {
                localVarQueryParameter['onlyPremium'] = onlyPremium;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Strength 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityStrengthList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/strength`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Strength Point 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityStrengthPointList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/strength-point`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/activity-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityTypeSimpleList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/activity-type/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AP Test Subject 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAptestSubjectList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/aptest-subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Award Level 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAwardLevelList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/award-level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 커리어 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCareerList: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/career`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 대학 조회
         * @param {string} [title] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCollegeNewList: async (title?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/college`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 도시 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCountryList: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Course 목록 조회
         * @param {number} [subjectCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCourseCategoryList: async (subjectCategoryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/course-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subjectCategoryId !== undefined) {
                localVarQueryParameter['subjectCategoryId'] = subjectCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 스킬 조회
         * @param {'MENTOR' | 'TUTOR' | 'COUNSELOR'} [expertType] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicExpertSkillList: async (expertType?: 'MENTOR' | 'TUTOR' | 'COUNSELOR', page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/expert/skill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertType !== undefined) {
                localVarQueryParameter['expertType'] = expertType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grade 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGradeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/grade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 고등학교 조회
         * @param {string} [schoolName] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [address] 
         * @param {string} [zip] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicHighSchoolList: async (schoolName?: string, city?: string, state?: string, address?: string, zip?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/high-school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolName !== undefined) {
                localVarQueryParameter['schoolName'] = schoolName;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (zip !== undefined) {
                localVarQueryParameter['zip'] = zip;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전공 조회
         * @param {string} searchType 검색 구분(survey, open, college, career)
         * @param {string} [careerId] career 식별번호(searchType 이 career 일 경우 필수)
         * @param {number} [collegeId] college 식별번호(searchType 이 college 일 경우 필수)
         * @param {string} [keyword] major 검색 키워드
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicMajorList: async (searchType: string, careerId?: string, collegeId?: number, keyword?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchType' is not null or undefined
            assertParamExists('publicMajorList', 'searchType', searchType)
            const localVarPath = `/open/major`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (careerId !== undefined) {
                localVarQueryParameter['careerId'] = careerId;
            }

            if (collegeId !== undefined) {
                localVarQueryParameter['collegeId'] = collegeId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary region별 지역 목록
         * @param {string} regionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicRegionStateList: async (regionName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('publicRegionStateList', 'regionName', regionName)
            const localVarPath = `/open/region-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionName !== undefined) {
                localVarQueryParameter['regionName'] = regionName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 지역 조회
         * @param {number} [countyId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicStateList: async (countyId?: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countyId !== undefined) {
                localVarQueryParameter['countyId'] = countyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subject Category 목록 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSubjectCategoryList: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/subject-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Target GPA 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicTargetGPAList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/target-gpa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicTestTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/test-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StripeAccountLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeAccountLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary analysis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAnalyticsEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary research paper request info
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerResearchPaperEmail: async (memberId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/open/research-paper`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {number} memberId 
         * @param {string} lastLinkedProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoomProfileCheck: async (memberId: number, lastLinkedProfileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('zoomProfileCheck', 'memberId', memberId)
            // verify required parameter 'lastLinkedProfileId' is not null or undefined
            assertParamExists('zoomProfileCheck', 'lastLinkedProfileId', lastLinkedProfileId)
            const localVarPath = `/open/zoom/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (lastLinkedProfileId !== undefined) {
                localVarQueryParameter['last_linked_profile_id'] = lastLinkedProfileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenDataApi - functional programming interface
 * @export
 */
export const OpenDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {string} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDeviceRedirectFromEmail(bookingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDeviceRedirectFromEmail(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {number} recommendedActivityId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysisReportActivity1(recommendedActivityId: number, memberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnalysisReportActivity1(recommendedActivityId, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary paymentRedirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventPaymentRedirect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventPaymentRedirect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mentor verification 상태 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geVerificationStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerificationStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geVerificationStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingCancelReason(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingCancelReason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingCancelReason(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingExpertCancelReason(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingCancelReason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingExpertCancelReason(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 등급별 ACT 계산
         * @param {number} userAct 
         * @param {number} tierRank 
         * @param {number} collegeAct25 
         * @param {number} collegeAct75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeActCalc(userAct: number, tierRank: number, collegeAct25: number, collegeAct75: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeActCalc(userAct, tierRank, collegeAct25, collegeAct75, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 등급별 GPA 계산
         * @param {number} userGpa 
         * @param {number} tierRank 
         * @param {number} collegeGpa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeGpaCalc(userGpa: number, tierRank: number, collegeGpa: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeGpaCalc(userGpa, tierRank, collegeGpa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 등급별 SAT 계산
         * @param {number} userSat 
         * @param {number} tierRank 
         * @param {number} collegeSat25 
         * @param {number} collegeSat75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeSatCalc(userSat: number, tierRank: number, collegeSat25: number, collegeSat75: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeSatCalc(userSat, tierRank, collegeSat25, collegeSat75, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 서베이 결과 계산
         * @param {ReqMemberCollegeSurvey} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeSurveyCalc(req: ReqMemberCollegeSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CollegeSurveyCardData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeSurveyCalc(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 전)
         * @param {ReqMemberCollegeSurvey} reqMemberCollegeSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeSurveyResult(reqMemberCollegeSurvey: ReqMemberCollegeSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCollegeSurveyResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeSurveyResult(reqMemberCollegeSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 등급 구분
         * @param {number} ipedsCollegeId 
         * @param {string} collegeTitle 
         * @param {number} acceptRate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollegeTierCalc(ipedsCollegeId: number, collegeTitle: string, acceptRate: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCollegeTier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollegeTierCalc(ipedsCollegeId, collegeTitle, acceptRate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary counselors theme 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCounselorsThemeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselorsTheme>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCounselorsThemeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDegree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationDegree>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDegree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDuration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Duration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDuration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field of Study 항목
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldStudy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResFieldStudy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldStudy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguageLevelList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguageLevelList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguageList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Language>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mentor advice 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMentorAdvice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MentorAdvice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMentorAdvice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenExpertDetail(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertExperience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenExpertDetail(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 인증없이 전문가 조회
         * @param {string} [expertType] 
         * @param {Array<string>} [weeklyDate] 
         * @param {Array<string>} [timeSet] 
         * @param {Array<string>} [collegeIds] 
         * @param {Array<string>} [getIntoCollegeIds] 
         * @param {Array<string>} [majors] 
         * @param {Array<number>} [counselorThemes] 
         * @param {Array<number>} [tutorTypes] 
         * @param {Array<number>} [mentorAdvices] 
         * @param {number} [memberId] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenExpertList(expertType?: string, weeklyDate?: Array<string>, timeSet?: Array<string>, collegeIds?: Array<string>, getIntoCollegeIds?: Array<string>, majors?: Array<string>, counselorThemes?: Array<number>, tutorTypes?: Array<number>, mentorAdvices?: Array<number>, memberId?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenExpertList(expertType, weeklyDate, timeSet, collegeIds, getIntoCollegeIds, majors, counselorThemes, tutorTypes, mentorAdvices, memberId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenProgramList(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenProgramList(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {number} expertId expertId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenReviewList(expertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramReviewList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenReviewList(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemesterList1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResSemesterList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemesterList1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 타임존 정보
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeZoneList(title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberTimeZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeZoneList(title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary tutor type 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTutorTypeCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutorTypeCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTutorTypeCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unweighted GPA 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnweightedGpaList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResUnweightedGpa>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnweightedGpaList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 유저 예상 합격률 계산
         * @param {number} userGpa 
         * @param {number} userSat 
         * @param {number} userAct 
         * @param {ResCollegeNicheIpeds} college 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserChancesRateCalc(userGpa: number, userSat: number, userAct: number, college: ResCollegeNicheIpeds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResUserChancesInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserChancesRateCalc(userGpa, userSat, userAct, college, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingCareer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingCareer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary landing page college
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingCollege(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingCollege(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingExtracurriculars(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingExtracurriculars(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingSignIn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingSignIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingSignUp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingSignUp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary major category 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async majorCategoryList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResMajorCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.majorCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전공 조회
         * @param {string} searchType 검색 구분(survey, open, college, career)
         * @param {string} [careerId] career 식별번호(searchType 이 career 일 경우 필수)
         * @param {number} [collegeId] college 식별번호(searchType 이 college 일 경우 필수)
         * @param {string} [keyword] major 검색 키워드
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async majorList(searchType: string, careerId?: string, collegeId?: number, keyword?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCollegeMajorGradList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.majorList(searchType, careerId, collegeId, keyword, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {string} [keyword] 
         * @param {Array<number>} [listingType] 
         * @param {Array<number>} [ecType] 
         * @param {Array<number>} [strengthPoint] 
         * @param {Array<number>} [majors] 
         * @param {Array<string>} [addressState] 
         * @param {boolean} [isOnline] 
         * @param {string} [onlyPremium] 
         * @param {string} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [memberId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityList(keyword?: string, listingType?: Array<number>, ecType?: Array<number>, strengthPoint?: Array<number>, majors?: Array<number>, addressState?: Array<string>, isOnline?: boolean, onlyPremium?: string, sort?: string, memberId?: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRecommendedActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityList(keyword, listingType, ecType, strengthPoint, majors, addressState, isOnline, onlyPremium, sort, memberId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Strength 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityStrengthList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResStrength>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityStrengthList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Strength Point 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityStrengthPointList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResStrengthPoint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityStrengthPointList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResActivityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicActivityTypeSimpleList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicActivityTypeSimpleList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AP Test Subject 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicAptestSubjectList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAptestSubject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicAptestSubjectList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Award Level 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicAwardLevelList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAwardLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicAwardLevelList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 커리어 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCareerList(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCareer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCareerList(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 대학 조회
         * @param {string} [title] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCollegeNewList(title?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCollege>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCollegeNewList(title, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 도시 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCountryList(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResCountry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCountryList(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Course 목록 조회
         * @param {number} [subjectCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCourseCategoryList(subjectCategoryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResCourseCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCourseCategoryList(subjectCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 스킬 조회
         * @param {'MENTOR' | 'TUTOR' | 'COUNSELOR'} [expertType] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicExpertSkillList(expertType?: 'MENTOR' | 'TUTOR' | 'COUNSELOR', page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResExpertSkill>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicExpertSkillList(expertType, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grade 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGradeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGrade>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGradeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 고등학교 조회
         * @param {string} [schoolName] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [address] 
         * @param {string} [zip] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicHighSchoolList(schoolName?: string, city?: string, state?: string, address?: string, zip?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResHighSchool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicHighSchoolList(schoolName, city, state, address, zip, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전공 조회
         * @param {string} searchType 검색 구분(survey, open, college, career)
         * @param {string} [careerId] career 식별번호(searchType 이 career 일 경우 필수)
         * @param {number} [collegeId] college 식별번호(searchType 이 college 일 경우 필수)
         * @param {string} [keyword] major 검색 키워드
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicMajorList(searchType: string, careerId?: string, collegeId?: number, keyword?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResMajor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicMajorList(searchType, careerId, collegeId, keyword, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary region별 지역 목록
         * @param {string} regionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicRegionStateList(regionName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRegionState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicRegionStateList(regionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 지역 조회
         * @param {number} [countyId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicStateList(countyId?: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicStateList(countyId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subject Category 목록 조회
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicSubjectCategoryList(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResSubjectCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicSubjectCategoryList(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Target GPA 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicTargetGPAList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResTargetGPA>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicTargetGPAList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicTestTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResTestType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicTestTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StripeAccountLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeAccountLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeAccountLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary analysis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerAnalyticsEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerAnalyticsEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary research paper request info
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerResearchPaperEmail(memberId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerResearchPaperEmail(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {number} memberId 
         * @param {string} lastLinkedProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoomProfileCheck(memberId: number, lastLinkedProfileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoomProfileCheck(memberId, lastLinkedProfileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpenDataApi - factory interface
 * @export
 */
export const OpenDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenDataApiFp(configuration)
    return {
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {OpenDataApiCheckDeviceRedirectFromEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDeviceRedirectFromEmail(requestParameters: OpenDataApiCheckDeviceRedirectFromEmailRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.checkDeviceRedirectFromEmail(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {OpenDataApiCreateAnalysisReportActivity1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportActivity1(requestParameters: OpenDataApiCreateAnalysisReportActivity1Request, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivity> {
            return localVarFp.createAnalysisReportActivity1(requestParameters.recommendedActivityId, requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary paymentRedirect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventPaymentRedirect(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.eventPaymentRedirect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mentor verification 상태 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geVerificationStatus(options?: AxiosRequestConfig): AxiosPromise<Array<VerificationStatus>> {
            return localVarFp.geVerificationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCancelReason(options?: AxiosRequestConfig): AxiosPromise<Array<BookingCancelReason>> {
            return localVarFp.getBookingCancelReason(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약 취소 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingExpertCancelReason(options?: AxiosRequestConfig): AxiosPromise<Array<BookingCancelReason>> {
            return localVarFp.getBookingExpertCancelReason(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 등급별 ACT 계산
         * @param {OpenDataApiGetCollegeActCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeActCalc(requestParameters: OpenDataApiGetCollegeActCalcRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCollegeActCalc(requestParameters.userAct, requestParameters.tierRank, requestParameters.collegeAct25, requestParameters.collegeAct75, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 등급별 GPA 계산
         * @param {OpenDataApiGetCollegeGpaCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeGpaCalc(requestParameters: OpenDataApiGetCollegeGpaCalcRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCollegeGpaCalc(requestParameters.userGpa, requestParameters.tierRank, requestParameters.collegeGpa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 등급별 SAT 계산
         * @param {OpenDataApiGetCollegeSatCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSatCalc(requestParameters: OpenDataApiGetCollegeSatCalcRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCollegeSatCalc(requestParameters.userSat, requestParameters.tierRank, requestParameters.collegeSat25, requestParameters.collegeSat75, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 서베이 결과 계산
         * @param {OpenDataApiGetCollegeSurveyCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyCalc(requestParameters: OpenDataApiGetCollegeSurveyCalcRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CollegeSurveyCardData>> {
            return localVarFp.getCollegeSurveyCalc(requestParameters.req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 서베이 결과 조회 (로그인 전)
         * @param {OpenDataApiGetCollegeSurveyResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeSurveyResult(requestParameters: OpenDataApiGetCollegeSurveyResultRequest, options?: AxiosRequestConfig): AxiosPromise<ResCollegeSurveyResults> {
            return localVarFp.getCollegeSurveyResult(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 등급 구분
         * @param {OpenDataApiGetCollegeTierCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollegeTierCalc(requestParameters: OpenDataApiGetCollegeTierCalcRequest, options?: AxiosRequestConfig): AxiosPromise<ResCollegeTier> {
            return localVarFp.getCollegeTierCalc(requestParameters.ipedsCollegeId, requestParameters.collegeTitle, requestParameters.acceptRate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary counselors theme 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounselorsThemeList(options?: AxiosRequestConfig): AxiosPromise<Array<CounselorsTheme>> {
            return localVarFp.getCounselorsThemeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegree(options?: AxiosRequestConfig): AxiosPromise<Array<EducationDegree>> {
            return localVarFp.getDegree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mentor program duration 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDuration(options?: AxiosRequestConfig): AxiosPromise<Array<Duration>> {
            return localVarFp.getDuration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field of Study 항목
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldStudy(options?: AxiosRequestConfig): AxiosPromise<Array<ResFieldStudy>> {
            return localVarFp.getFieldStudy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageLevelList(options?: AxiosRequestConfig): AxiosPromise<Array<LanguageLevel>> {
            return localVarFp.getLanguageLevelList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 언어 정보
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageList(options?: AxiosRequestConfig): AxiosPromise<Array<Language>> {
            return localVarFp.getLanguageList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mentor advice 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentorAdvice(options?: AxiosRequestConfig): AxiosPromise<Array<MentorAdvice>> {
            return localVarFp.getMentorAdvice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 상세 조회
         * @param {OpenDataApiGetOpenExpertDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenExpertDetail(requestParameters: OpenDataApiGetOpenExpertDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ExpertExperience> {
            return localVarFp.getOpenExpertDetail(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 인증없이 전문가 조회
         * @param {OpenDataApiGetOpenExpertListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenExpertList(requestParameters: OpenDataApiGetOpenExpertListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ExpertList>> {
            return localVarFp.getOpenExpertList(requestParameters.expertType, requestParameters.weeklyDate, requestParameters.timeSet, requestParameters.collegeIds, requestParameters.getIntoCollegeIds, requestParameters.majors, requestParameters.counselorThemes, requestParameters.tutorTypes, requestParameters.mentorAdvices, requestParameters.memberId, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 목록 조회
         * @param {OpenDataApiGetOpenProgramListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenProgramList(requestParameters: OpenDataApiGetOpenProgramListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ProgramList>> {
            return localVarFp.getOpenProgramList(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 리뷰 목록 조회
         * @param {OpenDataApiGetOpenReviewListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenReviewList(requestParameters: OpenDataApiGetOpenReviewListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ProgramReviewList>> {
            return localVarFp.getOpenReviewList(requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterList1(options?: AxiosRequestConfig): AxiosPromise<Array<ResSemesterList>> {
            return localVarFp.getSemesterList1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 타임존 정보
         * @param {OpenDataApiGetTimeZoneListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZoneList(requestParameters: OpenDataApiGetTimeZoneListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MemberTimeZone>> {
            return localVarFp.getTimeZoneList(requestParameters.title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary tutor type 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorTypeCode(options?: AxiosRequestConfig): AxiosPromise<Array<TutorTypeCode>> {
            return localVarFp.getTutorTypeCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unweighted GPA 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnweightedGpaList(options?: AxiosRequestConfig): AxiosPromise<Array<ResUnweightedGpa>> {
            return localVarFp.getUnweightedGpaList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 유저 예상 합격률 계산
         * @param {OpenDataApiGetUserChancesRateCalcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserChancesRateCalc(requestParameters: OpenDataApiGetUserChancesRateCalcRequest, options?: AxiosRequestConfig): AxiosPromise<ResUserChancesInfo> {
            return localVarFp.getUserChancesRateCalc(requestParameters.userGpa, requestParameters.userSat, requestParameters.userAct, requestParameters.college, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingCareer(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.landingCareer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary landing page college
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingCollege(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.landingCollege(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary landing page extracurriculars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingExtracurriculars(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.landingExtracurriculars(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingSignIn(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.landingSignIn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary landing page sign-up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingSignUp(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.landingSignUp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary major category 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        majorCategoryList(options?: AxiosRequestConfig): AxiosPromise<Array<ResMajorCategory>> {
            return localVarFp.majorCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전공 조회
         * @param {OpenDataApiMajorListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        majorList(requestParameters: OpenDataApiMajorListRequest, options?: AxiosRequestConfig): AxiosPromise<PageResCollegeMajorGradList> {
            return localVarFp.majorList(requestParameters.searchType, requestParameters.careerId, requestParameters.collegeId, requestParameters.keyword, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {OpenDataApiPublicActivityListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityList(requestParameters: OpenDataApiPublicActivityListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageRecommendedActivity> {
            return localVarFp.publicActivityList(requestParameters.keyword, requestParameters.listingType, requestParameters.ecType, requestParameters.strengthPoint, requestParameters.majors, requestParameters.addressState, requestParameters.isOnline, requestParameters.onlyPremium, requestParameters.sort, requestParameters.memberId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Strength 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityStrengthList(options?: AxiosRequestConfig): AxiosPromise<Array<ResStrength>> {
            return localVarFp.publicActivityStrengthList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Strength Point 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityStrengthPointList(options?: AxiosRequestConfig): AxiosPromise<Array<ResStrengthPoint>> {
            return localVarFp.publicActivityStrengthPointList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityTypeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResActivityType>> {
            return localVarFp.publicActivityTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicActivityTypeSimpleList(options?: AxiosRequestConfig): AxiosPromise<Array<ActivityType>> {
            return localVarFp.publicActivityTypeSimpleList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AP Test Subject 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAptestSubjectList(options?: AxiosRequestConfig): AxiosPromise<Array<ResAptestSubject>> {
            return localVarFp.publicAptestSubjectList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Award Level 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAwardLevelList(options?: AxiosRequestConfig): AxiosPromise<Array<ResAwardLevel>> {
            return localVarFp.publicAwardLevelList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 커리어 조회
         * @param {OpenDataApiPublicCareerListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCareerList(requestParameters: OpenDataApiPublicCareerListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResCareer> {
            return localVarFp.publicCareerList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 대학 조회
         * @param {OpenDataApiPublicCollegeNewListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCollegeNewList(requestParameters: OpenDataApiPublicCollegeNewListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResCollege> {
            return localVarFp.publicCollegeNewList(requestParameters.title, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 도시 조회
         * @param {OpenDataApiPublicCountryListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCountryList(requestParameters: OpenDataApiPublicCountryListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResCountry> {
            return localVarFp.publicCountryList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Course 목록 조회
         * @param {OpenDataApiPublicCourseCategoryListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCourseCategoryList(requestParameters: OpenDataApiPublicCourseCategoryListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ResCourseCategory>> {
            return localVarFp.publicCourseCategoryList(requestParameters.subjectCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 스킬 조회
         * @param {OpenDataApiPublicExpertSkillListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicExpertSkillList(requestParameters: OpenDataApiPublicExpertSkillListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResExpertSkill> {
            return localVarFp.publicExpertSkillList(requestParameters.expertType, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grade 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGradeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResGrade>> {
            return localVarFp.publicGradeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 고등학교 조회
         * @param {OpenDataApiPublicHighSchoolListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicHighSchoolList(requestParameters: OpenDataApiPublicHighSchoolListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResHighSchool> {
            return localVarFp.publicHighSchoolList(requestParameters.schoolName, requestParameters.city, requestParameters.state, requestParameters.address, requestParameters.zip, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전공 조회
         * @param {OpenDataApiPublicMajorListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicMajorList(requestParameters: OpenDataApiPublicMajorListRequest, options?: AxiosRequestConfig): AxiosPromise<PageResMajor> {
            return localVarFp.publicMajorList(requestParameters.searchType, requestParameters.careerId, requestParameters.collegeId, requestParameters.keyword, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary region별 지역 목록
         * @param {OpenDataApiPublicRegionStateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicRegionStateList(requestParameters: OpenDataApiPublicRegionStateListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRegionState>> {
            return localVarFp.publicRegionStateList(requestParameters.regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 지역 조회
         * @param {OpenDataApiPublicStateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicStateList(requestParameters: OpenDataApiPublicStateListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResState> {
            return localVarFp.publicStateList(requestParameters.countyId, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subject Category 목록 조회
         * @param {OpenDataApiPublicSubjectCategoryListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSubjectCategoryList(requestParameters: OpenDataApiPublicSubjectCategoryListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResSubjectCategory> {
            return localVarFp.publicSubjectCategoryList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Target GPA 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicTargetGPAList(options?: AxiosRequestConfig): AxiosPromise<Array<ResTargetGPA>> {
            return localVarFp.publicTargetGPAList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Type 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicTestTypeList(options?: AxiosRequestConfig): AxiosPromise<Array<ResTestType>> {
            return localVarFp.publicTestTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StripeAccountLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeAccountLink(options?: AxiosRequestConfig): AxiosPromise<Array<ProgramList>> {
            return localVarFp.stripeAccountLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary analysis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAnalyticsEmail(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.triggerAnalyticsEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary research paper request info
         * @param {OpenDataApiTriggerResearchPaperEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerResearchPaperEmail(requestParameters: OpenDataApiTriggerResearchPaperEmailRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.triggerResearchPaperEmail(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 이메일 받을때 기기 별 분기
         * @param {OpenDataApiZoomProfileCheckRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoomProfileCheck(requestParameters: OpenDataApiZoomProfileCheckRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.zoomProfileCheck(requestParameters.memberId, requestParameters.lastLinkedProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkDeviceRedirectFromEmail operation in OpenDataApi.
 * @export
 * @interface OpenDataApiCheckDeviceRedirectFromEmailRequest
 */
export interface OpenDataApiCheckDeviceRedirectFromEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiCheckDeviceRedirectFromEmail
     */
    readonly bookingId: string
}

/**
 * Request parameters for createAnalysisReportActivity1 operation in OpenDataApi.
 * @export
 * @interface OpenDataApiCreateAnalysisReportActivity1Request
 */
export interface OpenDataApiCreateAnalysisReportActivity1Request {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiCreateAnalysisReportActivity1
     */
    readonly recommendedActivityId: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiCreateAnalysisReportActivity1
     */
    readonly memberId: number
}

/**
 * Request parameters for getCollegeActCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeActCalcRequest
 */
export interface OpenDataApiGetCollegeActCalcRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeActCalc
     */
    readonly userAct: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeActCalc
     */
    readonly tierRank: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeActCalc
     */
    readonly collegeAct25: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeActCalc
     */
    readonly collegeAct75: number
}

/**
 * Request parameters for getCollegeGpaCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeGpaCalcRequest
 */
export interface OpenDataApiGetCollegeGpaCalcRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeGpaCalc
     */
    readonly userGpa: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeGpaCalc
     */
    readonly tierRank: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeGpaCalc
     */
    readonly collegeGpa: number
}

/**
 * Request parameters for getCollegeSatCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeSatCalcRequest
 */
export interface OpenDataApiGetCollegeSatCalcRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeSatCalc
     */
    readonly userSat: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeSatCalc
     */
    readonly tierRank: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeSatCalc
     */
    readonly collegeSat25: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeSatCalc
     */
    readonly collegeSat75: number
}

/**
 * Request parameters for getCollegeSurveyCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeSurveyCalcRequest
 */
export interface OpenDataApiGetCollegeSurveyCalcRequest {
    /**
     * 
     * @type {ReqMemberCollegeSurvey}
     * @memberof OpenDataApiGetCollegeSurveyCalc
     */
    readonly req: ReqMemberCollegeSurvey
}

/**
 * Request parameters for getCollegeSurveyResult operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeSurveyResultRequest
 */
export interface OpenDataApiGetCollegeSurveyResultRequest {
    /**
     * 
     * @type {ReqMemberCollegeSurvey}
     * @memberof OpenDataApiGetCollegeSurveyResult
     */
    readonly reqMemberCollegeSurvey: ReqMemberCollegeSurvey
}

/**
 * Request parameters for getCollegeTierCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetCollegeTierCalcRequest
 */
export interface OpenDataApiGetCollegeTierCalcRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeTierCalc
     */
    readonly ipedsCollegeId: number

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiGetCollegeTierCalc
     */
    readonly collegeTitle: string

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetCollegeTierCalc
     */
    readonly acceptRate: number
}

/**
 * Request parameters for getOpenExpertDetail operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetOpenExpertDetailRequest
 */
export interface OpenDataApiGetOpenExpertDetailRequest {
    /**
     * expertId
     * @type {number}
     * @memberof OpenDataApiGetOpenExpertDetail
     */
    readonly expertId: number
}

/**
 * Request parameters for getOpenExpertList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetOpenExpertListRequest
 */
export interface OpenDataApiGetOpenExpertListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly expertType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly weeklyDate?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly timeSet?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly collegeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly getIntoCollegeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly majors?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly counselorThemes?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly tutorTypes?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly mentorAdvices?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly memberId?: number

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiGetOpenExpertList
     */
    readonly sort?: string
}

/**
 * Request parameters for getOpenProgramList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetOpenProgramListRequest
 */
export interface OpenDataApiGetOpenProgramListRequest {
    /**
     * expertId
     * @type {number}
     * @memberof OpenDataApiGetOpenProgramList
     */
    readonly expertId: number
}

/**
 * Request parameters for getOpenReviewList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetOpenReviewListRequest
 */
export interface OpenDataApiGetOpenReviewListRequest {
    /**
     * expertId
     * @type {number}
     * @memberof OpenDataApiGetOpenReviewList
     */
    readonly expertId: number
}

/**
 * Request parameters for getTimeZoneList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetTimeZoneListRequest
 */
export interface OpenDataApiGetTimeZoneListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiGetTimeZoneList
     */
    readonly title?: string
}

/**
 * Request parameters for getUserChancesRateCalc operation in OpenDataApi.
 * @export
 * @interface OpenDataApiGetUserChancesRateCalcRequest
 */
export interface OpenDataApiGetUserChancesRateCalcRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetUserChancesRateCalc
     */
    readonly userGpa: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetUserChancesRateCalc
     */
    readonly userSat: number

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiGetUserChancesRateCalc
     */
    readonly userAct: number

    /**
     * 
     * @type {ResCollegeNicheIpeds}
     * @memberof OpenDataApiGetUserChancesRateCalc
     */
    readonly college: ResCollegeNicheIpeds
}

/**
 * Request parameters for majorList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiMajorListRequest
 */
export interface OpenDataApiMajorListRequest {
    /**
     * 검색 구분(survey, open, college, career)
     * @type {string}
     * @memberof OpenDataApiMajorList
     */
    readonly searchType: string

    /**
     * career 식별번호(searchType 이 career 일 경우 필수)
     * @type {string}
     * @memberof OpenDataApiMajorList
     */
    readonly careerId?: string

    /**
     * college 식별번호(searchType 이 college 일 경우 필수)
     * @type {number}
     * @memberof OpenDataApiMajorList
     */
    readonly collegeId?: number

    /**
     * major 검색 키워드
     * @type {string}
     * @memberof OpenDataApiMajorList
     */
    readonly keyword?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiMajorList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiMajorList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiMajorList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicActivityList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicActivityListRequest
 */
export interface OpenDataApiPublicActivityListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly keyword?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly listingType?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly ecType?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly strengthPoint?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly majors?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly addressState?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly isOnline?: boolean

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly onlyPremium?: string

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {string}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly memberId?: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicActivityList
     */
    readonly size?: number
}

/**
 * Request parameters for publicCareerList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicCareerListRequest
 */
export interface OpenDataApiPublicCareerListRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicCareerList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicCareerList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicCareerList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicCollegeNewList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicCollegeNewListRequest
 */
export interface OpenDataApiPublicCollegeNewListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicCollegeNewList
     */
    readonly title?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicCollegeNewList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicCollegeNewList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicCollegeNewList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicCountryList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicCountryListRequest
 */
export interface OpenDataApiPublicCountryListRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicCountryList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicCountryList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicCountryList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicCourseCategoryList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicCourseCategoryListRequest
 */
export interface OpenDataApiPublicCourseCategoryListRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiPublicCourseCategoryList
     */
    readonly subjectCategoryId?: number
}

/**
 * Request parameters for publicExpertSkillList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicExpertSkillListRequest
 */
export interface OpenDataApiPublicExpertSkillListRequest {
    /**
     * 
     * @type {'MENTOR' | 'TUTOR' | 'COUNSELOR'}
     * @memberof OpenDataApiPublicExpertSkillList
     */
    readonly expertType?: 'MENTOR' | 'TUTOR' | 'COUNSELOR'

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicExpertSkillList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicExpertSkillList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicExpertSkillList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicHighSchoolList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicHighSchoolListRequest
 */
export interface OpenDataApiPublicHighSchoolListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly schoolName?: string

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly city?: string

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly state?: string

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly zip?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicHighSchoolList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicMajorList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicMajorListRequest
 */
export interface OpenDataApiPublicMajorListRequest {
    /**
     * 검색 구분(survey, open, college, career)
     * @type {string}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly searchType: string

    /**
     * career 식별번호(searchType 이 career 일 경우 필수)
     * @type {string}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly careerId?: string

    /**
     * college 식별번호(searchType 이 college 일 경우 필수)
     * @type {number}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly collegeId?: number

    /**
     * major 검색 키워드
     * @type {string}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly keyword?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicMajorList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicRegionStateList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicRegionStateListRequest
 */
export interface OpenDataApiPublicRegionStateListRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenDataApiPublicRegionStateList
     */
    readonly regionName: string
}

/**
 * Request parameters for publicStateList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicStateListRequest
 */
export interface OpenDataApiPublicStateListRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiPublicStateList
     */
    readonly countyId?: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicStateList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicStateList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicStateList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for publicSubjectCategoryList operation in OpenDataApi.
 * @export
 * @interface OpenDataApiPublicSubjectCategoryListRequest
 */
export interface OpenDataApiPublicSubjectCategoryListRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OpenDataApiPublicSubjectCategoryList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof OpenDataApiPublicSubjectCategoryList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof OpenDataApiPublicSubjectCategoryList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for triggerResearchPaperEmail operation in OpenDataApi.
 * @export
 * @interface OpenDataApiTriggerResearchPaperEmailRequest
 */
export interface OpenDataApiTriggerResearchPaperEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiTriggerResearchPaperEmail
     */
    readonly memberId?: number
}

/**
 * Request parameters for zoomProfileCheck operation in OpenDataApi.
 * @export
 * @interface OpenDataApiZoomProfileCheckRequest
 */
export interface OpenDataApiZoomProfileCheckRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenDataApiZoomProfileCheck
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof OpenDataApiZoomProfileCheck
     */
    readonly lastLinkedProfileId: string
}

/**
 * OpenDataApi - object-oriented interface
 * @export
 * @class OpenDataApi
 * @extends {BaseAPI}
 */
export class OpenDataApi extends BaseAPI {
    /**
     * 
     * @summary 이메일 받을때 기기 별 분기
     * @param {OpenDataApiCheckDeviceRedirectFromEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public checkDeviceRedirectFromEmail(requestParameters: OpenDataApiCheckDeviceRedirectFromEmailRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).checkDeviceRedirectFromEmail(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommended Activity 데이터로 activity 생성
     * @param {OpenDataApiCreateAnalysisReportActivity1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public createAnalysisReportActivity1(requestParameters: OpenDataApiCreateAnalysisReportActivity1Request, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).createAnalysisReportActivity1(requestParameters.recommendedActivityId, requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary paymentRedirect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public eventPaymentRedirect(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).eventPaymentRedirect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mentor verification 상태 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public geVerificationStatus(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).geVerificationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 취소 사유
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getBookingCancelReason(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getBookingCancelReason(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약 취소 사유
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getBookingExpertCancelReason(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getBookingExpertCancelReason(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 등급별 ACT 계산
     * @param {OpenDataApiGetCollegeActCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeActCalc(requestParameters: OpenDataApiGetCollegeActCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeActCalc(requestParameters.userAct, requestParameters.tierRank, requestParameters.collegeAct25, requestParameters.collegeAct75, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 등급별 GPA 계산
     * @param {OpenDataApiGetCollegeGpaCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeGpaCalc(requestParameters: OpenDataApiGetCollegeGpaCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeGpaCalc(requestParameters.userGpa, requestParameters.tierRank, requestParameters.collegeGpa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 등급별 SAT 계산
     * @param {OpenDataApiGetCollegeSatCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeSatCalc(requestParameters: OpenDataApiGetCollegeSatCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeSatCalc(requestParameters.userSat, requestParameters.tierRank, requestParameters.collegeSat25, requestParameters.collegeSat75, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 서베이 결과 계산
     * @param {OpenDataApiGetCollegeSurveyCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeSurveyCalc(requestParameters: OpenDataApiGetCollegeSurveyCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeSurveyCalc(requestParameters.req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 서베이 결과 조회 (로그인 전)
     * @param {OpenDataApiGetCollegeSurveyResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeSurveyResult(requestParameters: OpenDataApiGetCollegeSurveyResultRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeSurveyResult(requestParameters.reqMemberCollegeSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 등급 구분
     * @param {OpenDataApiGetCollegeTierCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCollegeTierCalc(requestParameters: OpenDataApiGetCollegeTierCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCollegeTierCalc(requestParameters.ipedsCollegeId, requestParameters.collegeTitle, requestParameters.acceptRate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary counselors theme 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getCounselorsThemeList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getCounselorsThemeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mentor program duration 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getDegree(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getDegree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mentor program duration 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getDuration(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getDuration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field of Study 항목
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getFieldStudy(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getFieldStudy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 언어 정보
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getLanguageLevelList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getLanguageLevelList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 언어 정보
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getLanguageList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getLanguageList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mentor advice 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getMentorAdvice(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getMentorAdvice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 상세 조회
     * @param {OpenDataApiGetOpenExpertDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getOpenExpertDetail(requestParameters: OpenDataApiGetOpenExpertDetailRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getOpenExpertDetail(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 인증없이 전문가 조회
     * @param {OpenDataApiGetOpenExpertListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getOpenExpertList(requestParameters: OpenDataApiGetOpenExpertListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getOpenExpertList(requestParameters.expertType, requestParameters.weeklyDate, requestParameters.timeSet, requestParameters.collegeIds, requestParameters.getIntoCollegeIds, requestParameters.majors, requestParameters.counselorThemes, requestParameters.tutorTypes, requestParameters.mentorAdvices, requestParameters.memberId, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 목록 조회
     * @param {OpenDataApiGetOpenProgramListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getOpenProgramList(requestParameters: OpenDataApiGetOpenProgramListRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getOpenProgramList(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 리뷰 목록 조회
     * @param {OpenDataApiGetOpenReviewListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getOpenReviewList(requestParameters: OpenDataApiGetOpenReviewListRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getOpenReviewList(requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Semester List 목록
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getSemesterList1(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getSemesterList1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 타임존 정보
     * @param {OpenDataApiGetTimeZoneListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getTimeZoneList(requestParameters: OpenDataApiGetTimeZoneListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getTimeZoneList(requestParameters.title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary tutor type 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getTutorTypeCode(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getTutorTypeCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unweighted GPA 목록
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getUnweightedGpaList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getUnweightedGpaList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 유저 예상 합격률 계산
     * @param {OpenDataApiGetUserChancesRateCalcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public getUserChancesRateCalc(requestParameters: OpenDataApiGetUserChancesRateCalcRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).getUserChancesRateCalc(requestParameters.userGpa, requestParameters.userSat, requestParameters.userAct, requestParameters.college, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary landing page extracurriculars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public landingCareer(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).landingCareer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary landing page college
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public landingCollege(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).landingCollege(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary landing page extracurriculars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public landingExtracurriculars(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).landingExtracurriculars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary landing page sign-up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public landingSignIn(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).landingSignIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary landing page sign-up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public landingSignUp(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).landingSignUp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary major category 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public majorCategoryList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).majorCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전공 조회
     * @param {OpenDataApiMajorListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public majorList(requestParameters: OpenDataApiMajorListRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).majorList(requestParameters.searchType, requestParameters.careerId, requestParameters.collegeId, requestParameters.keyword, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity 목록 조회
     * @param {OpenDataApiPublicActivityListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicActivityList(requestParameters: OpenDataApiPublicActivityListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicActivityList(requestParameters.keyword, requestParameters.listingType, requestParameters.ecType, requestParameters.strengthPoint, requestParameters.majors, requestParameters.addressState, requestParameters.isOnline, requestParameters.onlyPremium, requestParameters.sort, requestParameters.memberId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Strength 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicActivityStrengthList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicActivityStrengthList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Strength Point 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicActivityStrengthPointList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicActivityStrengthPointList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity Type 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicActivityTypeList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicActivityTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity Type 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicActivityTypeSimpleList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicActivityTypeSimpleList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AP Test Subject 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicAptestSubjectList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicAptestSubjectList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Award Level 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicAwardLevelList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicAwardLevelList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 커리어 조회
     * @param {OpenDataApiPublicCareerListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicCareerList(requestParameters: OpenDataApiPublicCareerListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicCareerList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 대학 조회
     * @param {OpenDataApiPublicCollegeNewListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicCollegeNewList(requestParameters: OpenDataApiPublicCollegeNewListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicCollegeNewList(requestParameters.title, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 도시 조회
     * @param {OpenDataApiPublicCountryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicCountryList(requestParameters: OpenDataApiPublicCountryListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicCountryList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Course 목록 조회
     * @param {OpenDataApiPublicCourseCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicCourseCategoryList(requestParameters: OpenDataApiPublicCourseCategoryListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicCourseCategoryList(requestParameters.subjectCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 스킬 조회
     * @param {OpenDataApiPublicExpertSkillListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicExpertSkillList(requestParameters: OpenDataApiPublicExpertSkillListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicExpertSkillList(requestParameters.expertType, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grade 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicGradeList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicGradeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 고등학교 조회
     * @param {OpenDataApiPublicHighSchoolListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicHighSchoolList(requestParameters: OpenDataApiPublicHighSchoolListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicHighSchoolList(requestParameters.schoolName, requestParameters.city, requestParameters.state, requestParameters.address, requestParameters.zip, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전공 조회
     * @param {OpenDataApiPublicMajorListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicMajorList(requestParameters: OpenDataApiPublicMajorListRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicMajorList(requestParameters.searchType, requestParameters.careerId, requestParameters.collegeId, requestParameters.keyword, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary region별 지역 목록
     * @param {OpenDataApiPublicRegionStateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicRegionStateList(requestParameters: OpenDataApiPublicRegionStateListRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicRegionStateList(requestParameters.regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 지역 조회
     * @param {OpenDataApiPublicStateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicStateList(requestParameters: OpenDataApiPublicStateListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicStateList(requestParameters.countyId, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subject Category 목록 조회
     * @param {OpenDataApiPublicSubjectCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicSubjectCategoryList(requestParameters: OpenDataApiPublicSubjectCategoryListRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicSubjectCategoryList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Target GPA 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicTargetGPAList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicTargetGPAList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Type 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public publicTestTypeList(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).publicTestTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StripeAccountLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public stripeAccountLink(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).stripeAccountLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary analysis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public triggerAnalyticsEmail(options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).triggerAnalyticsEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary research paper request info
     * @param {OpenDataApiTriggerResearchPaperEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public triggerResearchPaperEmail(requestParameters: OpenDataApiTriggerResearchPaperEmailRequest = {}, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).triggerResearchPaperEmail(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 이메일 받을때 기기 별 분기
     * @param {OpenDataApiZoomProfileCheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataApi
     */
    public zoomProfileCheck(requestParameters: OpenDataApiZoomProfileCheckRequest, options?: AxiosRequestConfig) {
        return OpenDataApiFp(this.configuration).zoomProfileCheck(requestParameters.memberId, requestParameters.lastLinkedProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpenSignupApi - axios parameter creator
 * @export
 */
export const OpenSignupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 크로노피 콜백
         * @param {string} code 
         * @param {number} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronofyCallback: async (code: string, state: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('cronofyCallback', 'code', code)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('cronofyCallback', 'state', state)
            const localVarPath = `/open/sign-up/cronofy/setCronofyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 임의의 코드를 생성하여 사용자에게 발급한다.
         * @summary code 발급
         * @param {ReqEmailCodeIssued} reqEmailCodeIssued 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeIssued: async (reqEmailCodeIssued: ReqEmailCodeIssued, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqEmailCodeIssued' is not null or undefined
            assertParamExists('postEmailCodeIssued', 'reqEmailCodeIssued', reqEmailCodeIssued)
            const localVarPath = `/open/sign-up/email/code-issued`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqEmailCodeIssued, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 전달받은 코드가 유효한지 검증한다.
         * @summary code 인증
         * @param {ReqEmailCodeValid} reqEmailCodeValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeValid: async (reqEmailCodeValid: ReqEmailCodeValid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqEmailCodeValid' is not null or undefined
            assertParamExists('postEmailCodeValid', 'reqEmailCodeValid', reqEmailCodeValid)
            const localVarPath = `/open/sign-up/email/code-valid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqEmailCodeValid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원을 가입시킨다.
         * @summary 회원 가입
         * @param {ReqSignUp} reqSignUp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignUp: async (reqSignUp: ReqSignUp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqSignUp' is not null or undefined
            assertParamExists('postSignUp', 'reqSignUp', reqSignUp)
            const localVarPath = `/open/sign-up/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqSignUp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * firebase 로부터 발급받은 idToken을 인증한다.
         * @summary IdToken 인증
         * @param {ReqSocialIdTokenValid} reqSocialIdTokenValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSocialIdTokenValid: async (reqSocialIdTokenValid: ReqSocialIdTokenValid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqSocialIdTokenValid' is not null or undefined
            assertParamExists('postSocialIdTokenValid', 'reqSocialIdTokenValid', reqSocialIdTokenValid)
            const localVarPath = `/open/sign-up/social/id-token-valid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqSocialIdTokenValid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약 지불취소 
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBookingPaidCancel: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('setProgramBookingPaidCancel', 'bookingId', bookingId)
            const localVarPath = `/open/sign-up/expert/program/booking/paid/cancel/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로그램 예약 지불성공 
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBookingPaidComplete: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('setProgramBookingPaidComplete', 'bookingId', bookingId)
            const localVarPath = `/open/sign-up/expert/program/booking/paid/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary test
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test: async (bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('test', 'bookingId', bookingId)
            const localVarPath = `/open/sign-up/test/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenSignupApi - functional programming interface
 * @export
 */
export const OpenSignupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenSignupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 크로노피 콜백
         * @param {string} code 
         * @param {number} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronofyCallback(code: string, state: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronofyCallback(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 임의의 코드를 생성하여 사용자에게 발급한다.
         * @summary code 발급
         * @param {ReqEmailCodeIssued} reqEmailCodeIssued 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailCodeIssued(reqEmailCodeIssued: ReqEmailCodeIssued, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResEmailCodeIssued>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailCodeIssued(reqEmailCodeIssued, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 전달받은 코드가 유효한지 검증한다.
         * @summary code 인증
         * @param {ReqEmailCodeValid} reqEmailCodeValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailCodeValid(reqEmailCodeValid: ReqEmailCodeValid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResEmailCodeValid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailCodeValid(reqEmailCodeValid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원을 가입시킨다.
         * @summary 회원 가입
         * @param {ReqSignUp} reqSignUp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignUp(reqSignUp: ReqSignUp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignUp(reqSignUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * firebase 로부터 발급받은 idToken을 인증한다.
         * @summary IdToken 인증
         * @param {ReqSocialIdTokenValid} reqSocialIdTokenValid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSocialIdTokenValid(reqSocialIdTokenValid: ReqSocialIdTokenValid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResSocialIdTokenValid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSocialIdTokenValid(reqSocialIdTokenValid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약 지불취소 
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProgramBookingPaidCancel(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProgramBookingPaidCancel(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로그램 예약 지불성공 
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProgramBookingPaidComplete(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProgramBookingPaidComplete(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary test
         * @param {number} bookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async test(bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.test(bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpenSignupApi - factory interface
 * @export
 */
export const OpenSignupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenSignupApiFp(configuration)
    return {
        /**
         * 
         * @summary 크로노피 콜백
         * @param {OpenSignupApiCronofyCallbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronofyCallback(requestParameters: OpenSignupApiCronofyCallbackRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cronofyCallback(requestParameters.code, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * 임의의 코드를 생성하여 사용자에게 발급한다.
         * @summary code 발급
         * @param {OpenSignupApiPostEmailCodeIssuedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeIssued(requestParameters: OpenSignupApiPostEmailCodeIssuedRequest, options?: AxiosRequestConfig): AxiosPromise<ResEmailCodeIssued> {
            return localVarFp.postEmailCodeIssued(requestParameters.reqEmailCodeIssued, options).then((request) => request(axios, basePath));
        },
        /**
         * 전달받은 코드가 유효한지 검증한다.
         * @summary code 인증
         * @param {OpenSignupApiPostEmailCodeValidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailCodeValid(requestParameters: OpenSignupApiPostEmailCodeValidRequest, options?: AxiosRequestConfig): AxiosPromise<ResEmailCodeValid> {
            return localVarFp.postEmailCodeValid(requestParameters.reqEmailCodeValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원을 가입시킨다.
         * @summary 회원 가입
         * @param {OpenSignupApiPostSignUpRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignUp(requestParameters: OpenSignupApiPostSignUpRequest, options?: AxiosRequestConfig): AxiosPromise<ResMember> {
            return localVarFp.postSignUp(requestParameters.reqSignUp, options).then((request) => request(axios, basePath));
        },
        /**
         * firebase 로부터 발급받은 idToken을 인증한다.
         * @summary IdToken 인증
         * @param {OpenSignupApiPostSocialIdTokenValidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSocialIdTokenValid(requestParameters: OpenSignupApiPostSocialIdTokenValidRequest, options?: AxiosRequestConfig): AxiosPromise<ResSocialIdTokenValid> {
            return localVarFp.postSocialIdTokenValid(requestParameters.reqSocialIdTokenValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약 지불취소 
         * @param {OpenSignupApiSetProgramBookingPaidCancelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBookingPaidCancel(requestParameters: OpenSignupApiSetProgramBookingPaidCancelRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setProgramBookingPaidCancel(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로그램 예약 지불성공 
         * @param {OpenSignupApiSetProgramBookingPaidCompleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProgramBookingPaidComplete(requestParameters: OpenSignupApiSetProgramBookingPaidCompleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setProgramBookingPaidComplete(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary test
         * @param {OpenSignupApiTestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test(requestParameters: OpenSignupApiTestRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.test(requestParameters.bookingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cronofyCallback operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiCronofyCallbackRequest
 */
export interface OpenSignupApiCronofyCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenSignupApiCronofyCallback
     */
    readonly code: string

    /**
     * 
     * @type {number}
     * @memberof OpenSignupApiCronofyCallback
     */
    readonly state: number
}

/**
 * Request parameters for postEmailCodeIssued operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiPostEmailCodeIssuedRequest
 */
export interface OpenSignupApiPostEmailCodeIssuedRequest {
    /**
     * 
     * @type {ReqEmailCodeIssued}
     * @memberof OpenSignupApiPostEmailCodeIssued
     */
    readonly reqEmailCodeIssued: ReqEmailCodeIssued
}

/**
 * Request parameters for postEmailCodeValid operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiPostEmailCodeValidRequest
 */
export interface OpenSignupApiPostEmailCodeValidRequest {
    /**
     * 
     * @type {ReqEmailCodeValid}
     * @memberof OpenSignupApiPostEmailCodeValid
     */
    readonly reqEmailCodeValid: ReqEmailCodeValid
}

/**
 * Request parameters for postSignUp operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiPostSignUpRequest
 */
export interface OpenSignupApiPostSignUpRequest {
    /**
     * 
     * @type {ReqSignUp}
     * @memberof OpenSignupApiPostSignUp
     */
    readonly reqSignUp: ReqSignUp
}

/**
 * Request parameters for postSocialIdTokenValid operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiPostSocialIdTokenValidRequest
 */
export interface OpenSignupApiPostSocialIdTokenValidRequest {
    /**
     * 
     * @type {ReqSocialIdTokenValid}
     * @memberof OpenSignupApiPostSocialIdTokenValid
     */
    readonly reqSocialIdTokenValid: ReqSocialIdTokenValid
}

/**
 * Request parameters for setProgramBookingPaidCancel operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiSetProgramBookingPaidCancelRequest
 */
export interface OpenSignupApiSetProgramBookingPaidCancelRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenSignupApiSetProgramBookingPaidCancel
     */
    readonly bookingId: number
}

/**
 * Request parameters for setProgramBookingPaidComplete operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiSetProgramBookingPaidCompleteRequest
 */
export interface OpenSignupApiSetProgramBookingPaidCompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenSignupApiSetProgramBookingPaidComplete
     */
    readonly bookingId: number
}

/**
 * Request parameters for test operation in OpenSignupApi.
 * @export
 * @interface OpenSignupApiTestRequest
 */
export interface OpenSignupApiTestRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenSignupApiTest
     */
    readonly bookingId: number
}

/**
 * OpenSignupApi - object-oriented interface
 * @export
 * @class OpenSignupApi
 * @extends {BaseAPI}
 */
export class OpenSignupApi extends BaseAPI {
    /**
     * 
     * @summary 크로노피 콜백
     * @param {OpenSignupApiCronofyCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public cronofyCallback(requestParameters: OpenSignupApiCronofyCallbackRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).cronofyCallback(requestParameters.code, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 임의의 코드를 생성하여 사용자에게 발급한다.
     * @summary code 발급
     * @param {OpenSignupApiPostEmailCodeIssuedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public postEmailCodeIssued(requestParameters: OpenSignupApiPostEmailCodeIssuedRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).postEmailCodeIssued(requestParameters.reqEmailCodeIssued, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 전달받은 코드가 유효한지 검증한다.
     * @summary code 인증
     * @param {OpenSignupApiPostEmailCodeValidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public postEmailCodeValid(requestParameters: OpenSignupApiPostEmailCodeValidRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).postEmailCodeValid(requestParameters.reqEmailCodeValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원을 가입시킨다.
     * @summary 회원 가입
     * @param {OpenSignupApiPostSignUpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public postSignUp(requestParameters: OpenSignupApiPostSignUpRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).postSignUp(requestParameters.reqSignUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * firebase 로부터 발급받은 idToken을 인증한다.
     * @summary IdToken 인증
     * @param {OpenSignupApiPostSocialIdTokenValidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public postSocialIdTokenValid(requestParameters: OpenSignupApiPostSocialIdTokenValidRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).postSocialIdTokenValid(requestParameters.reqSocialIdTokenValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약 지불취소 
     * @param {OpenSignupApiSetProgramBookingPaidCancelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public setProgramBookingPaidCancel(requestParameters: OpenSignupApiSetProgramBookingPaidCancelRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).setProgramBookingPaidCancel(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로그램 예약 지불성공 
     * @param {OpenSignupApiSetProgramBookingPaidCompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public setProgramBookingPaidComplete(requestParameters: OpenSignupApiSetProgramBookingPaidCompleteRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).setProgramBookingPaidComplete(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary test
     * @param {OpenSignupApiTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSignupApi
     */
    public test(requestParameters: OpenSignupApiTestRequest, options?: AxiosRequestConfig) {
        return OpenSignupApiFp(this.configuration).test(requestParameters.bookingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 결제URL생성
         * @param {ReqCheckoutParam} reqCheckoutParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePaymentUrl: async (reqCheckoutParam: ReqCheckoutParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqCheckoutParam' is not null or undefined
            assertParamExists('makePaymentUrl', 'reqCheckoutParam', reqCheckoutParam)
            const localVarPath = `/payment/make-payment-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqCheckoutParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 구독결제 확인 링크 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentWebhook: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('paymentWebhook', 'body', body)
            const localVarPath = `/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 결제URL생성
         * @param {ReqCheckoutParam} reqCheckoutParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makePaymentUrl(reqCheckoutParam: ReqCheckoutParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makePaymentUrl(reqCheckoutParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 구독결제 확인 링크 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentWebhook(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentWebhook(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary 결제URL생성
         * @param {PaymentApiMakePaymentUrlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePaymentUrl(requestParameters: PaymentApiMakePaymentUrlRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.makePaymentUrl(requestParameters.reqCheckoutParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 구독결제 확인 링크 
         * @param {PaymentApiPaymentWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentWebhook(requestParameters: PaymentApiPaymentWebhookRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.paymentWebhook(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for makePaymentUrl operation in PaymentApi.
 * @export
 * @interface PaymentApiMakePaymentUrlRequest
 */
export interface PaymentApiMakePaymentUrlRequest {
    /**
     * 
     * @type {ReqCheckoutParam}
     * @memberof PaymentApiMakePaymentUrl
     */
    readonly reqCheckoutParam: ReqCheckoutParam
}

/**
 * Request parameters for paymentWebhook operation in PaymentApi.
 * @export
 * @interface PaymentApiPaymentWebhookRequest
 */
export interface PaymentApiPaymentWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentApiPaymentWebhook
     */
    readonly body: string
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary 결제URL생성
     * @param {PaymentApiMakePaymentUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public makePaymentUrl(requestParameters: PaymentApiMakePaymentUrlRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).makePaymentUrl(requestParameters.reqCheckoutParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 구독결제 확인 링크 
     * @param {PaymentApiPaymentWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentWebhook(requestParameters: PaymentApiPaymentWebhookRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentWebhook(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapApi - axios parameter creator
 * @export
 */
export const RoadmapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 로드맵 추천 설계
         * @param {ReqRoadmap} reqRoadmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoadmap: async (reqRoadmap: ReqRoadmap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmap' is not null or undefined
            assertParamExists('addRoadmap', 'reqRoadmap', reqRoadmap)
            const localVarPath = `/roadmap/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 로드맵 Self 설계
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoadmapMySelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/add/myself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoadmap: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap 데이터 초기화
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoadmapAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/semester-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap Semester 변경
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapSemester: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRoadmapSemester', 'body', body)
            const localVarPath = `/roadmap/semester`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap TargetGpa 변경
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetGpa: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRoadmapTargetGpa', 'body', body)
            const localVarPath = `/roadmap/target-gpa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap Dream College, Major 변경
         * @param {ReqRoadmap} reqRoadmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScore: async (reqRoadmap: ReqRoadmap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmap' is not null or undefined
            assertParamExists('updateRoadmapTargetScore', 'reqRoadmap', reqRoadmap)
            const localVarPath = `/roadmap/dream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap Target-Score ACT 변경
         * @param {ReqRoadmapAct} reqRoadmapAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScoreAct: async (reqRoadmapAct: ReqRoadmapAct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapAct' is not null or undefined
            assertParamExists('updateRoadmapTargetScoreAct', 'reqRoadmapAct', reqRoadmapAct)
            const localVarPath = `/roadmap/target-score/act`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Roadmap Target-Score SAT 변경
         * @param {ReqRoadmapSat} reqRoadmapSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScoreSat: async (reqRoadmapSat: ReqRoadmapSat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapSat' is not null or undefined
            assertParamExists('updateRoadmapTargetScoreSat', 'reqRoadmapSat', reqRoadmapSat)
            const localVarPath = `/roadmap/target-score/sat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapSat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapApi - functional programming interface
 * @export
 */
export const RoadmapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 로드맵 추천 설계
         * @param {ReqRoadmap} reqRoadmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoadmap(reqRoadmap: ReqRoadmap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoadmap(reqRoadmap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 로드맵 Self 설계
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoadmapMySelf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoadmapMySelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoadmap(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoadmap(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap 데이터 초기화
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoadmapAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoadmapAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemesterList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResSemesterList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemesterList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap Semester 변경
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapSemester(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapSemester(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap TargetGpa 변경
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTargetGpa(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTargetGpa(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap Dream College, Major 변경
         * @param {ReqRoadmap} reqRoadmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTargetScore(reqRoadmap: ReqRoadmap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTargetScore(reqRoadmap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap Target-Score ACT 변경
         * @param {ReqRoadmapAct} reqRoadmapAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTargetScoreAct(reqRoadmapAct: ReqRoadmapAct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTargetScoreAct(reqRoadmapAct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Roadmap Target-Score SAT 변경
         * @param {ReqRoadmapSat} reqRoadmapSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTargetScoreSat(reqRoadmapSat: ReqRoadmapSat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTargetScoreSat(reqRoadmapSat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapApi - factory interface
 * @export
 */
export const RoadmapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapApiFp(configuration)
    return {
        /**
         * 
         * @summary 로드맵 추천 설계
         * @param {RoadmapApiAddRoadmapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoadmap(requestParameters: RoadmapApiAddRoadmapRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.addRoadmap(requestParameters.reqRoadmap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 로드맵 Self 설계
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoadmapMySelf(options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.addRoadmapMySelf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoadmap(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRoadmap(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap 데이터 초기화
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoadmapAll(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRoadmapAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapInfo(options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.getRoadmapInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Semester List 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterList(options?: AxiosRequestConfig): AxiosPromise<Array<ResSemesterList>> {
            return localVarFp.getSemesterList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap Semester 변경
         * @param {RoadmapApiUpdateRoadmapSemesterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapSemester(requestParameters: RoadmapApiUpdateRoadmapSemesterRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.updateRoadmapSemester(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap TargetGpa 변경
         * @param {RoadmapApiUpdateRoadmapTargetGpaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetGpa(requestParameters: RoadmapApiUpdateRoadmapTargetGpaRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.updateRoadmapTargetGpa(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap Dream College, Major 변경
         * @param {RoadmapApiUpdateRoadmapTargetScoreRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScore(requestParameters: RoadmapApiUpdateRoadmapTargetScoreRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.updateRoadmapTargetScore(requestParameters.reqRoadmap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap Target-Score ACT 변경
         * @param {RoadmapApiUpdateRoadmapTargetScoreActRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScoreAct(requestParameters: RoadmapApiUpdateRoadmapTargetScoreActRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.updateRoadmapTargetScoreAct(requestParameters.reqRoadmapAct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Roadmap Target-Score SAT 변경
         * @param {RoadmapApiUpdateRoadmapTargetScoreSatRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTargetScoreSat(requestParameters: RoadmapApiUpdateRoadmapTargetScoreSatRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmap> {
            return localVarFp.updateRoadmapTargetScoreSat(requestParameters.reqRoadmapSat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addRoadmap operation in RoadmapApi.
 * @export
 * @interface RoadmapApiAddRoadmapRequest
 */
export interface RoadmapApiAddRoadmapRequest {
    /**
     * 
     * @type {ReqRoadmap}
     * @memberof RoadmapApiAddRoadmap
     */
    readonly reqRoadmap: ReqRoadmap
}

/**
 * Request parameters for updateRoadmapSemester operation in RoadmapApi.
 * @export
 * @interface RoadmapApiUpdateRoadmapSemesterRequest
 */
export interface RoadmapApiUpdateRoadmapSemesterRequest {
    /**
     * 
     * @type {string}
     * @memberof RoadmapApiUpdateRoadmapSemester
     */
    readonly body: string
}

/**
 * Request parameters for updateRoadmapTargetGpa operation in RoadmapApi.
 * @export
 * @interface RoadmapApiUpdateRoadmapTargetGpaRequest
 */
export interface RoadmapApiUpdateRoadmapTargetGpaRequest {
    /**
     * 
     * @type {string}
     * @memberof RoadmapApiUpdateRoadmapTargetGpa
     */
    readonly body: string
}

/**
 * Request parameters for updateRoadmapTargetScore operation in RoadmapApi.
 * @export
 * @interface RoadmapApiUpdateRoadmapTargetScoreRequest
 */
export interface RoadmapApiUpdateRoadmapTargetScoreRequest {
    /**
     * 
     * @type {ReqRoadmap}
     * @memberof RoadmapApiUpdateRoadmapTargetScore
     */
    readonly reqRoadmap: ReqRoadmap
}

/**
 * Request parameters for updateRoadmapTargetScoreAct operation in RoadmapApi.
 * @export
 * @interface RoadmapApiUpdateRoadmapTargetScoreActRequest
 */
export interface RoadmapApiUpdateRoadmapTargetScoreActRequest {
    /**
     * 
     * @type {ReqRoadmapAct}
     * @memberof RoadmapApiUpdateRoadmapTargetScoreAct
     */
    readonly reqRoadmapAct: ReqRoadmapAct
}

/**
 * Request parameters for updateRoadmapTargetScoreSat operation in RoadmapApi.
 * @export
 * @interface RoadmapApiUpdateRoadmapTargetScoreSatRequest
 */
export interface RoadmapApiUpdateRoadmapTargetScoreSatRequest {
    /**
     * 
     * @type {ReqRoadmapSat}
     * @memberof RoadmapApiUpdateRoadmapTargetScoreSat
     */
    readonly reqRoadmapSat: ReqRoadmapSat
}

/**
 * RoadmapApi - object-oriented interface
 * @export
 * @class RoadmapApi
 * @extends {BaseAPI}
 */
export class RoadmapApi extends BaseAPI {
    /**
     * 
     * @summary 로드맵 추천 설계
     * @param {RoadmapApiAddRoadmapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public addRoadmap(requestParameters: RoadmapApiAddRoadmapRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).addRoadmap(requestParameters.reqRoadmap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 로드맵 Self 설계
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public addRoadmapMySelf(options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).addRoadmapMySelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap 삭제
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public deleteRoadmap(options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).deleteRoadmap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap 데이터 초기화
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public deleteRoadmapAll(options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).deleteRoadmapAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap 정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public getRoadmapInfo(options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).getRoadmapInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Semester List 목록
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public getSemesterList(options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).getSemesterList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap Semester 변경
     * @param {RoadmapApiUpdateRoadmapSemesterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public updateRoadmapSemester(requestParameters: RoadmapApiUpdateRoadmapSemesterRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).updateRoadmapSemester(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap TargetGpa 변경
     * @param {RoadmapApiUpdateRoadmapTargetGpaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public updateRoadmapTargetGpa(requestParameters: RoadmapApiUpdateRoadmapTargetGpaRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).updateRoadmapTargetGpa(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap Dream College, Major 변경
     * @param {RoadmapApiUpdateRoadmapTargetScoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public updateRoadmapTargetScore(requestParameters: RoadmapApiUpdateRoadmapTargetScoreRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).updateRoadmapTargetScore(requestParameters.reqRoadmap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap Target-Score ACT 변경
     * @param {RoadmapApiUpdateRoadmapTargetScoreActRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public updateRoadmapTargetScoreAct(requestParameters: RoadmapApiUpdateRoadmapTargetScoreActRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).updateRoadmapTargetScoreAct(requestParameters.reqRoadmapAct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Roadmap Target-Score SAT 변경
     * @param {RoadmapApiUpdateRoadmapTargetScoreSatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapApi
     */
    public updateRoadmapTargetScoreSat(requestParameters: RoadmapApiUpdateRoadmapTargetScoreSatRequest, options?: AxiosRequestConfig) {
        return RoadmapApiFp(this.configuration).updateRoadmapTargetScoreSat(requestParameters.reqRoadmapSat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapActivityApi - axios parameter creator
 * @export
 */
export const RoadmapActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activity 생성
         * @param {ReqRoadmapActivity} reqRoadmapActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (reqRoadmapActivity: ReqRoadmapActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapActivity' is not null or undefined
            assertParamExists('createActivity', 'reqRoadmapActivity', reqRoadmapActivity)
            const localVarPath = `/roadmap/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity 삭제
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivity', 'activityId', activityId)
            const localVarPath = `/roadmap/activity/{activityId}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapActivities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity 상세 조회
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapActivity: async (activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getRoadmapActivity', 'activityId', activityId)
            const localVarPath = `/roadmap/activity/{activityId}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity Start
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startActivity: async (activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('startActivity', 'activityId', activityId)
            const localVarPath = `/roadmap/activity/start/{activityId}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity 수정
         * @param {number} activityId 
         * @param {ReqRoadmapActivity} reqRoadmapActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (activityId: number, reqRoadmapActivity: ReqRoadmapActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('updateActivity', 'activityId', activityId)
            // verify required parameter 'reqRoadmapActivity' is not null or undefined
            assertParamExists('updateActivity', 'reqRoadmapActivity', reqRoadmapActivity)
            const localVarPath = `/roadmap/activity/{activityId}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapActivityApi - functional programming interface
 * @export
 */
export const RoadmapActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activity 생성
         * @param {ReqRoadmapActivity} reqRoadmapActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(reqRoadmapActivity: ReqRoadmapActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(reqRoadmapActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity 삭제
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapActivities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapActivities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity 상세 조회
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapActivity(activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity Start
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startActivity(activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startActivity(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity 수정
         * @param {number} activityId 
         * @param {ReqRoadmapActivity} reqRoadmapActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(activityId: number, reqRoadmapActivity: ReqRoadmapActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(activityId, reqRoadmapActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapActivityApi - factory interface
 * @export
 */
export const RoadmapActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Activity 생성
         * @param {RoadmapActivityApiCreateActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(requestParameters: RoadmapActivityApiCreateActivityRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivity> {
            return localVarFp.createActivity(requestParameters.reqRoadmapActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity 삭제
         * @param {RoadmapActivityApiDeleteActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(requestParameters: RoadmapActivityApiDeleteActivityRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteActivity(requestParameters.activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapActivities(options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivityList> {
            return localVarFp.getRoadmapActivities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity 상세 조회
         * @param {RoadmapActivityApiGetRoadmapActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapActivity(requestParameters: RoadmapActivityApiGetRoadmapActivityRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivity> {
            return localVarFp.getRoadmapActivity(requestParameters.activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity Start
         * @param {RoadmapActivityApiStartActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startActivity(requestParameters: RoadmapActivityApiStartActivityRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startActivity(requestParameters.activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity 수정
         * @param {RoadmapActivityApiUpdateActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(requestParameters: RoadmapActivityApiUpdateActivityRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivity> {
            return localVarFp.updateActivity(requestParameters.activityId, requestParameters.reqRoadmapActivity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createActivity operation in RoadmapActivityApi.
 * @export
 * @interface RoadmapActivityApiCreateActivityRequest
 */
export interface RoadmapActivityApiCreateActivityRequest {
    /**
     * 
     * @type {ReqRoadmapActivity}
     * @memberof RoadmapActivityApiCreateActivity
     */
    readonly reqRoadmapActivity: ReqRoadmapActivity
}

/**
 * Request parameters for deleteActivity operation in RoadmapActivityApi.
 * @export
 * @interface RoadmapActivityApiDeleteActivityRequest
 */
export interface RoadmapActivityApiDeleteActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapActivityApiDeleteActivity
     */
    readonly activityId: number
}

/**
 * Request parameters for getRoadmapActivity operation in RoadmapActivityApi.
 * @export
 * @interface RoadmapActivityApiGetRoadmapActivityRequest
 */
export interface RoadmapActivityApiGetRoadmapActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapActivityApiGetRoadmapActivity
     */
    readonly activityId: number
}

/**
 * Request parameters for startActivity operation in RoadmapActivityApi.
 * @export
 * @interface RoadmapActivityApiStartActivityRequest
 */
export interface RoadmapActivityApiStartActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapActivityApiStartActivity
     */
    readonly activityId: number
}

/**
 * Request parameters for updateActivity operation in RoadmapActivityApi.
 * @export
 * @interface RoadmapActivityApiUpdateActivityRequest
 */
export interface RoadmapActivityApiUpdateActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapActivityApiUpdateActivity
     */
    readonly activityId: number

    /**
     * 
     * @type {ReqRoadmapActivity}
     * @memberof RoadmapActivityApiUpdateActivity
     */
    readonly reqRoadmapActivity: ReqRoadmapActivity
}

/**
 * RoadmapActivityApi - object-oriented interface
 * @export
 * @class RoadmapActivityApi
 * @extends {BaseAPI}
 */
export class RoadmapActivityApi extends BaseAPI {
    /**
     * 
     * @summary Activity 생성
     * @param {RoadmapActivityApiCreateActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public createActivity(requestParameters: RoadmapActivityApiCreateActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).createActivity(requestParameters.reqRoadmapActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity 삭제
     * @param {RoadmapActivityApiDeleteActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public deleteActivity(requestParameters: RoadmapActivityApiDeleteActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).deleteActivity(requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public getRoadmapActivities(options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).getRoadmapActivities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity 상세 조회
     * @param {RoadmapActivityApiGetRoadmapActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public getRoadmapActivity(requestParameters: RoadmapActivityApiGetRoadmapActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).getRoadmapActivity(requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity Start
     * @param {RoadmapActivityApiStartActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public startActivity(requestParameters: RoadmapActivityApiStartActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).startActivity(requestParameters.activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity 수정
     * @param {RoadmapActivityApiUpdateActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapActivityApi
     */
    public updateActivity(requestParameters: RoadmapActivityApiUpdateActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapActivityApiFp(this.configuration).updateActivity(requestParameters.activityId, requestParameters.reqRoadmapActivity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapAnalysisApi - axios parameter creator
 * @export
 */
export const RoadmapAnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReport} reqRoadmapAnalysisReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReport: async (reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapAnalysisReport' is not null or undefined
            assertParamExists('createAnalysisReport', 'reqRoadmapAnalysisReport', reqRoadmapAnalysisReport)
            const localVarPath = `/roadmap/analysis/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReportAct} reqRoadmapAnalysisReportAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportAct: async (reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapAnalysisReportAct' is not null or undefined
            assertParamExists('createAnalysisReportAct', 'reqRoadmapAnalysisReportAct', reqRoadmapAnalysisReportAct)
            const localVarPath = `/roadmap/analysis/report/act`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReportAct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {number} recommendedActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportActivity: async (recommendedActivityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendedActivityId' is not null or undefined
            assertParamExists('createAnalysisReportActivity', 'recommendedActivityId', recommendedActivityId)
            const localVarPath = `/roadmap/analysis/report/recommended/activity/{recommendedActivityId}`
                .replace(`{${"recommendedActivityId"}}`, encodeURIComponent(String(recommendedActivityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReportSat} reqRoadmapAnalysisReportSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportSat: async (reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapAnalysisReportSat' is not null or undefined
            assertParamExists('createAnalysisReportSat', 'reqRoadmapAnalysisReportSat', reqRoadmapAnalysisReportSat)
            const localVarPath = `/roadmap/analysis/report/sat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReportSat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 삭제
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalysisReport: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('deleteAnalysisReport', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report > Recommended Activity Detail > Recommended Activities 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisAcademicActivities: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getAnalysisAcademicActivities', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}/recommended/activities`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report Activities 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisActivities: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getAnalysisActivities', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/activity/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 조회 ( 최근 )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/analysis/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Comparative Analysis Academics 데이터 조회 ## Test Score, Hight School GPA - x 축 좌표 데이터 목록 조회 ( open data API > Unweighted GPA 목록 ) - myScore > myGpa, myTestScore, targetGpa, targetTestScore 값 사용 ## Admission Requirements Based on Core Curriculum - 차트 데이터  - subjectCategories > myCourseWorkYear, recommendedCourseWorkYear, coreCurriculumCourseWorkYear - Subject Category Names 정보  - coreSubjectCategories names 필터링 - 충족되지 않은 Subject Category Names 정보  - coreSubjectCategories > metFlag == false 인 것만 필터링 ## Recommended Course - 차트 데이터  - subjectCategories > myCourseCount, recommendedCourseCount - 충족되지 않은 Subject Category Names   - coreSubjectCategories > metCourseFlag == false 인 것만 필터링 > title, myCourseCount 값 사용 - English, Arts 정보 ( 랜덤 추출 및 AP 과목 추천은 서버에서 처리 후 최대 3개 데이터 전달 )  - coreSubjectCategories > title, courses > courseTitle  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Academic
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportAcademics: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getAnalysisReportAcademics', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}/academic`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Comparative Analysis Activities 데이터 조회 ## Activities Strength Points - 차트 데이터  - strengths > myStrength, recommendedStrength - 충족되지 않은 Strength Names 정보  - strengths > metFlag == false 인 것만 필터링 ## Activities Distribution by Category - 차트 데이터 ## activityCategories > mainAcitivtyCategory 기준으로 > recommendedCategoryCount, myCategoryCount 사용 - 충족되지 않은 Activity Category 정보  - activityCategories > metFlag == false 인 것만 필터링   - activityCategories > mainAcitivtyCategory, activityNames 값 사용 ## Recommended Activities - recommendedActivities 목록 조회  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Activities
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportActivities: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getAnalysisReportActivities', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}/activities`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 상세 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportDetail: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getAnalysisReportDetail', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Dream )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportDream: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/analysis/report/dream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Favorite )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportFavorite: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/analysis/report/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report Academics > Recommended course detail > Recommended Curriculum 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurriculumList: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('getCurriculumList', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/curriculum/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewingPassCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/analysis/report/viewingpass/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReport} reqRoadmapAnalysisReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReport: async (analysisReportId: number, reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('updateAnalysisReport', 'analysisReportId', analysisReportId)
            // verify required parameter 'reqRoadmapAnalysisReport' is not null or undefined
            assertParamExists('updateAnalysisReport', 'reqRoadmapAnalysisReport', reqRoadmapAnalysisReport)
            const localVarPath = `/roadmap/analysis/report/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReportAct} reqRoadmapAnalysisReportAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportAct: async (analysisReportId: number, reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('updateAnalysisReportAct', 'analysisReportId', analysisReportId)
            // verify required parameter 'reqRoadmapAnalysisReportAct' is not null or undefined
            assertParamExists('updateAnalysisReportAct', 'reqRoadmapAnalysisReportAct', reqRoadmapAnalysisReportAct)
            const localVarPath = `/roadmap/analysis/report/act/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReportAct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report Activity 수정
         * @param {number} analysisReportId 
         * @param {List} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportActivity: async (analysisReportId: number, body: List, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('updateAnalysisReportActivity', 'analysisReportId', analysisReportId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAnalysisReportActivity', 'body', body)
            const localVarPath = `/roadmap/analysis/report/activity/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report Favorite 수정
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportFavorite: async (analysisReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('updateAnalysisReportFavorite', 'analysisReportId', analysisReportId)
            const localVarPath = `/roadmap/analysis/report/favorite/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReportSat} reqRoadmapAnalysisReportSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportSat: async (analysisReportId: number, reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisReportId' is not null or undefined
            assertParamExists('updateAnalysisReportSat', 'analysisReportId', analysisReportId)
            // verify required parameter 'reqRoadmapAnalysisReportSat' is not null or undefined
            assertParamExists('updateAnalysisReportSat', 'reqRoadmapAnalysisReportSat', reqRoadmapAnalysisReportSat)
            const localVarPath = `/roadmap/analysis/report/sat/{analysisReportId}`
                .replace(`{${"analysisReportId"}}`, encodeURIComponent(String(analysisReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAnalysisReportSat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapAnalysisApi - functional programming interface
 * @export
 */
export const RoadmapAnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapAnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReport} reqRoadmapAnalysisReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysisReport(reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnalysisReport(reqRoadmapAnalysisReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReportAct} reqRoadmapAnalysisReportAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysisReportAct(reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnalysisReportAct(reqRoadmapAnalysisReportAct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {number} recommendedActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysisReportActivity(recommendedActivityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnalysisReportActivity(recommendedActivityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {ReqRoadmapAnalysisReportSat} reqRoadmapAnalysisReportSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysisReportSat(reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnalysisReportSat(reqRoadmapAnalysisReportSat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 삭제
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnalysisReport(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnalysisReport(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report > Recommended Activity Detail > Recommended Activities 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisAcademicActivities(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisAcademicActivities(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report Activities 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisActivities(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapAnalysisActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisActivities(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 조회 ( 최근 )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapAnalysisReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Comparative Analysis Academics 데이터 조회 ## Test Score, Hight School GPA - x 축 좌표 데이터 목록 조회 ( open data API > Unweighted GPA 목록 ) - myScore > myGpa, myTestScore, targetGpa, targetTestScore 값 사용 ## Admission Requirements Based on Core Curriculum - 차트 데이터  - subjectCategories > myCourseWorkYear, recommendedCourseWorkYear, coreCurriculumCourseWorkYear - Subject Category Names 정보  - coreSubjectCategories names 필터링 - 충족되지 않은 Subject Category Names 정보  - coreSubjectCategories > metFlag == false 인 것만 필터링 ## Recommended Course - 차트 데이터  - subjectCategories > myCourseCount, recommendedCourseCount - 충족되지 않은 Subject Category Names   - coreSubjectCategories > metCourseFlag == false 인 것만 필터링 > title, myCourseCount 값 사용 - English, Arts 정보 ( 랜덤 추출 및 AP 과목 추천은 서버에서 처리 후 최대 3개 데이터 전달 )  - coreSubjectCategories > title, courses > courseTitle  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Academic
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReportAcademics(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapReportAcademic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReportAcademics(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Comparative Analysis Activities 데이터 조회 ## Activities Strength Points - 차트 데이터  - strengths > myStrength, recommendedStrength - 충족되지 않은 Strength Names 정보  - strengths > metFlag == false 인 것만 필터링 ## Activities Distribution by Category - 차트 데이터 ## activityCategories > mainAcitivtyCategory 기준으로 > recommendedCategoryCount, myCategoryCount 사용 - 충족되지 않은 Activity Category 정보  - activityCategories > metFlag == false 인 것만 필터링   - activityCategories > mainAcitivtyCategory, activityNames 값 사용 ## Recommended Activities - recommendedActivities 목록 조회  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Activities
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReportActivities(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapReportActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReportActivities(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 상세 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReportDetail(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapAnalysisReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReportDetail(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Dream )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReportDream(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapAnalysisReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReportDream(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Favorite )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisReportFavorite(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapAnalysisReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisReportFavorite(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report Academics > Recommended course detail > Recommended Curriculum 조회
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurriculumList(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurriculumList(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewingPassCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewingPassCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReport} reqRoadmapAnalysisReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalysisReport(analysisReportId: number, reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalysisReport(analysisReportId, reqRoadmapAnalysisReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReportAct} reqRoadmapAnalysisReportAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalysisReportAct(analysisReportId: number, reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalysisReportAct(analysisReportId, reqRoadmapAnalysisReportAct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report Activity 수정
         * @param {number} analysisReportId 
         * @param {List} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalysisReportActivity(analysisReportId: number, body: List, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalysisReportActivity(analysisReportId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report Favorite 수정
         * @param {number} analysisReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalysisReportFavorite(analysisReportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalysisReportFavorite(analysisReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {number} analysisReportId 
         * @param {ReqRoadmapAnalysisReportSat} reqRoadmapAnalysisReportSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalysisReportSat(analysisReportId: number, reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalysisReportSat(analysisReportId, reqRoadmapAnalysisReportSat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapAnalysisApi - factory interface
 * @export
 */
export const RoadmapAnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapAnalysisApiFp(configuration)
    return {
        /**
         * 
         * @summary Analysis Report 생성
         * @param {RoadmapAnalysisApiCreateAnalysisReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReport(requestParameters: RoadmapAnalysisApiCreateAnalysisReportRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createAnalysisReport(requestParameters.reqRoadmapAnalysisReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {RoadmapAnalysisApiCreateAnalysisReportActRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportAct(requestParameters: RoadmapAnalysisApiCreateAnalysisReportActRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createAnalysisReportAct(requestParameters.reqRoadmapAnalysisReportAct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recommended Activity 데이터로 activity 생성
         * @param {RoadmapAnalysisApiCreateAnalysisReportActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportActivity(requestParameters: RoadmapAnalysisApiCreateAnalysisReportActivityRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapActivity> {
            return localVarFp.createAnalysisReportActivity(requestParameters.recommendedActivityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 생성
         * @param {RoadmapAnalysisApiCreateAnalysisReportSatRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysisReportSat(requestParameters: RoadmapAnalysisApiCreateAnalysisReportSatRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createAnalysisReportSat(requestParameters.reqRoadmapAnalysisReportSat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 삭제
         * @param {RoadmapAnalysisApiDeleteAnalysisReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalysisReport(requestParameters: RoadmapAnalysisApiDeleteAnalysisReportRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAnalysisReport(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report > Recommended Activity Detail > Recommended Activities 조회
         * @param {RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisAcademicActivities(requestParameters: RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapActivity>> {
            return localVarFp.getAnalysisAcademicActivities(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report Activities 조회
         * @param {RoadmapAnalysisApiGetAnalysisActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisActivities(requestParameters: RoadmapAnalysisApiGetAnalysisActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapAnalysisActivity>> {
            return localVarFp.getAnalysisActivities(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 조회 ( 최근 )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReport(options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapAnalysisReport>> {
            return localVarFp.getAnalysisReport(options).then((request) => request(axios, basePath));
        },
        /**
         * Comparative Analysis Academics 데이터 조회 ## Test Score, Hight School GPA - x 축 좌표 데이터 목록 조회 ( open data API > Unweighted GPA 목록 ) - myScore > myGpa, myTestScore, targetGpa, targetTestScore 값 사용 ## Admission Requirements Based on Core Curriculum - 차트 데이터  - subjectCategories > myCourseWorkYear, recommendedCourseWorkYear, coreCurriculumCourseWorkYear - Subject Category Names 정보  - coreSubjectCategories names 필터링 - 충족되지 않은 Subject Category Names 정보  - coreSubjectCategories > metFlag == false 인 것만 필터링 ## Recommended Course - 차트 데이터  - subjectCategories > myCourseCount, recommendedCourseCount - 충족되지 않은 Subject Category Names   - coreSubjectCategories > metCourseFlag == false 인 것만 필터링 > title, myCourseCount 값 사용 - English, Arts 정보 ( 랜덤 추출 및 AP 과목 추천은 서버에서 처리 후 최대 3개 데이터 전달 )  - coreSubjectCategories > title, courses > courseTitle  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Academic
         * @param {RoadmapAnalysisApiGetAnalysisReportAcademicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportAcademics(requestParameters: RoadmapAnalysisApiGetAnalysisReportAcademicsRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapReportAcademic> {
            return localVarFp.getAnalysisReportAcademics(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Comparative Analysis Activities 데이터 조회 ## Activities Strength Points - 차트 데이터  - strengths > myStrength, recommendedStrength - 충족되지 않은 Strength Names 정보  - strengths > metFlag == false 인 것만 필터링 ## Activities Distribution by Category - 차트 데이터 ## activityCategories > mainAcitivtyCategory 기준으로 > recommendedCategoryCount, myCategoryCount 사용 - 충족되지 않은 Activity Category 정보  - activityCategories > metFlag == false 인 것만 필터링   - activityCategories > mainAcitivtyCategory, activityNames 값 사용 ## Recommended Activities - recommendedActivities 목록 조회  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
         * @summary Analysis Report Detail Activities
         * @param {RoadmapAnalysisApiGetAnalysisReportActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportActivities(requestParameters: RoadmapAnalysisApiGetAnalysisReportActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapReportActivity> {
            return localVarFp.getAnalysisReportActivities(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 상세 조회
         * @param {RoadmapAnalysisApiGetAnalysisReportDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportDetail(requestParameters: RoadmapAnalysisApiGetAnalysisReportDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapAnalysisReport> {
            return localVarFp.getAnalysisReportDetail(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Dream )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportDream(options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapAnalysisReport>> {
            return localVarFp.getAnalysisReportDream(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 조회 ( Favorite )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisReportFavorite(options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapAnalysisReport>> {
            return localVarFp.getAnalysisReportFavorite(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report Academics > Recommended course detail > Recommended Curriculum 조회
         * @param {RoadmapAnalysisApiGetCurriculumListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurriculumList(requestParameters: RoadmapAnalysisApiGetCurriculumListRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapCourse>> {
            return localVarFp.getCurriculumList(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewingPassCount(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getViewingPassCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {RoadmapAnalysisApiUpdateAnalysisReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReport(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnalysisReport(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {RoadmapAnalysisApiUpdateAnalysisReportActRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportAct(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportActRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnalysisReportAct(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReportAct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report Activity 수정
         * @param {RoadmapAnalysisApiUpdateAnalysisReportActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportActivity(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportActivityRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnalysisReportActivity(requestParameters.analysisReportId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report Favorite 수정
         * @param {RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportFavorite(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnalysisReportFavorite(requestParameters.analysisReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis Report 수정
         * @param {RoadmapAnalysisApiUpdateAnalysisReportSatRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalysisReportSat(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportSatRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnalysisReportSat(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReportSat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAnalysisReport operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiCreateAnalysisReportRequest
 */
export interface RoadmapAnalysisApiCreateAnalysisReportRequest {
    /**
     * 
     * @type {ReqRoadmapAnalysisReport}
     * @memberof RoadmapAnalysisApiCreateAnalysisReport
     */
    readonly reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport
}

/**
 * Request parameters for createAnalysisReportAct operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiCreateAnalysisReportActRequest
 */
export interface RoadmapAnalysisApiCreateAnalysisReportActRequest {
    /**
     * 
     * @type {ReqRoadmapAnalysisReportAct}
     * @memberof RoadmapAnalysisApiCreateAnalysisReportAct
     */
    readonly reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct
}

/**
 * Request parameters for createAnalysisReportActivity operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiCreateAnalysisReportActivityRequest
 */
export interface RoadmapAnalysisApiCreateAnalysisReportActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiCreateAnalysisReportActivity
     */
    readonly recommendedActivityId: number
}

/**
 * Request parameters for createAnalysisReportSat operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiCreateAnalysisReportSatRequest
 */
export interface RoadmapAnalysisApiCreateAnalysisReportSatRequest {
    /**
     * 
     * @type {ReqRoadmapAnalysisReportSat}
     * @memberof RoadmapAnalysisApiCreateAnalysisReportSat
     */
    readonly reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat
}

/**
 * Request parameters for deleteAnalysisReport operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiDeleteAnalysisReportRequest
 */
export interface RoadmapAnalysisApiDeleteAnalysisReportRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiDeleteAnalysisReport
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getAnalysisAcademicActivities operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest
 */
export interface RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetAnalysisAcademicActivities
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getAnalysisActivities operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetAnalysisActivitiesRequest
 */
export interface RoadmapAnalysisApiGetAnalysisActivitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetAnalysisActivities
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getAnalysisReportAcademics operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetAnalysisReportAcademicsRequest
 */
export interface RoadmapAnalysisApiGetAnalysisReportAcademicsRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetAnalysisReportAcademics
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getAnalysisReportActivities operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetAnalysisReportActivitiesRequest
 */
export interface RoadmapAnalysisApiGetAnalysisReportActivitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetAnalysisReportActivities
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getAnalysisReportDetail operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetAnalysisReportDetailRequest
 */
export interface RoadmapAnalysisApiGetAnalysisReportDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetAnalysisReportDetail
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for getCurriculumList operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiGetCurriculumListRequest
 */
export interface RoadmapAnalysisApiGetCurriculumListRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiGetCurriculumList
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for updateAnalysisReport operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiUpdateAnalysisReportRequest
 */
export interface RoadmapAnalysisApiUpdateAnalysisReportRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReport
     */
    readonly analysisReportId: number

    /**
     * 
     * @type {ReqRoadmapAnalysisReport}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReport
     */
    readonly reqRoadmapAnalysisReport: ReqRoadmapAnalysisReport
}

/**
 * Request parameters for updateAnalysisReportAct operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiUpdateAnalysisReportActRequest
 */
export interface RoadmapAnalysisApiUpdateAnalysisReportActRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportAct
     */
    readonly analysisReportId: number

    /**
     * 
     * @type {ReqRoadmapAnalysisReportAct}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportAct
     */
    readonly reqRoadmapAnalysisReportAct: ReqRoadmapAnalysisReportAct
}

/**
 * Request parameters for updateAnalysisReportActivity operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiUpdateAnalysisReportActivityRequest
 */
export interface RoadmapAnalysisApiUpdateAnalysisReportActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportActivity
     */
    readonly analysisReportId: number

    /**
     * 
     * @type {List}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportActivity
     */
    readonly body: List
}

/**
 * Request parameters for updateAnalysisReportFavorite operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest
 */
export interface RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportFavorite
     */
    readonly analysisReportId: number
}

/**
 * Request parameters for updateAnalysisReportSat operation in RoadmapAnalysisApi.
 * @export
 * @interface RoadmapAnalysisApiUpdateAnalysisReportSatRequest
 */
export interface RoadmapAnalysisApiUpdateAnalysisReportSatRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportSat
     */
    readonly analysisReportId: number

    /**
     * 
     * @type {ReqRoadmapAnalysisReportSat}
     * @memberof RoadmapAnalysisApiUpdateAnalysisReportSat
     */
    readonly reqRoadmapAnalysisReportSat: ReqRoadmapAnalysisReportSat
}

/**
 * RoadmapAnalysisApi - object-oriented interface
 * @export
 * @class RoadmapAnalysisApi
 * @extends {BaseAPI}
 */
export class RoadmapAnalysisApi extends BaseAPI {
    /**
     * 
     * @summary Analysis Report 생성
     * @param {RoadmapAnalysisApiCreateAnalysisReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public createAnalysisReport(requestParameters: RoadmapAnalysisApiCreateAnalysisReportRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).createAnalysisReport(requestParameters.reqRoadmapAnalysisReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 생성
     * @param {RoadmapAnalysisApiCreateAnalysisReportActRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public createAnalysisReportAct(requestParameters: RoadmapAnalysisApiCreateAnalysisReportActRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).createAnalysisReportAct(requestParameters.reqRoadmapAnalysisReportAct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recommended Activity 데이터로 activity 생성
     * @param {RoadmapAnalysisApiCreateAnalysisReportActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public createAnalysisReportActivity(requestParameters: RoadmapAnalysisApiCreateAnalysisReportActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).createAnalysisReportActivity(requestParameters.recommendedActivityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 생성
     * @param {RoadmapAnalysisApiCreateAnalysisReportSatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public createAnalysisReportSat(requestParameters: RoadmapAnalysisApiCreateAnalysisReportSatRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).createAnalysisReportSat(requestParameters.reqRoadmapAnalysisReportSat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 삭제
     * @param {RoadmapAnalysisApiDeleteAnalysisReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public deleteAnalysisReport(requestParameters: RoadmapAnalysisApiDeleteAnalysisReportRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).deleteAnalysisReport(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report > Recommended Activity Detail > Recommended Activities 조회
     * @param {RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisAcademicActivities(requestParameters: RoadmapAnalysisApiGetAnalysisAcademicActivitiesRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisAcademicActivities(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report Activities 조회
     * @param {RoadmapAnalysisApiGetAnalysisActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisActivities(requestParameters: RoadmapAnalysisApiGetAnalysisActivitiesRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisActivities(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 조회 ( 최근 )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReport(options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Comparative Analysis Academics 데이터 조회 ## Test Score, Hight School GPA - x 축 좌표 데이터 목록 조회 ( open data API > Unweighted GPA 목록 ) - myScore > myGpa, myTestScore, targetGpa, targetTestScore 값 사용 ## Admission Requirements Based on Core Curriculum - 차트 데이터  - subjectCategories > myCourseWorkYear, recommendedCourseWorkYear, coreCurriculumCourseWorkYear - Subject Category Names 정보  - coreSubjectCategories names 필터링 - 충족되지 않은 Subject Category Names 정보  - coreSubjectCategories > metFlag == false 인 것만 필터링 ## Recommended Course - 차트 데이터  - subjectCategories > myCourseCount, recommendedCourseCount - 충족되지 않은 Subject Category Names   - coreSubjectCategories > metCourseFlag == false 인 것만 필터링 > title, myCourseCount 값 사용 - English, Arts 정보 ( 랜덤 추출 및 AP 과목 추천은 서버에서 처리 후 최대 3개 데이터 전달 )  - coreSubjectCategories > title, courses > courseTitle  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
     * @summary Analysis Report Detail Academic
     * @param {RoadmapAnalysisApiGetAnalysisReportAcademicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReportAcademics(requestParameters: RoadmapAnalysisApiGetAnalysisReportAcademicsRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReportAcademics(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Comparative Analysis Activities 데이터 조회 ## Activities Strength Points - 차트 데이터  - strengths > myStrength, recommendedStrength - 충족되지 않은 Strength Names 정보  - strengths > metFlag == false 인 것만 필터링 ## Activities Distribution by Category - 차트 데이터 ## activityCategories > mainAcitivtyCategory 기준으로 > recommendedCategoryCount, myCategoryCount 사용 - 충족되지 않은 Activity Category 정보  - activityCategories > metFlag == false 인 것만 필터링   - activityCategories > mainAcitivtyCategory, activityNames 값 사용 ## Recommended Activities - recommendedActivities 목록 조회  ## report 데이터가 안나올 경우 아래 데이터로 이용. - dream college Id : 110404 - dream major Id 11.0401
     * @summary Analysis Report Detail Activities
     * @param {RoadmapAnalysisApiGetAnalysisReportActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReportActivities(requestParameters: RoadmapAnalysisApiGetAnalysisReportActivitiesRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReportActivities(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 상세 조회
     * @param {RoadmapAnalysisApiGetAnalysisReportDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReportDetail(requestParameters: RoadmapAnalysisApiGetAnalysisReportDetailRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReportDetail(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 조회 ( Dream )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReportDream(options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReportDream(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 조회 ( Favorite )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getAnalysisReportFavorite(options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getAnalysisReportFavorite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report Academics > Recommended course detail > Recommended Curriculum 조회
     * @param {RoadmapAnalysisApiGetCurriculumListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getCurriculumList(requestParameters: RoadmapAnalysisApiGetCurriculumListRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getCurriculumList(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public getViewingPassCount(options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).getViewingPassCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 수정
     * @param {RoadmapAnalysisApiUpdateAnalysisReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public updateAnalysisReport(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).updateAnalysisReport(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 수정
     * @param {RoadmapAnalysisApiUpdateAnalysisReportActRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public updateAnalysisReportAct(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportActRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).updateAnalysisReportAct(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReportAct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report Activity 수정
     * @param {RoadmapAnalysisApiUpdateAnalysisReportActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public updateAnalysisReportActivity(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportActivityRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).updateAnalysisReportActivity(requestParameters.analysisReportId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report Favorite 수정
     * @param {RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public updateAnalysisReportFavorite(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportFavoriteRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).updateAnalysisReportFavorite(requestParameters.analysisReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis Report 수정
     * @param {RoadmapAnalysisApiUpdateAnalysisReportSatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAnalysisApi
     */
    public updateAnalysisReportSat(requestParameters: RoadmapAnalysisApiUpdateAnalysisReportSatRequest, options?: AxiosRequestConfig) {
        return RoadmapAnalysisApiFp(this.configuration).updateAnalysisReportSat(requestParameters.analysisReportId, requestParameters.reqRoadmapAnalysisReportSat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapAwardsApi - axios parameter creator
 * @export
 */
export const RoadmapAwardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Awards 저장
         * @param {ReqRoadmapAward} reqRoadmapAward 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapAward: async (reqRoadmapAward: ReqRoadmapAward, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapAward' is not null or undefined
            assertParamExists('createRoadmapAward', 'reqRoadmapAward', reqRoadmapAward)
            const localVarPath = `/roadmap/awards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapAward, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Awards 삭제
         * @param {number} awardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAward: async (awardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'awardId' is not null or undefined
            assertParamExists('deleteAward', 'awardId', awardId)
            const localVarPath = `/roadmap/awards/{awardId}`
                .replace(`{${"awardId"}}`, encodeURIComponent(String(awardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Awards 상세 조회
         * @param {number} awardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapAward: async (awardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'awardId' is not null or undefined
            assertParamExists('getRoadmapAward', 'awardId', awardId)
            const localVarPath = `/roadmap/awards/{awardId}`
                .replace(`{${"awardId"}}`, encodeURIComponent(String(awardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Awards 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapAwards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/awards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapAwardsApi - functional programming interface
 * @export
 */
export const RoadmapAwardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapAwardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Awards 저장
         * @param {ReqRoadmapAward} reqRoadmapAward 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoadmapAward(reqRoadmapAward: ReqRoadmapAward, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapAward>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoadmapAward(reqRoadmapAward, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Awards 삭제
         * @param {number} awardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAward(awardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAward(awardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Awards 상세 조회
         * @param {number} awardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapAward(awardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapAward>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapAward(awardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Awards 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapAwards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapAward>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapAwards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapAwardsApi - factory interface
 * @export
 */
export const RoadmapAwardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapAwardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Awards 저장
         * @param {RoadmapAwardsApiCreateRoadmapAwardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapAward(requestParameters: RoadmapAwardsApiCreateRoadmapAwardRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapAward> {
            return localVarFp.createRoadmapAward(requestParameters.reqRoadmapAward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Awards 삭제
         * @param {RoadmapAwardsApiDeleteAwardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAward(requestParameters: RoadmapAwardsApiDeleteAwardRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAward(requestParameters.awardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Awards 상세 조회
         * @param {RoadmapAwardsApiGetRoadmapAwardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapAward(requestParameters: RoadmapAwardsApiGetRoadmapAwardRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapAward> {
            return localVarFp.getRoadmapAward(requestParameters.awardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Awards 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapAwards(options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapAward>> {
            return localVarFp.getRoadmapAwards(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRoadmapAward operation in RoadmapAwardsApi.
 * @export
 * @interface RoadmapAwardsApiCreateRoadmapAwardRequest
 */
export interface RoadmapAwardsApiCreateRoadmapAwardRequest {
    /**
     * 
     * @type {ReqRoadmapAward}
     * @memberof RoadmapAwardsApiCreateRoadmapAward
     */
    readonly reqRoadmapAward: ReqRoadmapAward
}

/**
 * Request parameters for deleteAward operation in RoadmapAwardsApi.
 * @export
 * @interface RoadmapAwardsApiDeleteAwardRequest
 */
export interface RoadmapAwardsApiDeleteAwardRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAwardsApiDeleteAward
     */
    readonly awardId: number
}

/**
 * Request parameters for getRoadmapAward operation in RoadmapAwardsApi.
 * @export
 * @interface RoadmapAwardsApiGetRoadmapAwardRequest
 */
export interface RoadmapAwardsApiGetRoadmapAwardRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapAwardsApiGetRoadmapAward
     */
    readonly awardId: number
}

/**
 * RoadmapAwardsApi - object-oriented interface
 * @export
 * @class RoadmapAwardsApi
 * @extends {BaseAPI}
 */
export class RoadmapAwardsApi extends BaseAPI {
    /**
     * 
     * @summary Awards 저장
     * @param {RoadmapAwardsApiCreateRoadmapAwardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAwardsApi
     */
    public createRoadmapAward(requestParameters: RoadmapAwardsApiCreateRoadmapAwardRequest, options?: AxiosRequestConfig) {
        return RoadmapAwardsApiFp(this.configuration).createRoadmapAward(requestParameters.reqRoadmapAward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Awards 삭제
     * @param {RoadmapAwardsApiDeleteAwardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAwardsApi
     */
    public deleteAward(requestParameters: RoadmapAwardsApiDeleteAwardRequest, options?: AxiosRequestConfig) {
        return RoadmapAwardsApiFp(this.configuration).deleteAward(requestParameters.awardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Awards 상세 조회
     * @param {RoadmapAwardsApiGetRoadmapAwardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAwardsApi
     */
    public getRoadmapAward(requestParameters: RoadmapAwardsApiGetRoadmapAwardRequest, options?: AxiosRequestConfig) {
        return RoadmapAwardsApiFp(this.configuration).getRoadmapAward(requestParameters.awardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Awards 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapAwardsApi
     */
    public getRoadmapAwards(options?: AxiosRequestConfig) {
        return RoadmapAwardsApiFp(this.configuration).getRoadmapAwards(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapCourseworkApi - axios parameter creator
 * @export
 */
export const RoadmapCourseworkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary course 저장
         * @param {ReqRoadmapCourse} reqRoadmapCourse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreate: async (reqRoadmapCourse: ReqRoadmapCourse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapCourse' is not null or undefined
            assertParamExists('courseCreate', 'reqRoadmapCourse', reqRoadmapCourse)
            const localVarPath = `/roadmap/coursework/course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapCourse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학년별 Course 목록 조회
         * @param {number} courseWorkId 
         * @param {number} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGrade: async (courseWorkId: number, grade: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseWorkId' is not null or undefined
            assertParamExists('getRoadmapCourseListByGrade', 'courseWorkId', courseWorkId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('getRoadmapCourseListByGrade', 'grade', grade)
            const localVarPath = `/roadmap/coursework/course/{courseWorkId}/{grade}`
                .replace(`{${"courseWorkId"}}`, encodeURIComponent(String(courseWorkId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학년,학기 Course 목록 조회
         * @param {number} courseWorkId 
         * @param {number} grade 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGradeAndSemester: async (courseWorkId: number, grade: number, semester: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseWorkId' is not null or undefined
            assertParamExists('getRoadmapCourseListByGradeAndSemester', 'courseWorkId', courseWorkId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('getRoadmapCourseListByGradeAndSemester', 'grade', grade)
            // verify required parameter 'semester' is not null or undefined
            assertParamExists('getRoadmapCourseListByGradeAndSemester', 'semester', semester)
            const localVarPath = `/roadmap/coursework/course/{courseWorkId}/{grade}/{semester}`
                .replace(`{${"courseWorkId"}}`, encodeURIComponent(String(courseWorkId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 교과 관리 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseWorkInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/coursework`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary course 삭제
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove1: async (courseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('remove1', 'courseId', courseId)
            const localVarPath = `/roadmap/coursework/course/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapCourseworkApi - functional programming interface
 * @export
 */
export const RoadmapCourseworkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapCourseworkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary course 저장
         * @param {ReqRoadmapCourse} reqRoadmapCourse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreate(reqRoadmapCourse: ReqRoadmapCourse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapCourse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseCreate(reqRoadmapCourse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학년별 Course 목록 조회
         * @param {number} courseWorkId 
         * @param {number} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapCourseListByGrade(courseWorkId: number, grade: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapCourseListByGrade(courseWorkId, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학년,학기 Course 목록 조회
         * @param {number} courseWorkId 
         * @param {number} grade 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapCourseListByGradeAndSemester(courseWorkId: number, grade: number, semester: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapCourseListByGradeAndSemester(courseWorkId, grade, semester, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 교과 관리 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapCourseWorkInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapCourseWork>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapCourseWorkInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary course 삭제
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove1(courseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove1(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapCourseworkApi - factory interface
 * @export
 */
export const RoadmapCourseworkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapCourseworkApiFp(configuration)
    return {
        /**
         * 
         * @summary course 저장
         * @param {RoadmapCourseworkApiCourseCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreate(requestParameters: RoadmapCourseworkApiCourseCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapCourse> {
            return localVarFp.courseCreate(requestParameters.reqRoadmapCourse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학년별 Course 목록 조회
         * @param {RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGrade(requestParameters: RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapCourse>> {
            return localVarFp.getRoadmapCourseListByGrade(requestParameters.courseWorkId, requestParameters.grade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학년,학기 Course 목록 조회
         * @param {RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseListByGradeAndSemester(requestParameters: RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapCourse>> {
            return localVarFp.getRoadmapCourseListByGradeAndSemester(requestParameters.courseWorkId, requestParameters.grade, requestParameters.semester, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 교과 관리 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapCourseWorkInfo(options?: AxiosRequestConfig): AxiosPromise<ResRoadmapCourseWork> {
            return localVarFp.getRoadmapCourseWorkInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary course 삭제
         * @param {RoadmapCourseworkApiRemove1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove1(requestParameters: RoadmapCourseworkApiRemove1Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.remove1(requestParameters.courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for courseCreate operation in RoadmapCourseworkApi.
 * @export
 * @interface RoadmapCourseworkApiCourseCreateRequest
 */
export interface RoadmapCourseworkApiCourseCreateRequest {
    /**
     * 
     * @type {ReqRoadmapCourse}
     * @memberof RoadmapCourseworkApiCourseCreate
     */
    readonly reqRoadmapCourse: ReqRoadmapCourse
}

/**
 * Request parameters for getRoadmapCourseListByGrade operation in RoadmapCourseworkApi.
 * @export
 * @interface RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest
 */
export interface RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiGetRoadmapCourseListByGrade
     */
    readonly courseWorkId: number

    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiGetRoadmapCourseListByGrade
     */
    readonly grade: number
}

/**
 * Request parameters for getRoadmapCourseListByGradeAndSemester operation in RoadmapCourseworkApi.
 * @export
 * @interface RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest
 */
export interface RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemester
     */
    readonly courseWorkId: number

    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemester
     */
    readonly grade: number

    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemester
     */
    readonly semester: number
}

/**
 * Request parameters for remove1 operation in RoadmapCourseworkApi.
 * @export
 * @interface RoadmapCourseworkApiRemove1Request
 */
export interface RoadmapCourseworkApiRemove1Request {
    /**
     * 
     * @type {number}
     * @memberof RoadmapCourseworkApiRemove1
     */
    readonly courseId: number
}

/**
 * RoadmapCourseworkApi - object-oriented interface
 * @export
 * @class RoadmapCourseworkApi
 * @extends {BaseAPI}
 */
export class RoadmapCourseworkApi extends BaseAPI {
    /**
     * 
     * @summary course 저장
     * @param {RoadmapCourseworkApiCourseCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapCourseworkApi
     */
    public courseCreate(requestParameters: RoadmapCourseworkApiCourseCreateRequest, options?: AxiosRequestConfig) {
        return RoadmapCourseworkApiFp(this.configuration).courseCreate(requestParameters.reqRoadmapCourse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학년별 Course 목록 조회
     * @param {RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapCourseworkApi
     */
    public getRoadmapCourseListByGrade(requestParameters: RoadmapCourseworkApiGetRoadmapCourseListByGradeRequest, options?: AxiosRequestConfig) {
        return RoadmapCourseworkApiFp(this.configuration).getRoadmapCourseListByGrade(requestParameters.courseWorkId, requestParameters.grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학년,학기 Course 목록 조회
     * @param {RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapCourseworkApi
     */
    public getRoadmapCourseListByGradeAndSemester(requestParameters: RoadmapCourseworkApiGetRoadmapCourseListByGradeAndSemesterRequest, options?: AxiosRequestConfig) {
        return RoadmapCourseworkApiFp(this.configuration).getRoadmapCourseListByGradeAndSemester(requestParameters.courseWorkId, requestParameters.grade, requestParameters.semester, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 교과 관리 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapCourseworkApi
     */
    public getRoadmapCourseWorkInfo(options?: AxiosRequestConfig) {
        return RoadmapCourseworkApiFp(this.configuration).getRoadmapCourseWorkInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary course 삭제
     * @param {RoadmapCourseworkApiRemove1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapCourseworkApi
     */
    public remove1(requestParameters: RoadmapCourseworkApiRemove1Request, options?: AxiosRequestConfig) {
        return RoadmapCourseworkApiFp(this.configuration).remove1(requestParameters.courseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadmapTestScoresApi - axios parameter creator
 * @export
 */
export const RoadmapTestScoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Test Scores ACT 생성
         * @param {ReqRoadmapTestScoreAct} reqRoadmapTestScoreAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreAct: async (reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapTestScoreAct' is not null or undefined
            assertParamExists('createRoadmapTestScoreAct', 'reqRoadmapTestScoreAct', reqRoadmapTestScoreAct)
            const localVarPath = `/roadmap/test-scores/act`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreAct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores AP 생성
         * @param {ReqRoadmapTestScoreAp} reqRoadmapTestScoreAp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreAp: async (reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapTestScoreAp' is not null or undefined
            assertParamExists('createRoadmapTestScoreAp', 'reqRoadmapTestScoreAp', reqRoadmapTestScoreAp)
            const localVarPath = `/roadmap/test-scores/ap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreAp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores SAT 생성
         * @param {ReqRoadmapTestScoreSat} reqRoadmapTestScoreSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreSat: async (reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqRoadmapTestScoreSat' is not null or undefined
            assertParamExists('createRoadmapTestScoreSat', 'reqRoadmapTestScoreSat', reqRoadmapTestScoreSat)
            const localVarPath = `/roadmap/test-scores/sat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreSat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores 삭제
         * @param {number} testScoreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestScore: async (testScoreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testScoreId' is not null or undefined
            assertParamExists('deleteTestScore', 'testScoreId', testScoreId)
            const localVarPath = `/roadmap/test-scores/{testScoreId}`
                .replace(`{${"testScoreId"}}`, encodeURIComponent(String(testScoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores 상세 조회
         * @param {number} testScoreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScore: async (testScoreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testScoreId' is not null or undefined
            assertParamExists('getRoadmapTestScore', 'testScoreId', testScoreId)
            const localVarPath = `/roadmap/test-scores/{testScoreId}`
                .replace(`{${"testScoreId"}}`, encodeURIComponent(String(testScoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores My Score 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScoreMyScore: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/test-scores/my-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roadmap/test-scores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores ACT 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreAct} reqRoadmapTestScoreAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreAct: async (testScoreId: number, reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testScoreId' is not null or undefined
            assertParamExists('updateRoadmapTestScoreAct', 'testScoreId', testScoreId)
            // verify required parameter 'reqRoadmapTestScoreAct' is not null or undefined
            assertParamExists('updateRoadmapTestScoreAct', 'reqRoadmapTestScoreAct', reqRoadmapTestScoreAct)
            const localVarPath = `/roadmap/test-scores/act/{testScoreId}`
                .replace(`{${"testScoreId"}}`, encodeURIComponent(String(testScoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreAct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores AP 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreAp} reqRoadmapTestScoreAp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreAp: async (testScoreId: number, reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testScoreId' is not null or undefined
            assertParamExists('updateRoadmapTestScoreAp', 'testScoreId', testScoreId)
            // verify required parameter 'reqRoadmapTestScoreAp' is not null or undefined
            assertParamExists('updateRoadmapTestScoreAp', 'reqRoadmapTestScoreAp', reqRoadmapTestScoreAp)
            const localVarPath = `/roadmap/test-scores/ap/{testScoreId}`
                .replace(`{${"testScoreId"}}`, encodeURIComponent(String(testScoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreAp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Scores SAT 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreSat} reqRoadmapTestScoreSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreSat: async (testScoreId: number, reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testScoreId' is not null or undefined
            assertParamExists('updateRoadmapTestScoreSat', 'testScoreId', testScoreId)
            // verify required parameter 'reqRoadmapTestScoreSat' is not null or undefined
            assertParamExists('updateRoadmapTestScoreSat', 'reqRoadmapTestScoreSat', reqRoadmapTestScoreSat)
            const localVarPath = `/roadmap/test-scores/sat/{testScoreId}`
                .replace(`{${"testScoreId"}}`, encodeURIComponent(String(testScoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqRoadmapTestScoreSat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadmapTestScoresApi - functional programming interface
 * @export
 */
export const RoadmapTestScoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadmapTestScoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Test Scores ACT 생성
         * @param {ReqRoadmapTestScoreAct} reqRoadmapTestScoreAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoadmapTestScoreAct(reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoadmapTestScoreAct(reqRoadmapTestScoreAct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores AP 생성
         * @param {ReqRoadmapTestScoreAp} reqRoadmapTestScoreAp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoadmapTestScoreAp(reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoadmapTestScoreAp(reqRoadmapTestScoreAp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores SAT 생성
         * @param {ReqRoadmapTestScoreSat} reqRoadmapTestScoreSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoadmapTestScoreSat(reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoadmapTestScoreSat(reqRoadmapTestScoreSat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores 삭제
         * @param {number} testScoreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestScore(testScoreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestScore(testScoreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores 상세 조회
         * @param {number} testScoreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapTestScore(testScoreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapTestScore(testScoreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores My Score 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapTestScoreMyScore(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScoreMyScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapTestScoreMyScore(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoadmapTestScores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResRoadmapTestScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoadmapTestScores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores ACT 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreAct} reqRoadmapTestScoreAct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTestScoreAct(testScoreId: number, reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTestScoreAct(testScoreId, reqRoadmapTestScoreAct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores AP 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreAp} reqRoadmapTestScoreAp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTestScoreAp(testScoreId: number, reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTestScoreAp(testScoreId, reqRoadmapTestScoreAp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Scores SAT 수정
         * @param {number} testScoreId 
         * @param {ReqRoadmapTestScoreSat} reqRoadmapTestScoreSat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoadmapTestScoreSat(testScoreId: number, reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResRoadmapTestScore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoadmapTestScoreSat(testScoreId, reqRoadmapTestScoreSat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadmapTestScoresApi - factory interface
 * @export
 */
export const RoadmapTestScoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadmapTestScoresApiFp(configuration)
    return {
        /**
         * 
         * @summary Test Scores ACT 생성
         * @param {RoadmapTestScoresApiCreateRoadmapTestScoreActRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreAct(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreActRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.createRoadmapTestScoreAct(requestParameters.reqRoadmapTestScoreAct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores AP 생성
         * @param {RoadmapTestScoresApiCreateRoadmapTestScoreApRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreAp(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreApRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.createRoadmapTestScoreAp(requestParameters.reqRoadmapTestScoreAp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores SAT 생성
         * @param {RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoadmapTestScoreSat(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.createRoadmapTestScoreSat(requestParameters.reqRoadmapTestScoreSat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores 삭제
         * @param {RoadmapTestScoresApiDeleteTestScoreRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestScore(requestParameters: RoadmapTestScoresApiDeleteTestScoreRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTestScore(requestParameters.testScoreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores 상세 조회
         * @param {RoadmapTestScoresApiGetRoadmapTestScoreRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScore(requestParameters: RoadmapTestScoresApiGetRoadmapTestScoreRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.getRoadmapTestScore(requestParameters.testScoreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores My Score 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScoreMyScore(options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScoreMyScore> {
            return localVarFp.getRoadmapTestScoreMyScore(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapTestScores(options?: AxiosRequestConfig): AxiosPromise<Array<ResRoadmapTestScore>> {
            return localVarFp.getRoadmapTestScores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores ACT 수정
         * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreAct(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.updateRoadmapTestScoreAct(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreAct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores AP 수정
         * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreAp(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.updateRoadmapTestScoreAp(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreAp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Scores SAT 수정
         * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoadmapTestScoreSat(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest, options?: AxiosRequestConfig): AxiosPromise<ResRoadmapTestScore> {
            return localVarFp.updateRoadmapTestScoreSat(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreSat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRoadmapTestScoreAct operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiCreateRoadmapTestScoreActRequest
 */
export interface RoadmapTestScoresApiCreateRoadmapTestScoreActRequest {
    /**
     * 
     * @type {ReqRoadmapTestScoreAct}
     * @memberof RoadmapTestScoresApiCreateRoadmapTestScoreAct
     */
    readonly reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct
}

/**
 * Request parameters for createRoadmapTestScoreAp operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiCreateRoadmapTestScoreApRequest
 */
export interface RoadmapTestScoresApiCreateRoadmapTestScoreApRequest {
    /**
     * 
     * @type {ReqRoadmapTestScoreAp}
     * @memberof RoadmapTestScoresApiCreateRoadmapTestScoreAp
     */
    readonly reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp
}

/**
 * Request parameters for createRoadmapTestScoreSat operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest
 */
export interface RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest {
    /**
     * 
     * @type {ReqRoadmapTestScoreSat}
     * @memberof RoadmapTestScoresApiCreateRoadmapTestScoreSat
     */
    readonly reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat
}

/**
 * Request parameters for deleteTestScore operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiDeleteTestScoreRequest
 */
export interface RoadmapTestScoresApiDeleteTestScoreRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapTestScoresApiDeleteTestScore
     */
    readonly testScoreId: number
}

/**
 * Request parameters for getRoadmapTestScore operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiGetRoadmapTestScoreRequest
 */
export interface RoadmapTestScoresApiGetRoadmapTestScoreRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapTestScoresApiGetRoadmapTestScore
     */
    readonly testScoreId: number
}

/**
 * Request parameters for updateRoadmapTestScoreAct operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest
 */
export interface RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreAct
     */
    readonly testScoreId: number

    /**
     * 
     * @type {ReqRoadmapTestScoreAct}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreAct
     */
    readonly reqRoadmapTestScoreAct: ReqRoadmapTestScoreAct
}

/**
 * Request parameters for updateRoadmapTestScoreAp operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest
 */
export interface RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreAp
     */
    readonly testScoreId: number

    /**
     * 
     * @type {ReqRoadmapTestScoreAp}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreAp
     */
    readonly reqRoadmapTestScoreAp: ReqRoadmapTestScoreAp
}

/**
 * Request parameters for updateRoadmapTestScoreSat operation in RoadmapTestScoresApi.
 * @export
 * @interface RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest
 */
export interface RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreSat
     */
    readonly testScoreId: number

    /**
     * 
     * @type {ReqRoadmapTestScoreSat}
     * @memberof RoadmapTestScoresApiUpdateRoadmapTestScoreSat
     */
    readonly reqRoadmapTestScoreSat: ReqRoadmapTestScoreSat
}

/**
 * RoadmapTestScoresApi - object-oriented interface
 * @export
 * @class RoadmapTestScoresApi
 * @extends {BaseAPI}
 */
export class RoadmapTestScoresApi extends BaseAPI {
    /**
     * 
     * @summary Test Scores ACT 생성
     * @param {RoadmapTestScoresApiCreateRoadmapTestScoreActRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public createRoadmapTestScoreAct(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreActRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).createRoadmapTestScoreAct(requestParameters.reqRoadmapTestScoreAct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores AP 생성
     * @param {RoadmapTestScoresApiCreateRoadmapTestScoreApRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public createRoadmapTestScoreAp(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreApRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).createRoadmapTestScoreAp(requestParameters.reqRoadmapTestScoreAp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores SAT 생성
     * @param {RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public createRoadmapTestScoreSat(requestParameters: RoadmapTestScoresApiCreateRoadmapTestScoreSatRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).createRoadmapTestScoreSat(requestParameters.reqRoadmapTestScoreSat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores 삭제
     * @param {RoadmapTestScoresApiDeleteTestScoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public deleteTestScore(requestParameters: RoadmapTestScoresApiDeleteTestScoreRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).deleteTestScore(requestParameters.testScoreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores 상세 조회
     * @param {RoadmapTestScoresApiGetRoadmapTestScoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public getRoadmapTestScore(requestParameters: RoadmapTestScoresApiGetRoadmapTestScoreRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).getRoadmapTestScore(requestParameters.testScoreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores My Score 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public getRoadmapTestScoreMyScore(options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).getRoadmapTestScoreMyScore(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public getRoadmapTestScores(options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).getRoadmapTestScores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores ACT 수정
     * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public updateRoadmapTestScoreAct(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreActRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).updateRoadmapTestScoreAct(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreAct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores AP 수정
     * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public updateRoadmapTestScoreAp(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreApRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).updateRoadmapTestScoreAp(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreAp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Scores SAT 수정
     * @param {RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadmapTestScoresApi
     */
    public updateRoadmapTestScoreSat(requestParameters: RoadmapTestScoresApiUpdateRoadmapTestScoreSatRequest, options?: AxiosRequestConfig) {
        return RoadmapTestScoresApiFp(this.configuration).updateRoadmapTestScoreSat(requestParameters.testScoreId, requestParameters.reqRoadmapTestScoreSat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SurveyApi - axios parameter creator
 * @export
 */
export const SurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 전문가 설문 작성
         * @param {ReqExpertSurvey} reqExpertSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSurveyCreate: async (reqExpertSurvey: ReqExpertSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqExpertSurvey' is not null or undefined
            assertParamExists('expertSurveyCreate', 'reqExpertSurvey', reqExpertSurvey)
            const localVarPath = `/survey/write/expert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqExpertSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전문가 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyExpert: async (memberSurveyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSurveyId' is not null or undefined
            assertParamExists('getSurveyExpert', 'memberSurveyId', memberSurveyId)
            const localVarPath = `/survey/get/expert/{memberSurveyId}`
                .replace(`{${"memberSurveyId"}}`, encodeURIComponent(String(memberSurveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 부모 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyParent: async (memberSurveyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSurveyId' is not null or undefined
            assertParamExists('getSurveyParent', 'memberSurveyId', memberSurveyId)
            const localVarPath = `/survey/get/parent/{memberSurveyId}`
                .replace(`{${"memberSurveyId"}}`, encodeURIComponent(String(memberSurveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyStudent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/survey/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyStudent1: async (memberSurveyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSurveyId' is not null or undefined
            assertParamExists('getSurveyStudent1', 'memberSurveyId', memberSurveyId)
            const localVarPath = `/survey/get/student/{memberSurveyId}`
                .replace(`{${"memberSurveyId"}}`, encodeURIComponent(String(memberSurveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 설문기본정보 조회(설문 등록 이력이 없을 경우 조회)-설문전에 mypond에서 사용자 정보를 입력했을 경우 해당 값을 사용하기 위해 사용하는 api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTempProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/survey/default-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 부모 설문 작성
         * @param {ReqParentSurvey} reqParentSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentSurveyCreate: async (reqParentSurvey: ReqParentSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqParentSurvey' is not null or undefined
            assertParamExists('parentSurveyCreate', 'reqParentSurvey', reqParentSurvey)
            const localVarPath = `/survey/write/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqParentSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 설문 삭제
         * @param {number} surveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSurvey: async (surveyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('removeSurvey', 'surveyId', surveyId)
            const localVarPath = `/survey/delete/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 설문 작성
         * @param {ReqStudentSurvey} reqStudentSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSurveyCreate: async (reqStudentSurvey: ReqStudentSurvey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reqStudentSurvey' is not null or undefined
            assertParamExists('studentSurveyCreate', 'reqStudentSurvey', reqStudentSurvey)
            const localVarPath = `/survey/write/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqStudentSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 학생 설문 수정
         * @param {number} surveyId 
         * @param {ReqStudentSurveyUpdate} reqStudentSurveyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSurveyUpdate: async (surveyId: number, reqStudentSurveyUpdate: ReqStudentSurveyUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('studentSurveyUpdate', 'surveyId', surveyId)
            // verify required parameter 'reqStudentSurveyUpdate' is not null or undefined
            assertParamExists('studentSurveyUpdate', 'reqStudentSurveyUpdate', reqStudentSurveyUpdate)
            const localVarPath = `/survey/update/student/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqStudentSurveyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyApi - functional programming interface
 * @export
 */
export const SurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 전문가 설문 작성
         * @param {ReqExpertSurvey} reqExpertSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertSurveyCreate(reqExpertSurvey: ReqExpertSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResExpertSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertSurveyCreate(reqExpertSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전문가 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyExpert(memberSurveyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResExpertSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyExpert(memberSurveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 부모 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyParent(memberSurveyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResParentSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyParent(memberSurveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyStudent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResStudentSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyStudent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {number} memberSurveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyStudent1(memberSurveyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResStudentSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyStudent1(memberSurveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 설문기본정보 조회(설문 등록 이력이 없을 경우 조회)-설문전에 mypond에서 사용자 정보를 입력했을 경우 해당 값을 사용하기 위해 사용하는 api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTempProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMemberSurveyTempProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTempProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 부모 설문 작성
         * @param {ReqParentSurvey} reqParentSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parentSurveyCreate(reqParentSurvey: ReqParentSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResParentSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parentSurveyCreate(reqParentSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 설문 삭제
         * @param {number} surveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSurvey(surveyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSurvey(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 설문 작성
         * @param {ReqStudentSurvey} reqStudentSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentSurveyCreate(reqStudentSurvey: ReqStudentSurvey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResStudentSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentSurveyCreate(reqStudentSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 학생 설문 수정
         * @param {number} surveyId 
         * @param {ReqStudentSurveyUpdate} reqStudentSurveyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentSurveyUpdate(surveyId: number, reqStudentSurveyUpdate: ReqStudentSurveyUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentSurveyUpdate(surveyId, reqStudentSurveyUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyApi - factory interface
 * @export
 */
export const SurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyApiFp(configuration)
    return {
        /**
         * 
         * @summary 전문가 설문 작성
         * @param {SurveyApiExpertSurveyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSurveyCreate(requestParameters: SurveyApiExpertSurveyCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ResExpertSurvey> {
            return localVarFp.expertSurveyCreate(requestParameters.reqExpertSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전문가 설문 조회
         * @param {SurveyApiGetSurveyExpertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyExpert(requestParameters: SurveyApiGetSurveyExpertRequest, options?: AxiosRequestConfig): AxiosPromise<ResExpertSurvey> {
            return localVarFp.getSurveyExpert(requestParameters.memberSurveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 부모 설문 조회
         * @param {SurveyApiGetSurveyParentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyParent(requestParameters: SurveyApiGetSurveyParentRequest, options?: AxiosRequestConfig): AxiosPromise<ResParentSurvey> {
            return localVarFp.getSurveyParent(requestParameters.memberSurveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyStudent(options?: AxiosRequestConfig): AxiosPromise<ResStudentSurvey> {
            return localVarFp.getSurveyStudent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 설문 조회
         * @param {SurveyApiGetSurveyStudent1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyStudent1(requestParameters: SurveyApiGetSurveyStudent1Request, options?: AxiosRequestConfig): AxiosPromise<ResStudentSurvey> {
            return localVarFp.getSurveyStudent1(requestParameters.memberSurveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 설문기본정보 조회(설문 등록 이력이 없을 경우 조회)-설문전에 mypond에서 사용자 정보를 입력했을 경우 해당 값을 사용하기 위해 사용하는 api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTempProfile(options?: AxiosRequestConfig): AxiosPromise<ResMemberSurveyTempProfile> {
            return localVarFp.getTempProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 부모 설문 작성
         * @param {SurveyApiParentSurveyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentSurveyCreate(requestParameters: SurveyApiParentSurveyCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ResParentSurvey> {
            return localVarFp.parentSurveyCreate(requestParameters.reqParentSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 설문 삭제
         * @param {SurveyApiRemoveSurveyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSurvey(requestParameters: SurveyApiRemoveSurveyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeSurvey(requestParameters.surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 설문 작성
         * @param {SurveyApiStudentSurveyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSurveyCreate(requestParameters: SurveyApiStudentSurveyCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ResStudentSurvey> {
            return localVarFp.studentSurveyCreate(requestParameters.reqStudentSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 학생 설문 수정
         * @param {SurveyApiStudentSurveyUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSurveyUpdate(requestParameters: SurveyApiStudentSurveyUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.studentSurveyUpdate(requestParameters.surveyId, requestParameters.reqStudentSurveyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for expertSurveyCreate operation in SurveyApi.
 * @export
 * @interface SurveyApiExpertSurveyCreateRequest
 */
export interface SurveyApiExpertSurveyCreateRequest {
    /**
     * 
     * @type {ReqExpertSurvey}
     * @memberof SurveyApiExpertSurveyCreate
     */
    readonly reqExpertSurvey: ReqExpertSurvey
}

/**
 * Request parameters for getSurveyExpert operation in SurveyApi.
 * @export
 * @interface SurveyApiGetSurveyExpertRequest
 */
export interface SurveyApiGetSurveyExpertRequest {
    /**
     * 
     * @type {number}
     * @memberof SurveyApiGetSurveyExpert
     */
    readonly memberSurveyId: number
}

/**
 * Request parameters for getSurveyParent operation in SurveyApi.
 * @export
 * @interface SurveyApiGetSurveyParentRequest
 */
export interface SurveyApiGetSurveyParentRequest {
    /**
     * 
     * @type {number}
     * @memberof SurveyApiGetSurveyParent
     */
    readonly memberSurveyId: number
}

/**
 * Request parameters for getSurveyStudent1 operation in SurveyApi.
 * @export
 * @interface SurveyApiGetSurveyStudent1Request
 */
export interface SurveyApiGetSurveyStudent1Request {
    /**
     * 
     * @type {number}
     * @memberof SurveyApiGetSurveyStudent1
     */
    readonly memberSurveyId: number
}

/**
 * Request parameters for parentSurveyCreate operation in SurveyApi.
 * @export
 * @interface SurveyApiParentSurveyCreateRequest
 */
export interface SurveyApiParentSurveyCreateRequest {
    /**
     * 
     * @type {ReqParentSurvey}
     * @memberof SurveyApiParentSurveyCreate
     */
    readonly reqParentSurvey: ReqParentSurvey
}

/**
 * Request parameters for removeSurvey operation in SurveyApi.
 * @export
 * @interface SurveyApiRemoveSurveyRequest
 */
export interface SurveyApiRemoveSurveyRequest {
    /**
     * 
     * @type {number}
     * @memberof SurveyApiRemoveSurvey
     */
    readonly surveyId: number
}

/**
 * Request parameters for studentSurveyCreate operation in SurveyApi.
 * @export
 * @interface SurveyApiStudentSurveyCreateRequest
 */
export interface SurveyApiStudentSurveyCreateRequest {
    /**
     * 
     * @type {ReqStudentSurvey}
     * @memberof SurveyApiStudentSurveyCreate
     */
    readonly reqStudentSurvey: ReqStudentSurvey
}

/**
 * Request parameters for studentSurveyUpdate operation in SurveyApi.
 * @export
 * @interface SurveyApiStudentSurveyUpdateRequest
 */
export interface SurveyApiStudentSurveyUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SurveyApiStudentSurveyUpdate
     */
    readonly surveyId: number

    /**
     * 
     * @type {ReqStudentSurveyUpdate}
     * @memberof SurveyApiStudentSurveyUpdate
     */
    readonly reqStudentSurveyUpdate: ReqStudentSurveyUpdate
}

/**
 * SurveyApi - object-oriented interface
 * @export
 * @class SurveyApi
 * @extends {BaseAPI}
 */
export class SurveyApi extends BaseAPI {
    /**
     * 
     * @summary 전문가 설문 작성
     * @param {SurveyApiExpertSurveyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public expertSurveyCreate(requestParameters: SurveyApiExpertSurveyCreateRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).expertSurveyCreate(requestParameters.reqExpertSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전문가 설문 조회
     * @param {SurveyApiGetSurveyExpertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public getSurveyExpert(requestParameters: SurveyApiGetSurveyExpertRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).getSurveyExpert(requestParameters.memberSurveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 부모 설문 조회
     * @param {SurveyApiGetSurveyParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public getSurveyParent(requestParameters: SurveyApiGetSurveyParentRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).getSurveyParent(requestParameters.memberSurveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 설문 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public getSurveyStudent(options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).getSurveyStudent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 설문 조회
     * @param {SurveyApiGetSurveyStudent1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public getSurveyStudent1(requestParameters: SurveyApiGetSurveyStudent1Request, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).getSurveyStudent1(requestParameters.memberSurveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 설문기본정보 조회(설문 등록 이력이 없을 경우 조회)-설문전에 mypond에서 사용자 정보를 입력했을 경우 해당 값을 사용하기 위해 사용하는 api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public getTempProfile(options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).getTempProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 부모 설문 작성
     * @param {SurveyApiParentSurveyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public parentSurveyCreate(requestParameters: SurveyApiParentSurveyCreateRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).parentSurveyCreate(requestParameters.reqParentSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 설문 삭제
     * @param {SurveyApiRemoveSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public removeSurvey(requestParameters: SurveyApiRemoveSurveyRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).removeSurvey(requestParameters.surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 설문 작성
     * @param {SurveyApiStudentSurveyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public studentSurveyCreate(requestParameters: SurveyApiStudentSurveyCreateRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).studentSurveyCreate(requestParameters.reqStudentSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 학생 설문 수정
     * @param {SurveyApiStudentSurveyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public studentSurveyUpdate(requestParameters: SurveyApiStudentSurveyUpdateRequest, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).studentSurveyUpdate(requestParameters.surveyId, requestParameters.reqStudentSurveyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


